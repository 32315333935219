import React, { Component } from 'react';
import 'react-select/dist/react-select.css';
import meterInfoClient from './MeterInfoClient';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Header from './components/header';
import { withRouter } from './common/withRouter';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography/Typography';
import { DatePicker } from '@mui/lab';
import withStyles from '@mui/styles/withStyles';
import { withTranslation } from 'react-i18next';

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(2),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
});

class MeterInfo extends Component {
  constructor(props) {
    super(props);
    this.state = { meterInfo: null, open: false };
    this.loadMeterInfoFromServer = this.loadMeterInfoFromServer.bind(this);
    this.handleFactoryNumberChange = this.handleFactoryNumberChange.bind(this);
    this.handleBrandChange = this.handleBrandChange.bind(this);
    this.handleDiameterChange = this.handleDiameterChange.bind(this);
    this.handleQ3Change = this.handleQ3Change.bind(this);
    this.handleRChange = this.handleRChange.bind(this);
    this.handleMountDateChange = this.handleMountDateChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleConfigSaved = this.handleConfigSaved.bind(this);
    this.handleRequestClose = this.handleRequestClose.bind(this);
    this.errorCallback = this.errorCallback.bind(this);
  }

  errorCallback(error) {
    if (error.response === undefined) {
      throw error;
    }
    if (error.response.status === 401 || error.response.status === 403) {
      this.props.history.push('/app/signout');
    } else if (error.response.data) {
      if (error.response.data.message) {
        alert('Error: ' + error.response.data.message);
      } else {
        alert('Error: ' + error.response.data);
      }
    }
  }

  handleFactoryNumberChange = (event) =>
    this.setState({ meterInfo: { ...this.state.meterInfo, factoryNumber: event.target.value } });
  handleBrandChange = (event) =>
    this.setState({ meterInfo: { ...this.state.meterInfo, brand: event.target.value } });
  handleDiameterChange = (event) =>
    this.setState({ meterInfo: { ...this.state.meterInfo, diameter: event.target.value } });
  handleQ3Change = (event) =>
    this.setState({ meterInfo: { ...this.state.meterInfo, q3: event.target.value } });
  handleRChange = (event) =>
    this.setState({ meterInfo: { ...this.state.meterInfo, r: event.target.value } });
  handleMountDateChange = (date) =>
    this.setState({ meterInfo: { ...this.state.meterInfo, mountDate: date } });

  handleRequestClose = () => {
    this.setState({ open: false });
  };

  handleSubmit(event) {
    this.setState({ saveDisabled: true });
    meterInfoClient.putMeterInfo(
      this.props.match.params.deviceNumber,
      this.state.meterInfo,
      this.handleConfigSaved,
      this.errorCallback
    );
    event.preventDefault();
  }

  handleConfigSaved() {
    this.setState({ open: true, saveDisabled: false });
  }

  render() {
    const { classes } = this.props;
    const wellStyles = { maxWidth: 400, margin: '0 auto 10px' };
    return (
      <Header>
        <div className="well" style={wellStyles}>
          <form>
            <Grid container justifyContent={'center'}>
              <Grid item>
                <Typography variant="h6" component="h5" paragraph>
                  {this.props.t('meter_info_meter_info', {
                    device_number: this.props.match.params.deviceNumber,
                  })}
                </Typography>
              </Grid>
              {this.state.meterInfo == null && <CircularProgress size={80} thickness={5} />}
              {this.state.meterInfo != null && (
                <React.Fragment>
                  <Grid item>
                    <TextField
                      id="standard-name"
                      label={this.props.t('meter_info_factory_number')}
                      className={classes.textField}
                      value={this.state.meterInfo.factoryNumber}
                      onChange={this.handleFactoryNumberChange}
                      margin="normal"
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      id="standard-name"
                      label={this.props.t('meter_info_brand')}
                      className={classes.textField}
                      value={this.state.meterInfo.brand}
                      onChange={this.handleBrandChange}
                      margin="normal"
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      id="standard-name"
                      label={this.props.t('meter_info_enter_diameter')}
                      className={classes.textField}
                      value={this.state.meterInfo.diameter}
                      onChange={this.handleDiameterChange}
                      margin="normal"
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      id="standard-name"
                      label="Q3"
                      className={classes.textField}
                      value={this.state.meterInfo.q3}
                      onChange={this.handleQ3Change}
                      margin="normal"
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      id="standard-name"
                      label="R"
                      className={classes.textField}
                      value={this.state.meterInfo.r}
                      onChange={this.handleRChange}
                      margin="normal"
                    />
                  </Grid>
                  <Grid item>
                    <DatePicker
                      label={this.props.t('meter_info_mount_date')}
                      autoOk
                      variant="inline"
                      className={classes.textField}
                      value={this.state.meterInfo.mountDate}
                      onChange={(date) => this.handleMountDateChange(date)}
                      format="dd/MM/yyyy"
                      renderInput={(params) => <TextField size="small" {...params} />}
                    />
                  </Grid>
                  <Grid container alignItems="flex-end" justifyContent={'center'} spacing={4}>
                    <Grid item>
                      <Button
                        variant="contained"
                        className={classes.margin}
                        onClick={this.handleSubmit}
                        disabled={this.state.saveDisabled}
                      >
                        {this.props.t('button_save')}
                      </Button>
                    </Grid>
                  </Grid>
                </React.Fragment>
              )}
            </Grid>
          </form>
          <Snackbar
            open={this.state.open}
            message={this.props.t('meter_info_meter_info_saved')}
            autoHideDuration={4000}
            onClose={this.handleRequestClose}
          />
        </div>
      </Header>
    );
  }

  componentDidMount() {
    this.loadMeterInfoFromServer();
  }

  componentWillUnmount() {}

  loadMeterInfoFromServer() {
    meterInfoClient.getMeterInfo(
      this.props.match.params.deviceNumber,
      (meterInfo) => {
        if (meterInfo === null || meterInfo === '') {
          meterInfo = {
            factoryNumber: '',
            brand: '',
            diameter: '',
            q3: '',
            r: '',
            mountDate: '',
          };
        }
        if (meterInfo.mountDate !== null && meterInfo.mountDate !== '')
          meterInfo.mountDate = new Date(meterInfo.mountDate);
        this.setState({
          meterInfo: meterInfo,
        });
      },
      this.errorCallback
    );
  }
}

export default withRouter(withStyles(styles)(withTranslation()(MeterInfo)));
