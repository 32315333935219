import React, { FC, ReactElement } from 'react';

import { useTranslation } from 'react-i18next';
import { UseFormReturn } from 'react-hook-form';
import { TextField } from '@mui/material';

import GenericAnalogConfig from './GenericAnalogConfig';
import InitialConfigFormFields from '../../models/InitialConfigFormFields';
import ConnectForm from '../../../../common/reactHookForm/ConnectForm';
import { FormulaValidationResult } from '@thingslog/repositories';
import { HelperTextInputField } from '@thingslog/ui-components';

const ModbusGenericAnalogConfig: FC<ModbusGenericAnalogConfigProps> = ({
  index,
  validateFormula,
}: ModbusGenericAnalogConfigProps) => {
  const { t } = useTranslation();

  return (
    <ConnectForm<InitialConfigFormFields>>
      {({ register }: UseFormReturn<InitialConfigFormFields>): ReactElement => (
        <>
          <HelperTextInputField
            {...register(`ports.${index}.sensor.registerAddress`)}
            label={t('device_initial_config_modbus_address')}
            size="small"
          />

          <GenericAnalogConfig index={index} validateFormula={validateFormula} />
        </>
      )}
    </ConnectForm>
  );
};

interface ModbusGenericAnalogConfigProps {
  index: number;
  validateFormula: (formula: string) => Promise<FormulaValidationResult>;
}

export default ModbusGenericAnalogConfig;
