export const analogSensorTypeOptions: SensorTypeOption[] = [
  { value: 'generic_analog_sensor', label: 'generic_analog_sensor' },
  { value: 'generic_virtual_sensor', label: 'generic_virtual_sensor' },
  { value: 'pressure_sensor', label: 'pressure_sensor' },
  { value: 'temperature_sensor', label: 'temperature_sensor' },
  { value: 'humidity_sensor', label: 'humidity_sensor' },
  { value: 'level_sensor', label: 'level_sensor' },
  { value: 'weight_meter', label: 'weight_meter' },
  { value: 'weight_sensor', label: 'weight_sensor' },
  { value: 'load_cells', label: 'load_cells' },
  { value: 'wind_direction_sensor', label: 'wind_direction_sensor' },
  { value: 'barometric_pressure_sensor', label: 'barometric_pressure_sensor' },
  { value: 'soil_moisture_sensor', label: 'soil_moisture_sensor' },
  { value: 'dew_point_sensor', label: 'dew_point_sensor' },
  { value: 'soil_temperature_sensor', label: 'soil_temperature_sensor' },
  { value: 'soil_potential_sensor', label: 'soil_potential_sensor' },
  { value: 'particulate_matter_sensor', label: 'particulate_matter_sensor' },
  { value: 'leaf_wetness_sensor', label: 'leaf_wetness_sensor' },
];

export interface SensorTypeOption {
  value: string;
  label: string;
}
