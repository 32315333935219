import React, { Component, Fragment } from 'react';
import 'react-select/dist/react-select.css';
import sensorsConfigClient from './SensorsConfigClient';
import deviceConfigClient from './DeviceConfigClient';
import alarmConfigClient from './AlarmConfigClient';
import devicesClient from './DevicesClient';
import { FormGroup, ControlLabel, FormControl } from 'react-bootstrap';
import connect from 'react-redux/es/connect/connect';
import * as actions from './actions';
import { withTranslation } from 'react-i18next';
import {
  CircularProgress,
  Typography,
  TextField,
  Snackbar,
  Button,
  Switch,
  Box,
} from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import EmailIcon from '@mui/icons-material/Email';
import GoogleAnalyticsService from './common/GoogleAnalyticsService';
import { GaEventCategory } from './common/GaEventCategory';
import { GaEventAction } from './common/GaEventAction';
import { withRouter } from './common/withRouter';
import NotificationCheckbox from './components/NotificationCheckbox/NotificationCheckbox';
import TitleHeader from './components/TitleHeader/TitleHeader';
import Header from './components/header';
import { Units } from './model/Units/Units';

const digitalSensorIndexes = [0, 1];

class AlarmConfig extends Component {
  constructor(props) {
    super(props);
    const { t } = props;
    this.state = {
      sensor1Name: t('pulse_sensor_1'),
      sensor2Name: t('pulse_sensor_2'),
      sensor3Name: t('analog_sensor_1'),
      sensor4Name: t('analog_sensor_2'),
      sensor5Name: t('on_off_sensor_1'),
      sensor6Name: t('on_off_sensor_2'),
      sensor7Name: t('on_off_sensor_3'),
      sensor8Name: t('on_off_sensor_4'),
      sensor9Name: t('analog_sensor_3'),
      sensor10Name: t('analog_sensor_4'),
      sensor11Name: t('analog_sensor_5'),
      sensor12Name: t('analog_sensor_6'),
      sensor13Name: t('analog_sensor_7'),
      sensor14Name: t('analog_sensor_8'),
      sensor1Enabeled: false,
      sensor2Enabeled: false,
      sensor3Enabeled: false,
      sensor4Enabeled: false,
      sensor5Enabeled: false,
      sensor6Enabeled: false,
      sensor7Exists: false,
      sensor7Enabled: false,
      sensor8Exists: false,
      sensor8Enabled: false,
      sensor9Exists: false,
      sensor9Enabled: false,
      sensor10Exists: false,
      sensor10Enabled: false,
      sensor11Exists: false,
      sensor11Enabled: false,
      sensor12Exists: false,
      sensor12Enabled: false,
      sensor13Exists: false,
      sensor13Enabled: false,
      sensor14Exists: false,
      sensor14Enabled: false,
      sensor1LowFlowAlarmThreshold: 0,
      sensor2LowFlowAlarmThreshold: 0,
      sensor1HighFlowAlarmThreshold: 0,
      sensor2HighFlowAlarmThreshold: 0,
      sensor1HighConsumptionThreshold: 0,
      sensor2HighConsumptionThreshold: 0,
      sensor1HighLowFlowStartTime: null,
      sensor1HighLowFlowEndTime: null,
      sensor2HighLowFlowStartTime: null,
      sensor2HighLowFlowEndTime: null,
      sensor1NonZeroConsumptionStartTime: null,
      sensor1NonZeroConsumptionEndTime: null,
      sensor2NonZeroConsumptionStartTime: null,
      sensor2NonZeroConsumptionEndTime: null,

      sensor1HighConsumptionEmailNotificationEnabled: false,
      sensor1HighConsumptionMobileNotificationEnabled: false,
      sensor2HighConsumptionEmailNotificationEnabled: false,
      sensor2HighConsumptionMobileNotificationEnabled: false,

      sensor1HighFlowEmailNotificationEnabled: false,
      sensor1HighFlowMobileNotificationEnabled: false,
      sensor2HighFlowEmailNotificationEnabled: false,
      sensor2HighFlowMobileNotificationEnabled: false,

      sensor1LowFlowEmailNotificationEnabled: false,
      sensor1LowFlowMobileNotificationEnabled: false,
      sensor2LowFlowEmailNotificationEnabled: false,
      sensor2LowFlowMobileNotificationEnabled: false,

      sensor1NonZeroConsumptionEmailNotificationEnabled: false,
      sensor1NonZeroConsumptionMobileNotificationEnabled: false,
      sensor2NonZeroConsumptionEmailNotificationEnabled: false,
      sensor2NonZeroConsumptionMobileNotificationEnabled: false,

      isSensor1NonZeroConsumptionRangeValid: true,
      isSensor2NonZeroConsumptionRangeValid: true,

      sensor1LowFlowSeverity: null,
      sensor1HighFlowSeverity: null,
      sensor1HighConsumptionSeverity: null,
      sensor1AllZeroSeverity: null,
      sensor1NonZeroConsumptionSeverity: null,
      sensor1OnOffSeverity: null,

      sensor2LowFlowSeverity: null,
      sensor2HighFlowSeverity: null,
      sensor2HighConsumptionSeverity: null,
      sensor2AllZeroSeverity: null,
      sensor2NonZeroConsumptionSeverity: null,
      sensor2OnOffSeverity: null,

      sensor3LowFlowSeverity: null,
      sensor3HighFlowSeverity: null,
      sensor3HighConsumptionSeverity: null,
      sensor3AllZeroSeverity: null,
      sensor3NonZeroConsumptionSeverity: null,
      sensor3OnOffSeverity: null,

      sensor4LowFlowSeverity: null,
      sensor4HighFlowSeverity: null,
      sensor4HighConsumptionSeverity: null,
      sensor4AllZeroSeverity: null,
      sensor4NonZeroConsumptionSeverity: null,
      sensor4OnOffSeverity: null,

      sensor5LowFlowSeverity: null,
      sensor5HighFlowSeverity: null,
      sensor5HighConsumptionSeverity: null,
      sensor5AllZeroSeverity: null,
      sensor5NonZeroConsumptionSeverity: null,
      sensor5OnOffSeverity: null,

      sensor6LowFlowSeverity: null,
      sensor6HighFlowSeverity: null,
      sensor6HighConsumptionSeverity: null,
      sensor6AllZeroSeverity: null,
      sensor6NonZeroConsumptionSeverity: null,
      sensor6OnOffSeverity: null,

      sensor7LowFlowSeverity: null,
      sensor7HighFlowSeverity: null,
      sensor7HighConsumptionSeverity: null,
      sensor7AllZeroSeverity: null,
      sensor7NonZeroConsumptionSeverity: null,
      sensor7OnOffSeverity: null,

      sensor8LowFlowSeverity: null,
      sensor8HighFlowSeverity: null,
      sensor8HighConsumptionSeverity: null,
      sensor8AllZeroSeverity: null,
      sensor8NonZeroConsumptionSeverity: null,
      sensor8OnOffSeverity: null,

      sensor9LowFlowSeverity: null,
      sensor9HighFlowSeverity: null,
      sensor9HighConsumptionSeverity: null,
      sensor9AllZeroSeverity: null,
      sensor9NonZeroConsumptionSeverity: null,
      sensor9OnOffSeverity: null,

      sensor10LowFlowSeverity: null,
      sensor10HighFlowSeverity: null,
      sensor10HighConsumptionSeverity: null,
      sensor10AllZeroSeverity: null,
      sensor10NonZeroConsumptionSeverity: null,
      sensor10OnOffSeverity: null,

      sensor11LowFlowSeverity: null,
      sensor11HighFlowSeverity: null,
      sensor11HighConsumptionSeverity: null,
      sensor11AllZeroSeverity: null,
      sensor11NonZeroConsumptionSeverity: null,
      sensor11OnOffSeverity: null,

      sensor12LowFlowSeverity: null,
      sensor12HighFlowSeverity: null,
      sensor12HighConsumptionSeverity: null,
      sensor12AllZeroSeverity: null,
      sensor12NonZeroConsumptionSeverity: null,
      sensor12OnOffSeverity: null,

      sensor13LowFlowSeverity: null,
      sensor13HighFlowSeverity: null,
      sensor13HighConsumptionSeverity: null,
      sensor13AllZeroSeverity: null,
      sensor13NonZeroConsumptionSeverity: null,
      sensor13OnOffSeverity: null,

      sensor14LowFlowSeverity: null,
      sensor14HighFlowSeverity: null,
      sensor14HighConsumptionSeverity: null,
      sensor14AllZeroSeverity: null,
      sensor14NonZeroConsumptionSeverity: null,
      sensor14OnOffSeverity: null,

      transmissionEvery: 1,
      transmissionPeriod: 'MINUTES',
      confEvery: 1,
      sensor1FlowTimeBase: 1,
      sensor2FlowTimeBase: 1,
      notifEmails: '',
      missedTransmissionEmails: '',
      deviceName: null,
      customerInfo: null,
      open: false,
      loaded: false,
    };
    this.handleSensor1LowFlowAlarmThresholdChange =
      this.handleSensor1LowFlowAlarmThresholdChange.bind(this);
    this.handleSensor2LowFlowAlarmThresholdChange =
      this.handleSensor2LowFlowAlarmThresholdChange.bind(this);

    this.handleSensor1HighFlowAlarmThresholdChange =
      this.handleSensor1HighFlowAlarmThresholdChange.bind(this);
    this.handleSensor2HighFlowAlarmThresholdChange =
      this.handleSensor2HighFlowAlarmThresholdChange.bind(this);

    this.handleSensor1HighConsumptionThresholdChange =
      this.handleSensor1HighConsumptionThresholdChange.bind(this);
    this.handleSensor2HighConsumptionThresholdChange =
      this.handleSensor2HighConsumptionThresholdChange.bind(this);

    this.handleSensor1NonZeroConsumptionStartTimeChange =
      this.handleSensor1NonZeroConsumptionStartTimeChange.bind(this);
    this.handleSensor2NonZeroConsumptionStartTimeChange =
      this.handleSensor2NonZeroConsumptionStartTimeChange.bind(this);

    this.handleSensor1NonZeroConsumptionEndTimeChange =
      this.handleSensor1NonZeroConsumptionEndTimeChange.bind(this);
    this.handleSensor2NonZeroConsumptionEndTimeChange =
      this.handleSensor2NonZeroConsumptionEndTimeChange.bind(this);

    this.handleSensor1HighLowFlowStartTimeChange =
      this.handleSensor1HighLowFlowStartTimeChange.bind(this);
    this.handleSensor1HighLowFlowEndTimeChange =
      this.handleSensor1HighLowFlowEndTimeChange.bind(this);

    this.handleSensor2HighLowFlowStartTimeChange =
      this.handleSensor2HighLowFlowStartTimeChange.bind(this);
    this.handleSensor2HighLowFlowEndTimeChange =
      this.handleSensor2HighLowFlowEndTimeChange.bind(this);

    this.handleSensor1FlowTImeBaseChange = this.handleSensor1FlowTImeBaseChange.bind(this);
    this.handleSensor2FlowTImeBaseChange = this.handleSensor2FlowTImeBaseChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.loadDeviceConfigFromServer = this.loadDeviceConfigFromServer.bind(this);
    this.handleConfigSaved = this.handleConfigSaved.bind(this);
    this.handleRequestClose = this.handleRequestClose.bind(this);
    this.errorCallback = this.errorCallback.bind(this);
    this.toTimeString = this.toTimeString.bind(this);
    this.timeToDate = this.timeToDate.bind(this);
    this.updateSensorAlarmConfigList = this.updateSensorAlarmConfigList.bind(this);

    this.handlerSensor1HighConsumptionEmailNotificationEnabled =
      this.handlerSensor1HighConsumptionEmailNotificationEnabled.bind(this);
    this.handlerSensor1HighConsumptionMobileNotificationEnabled =
      this.handlerSensor1HighConsumptionMobileNotificationEnabled.bind(this);
    this.handlerSensor2HighConsumptionEmailNotificationEnabled =
      this.handlerSensor2HighConsumptionEmailNotificationEnabled.bind(this);
    this.handlerSensor2HighConsumptionMobileNotificationEnabled =
      this.handlerSensor2HighConsumptionMobileNotificationEnabled.bind(this);

    this.handlerSensor1LowFlowEmailNotificationEnabled =
      this.handlerSensor1LowFlowEmailNotificationEnabled.bind(this);
    this.handlerSensor1LowFlowMobileNotificationEnabled =
      this.handlerSensor1LowFlowMobileNotificationEnabled.bind(this);
    this.handlerSensor2LowFlowEmailNotificationEnabled =
      this.handlerSensor2LowFlowEmailNotificationEnabled.bind(this);
    this.handlerSensor2LowFlowMobileNotificationEnabled =
      this.handlerSensor2LowFlowMobileNotificationEnabled.bind(this);

    this.handlerSensor1HighFlowEmailNotificationEnabled =
      this.handlerSensor1HighFlowEmailNotificationEnabled.bind(this);
    this.handlerSensor1HighFlowMobileNotificationEnabled =
      this.handlerSensor1HighFlowMobileNotificationEnabled.bind(this);
    this.handlerSensor2HighFlowEmailNotificationEnabled =
      this.handlerSensor2HighFlowEmailNotificationEnabled.bind(this);
    this.handlerSensor2HighFlowMobileNotificationEnabled =
      this.handlerSensor2HighFlowMobileNotificationEnabled.bind(this);

    this.handlerSensor1NonZeroConsumptionEmailNotificationEnabled =
      this.handlerSensor1NonZeroConsumptionEmailNotificationEnabled.bind(this);
    this.handlerSensor1NonZeroConsumptionMobileNotificationEnabled =
      this.handlerSensor1NonZeroConsumptionMobileNotificationEnabled.bind(this);
    this.handlerSensor2NonZeroConsumptionEmailNotificationEnabled =
      this.handlerSensor2NonZeroConsumptionEmailNotificationEnabled.bind(this);
    this.handlerSensor2NonZeroConsumptionMobileNotificationEnabled =
      this.handlerSensor2NonZeroConsumptionMobileNotificationEnabled.bind(this);

    this.getPortsNotificationsConfig = this.getPortsNotificationsConfig.bind(this);
    this.validateTimeRange = this.validateTimeRange.bind(this);
    this.handleMissedTransmissionEmailsChange =
      this.handleMissedTransmissionEmailsChange.bind(this);
    this.getMissedTransmissionEmail = this.getMissedTransmissionEmail.bind(this);
  }

  isFeatureFlagOn = (flagName) => {
    return this.props.featureFlags?.featureFlagsArray?.includes(flagName);
  };

  errorCallback(error) {
    if (error.response === undefined) {
      throw error;
    }
    if (error.response.status === 401 || error.response.status === 403) {
      this.props.history.push('/app/signout');
    } else if (error.response.data) {
      if (error.response.data.message) {
        alert('Error: ' + error.response.data.message);
      } else {
        alert('Error: ' + error.response.data);
      }
    }
  }

  toTimeString(d) {
    if (d === undefined || d === null) return '';
    return ('0' + d.getHours()).slice(-2) + ':' + ('0' + d.getMinutes()).slice(-2);
  }

  handleSubmit(event) {
    GoogleAnalyticsService.triggerEvent(
      GaEventCategory.ALARM_CONFIG_PAGE,
      GaEventAction.ALARM_CONFIG_SAVE
    );
    this.setState({ saveDisabled: true });
    let sensorConfigs = [];

    if (this.state.sensor1Enabeled) {
      sensorConfigs.push({
        index: 0,
        enabled: this.state.sensor1Enabeled,
        parameters: {
          low_flow_alarm_threshold: this.state.sensor1LowFlowAlarmThreshold,
          high_flow_alarm_threshold: this.state.sensor1HighFlowAlarmThreshold,
          flow_time_base: this.state.sensor1FlowTimeBase,
          high_consumption_threshold: this.state.sensor1HighConsumptionThreshold,
          notif_emails: this.state.notifEmails,
          missed_transmission_emails: this.state.missedTransmissionEmails,
          non_zero_consumption_start_time: this.toTimeString(
            this.state.sensor1NonZeroConsumptionStartTime
          ),
          non_zero_consumption_end_time: this.toTimeString(
            this.state.sensor1NonZeroConsumptionEndTime
          ),
          high_low_flow_start_time: this.toTimeString(this.state.sensor1HighLowFlowStartTime),
          high_low_flow_end_time: this.toTimeString(this.state.sensor1HighLowFlowEndTime),
          low_flow_severity:
            this.state.sensor1LowFlowSeverity !== null
              ? this.state.sensor1LowFlowSeverity.toString()
              : undefined,
          high_flow_severity:
            this.state.sensor1HighFlowSeverity !== null
              ? this.state.sensor1HighFlowSeverity.toString()
              : undefined,
          high_consumption_severity:
            this.state.sensor1HighConsumptionSeverity !== null
              ? this.state.sensor1HighConsumptionSeverity.toString()
              : undefined,
          all_zero_consumption_severity:
            this.state.sensor1AllZeroSeverity !== null
              ? this.state.sensor1AllZeroSeverity.toString()
              : undefined,
          non_zero_consumption_severity:
            this.state.sensor1NonZeroConsumptionSeverity !== null
              ? this.state.sensor1NonZeroConsumptionSeverity.toString()
              : undefined,
          on_off_severity:
            this.state.sensor1OnOffSeverity !== null
              ? this.state.sensor1OnOffSeverity.toString()
              : undefined,
        },
      });
    } else {
      sensorConfigs.push({
        index: 0,
        enabled: this.state.sensor1Enabeled,
        parameters: {
          notif_emails: this.state.notifEmails,
          missed_transmission_emails: this.state.missedTransmissionEmails,
        },
      });
    }

    if (this.state.sensor2Enabeled) {
      sensorConfigs.push({
        index: 1,
        enabled: this.state.sensor2Enabeled,
        parameters: {
          low_flow_alarm_threshold: this.state.sensor2LowFlowAlarmThreshold,
          high_flow_alarm_threshold: this.state.sensor2HighFlowAlarmThreshold,
          flow_time_base: this.state.sensor2FlowTimeBase,
          high_consumption_threshold: this.state.sensor2HighConsumptionThreshold,
          notif_emails: this.state.notifEmails,
          missed_transmission_emails: this.state.missedTransmissionEmails,
          non_zero_consumption_start_time: this.toTimeString(
            this.state.sensor2NonZeroConsumptionStartTime
          ),
          non_zero_consumption_end_time: this.toTimeString(
            this.state.sensor2NonZeroConsumptionEndTime
          ),
          high_low_flow_start_time: this.toTimeString(this.state.sensor2HighLowFlowStartTime),
          high_low_flow_end_time: this.toTimeString(this.state.sensor2HighLowFlowEndTime),
          low_flow_severity:
            this.state.sensor2LowFlowSeverity !== null
              ? this.state.sensor2LowFlowSeverity.toString()
              : undefined,
          high_flow_severity:
            this.state.sensor2HighFlowSeverity !== null
              ? this.state.sensor2HighFlowSeverity.toString()
              : undefined,
          high_consumption_severity:
            this.state.sensor2HighConsumptionSeverity !== null
              ? this.state.sensor2HighConsumptionSeverity.toString()
              : undefined,
          all_zero_consumption_severity:
            this.state.sensor2AllZeroSeverity !== null
              ? this.state.sensor2AllZeroSeverity.toString()
              : undefined,
          non_zero_consumption_severity:
            this.state.sensor2NonZeroConsumptionSeverity !== null
              ? this.state.sensor2NonZeroConsumptionSeverity.toString()
              : undefined,
          on_off_severity:
            this.state.sensor2OnOffSeverity !== null
              ? this.state.sensor2OnOffSeverity.toString()
              : undefined,
        },
      });
    } else {
      sensorConfigs.push({
        index: 1,
        enabled: this.state.sensor2Enabeled,
        parameters: {
          notif_emails: this.state.notifEmails,
          missed_transmission_emails: this.state.missedTransmissionEmails,
        },
      });
    }

    if (this.state.sensor3Enabeled) {
      sensorConfigs.push({
        index: 2,
        enabled: this.state.sensor3Enabeled,
        parameters: {
          notif_emails: this.state.notifEmails,
          missed_transmission_emails: this.state.missedTransmissionEmails,
          low_flow_severity:
            this.state.sensor3LowFlowSeverity !== null
              ? this.state.sensor3LowFlowSeverity.toString()
              : undefined,
          high_flow_severity:
            this.state.sensor3HighFlowSeverity !== null
              ? this.state.sensor3HighFlowSeverity.toString()
              : undefined,
          high_consumption_severity:
            this.state.sensor3HighConsumptionSeverity !== null
              ? this.state.sensor3HighConsumptionSeverity.toString()
              : undefined,
          all_zero_consumption_severity:
            this.state.sensor3AllZeroSeverity !== null
              ? this.state.sensor3AllZeroSeverity.toString()
              : undefined,
          non_zero_consumption_severity:
            this.state.sensor3NonZeroConsumptionSeverity !== null
              ? this.state.sensor3NonZeroConsumptionSeverity.toString()
              : undefined,
          on_off_severity:
            this.state.sensor3OnOffSeverity !== null
              ? this.state.sensor3OnOffSeverity.toString()
              : undefined,
        },
      });
    } else {
      sensorConfigs.push({
        index: 2,
        enabled: this.state.sensor3Enabeled,
        parameters: {
          notif_emails: this.state.notifEmails,
          missed_transmission_emails: this.state.missedTransmissionEmails,
        },
      });
    }

    if (this.state.sensor4Enabeled) {
      sensorConfigs.push({
        index: 3,
        enabled: this.state.sensor4Enabeled,
        parameters: {
          notif_emails: this.state.notifEmails,
          missed_transmission_emails: this.state.missedTransmissionEmails,
          low_flow_severity:
            this.state.sensor4LowFlowSeverity !== null
              ? this.state.sensor4LowFlowSeverity.toString()
              : undefined,
          high_flow_severity:
            this.state.sensor4HighFlowSeverity !== null
              ? this.state.sensor4HighFlowSeverity.toString()
              : undefined,
          high_consumption_severity:
            this.state.sensor4HighConsumptionSeverity !== null
              ? this.state.sensor4HighConsumptionSeverity.toString()
              : undefined,
          all_zero_consumption_severity:
            this.state.sensor4AllZeroSeverity !== null
              ? this.state.sensor4AllZeroSeverity.toString()
              : undefined,
          non_zero_consumption_severity:
            this.state.sensor4NonZeroConsumptionSeverity !== null
              ? this.state.sensor4NonZeroConsumptionSeverity.toString()
              : undefined,
          on_off_severity:
            this.state.sensor4OnOffSeverity !== null
              ? this.state.sensor4OnOffSeverity.toString()
              : undefined,
        },
      });
    } else {
      sensorConfigs.push({
        index: 3,
        enabled: this.state.sensor4Enabeled,
        parameters: {
          notif_emails: this.state.notifEmails,
          missed_transmission_emails: this.state.missedTransmissionEmails,
        },
      });
    }

    if (this.state.sensor5Enabeled) {
      sensorConfigs.push({
        index: 4,
        enabled: this.state.sensor5Enabeled,
        parameters: {
          notif_emails: this.state.notifEmails,
          missed_transmission_emails: this.state.missedTransmissionEmails,
          low_flow_severity:
            this.state.sensor5LowFlowSeverity !== null
              ? this.state.sensor5LowFlowSeverity.toString()
              : undefined,
          high_flow_severity:
            this.state.sensor5HighFlowSeverity !== null
              ? this.state.sensor5HighFlowSeverity.toString()
              : undefined,
          high_consumption_severity:
            this.state.sensor5HighConsumptionSeverity !== null
              ? this.state.sensor5HighConsumptionSeverity.toString()
              : undefined,
          all_zero_consumption_severity:
            this.state.sensor5AllZeroSeverity !== null
              ? this.state.sensor5AllZeroSeverity.toString()
              : undefined,
          non_zero_consumption_severity:
            this.state.sensor5NonZeroConsumptionSeverity !== null
              ? this.state.sensor5NonZeroConsumptionSeverity.toString()
              : undefined,
          on_off_severity:
            this.state.sensor5OnOffSeverity !== null
              ? this.state.sensor5OnOffSeverity.toString()
              : undefined,
        },
      });
    } else {
      sensorConfigs.push({
        index: 4,
        enabled: this.state.sensor5Enabeled,
        parameters: {
          notif_emails: this.state.notifEmails,
          missed_transmission_emails: this.state.missedTransmissionEmails,
        },
      });
    }

    if (this.state.sensor6Enabeled) {
      sensorConfigs.push({
        index: 5,
        enabled: this.state.sensor6Enabeled,
        parameters: {
          notif_emails: this.state.notifEmails,
          missed_transmission_emails: this.state.missedTransmissionEmails,
          low_flow_severity:
            this.state.sensor6LowFlowSeverity !== null
              ? this.state.sensor6LowFlowSeverity.toString()
              : undefined,
          high_flow_severity:
            this.state.sensor6HighFlowSeverity !== null
              ? this.state.sensor6HighFlowSeverity.toString()
              : undefined,
          high_consumption_severity:
            this.state.sensor6HighConsumptionSeverity !== null
              ? this.state.sensor6HighConsumptionSeverity.toString()
              : undefined,
          all_zero_consumption_severity:
            this.state.sensor6AllZeroSeverity !== null
              ? this.state.sensor6AllZeroSeverity.toString()
              : undefined,
          non_zero_consumption_severity:
            this.state.sensor6NonZeroConsumptionSeverity !== null
              ? this.state.sensor6NonZeroConsumptionSeverity.toString()
              : undefined,
          on_off_severity:
            this.state.sensor6OnOffSeverity !== null
              ? this.state.sensor6OnOffSeverity.toString()
              : undefined,
        },
      });
    } else {
      sensorConfigs.push({
        index: 5,
        enabled: this.state.sensor6Enabeled,
        parameters: {
          notif_emails: this.state.notifEmails,
          missed_transmission_emails: this.state.missedTransmissionEmails,
        },
      });
    }

    if (this.state.sensor7Exists) {
      sensorConfigs.push({
        index: 6,
        enabled: this.state.sensor7Enabled,
        parameters: {
          notif_emails: this.state.notifEmails,
          missed_transmission_emails: this.state.missedTransmissionEmails,
          low_flow_severity:
            this.state.sensor7LowFlowSeverity !== null
              ? this.state.sensor7LowFlowSeverity.toString()
              : undefined,
          high_flow_severity:
            this.state.sensor7HighFlowSeverity !== null
              ? this.state.sensor7HighFlowSeverity.toString()
              : undefined,
          high_consumption_severity:
            this.state.sensor7HighConsumptionSeverity !== null
              ? this.state.sensor7HighConsumptionSeverity.toString()
              : undefined,
          all_zero_consumption_severity:
            this.state.sensor7AllZeroSeverity !== null
              ? this.state.sensor7AllZeroSeverity.toString()
              : undefined,
          non_zero_consumption_severity:
            this.state.sensor7NonZeroConsumptionSeverity !== null
              ? this.state.sensor7NonZeroConsumptionSeverity.toString()
              : undefined,
          on_off_severity:
            this.state.sensor7OnOffSeverity !== null
              ? this.state.sensor7OnOffSeverity.toString()
              : undefined,
        },
      });
    }

    if (this.state.sensor8Exists) {
      sensorConfigs.push({
        index: 7,
        enabled: this.state.sensor8Enabled,
        parameters: {
          notif_emails: this.state.notifEmails,
          missed_transmission_emails: this.state.missedTransmissionEmails,
          low_flow_severity:
            this.state.sensor8LowFlowSeverity !== null
              ? this.state.sensor8LowFlowSeverity.toString()
              : undefined,
          high_flow_severity:
            this.state.sensor8HighFlowSeverity !== null
              ? this.state.sensor8HighFlowSeverity.toString()
              : undefined,
          high_consumption_severity:
            this.state.sensor8HighConsumptionSeverity !== null
              ? this.state.sensor8HighConsumptionSeverity.toString()
              : undefined,
          all_zero_consumption_severity:
            this.state.sensor8AllZeroSeverity !== null
              ? this.state.sensor8AllZeroSeverity.toString()
              : undefined,
          non_zero_consumption_severity:
            this.state.sensor8NonZeroConsumptionSeverity !== null
              ? this.state.sensor8NonZeroConsumptionSeverity.toString()
              : undefined,
          on_off_severity:
            this.state.sensor8OnOffSeverity !== null
              ? this.state.sensor8OnOffSeverity.toString()
              : undefined,
        },
      });
    }

    if (this.state.sensor9Exists) {
      sensorConfigs.push({
        index: 8,
        enabled: this.state.sensor9Enabled,
        parameters: {
          notif_emails: this.state.notifEmails,
          missed_transmission_emails: this.state.missedTransmissionEmails,
          low_flow_severity:
            this.state.sensor9LowFlowSeverity !== null
              ? this.state.sensor9LowFlowSeverity.toString()
              : undefined,
          high_flow_severity:
            this.state.sensor9HighFlowSeverity !== null
              ? this.state.sensor9HighFlowSeverity.toString()
              : undefined,
          high_consumption_severity:
            this.state.sensor9HighConsumptionSeverity !== null
              ? this.state.sensor9HighConsumptionSeverity.toString()
              : undefined,
          all_zero_consumption_severity:
            this.state.sensor9AllZeroSeverity !== null
              ? this.state.sensor9AllZeroSeverity.toString()
              : undefined,
          non_zero_consumption_severity:
            this.state.sensor9NonZeroConsumptionSeverity !== null
              ? this.state.sensor9NonZeroConsumptionSeverity.toString()
              : undefined,
          on_off_severity:
            this.state.sensor9OnOffSeverity !== null
              ? this.state.sensor9OnOffSeverity.toString()
              : undefined,
        },
      });
    }

    if (this.state.sensor10Exists) {
      sensorConfigs.push({
        index: 9,
        enabled: this.state.sensor10Enabled,
        parameters: {
          notif_emails: this.state.notifEmails,
          missed_transmission_emails: this.state.missedTransmissionEmails,
          low_flow_severity:
            this.state.sensor10LowFlowSeverity !== null
              ? this.state.sensor10LowFlowSeverity.toString()
              : undefined,
          high_flow_severity:
            this.state.sensor10HighFlowSeverity !== null
              ? this.state.sensor10HighFlowSeverity.toString()
              : undefined,
          high_consumption_severity:
            this.state.sensor10HighConsumptionSeverity !== null
              ? this.state.sensor10HighConsumptionSeverity.toString()
              : undefined,
          all_zero_consumption_severity:
            this.state.sensor10AllZeroSeverity !== null
              ? this.state.sensor10AllZeroSeverity.toString()
              : undefined,
          non_zero_consumption_severity:
            this.state.sensor10NonZeroConsumptionSeverity !== null
              ? this.state.sensor10NonZeroConsumptionSeverity.toString()
              : undefined,
          on_off_severity:
            this.state.sensor10OnOffSeverity !== null
              ? this.state.sensor10OnOffSeverity.toString()
              : undefined,
        },
      });
    }

    if (this.state.sensor11Exists) {
      sensorConfigs.push({
        index: 10,
        enabled: this.state.sensor11Enabled,
        parameters: {
          notif_emails: this.state.notifEmails,
          missed_transmission_emails: this.state.missedTransmissionEmails,
          low_flow_severity:
            this.state.sensor11LowFlowSeverity !== null
              ? this.state.sensor11LowFlowSeverity.toString()
              : undefined,
          high_flow_severity:
            this.state.sensor11HighFlowSeverity !== null
              ? this.state.sensor11HighFlowSeverity.toString()
              : undefined,
          high_consumption_severity:
            this.state.sensor11HighConsumptionSeverity !== null
              ? this.state.sensor11HighConsumptionSeverity.toString()
              : undefined,
          all_zero_consumption_severity:
            this.state.sensor11AllZeroSeverity !== null
              ? this.state.sensor11AllZeroSeverity.toString()
              : undefined,
          non_zero_consumption_severity:
            this.state.sensor11NonZeroConsumptionSeverity !== null
              ? this.state.sensor11NonZeroConsumptionSeverity.toString()
              : undefined,
          on_off_severity:
            this.state.sensor11OnOffSeverity !== null
              ? this.state.sensor11OnOffSeverity.toString()
              : undefined,
        },
      });
    }

    if (this.state.sensor12Exists) {
      sensorConfigs.push({
        index: 11,
        enabled: this.state.sensor12Enabled,
        parameters: {
          notif_emails: this.state.notifEmails,
          missed_transmission_emails: this.state.missedTransmissionEmails,
          low_flow_severity:
            this.state.sensor12LowFlowSeverity !== null
              ? this.state.sensor12LowFlowSeverity.toString()
              : undefined,
          high_flow_severity:
            this.state.sensor12HighFlowSeverity !== null
              ? this.state.sensor12HighFlowSeverity.toString()
              : undefined,
          high_consumption_severity:
            this.state.sensor12HighConsumptionSeverity !== null
              ? this.state.sensor12HighConsumptionSeverity.toString()
              : undefined,
          all_zero_consumption_severity:
            this.state.sensor12AllZeroSeverity !== null
              ? this.state.sensor12AllZeroSeverity.toString()
              : undefined,
          non_zero_consumption_severity:
            this.state.sensor12NonZeroConsumptionSeverity !== null
              ? this.state.sensor12NonZeroConsumptionSeverity.toString()
              : undefined,
          on_off_severity:
            this.state.sensor12OnOffSeverity !== null
              ? this.state.sensor12OnOffSeverity.toString()
              : undefined,
        },
      });
    }

    if (this.state.sensor13Exists) {
      sensorConfigs.push({
        index: 12,
        enabled: this.state.sensor13Enabled,
        parameters: {
          notif_emails: this.state.notifEmails,
          missed_transmission_emails: this.state.missedTransmissionEmails,
          low_flow_severity:
            this.state.sensor13LowFlowSeverity !== null
              ? this.state.sensor13LowFlowSeverity.toString()
              : undefined,
          high_flow_severity:
            this.state.sensor13HighFlowSeverity !== null
              ? this.state.sensor13HighFlowSeverity.toString()
              : undefined,
          high_consumption_severity:
            this.state.sensor13HighConsumptionSeverity !== null
              ? this.state.sensor13HighConsumptionSeverity.toString()
              : undefined,
          all_zero_consumption_severity:
            this.state.sensor13AllZeroSeverity !== null
              ? this.state.sensor13AllZeroSeverity.toString()
              : undefined,
          non_zero_consumption_severity:
            this.state.sensor13NonZeroConsumptionSeverity !== null
              ? this.state.sensor13NonZeroConsumptionSeverity.toString()
              : undefined,
          on_off_severity:
            this.state.sensor13OnOffSeverity !== null
              ? this.state.sensor13OnOffSeverity.toString()
              : undefined,
        },
      });
    }

    if (this.state.sensor14Exists) {
      sensorConfigs.push({
        index: 13,
        enabled: this.state.sensor14Enabled,
        parameters: {
          notif_emails: this.state.notifEmails,
          missed_transmission_emails: this.state.missedTransmissionEmails,
          low_flow_severity:
            this.state.sensor14LowFlowSeverity !== null
              ? this.state.sensor14LowFlowSeverity.toString()
              : undefined,
          high_flow_severity:
            this.state.sensor14HighFlowSeverity !== null
              ? this.state.sensor14HighFlowSeverity.toString()
              : undefined,
          high_consumption_severity:
            this.state.sensor14HighConsumptionSeverity !== null
              ? this.state.sensor14HighConsumptionSeverity.toString()
              : undefined,
          all_zero_consumption_severity:
            this.state.sensor14AllZeroSeverity !== null
              ? this.state.sensor14AllZeroSeverity.toString()
              : undefined,
          non_zero_consumption_severity:
            this.state.sensor14NonZeroConsumptionSeverity !== null
              ? this.state.sensor14NonZeroConsumptionSeverity.toString()
              : undefined,
          on_off_severity:
            this.state.sensor14OnOffSeverity !== null
              ? this.state.sensor14OnOffSeverity.toString()
              : undefined,
        },
      });
    }

    alarmConfigClient.putSensorsConfig(
      this.props.match.params.deviceNumber,
      sensorConfigs,
      this.handleConfigSaved,
      this.errorCallback
    );

    this.updateSensorAlarmConfigList(this.state.portsConfig);

    sensorsConfigClient.putPortsConfig(
      this.props.match.params.deviceNumber,
      this.state.portsConfig,
      this.handleConfigSaved,
      this.errorCallback
    );
    event.preventDefault();
  }

  updateSensorAlarmConfigList(portsConfig) {
    digitalSensorIndexes.map((digitalPortIndex) => {
      if (portsConfig && portsConfig[digitalPortIndex] && portsConfig[digitalPortIndex].sensor) {
        const alarms = this.getAlarmStatusForPort(digitalPortIndex);
        let alarmConfigList = [];
        if (alarms?.highConsumptionAlarmEnabled) {
          alarmConfigList.push({
            type: 'HIGH_CONSUMPTION',
            threshold: alarms.highConsumptionAlarmThreshold,
            enableEmailNotification: alarms.highConsumptionAlarmEmailNotificationEnabled,
            enableMobileNotification: alarms.highConsumptionAlarmMobileNotificationEnabled,
          });
        }
        if (alarms?.lowFlowAlarmEnabled) {
          alarmConfigList.push({
            type: 'LOW_FLOW',
            threshold: alarms.lowFlowAlarmThreshold,
            enableEmailNotification: alarms.lowFlowAlarmEmailNotificationEnabled,
            enableMobileNotification: alarms.lowFlowAlarmMobileNotificationEnabled,
          });
        }
        if (alarms?.highFlowAlarmEnabled) {
          alarmConfigList.push({
            type: 'HIGH_FLOW',
            threshold: alarms.highFlowAlarmThreshold,
            enableEmailNotification: alarms.highFlowAlarmEmailNotificationEnabled,
            enableMobileNotification: alarms.highFlowAlarmMobileNotificationEnabled,
          });
        }
        if (alarms?.nonZeroConsumptionAlarmEnabled) {
          alarmConfigList.push({
            type: 'NO_ZERO_CONSUMPTION',
            enableEmailNotification: alarms.nonZeroConsumptionAlarmEmailNotificationEnabled,
            enableMobileNotification: alarms.nonZeroConsumptionAlarmMobileNotificationEnabled,
          });
        }
        portsConfig[digitalPortIndex].sensor.alarmConfigList = alarmConfigList;
      }
    });
  }

  getAlarmStatusForPort(portIndex) {
    let alarmStatus = {
      highConsumptionAlarmEnabled: false,
      highConsumptionAlarmThreshold: 0,
      highConsumptionAlarmEmailNotificationEnabled: false,
      highConsumptionAlarmMobileNotificationEnabled: false,

      lowFlowAlarmEnabled: false,
      lowFlowAlarmThreshold: 0,
      lowFlowAlarmEmailNotificationEnabled: false,
      lowFlowAlarmMobileNotificationEnabled: false,

      highFlowAlarmEnabled: false,
      highFlowAlarmThreshold: 0,
      highFlowAlarmEmailNotificationEnabled: false,
      highFlowAlarmMobileNotificationEnabled: false,

      nonZeroConsumptionAlarmEnabled: false,
      nonZeroConsumptionAlarmEmailNotificationEnabled: false,
      nonZeroConsumptionAlarmMobileNotificationEnabled: false,
    };
    switch (portIndex) {
      case 0:
        alarmStatus.highConsumptionAlarmEnabled = !!this.state.sensor1HighConsumptionThreshold;
        alarmStatus.highConsumptionAlarmThreshold = this.state.sensor1HighConsumptionThreshold;
        alarmStatus.highConsumptionAlarmEmailNotificationEnabled =
          this.state.sensor1HighConsumptionEmailNotificationEnabled;
        alarmStatus.highConsumptionAlarmMobileNotificationEnabled =
          this.state.sensor1HighConsumptionMobileNotificationEnabled;

        alarmStatus.lowFlowAlarmEnabled = !!this.state.sensor1LowFlowAlarmThreshold;
        alarmStatus.lowFlowAlarmThreshold = this.state.sensor1LowFlowAlarmThreshold;
        alarmStatus.lowFlowAlarmEmailNotificationEnabled =
          this.state.sensor1LowFlowEmailNotificationEnabled;
        alarmStatus.lowFlowAlarmMobileNotificationEnabled =
          this.state.sensor1LowFlowMobileNotificationEnabled;

        alarmStatus.highFlowAlarmEnabled = !!this.state.sensor1HighFlowAlarmThreshold;
        alarmStatus.highFlowAlarmThreshold = this.state.sensor1HighFlowAlarmThreshold;
        alarmStatus.highFlowAlarmEmailNotificationEnabled =
          this.state.sensor1HighFlowEmailNotificationEnabled;
        alarmStatus.highFlowAlarmMobileNotificationEnabled =
          this.state.sensor1HighFlowMobileNotificationEnabled;

        alarmStatus.nonZeroConsumptionAlarmEnabled =
          !!this.state.sensor1NonZeroConsumptionStartTime &&
          !!this.state.sensor1NonZeroConsumptionEndTime;
        alarmStatus.nonZeroConsumptionAlarmEmailNotificationEnabled =
          this.state.sensor1NonZeroConsumptionEmailNotificationEnabled;
        alarmStatus.nonZeroConsumptionAlarmMobileNotificationEnabled =
          this.state.sensor1NonZeroConsumptionMobileNotificationEnabled;

        return alarmStatus;
      case 1:
        alarmStatus.highConsumptionAlarmEnabled = !!this.state.sensor2HighConsumptionThreshold;
        alarmStatus.highConsumptionAlarmThreshold = this.state.sensor2HighConsumptionThreshold;
        alarmStatus.highConsumptionAlarmEmailNotificationEnabled =
          this.state.sensor2HighConsumptionEmailNotificationEnabled;
        alarmStatus.highConsumptionAlarmMobileNotificationEnabled =
          this.state.sensor2HighConsumptionMobileNotificationEnabled;

        alarmStatus.lowFlowAlarmEnabled = !!this.state.sensor2LowFlowAlarmThreshold;
        alarmStatus.lowFlowAlarmThreshold = this.state.sensor2LowFlowAlarmThreshold;
        alarmStatus.lowFlowAlarmEmailNotificationEnabled =
          this.state.sensor2LowFlowEmailNotificationEnabled;
        alarmStatus.lowFlowAlarmMobileNotificationEnabled =
          this.state.sensor2LowFlowMobileNotificationEnabled;

        alarmStatus.highFlowAlarmEnabled = !!this.state.sensor2HighFlowAlarmThreshold;
        alarmStatus.highFlowAlarmThreshold = this.state.sensor2HighFlowAlarmThreshold;
        alarmStatus.highFlowAlarmEmailNotificationEnabled =
          this.state.sensor2HighFlowEmailNotificationEnabled;
        alarmStatus.highFlowAlarmMobileNotificationEnabled =
          this.state.sensor2HighFlowMobileNotificationEnabled;

        alarmStatus.nonZeroConsumptionAlarmEnabled =
          !!this.state.sensor2NonZeroConsumptionStartTime &&
          !!this.state.sensor2NonZeroConsumptionEndTime;
        alarmStatus.nonZeroConsumptionAlarmEmailNotificationEnabled =
          this.state.sensor2NonZeroConsumptionEmailNotificationEnabled;
        alarmStatus.nonZeroConsumptionAlarmMobileNotificationEnabled =
          this.state.sensor2NonZeroConsumptionMobileNotificationEnabled;
        return alarmStatus;
      default:
        return null;
    }
  }

  getPortsNotificationsConfig = (portsConfig, sensorConfig, sensorIndex) => {
    let alarmsConfig = {
      highConsumptionThreshold: sensorConfig[sensorIndex].parameters.high_consumption_threshold,
      highConsumptionAlarmEnabled: false,
      highConsumptionMobileNotificationEnabled: false,
      highConsumptionEmailNotificationEnabled:
        !!sensorConfig[sensorIndex].parameters.high_consumption_threshold,

      lowFlowAlarmThreshold: sensorConfig[sensorIndex].parameters.low_flow_alarm_threshold,
      lowFlowAlarmEnabled: false,
      lowFlowMobileNotificationEnabled: false,
      lowFlowEmailNotificationEnabled:
        !!sensorConfig[sensorIndex].parameters.low_flow_alarm_threshold,

      highFlowAlarmThreshold: sensorConfig[sensorIndex].parameters.high_flow_alarm_threshold,
      highFlowAlarmEnabled: false,
      highFlowMobileNotificationEnabled: false,
      highFlowEmailNotificationEnabled:
        !!sensorConfig[sensorIndex].parameters.high_flow_alarm_threshold,

      nonZeroConsumptionAlarmEnabled:
        sensorConfig[sensorIndex].parameters.non_zero_consumption_start_time &&
        sensorConfig[sensorIndex].parameters.non_zero_consumption_end_time,
      nonZeroConsumptionMobileNotificationEnabled: false,
      nonZeroConsumptionEmailNotificationEnabled:
        !!sensorConfig[sensorIndex].parameters.non_zero_consumption_start_time &&
        !!sensorConfig[sensorIndex].parameters.non_zero_consumption_end_time,
    };

    const alarmsConfigList = portsConfig[sensorIndex]?.sensor.alarmConfigList;

    if (alarmsConfigList && alarmsConfigList.length > 0) {
      for (const alarm of alarmsConfigList) {
        if (alarm.type === 'HIGH_CONSUMPTION') {
          alarmsConfig.highConsumptionThreshold = alarm.threshold;
          alarmsConfig.highConsumptionAlarmEnabled = true;
          alarmsConfig.highConsumptionMobileNotificationEnabled = alarm.enableMobileNotification;
          alarmsConfig.highConsumptionEmailNotificationEnabled = alarm.enableEmailNotification;
        }
        if (alarm.type === 'LOW_FLOW') {
          alarmsConfig.lowFlowAlarmThreshold = alarm.threshold;
          alarmsConfig.lowFlowAlarmEnabled = true;
          alarmsConfig.lowFlowMobileNotificationEnabled = alarm.enableMobileNotification;
          alarmsConfig.lowFlowEmailNotificationEnabled = alarm.enableEmailNotification;
        }
        if (alarm.type === 'HIGH_FLOW') {
          alarmsConfig.highFlowAlarmThreshold = alarm.threshold;
          alarmsConfig.highFlowAlarmEnabled = true;
          alarmsConfig.highFlowMobileNotificationEnabled = alarm.enableMobileNotification;
          alarmsConfig.highFlowEmailNotificationEnabled = alarm.enableEmailNotification;
        }
        if (alarm.type === 'NO_ZERO_CONSUMPTION') {
          alarmsConfig.nonZeroConsumptionAlarmEnabled = true;
          alarmsConfig.nonZeroConsumptionMobileNotificationEnabled = alarm.enableMobileNotification;
          alarmsConfig.nonZeroConsumptionEmailNotificationEnabled = alarm.enableEmailNotification;
        }
      }
    }
    return alarmsConfig;
  };

  handleConfigSaved() {
    this.setState({ open: true, saveDisabled: false });
  }

  handleSensor1LowFlowAlarmThresholdChange = (event) => {
    this.setState({ sensor1LowFlowAlarmThreshold: event.target.value });
  };

  handleSensor2LowFlowAlarmThresholdChange = (event) => {
    this.setState({ sensor2LowFlowAlarmThreshold: event.target.value });
  };

  handleSensor1HighFlowAlarmThresholdChange = (event) => {
    this.setState({ sensor1HighFlowAlarmThreshold: event.target.value });
  };

  handleSensor2HighFlowAlarmThresholdChange = (event) => {
    this.setState({ sensor2HighFlowAlarmThreshold: event.target.value });
  };

  handleSensor1FlowTImeBaseChange = (event) => {
    this.setState({ sensor1FlowTimeBase: event.target.value });
  };

  handleSensor2FlowTImeBaseChange = (event) => {
    this.setState({ sensor2FlowTimeBase: event.target.value });
  };

  handleSensor1HighConsumptionThresholdChange = (event) => {
    this.setState({ sensor1HighConsumptionThreshold: event.target.value });
  };

  handleSensor2HighConsumptionThresholdChange = (event) => {
    this.setState({ sensor2HighConsumptionThreshold: event.target.value });
  };

  handleSensor1NonZeroConsumptionStartTimeChange = (date, keyboardInputValue) => {
    this.setState({ sensor1NonZeroConsumptionStartTime: date }, this.validateTimeRange);
  };

  handleSensor2NonZeroConsumptionStartTimeChange = (date, keyboardInputValue) => {
    this.setState({ sensor2NonZeroConsumptionStartTime: date }, this.validateTimeRange);
  };

  handleSensor1NonZeroConsumptionEndTimeChange = (date, keyboardInputValue) => {
    this.setState({ sensor1NonZeroConsumptionEndTime: date }, this.validateTimeRange);
  };

  handleSensor2NonZeroConsumptionEndTimeChange = (date, keyboardInputValue) => {
    this.setState({ sensor2NonZeroConsumptionEndTime: date }, this.validateTimeRange);
  };

  handleSensor1HighLowFlowStartTimeChange = (date, keyboardInputValue) => {
    this.setState({ sensor1HighLowFlowStartTime: date });
  };

  handleSensor1HighLowFlowEndTimeChange = (date, keyboardInputValue) => {
    this.setState({ sensor1HighLowFlowEndTime: date });
  };

  handleSensor2HighLowFlowStartTimeChange = (date, keyboardInputValue) => {
    this.setState({ sensor2HighLowFlowStartTime: date });
  };

  handleSensor2HighLowFlowEndTimeChange = (date, keyboardInputValue) => {
    this.setState({ sensor2HighLowFlowEndTime: date });
  };

  handleNotifEmailsChange = (event) => {
    this.setState({ notifEmails: event.target.value });
  };

  handleMissedTransmissionEmailsChange = (event) => {
    this.setState({ missedTransmissionEmails: event.target.value });
  };

  handleRequestClose = () => {
    this.setState({ open: false });
  };

  handlerSensor1HighConsumptionEmailNotificationEnabled = (event) => {
    this.setState({ sensor1HighConsumptionEmailNotificationEnabled: event.target.checked });
  };
  handlerSensor1HighConsumptionMobileNotificationEnabled = (event) => {
    this.setState({ sensor1HighConsumptionMobileNotificationEnabled: event.target.checked });
  };
  handlerSensor2HighConsumptionEmailNotificationEnabled = (event) => {
    this.setState({ sensor2HighConsumptionEmailNotificationEnabled: event.target.checked });
  };
  handlerSensor2HighConsumptionMobileNotificationEnabled = (event) => {
    this.setState({ sensor2HighConsumptionMobileNotificationEnabled: event.target.checked });
  };

  handlerSensor1LowFlowEmailNotificationEnabled = (event) => {
    this.setState({ sensor1LowFlowEmailNotificationEnabled: event.target.checked });
  };
  handlerSensor1LowFlowMobileNotificationEnabled = (event) => {
    this.setState({ sensor1LowFlowMobileNotificationEnabled: event.target.checked });
  };
  handlerSensor2LowFlowEmailNotificationEnabled = (event) => {
    this.setState({ sensor2LowFlowEmailNotificationEnabled: event.target.checked });
  };
  handlerSensor2LowFlowMobileNotificationEnabled = (event) => {
    this.setState({ sensor2LowFlowMobileNotificationEnabled: event.target.checked });
  };

  handlerSensor1HighFlowEmailNotificationEnabled = (event) => {
    this.setState({ sensor1HighFlowEmailNotificationEnabled: event.target.checked });
  };
  handlerSensor1HighFlowMobileNotificationEnabled = (event) => {
    this.setState({ sensor1HighFlowMobileNotificationEnabled: event.target.checked });
  };
  handlerSensor2HighFlowEmailNotificationEnabled = (event) => {
    this.setState({ sensor2HighFlowEmailNotificationEnabled: event.target.checked });
  };
  handlerSensor2HighFlowMobileNotificationEnabled = (event) => {
    this.setState({ sensor2HighFlowMobileNotificationEnabled: event.target.checked });
  };

  handlerSensor1NonZeroConsumptionEmailNotificationEnabled = (event) => {
    this.setState({ sensor1NonZeroConsumptionEmailNotificationEnabled: event.target.checked });
  };
  handlerSensor1NonZeroConsumptionMobileNotificationEnabled = (event) => {
    this.setState({ sensor1NonZeroConsumptionMobileNotificationEnabled: event.target.checked });
  };
  handlerSensor2NonZeroConsumptionEmailNotificationEnabled = (event) => {
    this.setState({ sensor2NonZeroConsumptionEmailNotificationEnabled: event.target.checked });
  };
  handlerSensor2NonZeroConsumptionMobileNotificationEnabled = (event) => {
    this.setState({ sensor2NonZeroConsumptionMobileNotificationEnabled: event.target.checked });
  };

  // Sensor 1
  handleSensor1HighConsumptionSeverityChange = (event) => {
    this.setState({
      sensor1HighConsumptionSeverity:
        event.target.value === '' ? null : parseInt(event.target.value),
    });
  };

  handleSensor1LowFlowSeverityChange = (event) => {
    this.setState({
      sensor1LowFlowSeverity: event.target.value === '' ? null : parseInt(event.target.value),
    });
  };

  handleSensor1HighFlowSeverityChange = (event) => {
    this.setState({
      sensor1HighFlowSeverity: event.target.value === '' ? null : parseInt(event.target.value),
    });
  };

  handleSensor1AllZeroSeverityChange = (event) => {
    this.setState({
      sensor1AllZeroSeverity: event.target.value === '' ? null : parseInt(event.target.value),
    });
  };

  handleSensor1NonZeroConsumptionSeverityChange = (event) => {
    this.setState({
      sensor1NonZeroConsumptionSeverity:
        event.target.value === '' ? null : parseInt(event.target.value),
    });
  };

  handleSensor1OnOffSeverityChange = (event) => {
    this.setState({
      sensor1OnOffSeverity: event.target.value === '' ? null : parseInt(event.target.value),
    });
  };

  // Sensor 2
  handleSensor2HighConsumptionSeverityChange = (event) => {
    this.setState({
      sensor2HighConsumptionSeverity:
        event.target.value === '' ? null : parseInt(event.target.value),
    });
  };

  handleSensor2LowFlowSeverityChange = (event) => {
    this.setState({
      sensor2LowFlowSeverity: event.target.value === '' ? null : parseInt(event.target.value),
    });
  };

  handleSensor2HighFlowSeverityChange = (event) => {
    this.setState({
      sensor2HighFlowSeverity: event.target.value === '' ? null : parseInt(event.target.value),
    });
  };

  handleSensor2AllZeroSeverityChange = (event) => {
    this.setState({
      sensor2AllZeroSeverity: event.target.value === '' ? null : parseInt(event.target.value),
    });
  };

  handleSensor2NonZeroConsumptionSeverityChange = (event) => {
    this.setState({
      sensor2NonZeroConsumptionSeverity:
        event.target.value === '' ? null : parseInt(event.target.value),
    });
  };

  handleSensor2OnOffSeverityChange = (event) => {
    this.setState({
      sensor2OnOffSeverity: event.target.value === '' ? null : parseInt(event.target.value),
    });
  };

  // Sensor 3
  handleSensor3HighConsumptionSeverityChange = (event) => {
    this.setState({
      sensor3HighConsumptionSeverity:
        event.target.value === '' ? null : parseInt(event.target.value),
    });
  };

  handleSensor3LowFlowSeverityChange = (event) => {
    this.setState({
      sensor3LowFlowSeverity: event.target.value === '' ? null : parseInt(event.target.value),
    });
  };

  handleSensor3HighFlowSeverityChange = (event) => {
    this.setState({
      sensor3HighFlowSeverity: event.target.value === '' ? null : parseInt(event.target.value),
    });
  };

  handleSensor3AllZeroSeverityChange = (event) => {
    this.setState({
      sensor3AllZeroSeverity: event.target.value === '' ? null : parseInt(event.target.value),
    });
  };

  handleSensor3NonZeroConsumptionSeverityChange = (event) => {
    this.setState({
      sensor3NonZeroConsumptionSeverity:
        event.target.value === '' ? null : parseInt(event.target.value),
    });
  };

  handleSensor3OnOffSeverityChange = (event) => {
    this.setState({
      sensor3OnOffSeverity: event.target.value === '' ? null : parseInt(event.target.value),
    });
  };

  // Sensor 4
  handleSensor4HighConsumptionSeverityChange = (event) => {
    this.setState({
      sensor4HighConsumptionSeverity:
        event.target.value === '' ? null : parseInt(event.target.value),
    });
  };

  handleSensor4LowFlowSeverityChange = (event) => {
    this.setState({
      sensor4LowFlowSeverity: event.target.value === '' ? null : parseInt(event.target.value),
    });
  };

  handleSensor4HighFlowSeverityChange = (event) => {
    this.setState({
      sensor4HighFlowSeverity: event.target.value === '' ? null : parseInt(event.target.value),
    });
  };

  handleSensor4AllZeroSeverityChange = (event) => {
    this.setState({
      sensor4AllZeroSeverity: event.target.value === '' ? null : parseInt(event.target.value),
    });
  };

  handleSensor4NonZeroConsumptionSeverityChange = (event) => {
    this.setState({
      sensor4NonZeroConsumptionSeverity:
        event.target.value === '' ? null : parseInt(event.target.value),
    });
  };

  handleSensor4OnOffSeverityChange = (event) => {
    this.setState({
      sensor4OnOffSeverity: event.target.value === '' ? null : parseInt(event.target.value),
    });
  };

  // Sensor 5
  handleSensor5HighConsumptionSeverityChange = (event) => {
    this.setState({
      sensor5HighConsumptionSeverity:
        event.target.value === '' ? null : parseInt(event.target.value),
    });
  };

  handleSensor5LowFlowSeverityChange = (event) => {
    this.setState({
      sensor5LowFlowSeverity: event.target.value === '' ? null : parseInt(event.target.value),
    });
  };

  handleSensor5HighFlowSeverityChange = (event) => {
    this.setState({
      sensor5HighFlowSeverity: event.target.value === '' ? null : parseInt(event.target.value),
    });
  };

  handleSensor5AllZeroSeverityChange = (event) => {
    this.setState({
      sensor5AllZeroSeverity: event.target.value === '' ? null : parseInt(event.target.value),
    });
  };

  handleSensor5NonZeroConsumptionSeverityChange = (event) => {
    this.setState({
      sensor5NonZeroConsumptionSeverity:
        event.target.value === '' ? null : parseInt(event.target.value),
    });
  };

  handleSensor5OnOffSeverityChange = (event) => {
    this.setState({
      sensor5OnOffSeverity: event.target.value === '' ? null : parseInt(event.target.value),
    });
  };

  // Sensor 6
  handleSensor6HighConsumptionSeverityChange = (event) => {
    this.setState({
      sensor6HighConsumptionSeverity:
        event.target.value === '' ? null : parseInt(event.target.value),
    });
  };

  handleSensor6LowFlowSeverityChange = (event) => {
    this.setState({
      sensor6LowFlowSeverity: event.target.value === '' ? null : parseInt(event.target.value),
    });
  };

  handleSensor6HighFlowSeverityChange = (event) => {
    this.setState({
      sensor6HighFlowSeverity: event.target.value === '' ? null : parseInt(event.target.value),
    });
  };

  handleSensor6AllZeroSeverityChange = (event) => {
    this.setState({
      sensor6AllZeroSeverity: event.target.value === '' ? null : parseInt(event.target.value),
    });
  };

  handleSensor6NonZeroConsumptionSeverityChange = (event) => {
    this.setState({
      sensor6NonZeroConsumptionSeverity:
        event.target.value === '' ? null : parseInt(event.target.value),
    });
  };

  handleSensor6OnOffSeverityChange = (event) => {
    this.setState({
      sensor6OnOffSeverity: event.target.value === '' ? null : parseInt(event.target.value),
    });
  };

  // Sensor 7
  handleSensor7HighConsumptionSeverityChange = (event) => {
    this.setState({
      sensor7HighConsumptionSeverity:
        event.target.value === '' ? null : parseInt(event.target.value),
    });
  };

  handleSensor7LowFlowSeverityChange = (event) => {
    this.setState({
      sensor7LowFlowSeverity: event.target.value === '' ? null : parseInt(event.target.value),
    });
  };

  handleSensor7HighFlowSeverityChange = (event) => {
    this.setState({
      sensor7HighFlowSeverity: event.target.value === '' ? null : parseInt(event.target.value),
    });
  };

  handleSensor7AllZeroSeverityChange = (event) => {
    this.setState({
      sensor7AllZeroSeverity: event.target.value === '' ? null : parseInt(event.target.value),
    });
  };

  handleSensor7NonZeroConsumptionSeverityChange = (event) => {
    this.setState({
      sensor7NonZeroConsumptionSeverity:
        event.target.value === '' ? null : parseInt(event.target.value),
    });
  };

  handleSensor7OnOffSeverityChange = (event) => {
    this.setState({
      sensor7OnOffSeverity: event.target.value === '' ? null : parseInt(event.target.value),
    });
  };

  // Sensor 8
  handleSensor8HighConsumptionSeverityChange = (event) => {
    this.setState({
      sensor8HighConsumptionSeverity:
        event.target.value === '' ? null : parseInt(event.target.value),
    });
  };

  handleSensor8LowFlowSeverityChange = (event) => {
    this.setState({
      sensor8LowFlowSeverity: event.target.value === '' ? null : parseInt(event.target.value),
    });
  };

  handleSensor8HighFlowSeverityChange = (event) => {
    this.setState({
      sensor8HighFlowSeverity: event.target.value === '' ? null : parseInt(event.target.value),
    });
  };

  handleSensor8AllZeroSeverityChange = (event) => {
    this.setState({
      sensor8AllZeroSeverity: event.target.value === '' ? null : parseInt(event.target.value),
    });
  };

  handleSensor8NonZeroConsumptionSeverityChange = (event) => {
    this.setState({
      sensor8NonZeroConsumptionSeverity:
        event.target.value === '' ? null : parseInt(event.target.value),
    });
  };

  handleSensor8OnOffSeverityChange = (event) => {
    this.setState({
      sensor8OnOffSeverity: event.target.value === '' ? null : parseInt(event.target.value),
    });
  };

  // Sensor 9
  handleSensor9HighConsumptionSeverityChange = (event) => {
    this.setState({
      sensor9HighConsumptionSeverity:
        event.target.value === '' ? null : parseInt(event.target.value),
    });
  };

  handleSensor9LowFlowSeverityChange = (event) => {
    this.setState({
      sensor9LowFlowSeverity: event.target.value === '' ? null : parseInt(event.target.value),
    });
  };

  handleSensor9HighFlowSeverityChange = (event) => {
    this.setState({
      sensor9HighFlowSeverity: event.target.value === '' ? null : parseInt(event.target.value),
    });
  };

  handleSensor9AllZeroSeverityChange = (event) => {
    this.setState({
      sensor9AllZeroSeverity: event.target.value === '' ? null : parseInt(event.target.value),
    });
  };

  handleSensor9NonZeroConsumptionSeverityChange = (event) => {
    this.setState({
      sensor9NonZeroConsumptionSeverity:
        event.target.value === '' ? null : parseInt(event.target.value),
    });
  };

  handleSensor9OnOffSeverityChange = (event) => {
    this.setState({
      sensor9OnOffSeverity: event.target.value === '' ? null : parseInt(event.target.value),
    });
  };

  // Sensor 10
  handleSensor10HighConsumptionSeverityChange = (event) => {
    this.setState({
      sensor10HighConsumptionSeverity:
        event.target.value === '' ? null : parseInt(event.target.value),
    });
  };

  handleSensor10LowFlowSeverityChange = (event) => {
    this.setState({
      sensor10LowFlowSeverity: event.target.value === '' ? null : parseInt(event.target.value),
    });
  };

  handleSensor10HighFlowSeverityChange = (event) => {
    this.setState({
      sensor10HighFlowSeverity: event.target.value === '' ? null : parseInt(event.target.value),
    });
  };

  handleSensor10AllZeroSeverityChange = (event) => {
    this.setState({
      sensor10AllZeroSeverity: event.target.value === '' ? null : parseInt(event.target.value),
    });
  };

  handleSensor10NonZeroConsumptionSeverityChange = (event) => {
    this.setState({
      sensor10NonZeroConsumptionSeverity:
        event.target.value === '' ? null : parseInt(event.target.value),
    });
  };

  handleSensor10OnOffSeverityChange = (event) => {
    this.setState({
      sensor10OnOffSeverity: event.target.value === '' ? null : parseInt(event.target.value),
    });
  };

  // Sensor 11
  handleSensor11HighConsumptionSeverityChange = (event) => {
    this.setState({
      sensor11HighConsumptionSeverity:
        event.target.value === '' ? null : parseInt(event.target.value),
    });
  };

  handleSensor11LowFlowSeverityChange = (event) => {
    this.setState({
      sensor11LowFlowSeverity: event.target.value === '' ? null : parseInt(event.target.value),
    });
  };

  handleSensor11HighFlowSeverityChange = (event) => {
    this.setState({
      sensor11HighFlowSeverity: event.target.value === '' ? null : parseInt(event.target.value),
    });
  };

  handleSensor11AllZeroSeverityChange = (event) => {
    this.setState({
      sensor11AllZeroSeverity: event.target.value === '' ? null : parseInt(event.target.value),
    });
  };

  handleSensor11NonZeroConsumptionSeverityChange = (event) => {
    this.setState({
      sensor11NonZeroConsumptionSeverity:
        event.target.value === '' ? null : parseInt(event.target.value),
    });
  };

  handleSensor11OnOffSeverityChange = (event) => {
    this.setState({
      sensor11OnOffSeverity: event.target.value === '' ? null : parseInt(event.target.value),
    });
  };

  // Sensor 12
  handleSensor12HighConsumptionSeverityChange = (event) => {
    this.setState({
      sensor12HighConsumptionSeverity:
        event.target.value === '' ? null : parseInt(event.target.value),
    });
  };

  handleSensor12LowFlowSeverityChange = (event) => {
    this.setState({
      sensor12LowFlowSeverity: event.target.value === '' ? null : parseInt(event.target.value),
    });
  };

  handleSensor12HighFlowSeverityChange = (event) => {
    this.setState({
      sensor12HighFlowSeverity: event.target.value === '' ? null : parseInt(event.target.value),
    });
  };

  handleSensor12AllZeroSeverityChange = (event) => {
    this.setState({
      sensor12AllZeroSeverity: event.target.value === '' ? null : parseInt(event.target.value),
    });
  };

  handleSensor12NonZeroConsumptionSeverityChange = (event) => {
    this.setState({
      sensor12NonZeroConsumptionSeverity:
        event.target.value === '' ? null : parseInt(event.target.value),
    });
  };

  handleSensor12OnOffSeverityChange = (event) => {
    this.setState({
      sensor12OnOffSeverity: event.target.value === '' ? null : parseInt(event.target.value),
    });
  };

  // Sensor 13
  handleSensor13HighConsumptionSeverityChange = (event) => {
    this.setState({
      sensor13HighConsumptionSeverity:
        event.target.value === '' ? null : parseInt(event.target.value),
    });
  };

  handleSensor13LowFlowSeverityChange = (event) => {
    this.setState({
      sensor13LowFlowSeverity: event.target.value === '' ? null : parseInt(event.target.value),
    });
  };

  handleSensor13HighFlowSeverityChange = (event) => {
    this.setState({
      sensor13HighFlowSeverity: event.target.value === '' ? null : parseInt(event.target.value),
    });
  };

  handleSensor13AllZeroSeverityChange = (event) => {
    this.setState({
      sensor13AllZeroSeverity: event.target.value === '' ? null : parseInt(event.target.value),
    });
  };

  handleSensor13NonZeroConsumptionSeverityChange = (event) => {
    this.setState({
      sensor13NonZeroConsumptionSeverity:
        event.target.value === '' ? null : parseInt(event.target.value),
    });
  };

  handleSensor13OnOffSeverityChange = (event) => {
    this.setState({
      sensor13OnOffSeverity: event.target.value === '' ? null : parseInt(event.target.value),
    });
  };

  // Sensor 14
  handleSensor14HighConsumptionSeverityChange = (event) => {
    this.setState({
      sensor14HighConsumptionSeverity:
        event.target.value === '' ? null : parseInt(event.target.value),
    });
  };

  handleSensor14LowFlowSeverityChange = (event) => {
    this.setState({
      sensor14LowFlowSeverity: event.target.value === '' ? null : parseInt(event.target.value),
    });
  };

  handleSensor14HighFlowSeverityChange = (event) => {
    this.setState({
      sensor14HighFlowSeverity: event.target.value === '' ? null : parseInt(event.target.value),
    });
  };

  handleSensor14AllZeroSeverityChange = (event) => {
    this.setState({
      sensor14AllZeroSeverity: event.target.value === '' ? null : parseInt(event.target.value),
    });
  };

  handleSensor14NonZeroConsumptionSeverityChange = (event) => {
    this.setState({
      sensor14NonZeroConsumptionSeverity:
        event.target.value === '' ? null : parseInt(event.target.value),
    });
  };

  handleSensor14OnOffSeverityChange = (event) => {
    this.setState({
      sensor14OnOffSeverity: event.target.value === '' ? null : parseInt(event.target.value),
    });
  };

  validateTimeRange = () => {
    const {
      sensor1NonZeroConsumptionStartTime,
      sensor1NonZeroConsumptionEndTime,
      sensor2NonZeroConsumptionStartTime,
      sensor2NonZeroConsumptionEndTime,
    } = this.state;

    const isSensor1Valid =
      (sensor1NonZeroConsumptionStartTime && sensor1NonZeroConsumptionEndTime) ||
      (!sensor1NonZeroConsumptionStartTime && !sensor1NonZeroConsumptionEndTime);

    const isSensor2Valid =
      (sensor2NonZeroConsumptionStartTime && sensor2NonZeroConsumptionEndTime) ||
      (!sensor2NonZeroConsumptionStartTime && !sensor2NonZeroConsumptionEndTime);

    this.setState({
      isSensor1NonZeroConsumptionRangeValid: isSensor1Valid,
      isSensor2NonZeroConsumptionRangeValid: isSensor2Valid,
    });

    if (!isSensor1Valid || !isSensor2Valid) {
      this.setState({ saveDisabled: true });
    } else {
      this.setState({ saveDisabled: false });
    }
  };

  renderSeveritySelect = (sensorIndex, title, value, onChangeCallback) => {
    return (
      <FormGroup controlId={`${title} ${sensorIndex}`}>
        <ControlLabel>{title}</ControlLabel>
        <FormControl
          value={value}
          componentClass="select"
          placeholder={title}
          onChange={onChangeCallback}
        >
          <option value={''}>{this.props.t('none')}</option>
          {[...Array(10).keys()].map((num) => (
            <option key={`${title}-${sensorIndex}-${num}`} value={`${num}`}>
              {num}
            </option>
          ))}
        </FormControl>
      </FormGroup>
    );
  };

  render() {
    console.log(this.state);
    const wellStyles = { maxWidth: 450, margin: '0 auto 10px' };
    if (this.state.loaded) {
      return (
        <Header>
          <div className="well" style={wellStyles}>
            <TitleHeader
              className="mb-4"
              pageType="form"
              title={this.props.t('alarm_config_header')}
              deviceNumber={this.props.match.params.deviceNumber}
              deviceName={this.state.deviceName}
              customerInfo={this.state.customerInfo}
            />
            <form>
              <FormGroup controlId="Notif Emails">
                <ControlLabel>{this.props.t('alarm_config_notification_email')}</ControlLabel>
                <FormControl
                  value={this.state.notifEmails}
                  type="text"
                  placeholder={this.props.t('alarm_config_notification_email_place_holder')}
                  onChange={this.handleNotifEmailsChange}
                />
              </FormGroup>
              <FormGroup controlId="Missed Transmission Emails">
                <ControlLabel>
                  {this.props.t('alarm_config_missed_transmission_email')}
                </ControlLabel>
                <FormControl
                  value={this.state.missedTransmissionEmails}
                  type="text"
                  placeholder={this.props.t('alarm_config_missed_transmission_email_place_holder')}
                  onChange={this.handleMissedTransmissionEmailsChange}
                />
              </FormGroup>
              <FormGroup controlId="Pulse Sensor 1">
                <ControlLabel>{this.state.sensor1Name}</ControlLabel>
                <Switch checked={this.state.sensor1Enabeled} />
              </FormGroup>
              <div style={{ display: this.state.sensor1Enabeled ? 'block' : 'none' }}>
                {this.state.sensor1Enabeled && (
                  <Fragment>
                    <FormGroup controlId="Threshold alarm">
                      <div className="flex items-center justify-between">
                        <ControlLabel className=" text-left w-2/3">
                          {this.props.t('alarm_config_high_consumption_threshold', {
                            transmission_every: this.state.transmissionEvery,
                            transmission_period: this.state.transmissionPeriod,
                            units: this.state.portsConfig
                              ? Units[this.state.portsConfig[0].sensor.units]
                              : '',
                          })}
                        </ControlLabel>
                        <NotificationCheckbox
                          notificationCheckboxes={[
                            {
                              icon: <EmailIcon />,
                              checked: this.state.sensor1HighConsumptionEmailNotificationEnabled,
                              onChange: this.handlerSensor1HighConsumptionEmailNotificationEnabled,
                            },
                            {
                              icon: <PhoneIphoneIcon />,
                              checked: this.state.sensor1HighConsumptionMobileNotificationEnabled,
                              onChange: this.handlerSensor1HighConsumptionMobileNotificationEnabled,
                              disabled: !this.isFeatureFlagOn('TOGGLE_MOBILE_NOTIFICATIONS'),
                            },
                          ]}
                        />
                      </div>
                      <FormControl
                        value={this.state.sensor1HighConsumptionThreshold}
                        type="text"
                        placeholder=""
                        onChange={this.handleSensor1HighConsumptionThresholdChange}
                      />
                    </FormGroup>
                    <FormGroup controlId="Pulse sensor low flow">
                      <div className="flex items-center justify-between">
                        <ControlLabel>
                          {this.props.t('alarm_config_low_flow_alarm_threshold', {
                            units: this.state.portsConfig
                              ? Units[this.state.portsConfig[0].sensor.units] + '/h'
                              : '',
                          })}
                        </ControlLabel>
                        <NotificationCheckbox
                          notificationCheckboxes={[
                            {
                              icon: <EmailIcon />,
                              checked: this.state.sensor1LowFlowEmailNotificationEnabled,
                              onChange: this.handlerSensor1LowFlowEmailNotificationEnabled,
                            },
                            {
                              icon: <PhoneIphoneIcon />,
                              checked: this.state.sensor1LowFlowMobileNotificationEnabled,
                              onChange: this.handlerSensor1LowFlowMobileNotificationEnabled,
                              disabled: !this.isFeatureFlagOn('TOGGLE_MOBILE_NOTIFICATIONS'),
                            },
                          ]}
                        />
                      </div>
                      <FormControl
                        value={this.state.sensor1LowFlowAlarmThreshold}
                        type="text"
                        placeholder=""
                        onChange={this.handleSensor1LowFlowAlarmThresholdChange}
                      />
                    </FormGroup>
                    <FormGroup controlId="Pulse sensor high flow">
                      <div className="flex items-center justify-between">
                        <ControlLabel className=" text-left w-2/3">
                          {this.props.t('alarm_config_high_flow_alarm_threshold', {
                            units: this.state.portsConfig
                              ? Units[this.state.portsConfig[0].sensor.units] + '/h'
                              : '',
                          })}
                        </ControlLabel>
                        <NotificationCheckbox
                          notificationCheckboxes={[
                            {
                              icon: <EmailIcon />,
                              checked: this.state.sensor1HighFlowEmailNotificationEnabled,
                              onChange: this.handlerSensor1HighFlowEmailNotificationEnabled,
                            },
                            {
                              icon: <PhoneIphoneIcon />,
                              checked: this.state.sensor1HighFlowMobileNotificationEnabled,
                              onChange: this.handlerSensor1HighFlowMobileNotificationEnabled,
                              disabled: !this.isFeatureFlagOn('TOGGLE_MOBILE_NOTIFICATIONS'),
                            },
                          ]}
                        />
                      </div>
                      <FormControl
                        value={this.state.sensor1HighFlowAlarmThreshold}
                        type="text"
                        placeholder=""
                        onChange={this.handleSensor1HighFlowAlarmThresholdChange}
                      />
                    </FormGroup>
                    <FormGroup controlId="High/Low Flow alarm time range">
                      <ControlLabel>
                        {this.props.t('alarm_config_high_low_flow_time_range')}
                      </ControlLabel>
                      <Box display="flex">
                        <TimePicker
                          label={this.props.t('alarm_config_start_time')}
                          value={this.state.sensor1HighLowFlowStartTime}
                          onChange={this.handleSensor1HighLowFlowStartTimeChange}
                          renderInput={(params) => <TextField size="small" {...params} />}
                        />
                        <TimePicker
                          label={this.props.t('alarm_config_end_time')}
                          value={this.state.sensor1HighLowFlowEndTime}
                          onChange={this.handleSensor1HighLowFlowEndTimeChange}
                          renderInput={(params) => <TextField size="small" {...params} />}
                        />
                      </Box>
                    </FormGroup>
                    <FormGroup controlId="Flow calculation time base">
                      <ControlLabel>
                        {this.props.t('alarm_config_flow_calc_time_base')}
                      </ControlLabel>
                      <FormControl
                        value={this.state.sensor1FlowTimeBase}
                        componentClass="select"
                        placeholder=""
                        onChange={this.handleSensor1FlowTImeBaseChange.bind(this)}
                      >
                        <option value={this.state.confEvery * 1}>
                          {this.state.confEvery * 1} min
                        </option>
                        <option value={this.state.confEvery * 2}>
                          {this.state.confEvery * 2} min
                        </option>
                        <option value={this.state.confEvery * 3}>
                          {this.state.confEvery * 3} min
                        </option>
                        <option value={this.state.confEvery * 5}>
                          {this.state.confEvery * 5} min
                        </option>
                        <option value={this.state.confEvery * 10}>
                          {this.state.confEvery * 10} min
                        </option>
                        <option value={this.state.confEvery * 15}>
                          {this.state.confEvery * 15} min
                        </option>
                        <option value={this.state.confEvery * 30}>
                          {this.state.confEvery * 30} min
                        </option>
                        <option value={this.state.confEvery * 60}>
                          {this.state.confEvery * 60} min
                        </option>
                      </FormControl>
                    </FormGroup>
                    <FormGroup controlId="Non-Zero consumption time range">
                      <div className="flex items-center justify-between">
                        <ControlLabel className=" text-left w-2/3">
                          {this.props.t('alarm_config_non_zero_consumption_time_range')}
                        </ControlLabel>
                        <NotificationCheckbox
                          notificationCheckboxes={[
                            {
                              icon: <EmailIcon />,
                              checked: this.state.sensor1NonZeroConsumptionEmailNotificationEnabled,
                              onChange:
                                this.handlerSensor1NonZeroConsumptionEmailNotificationEnabled,
                            },
                            {
                              icon: <PhoneIphoneIcon />,
                              checked:
                                this.state.sensor1NonZeroConsumptionMobileNotificationEnabled,
                              onChange:
                                this.handlerSensor1NonZeroConsumptionMobileNotificationEnabled,
                              disabled: !this.isFeatureFlagOn('TOGGLE_MOBILE_NOTIFICATIONS'),
                            },
                          ]}
                        />
                      </div>
                      <Box display="flex">
                        <TimePicker
                          label={this.props.t('alarm_config_start_time')}
                          value={this.state.sensor1NonZeroConsumptionStartTime}
                          onChange={this.handleSensor1NonZeroConsumptionStartTimeChange}
                          renderInput={(params) => <TextField size="small" {...params} />}
                        />
                        <TimePicker
                          label={this.props.t('alarm_config_end_time')}
                          value={this.state.sensor1NonZeroConsumptionEndTime}
                          onChange={this.handleSensor1NonZeroConsumptionEndTimeChange}
                          renderInput={(params) => <TextField size="small" {...params} />}
                        />
                      </Box>
                      {!this.state.isSensor1NonZeroConsumptionRangeValid && (
                        <Typography variant="caption" color="error">
                          {this.props.t('alarm_config_non_zero_consumption_time_range_error')}
                        </Typography>
                      )}
                    </FormGroup>
                    {this.renderSeveritySelect(
                      0,
                      this.props.t('alarm_config_low_flow_severity'),
                      this.state.sensor1LowFlowSeverity ?? '',
                      this.handleSensor1LowFlowSeverityChange.bind(this)
                    )}
                    {this.renderSeveritySelect(
                      0,
                      this.props.t('alarm_config_high_flow_severity'),
                      this.state.sensor1HighFlowSeverity ?? '',
                      this.handleSensor1HighFlowSeverityChange.bind(this)
                    )}
                    {this.renderSeveritySelect(
                      0,
                      this.props.t('alarm_config_high_consumption_severity'),
                      this.state.sensor1HighConsumptionSeverity ?? '',
                      this.handleSensor1HighConsumptionSeverityChange.bind(this)
                    )}
                    {this.renderSeveritySelect(
                      0,
                      this.props.t('alarm_config_all_zero_severity'),
                      this.state.sensor1AllZeroSeverity ?? '',
                      this.handleSensor1AllZeroSeverityChange.bind(this)
                    )}
                    {this.renderSeveritySelect(
                      0,
                      this.props.t('alarm_config_non_zero_consumption_severity'),
                      this.state.sensor1NonZeroConsumptionSeverity ?? '',
                      this.handleSensor1NonZeroConsumptionSeverityChange.bind(this)
                    )}
                    {this.renderSeveritySelect(
                      0,
                      this.props.t('alarm_config_on_off_severity'),
                      this.state.sensor1OnOffSeverity ?? '',
                      this.handleSensor1OnOffSeverityChange.bind(this)
                    )}
                  </Fragment>
                )}
              </div>
              <FormGroup controlId="Pulse Sensor 2">
                <ControlLabel>{this.state.sensor2Name}</ControlLabel>
                <Switch checked={this.state.sensor2Enabeled} />
              </FormGroup>
              <div style={{ display: this.state.sensor2Enabeled ? 'block' : 'none' }}>
                {this.state.sensor2Enabeled && (
                  <Fragment>
                    <FormGroup controlId="Threshold alarm">
                      <div className="flex items-center justify-between">
                        <ControlLabel className=" text-left w-2/3">
                          {this.props.t('alarm_config_high_consumption_threshold', {
                            transmission_every: this.state.transmissionEvery,
                            transmission_period: this.state.transmissionPeriod,
                            units: this.state.portsConfig
                              ? Units[this.state.portsConfig[0].sensor.units]
                              : '',
                          })}
                        </ControlLabel>
                        <NotificationCheckbox
                          notificationCheckboxes={[
                            {
                              icon: <EmailIcon />,
                              checked: this.state.sensor2HighConsumptionEmailNotificationEnabled,
                              onChange: this.handlerSensor2HighConsumptionEmailNotificationEnabled,
                            },
                            {
                              icon: <PhoneIphoneIcon />,
                              checked: this.state.sensor2HighConsumptionMobileNotificationEnabled,
                              onChange: this.handlerSensor2HighConsumptionMobileNotificationEnabled,
                              disabled: !this.isFeatureFlagOn('TOGGLE_MOBILE_NOTIFICATIONS'),
                            },
                          ]}
                        />
                      </div>
                      <FormControl
                        value={this.state.sensor2HighConsumptionThreshold}
                        type="text"
                        placeholder=""
                        onChange={this.handleSensor2HighConsumptionThresholdChange}
                      />
                    </FormGroup>
                    <FormGroup controlId="Pulse sensor low flow">
                      <div className="flex items-center justify-between">
                        <ControlLabel className=" text-left w-2/3">
                          {this.props.t('alarm_config_low_flow_alarm_threshold', {
                            units: this.state.portsConfig
                              ? Units[this.state.portsConfig[0].sensor.units] + '/h'
                              : '',
                          })}
                        </ControlLabel>
                        <NotificationCheckbox
                          notificationCheckboxes={[
                            {
                              icon: <EmailIcon />,
                              checked: this.state.sensor2LowFlowEmailNotificationEnabled,
                              onChange: this.handlerSensor2LowFlowEmailNotificationEnabled,
                            },
                            {
                              icon: <PhoneIphoneIcon />,
                              checked: this.state.sensor2LowFlowMobileNotificationEnabled,
                              onChange: this.handlerSensor2LowFlowMobileNotificationEnabled,
                              disabled: !this.isFeatureFlagOn('TOGGLE_MOBILE_NOTIFICATIONS'),
                            },
                          ]}
                        />
                      </div>
                      <FormControl
                        value={this.state.sensor2LowFlowAlarmThreshold}
                        type="text"
                        placeholder=""
                        onChange={this.handleSensor2LowFlowAlarmThresholdChange}
                      />
                    </FormGroup>
                    <FormGroup controlId="Pulse sensor high flow">
                      <div className="flex items-center justify-between">
                        <ControlLabel className=" text-left w-2/3">
                          {this.props.t('alarm_config_high_flow_alarm_threshold', {
                            units: this.state.portsConfig
                              ? Units[this.state.portsConfig[0].sensor.units] + '/h'
                              : '',
                          })}
                        </ControlLabel>
                        <NotificationCheckbox
                          notificationCheckboxes={[
                            {
                              icon: <EmailIcon />,
                              checked: this.state.sensor2HighFlowEmailNotificationEnabled,
                              onChange: this.handlerSensor2HighFlowEmailNotificationEnabled,
                            },
                            {
                              icon: <PhoneIphoneIcon />,
                              checked: this.state.sensor2HighFlowMobileNotificationEnabled,
                              onChange: this.handlerSensor2HighFlowMobileNotificationEnabled,
                              disabled: !this.isFeatureFlagOn('TOGGLE_MOBILE_NOTIFICATIONS'),
                            },
                          ]}
                        />
                      </div>
                      <FormControl
                        value={this.state.sensor2HighFlowAlarmThreshold}
                        type="text"
                        placeholder=""
                        onChange={this.handleSensor2HighFlowAlarmThresholdChange}
                      />
                    </FormGroup>
                    <FormGroup controlId="High/Low Flow alarm time range">
                      <ControlLabel>
                        {this.props.t('alarm_config_high_low_flow_time_range')}
                      </ControlLabel>
                      <Box display="flex">
                        <TimePicker
                          label={this.props.t('alarm_config_start_time')}
                          value={this.state.sensor2HighLowFlowStartTime}
                          onChange={this.handleSensor2HighLowFlowStartTimeChange}
                          renderInput={(params) => <TextField size="small" {...params} />}
                        />
                        <TimePicker
                          label={this.props.t('alarm_config_end_time')}
                          value={this.state.sensor2HighLowFlowEndTime}
                          onChange={this.handleSensor2HighLowFlowEndTimeChange}
                          renderInput={(params) => <TextField size="small" {...params} />}
                        />
                      </Box>
                    </FormGroup>
                    <FormGroup controlId="Flow calculation time base">
                      <ControlLabel>
                        {this.props.t('alarm_config_flow_calc_time_base')}
                      </ControlLabel>
                      <FormControl
                        value={this.state.sensor2FlowTimeBase}
                        componentClass="select"
                        placeholder=""
                        onChange={this.handleSensor2FlowTImeBaseChange.bind(this)}
                      >
                        <option value={this.state.confEvery * 1}>
                          {this.state.confEvery * 1} min
                        </option>
                        <option value={this.state.confEvery * 2}>
                          {this.state.confEvery * 2} min
                        </option>
                        <option value={this.state.confEvery * 3}>
                          {this.state.confEvery * 3} min
                        </option>
                        <option value={this.state.confEvery * 5}>
                          {this.state.confEvery * 5} min
                        </option>
                        <option value={this.state.confEvery * 10}>
                          {this.state.confEvery * 10} min
                        </option>
                        <option value={this.state.confEvery * 15}>
                          {this.state.confEvery * 15} min
                        </option>
                        <option value={this.state.confEvery * 30}>
                          {this.state.confEvery * 30} min
                        </option>
                        <option value={this.state.confEvery * 60}>
                          {this.state.confEvery * 60} min
                        </option>
                      </FormControl>
                    </FormGroup>
                    <FormGroup controlId="Non-Zero consumption time range">
                      <div className="flex items-center justify-between">
                        <ControlLabel className=" text-left w-2/3">
                          {this.props.t('alarm_config_non_zero_consumption_time_range')}
                        </ControlLabel>
                        <NotificationCheckbox
                          notificationCheckboxes={[
                            {
                              icon: <EmailIcon />,
                              checked: this.state.sensor2NonZeroConsumptionEmailNotificationEnabled,
                              onChange:
                                this.handlerSensor2NonZeroConsumptionEmailNotificationEnabled,
                            },
                            {
                              icon: <PhoneIphoneIcon />,
                              checked:
                                this.state.sensor2NonZeroConsumptionMobileNotificationEnabled,
                              onChange:
                                this.handlerSensor2NonZeroConsumptionMobileNotificationEnabled,
                              disabled: !this.isFeatureFlagOn('TOGGLE_MOBILE_NOTIFICATIONS'),
                            },
                          ]}
                        />
                      </div>
                      <Box display="flex">
                        <TimePicker
                          label={this.props.t('alarm_config_start_time')}
                          value={this.state.sensor2NonZeroConsumptionStartTime}
                          onChange={this.handleSensor2NonZeroConsumptionStartTimeChange}
                          renderInput={(params) => <TextField size="small" {...params} />}
                        />
                        <TimePicker
                          label={this.props.t('alarm_config_end_time')}
                          value={this.state.sensor2NonZeroConsumptionEndTime}
                          onChange={this.handleSensor2NonZeroConsumptionEndTimeChange}
                          renderInput={(params) => <TextField size="small" {...params} />}
                        />
                      </Box>
                      {!this.state.isSensor2NonZeroConsumptionRangeValid && (
                        <Typography variant="caption" color="error">
                          {this.props.t('alarm_config_non_zero_consumption_time_range_error')}
                        </Typography>
                      )}
                    </FormGroup>
                    {this.renderSeveritySelect(
                      1,
                      this.props.t('alarm_config_low_flow_severity'),
                      this.state.sensor2LowFlowSeverity ?? '',
                      this.handleSensor2LowFlowSeverityChange.bind(this)
                    )}
                    {this.renderSeveritySelect(
                      1,
                      this.props.t('alarm_config_high_flow_severity'),
                      this.state.sensor2HighFlowSeverity ?? '',
                      this.handleSensor2HighFlowSeverityChange.bind(this)
                    )}
                    {this.renderSeveritySelect(
                      1,
                      this.props.t('alarm_config_high_consumption_severity'),
                      this.state.sensor2HighConsumptionSeverity ?? '',
                      this.handleSensor2HighConsumptionSeverityChange.bind(this)
                    )}
                    {this.renderSeveritySelect(
                      1,
                      this.props.t('alarm_config_all_zero_severity'),
                      this.state.sensor2AllZeroSeverity ?? '',
                      this.handleSensor2AllZeroSeverityChange.bind(this)
                    )}
                    {this.renderSeveritySelect(
                      1,
                      this.props.t('alarm_config_non_zero_consumption_severity'),
                      this.state.sensor2NonZeroConsumptionSeverity ?? '',
                      this.handleSensor2NonZeroConsumptionSeverityChange.bind(this)
                    )}
                    {this.renderSeveritySelect(
                      1,
                      this.props.t('alarm_config_on_off_severity'),
                      this.state.sensor2OnOffSeverity ?? '',
                      this.handleSensor2OnOffSeverityChange.bind(this)
                    )}
                  </Fragment>
                )}
              </div>
              <FormGroup controlId="Digits - Pulse Sensor 2">
                <ControlLabel>{this.state.sensor3Name} </ControlLabel>
                <Switch checked={this.state.sensor3Enabeled} />
                {this.state.sensor3Enabeled && (
                  <Fragment>
                    {this.renderSeveritySelect(
                      2,
                      this.props.t('alarm_config_low_flow_severity'),
                      this.state.sensor3LowFlowSeverity ?? '',
                      this.handleSensor3LowFlowSeverityChange.bind(this)
                    )}
                    {this.renderSeveritySelect(
                      2,
                      this.props.t('alarm_config_high_flow_severity'),
                      this.state.sensor3HighFlowSeverity ?? '',
                      this.handleSensor3HighFlowSeverityChange.bind(this)
                    )}
                    {this.renderSeveritySelect(
                      2,
                      this.props.t('alarm_config_high_consumption_severity'),
                      this.state.sensor3HighConsumptionSeverity ?? '',
                      this.handleSensor3HighConsumptionSeverityChange.bind(this)
                    )}
                    {this.renderSeveritySelect(
                      2,
                      this.props.t('alarm_config_all_zero_severity'),
                      this.state.sensor3AllZeroSeverity ?? '',
                      this.handleSensor3AllZeroSeverityChange.bind(this)
                    )}
                    {this.renderSeveritySelect(
                      2,
                      this.props.t('alarm_config_non_zero_consumption_severity'),
                      this.state.sensor3NonZeroConsumptionSeverity ?? '',
                      this.handleSensor3NonZeroConsumptionSeverityChange.bind(this)
                    )}
                    {this.renderSeveritySelect(
                      2,
                      this.props.t('alarm_config_on_off_severity'),
                      this.state.sensor3OnOffSeverity ?? '',
                      this.handleSensor3OnOffSeverityChange.bind(this)
                    )}
                  </Fragment>
                )}
              </FormGroup>
              <FormGroup controlId="Analog Sensor 2">
                <ControlLabel>{this.state.sensor4Name} </ControlLabel>
                <Switch checked={this.state.sensor4Enabeled} />
                {this.state.sensor4Enabeled && (
                  <Fragment>
                    {this.renderSeveritySelect(
                      3,
                      this.props.t('alarm_config_low_flow_severity'),
                      this.state.sensor4LowFlowSeverity ?? '',
                      this.handleSensor4LowFlowSeverityChange.bind(this)
                    )}
                    {this.renderSeveritySelect(
                      3,
                      this.props.t('alarm_config_high_flow_severity'),
                      this.state.sensor4HighFlowSeverity ?? '',
                      this.handleSensor4HighFlowSeverityChange.bind(this)
                    )}
                    {this.renderSeveritySelect(
                      3,
                      this.props.t('alarm_config_high_consumption_severity'),
                      this.state.sensor4HighConsumptionSeverity ?? '',
                      this.handleSensor4HighConsumptionSeverityChange.bind(this)
                    )}
                    {this.renderSeveritySelect(
                      3,
                      this.props.t('alarm_config_all_zero_severity'),
                      this.state.sensor4AllZeroSeverity ?? '',
                      this.handleSensor4AllZeroSeverityChange.bind(this)
                    )}
                    {this.renderSeveritySelect(
                      3,
                      this.props.t('alarm_config_non_zero_consumption_severity'),
                      this.state.sensor4NonZeroConsumptionSeverity ?? '',
                      this.handleSensor4NonZeroConsumptionSeverityChange.bind(this)
                    )}
                    {this.renderSeveritySelect(
                      3,
                      this.props.t('alarm_config_on_off_severity'),
                      this.state.sensor4OnOffSeverity ?? '',
                      this.handleSensor4OnOffSeverityChange.bind(this)
                    )}
                  </Fragment>
                )}
              </FormGroup>
              {this.state.sensor9Exists && (
                <FormGroup controlId="Analog Sensor 3">
                  <ControlLabel>{this.state.sensor9Name} </ControlLabel>
                  <Switch checked={this.state.sensor9Enabled} />
                  {this.state.sensor9Enabled && (
                    <Fragment>
                      {this.renderSeveritySelect(
                        8,
                        this.props.t('alarm_config_low_flow_severity'),
                        this.state.sensor9LowFlowSeverity ?? '',
                        this.handleSensor9LowFlowSeverityChange.bind(this)
                      )}
                      {this.renderSeveritySelect(
                        8,
                        this.props.t('alarm_config_high_flow_severity'),
                        this.state.sensor9HighFlowSeverity ?? '',
                        this.handleSensor9HighFlowSeverityChange.bind(this)
                      )}
                      {this.renderSeveritySelect(
                        8,
                        this.props.t('alarm_config_high_consumption_severity'),
                        this.state.sensor9HighConsumptionSeverity ?? '',
                        this.handleSensor9HighConsumptionSeverityChange.bind(this)
                      )}
                      {this.renderSeveritySelect(
                        8,
                        this.props.t('alarm_config_all_zero_severity'),
                        this.state.sensor9AllZeroSeverity ?? '',
                        this.handleSensor9AllZeroSeverityChange.bind(this)
                      )}
                      {this.renderSeveritySelect(
                        8,
                        this.props.t('alarm_config_non_zero_consumption_severity'),
                        this.state.sensor9NonZeroConsumptionSeverity ?? '',
                        this.handleSensor9NonZeroConsumptionSeverityChange.bind(this)
                      )}
                      {this.renderSeveritySelect(
                        8,
                        this.props.t('alarm_config_on_off_severity'),
                        this.state.sensor9OnOffSeverity ?? '',
                        this.handleSensor9OnOffSeverityChange.bind(this)
                      )}
                    </Fragment>
                  )}
                </FormGroup>
              )}
              {this.state.sensor10Exists && (
                <FormGroup controlId="Analog Sensor 4">
                  <ControlLabel>{this.state.sensor10Name}</ControlLabel>
                  <Switch checked={this.state.sensor10Enabled} />
                  {this.state.sensor10Enabled && (
                    <Fragment>
                      {this.renderSeveritySelect(
                        9,
                        this.props.t('alarm_config_low_flow_severity'),
                        this.state.sensor10LowFlowSeverity ?? '',
                        this.handleSensor10LowFlowSeverityChange.bind(this)
                      )}
                      {this.renderSeveritySelect(
                        9,
                        this.props.t('alarm_config_high_flow_severity'),
                        this.state.sensor10HighFlowSeverity ?? '',
                        this.handleSensor10HighFlowSeverityChange.bind(this)
                      )}
                      {this.renderSeveritySelect(
                        9,
                        this.props.t('alarm_config_high_consumption_severity'),
                        this.state.sensor10HighConsumptionSeverity ?? '',
                        this.handleSensor10HighConsumptionSeverityChange.bind(this)
                      )}
                      {this.renderSeveritySelect(
                        9,
                        this.props.t('alarm_config_all_zero_severity'),
                        this.state.sensor10AllZeroSeverity ?? '',
                        this.handleSensor10AllZeroSeverityChange.bind(this)
                      )}
                      {this.renderSeveritySelect(
                        9,
                        this.props.t('alarm_config_non_zero_consumption_severity'),
                        this.state.sensor10NonZeroConsumptionSeverity ?? '',
                        this.handleSensor10NonZeroConsumptionSeverityChange.bind(this)
                      )}
                      {this.renderSeveritySelect(
                        9,
                        this.props.t('alarm_config_on_off_severity'),
                        this.state.sensor10OnOffSeverity ?? '',
                        this.handleSensor10OnOffSeverityChange.bind(this)
                      )}
                    </Fragment>
                  )}
                </FormGroup>
              )}
              {this.state.sensor11Exists && (
                <FormGroup controlId="Analog Sensor 5">
                  <ControlLabel>{this.state.sensor11Name}</ControlLabel>
                  <Switch checked={this.state.sensor11Enabled} />
                  {this.state.sensor11Enabled && (
                    <Fragment>
                      {this.renderSeveritySelect(
                        10,
                        this.props.t('alarm_config_low_flow_severity'),
                        this.state.sensor11LowFlowSeverity ?? '',
                        this.handleSensor11LowFlowSeverityChange.bind(this)
                      )}
                      {this.renderSeveritySelect(
                        10,
                        this.props.t('alarm_config_high_flow_severity'),
                        this.state.sensor11HighFlowSeverity ?? '',
                        this.handleSensor11HighFlowSeverityChange.bind(this)
                      )}
                      {this.renderSeveritySelect(
                        10,
                        this.props.t('alarm_config_high_consumption_severity'),
                        this.state.sensor11HighConsumptionSeverity ?? '',
                        this.handleSensor11HighConsumptionSeverityChange.bind(this)
                      )}
                      {this.renderSeveritySelect(
                        10,
                        this.props.t('alarm_config_all_zero_severity'),
                        this.state.sensor11AllZeroSeverity ?? '',
                        this.handleSensor11AllZeroSeverityChange.bind(this)
                      )}
                      {this.renderSeveritySelect(
                        10,
                        this.props.t('alarm_config_non_zero_consumption_severity'),
                        this.state.sensor11NonZeroConsumptionSeverity ?? '',
                        this.handleSensor11NonZeroConsumptionSeverityChange.bind(this)
                      )}
                      {this.renderSeveritySelect(
                        10,
                        this.props.t('alarm_config_on_off_severity'),
                        this.state.sensor11OnOffSeverity ?? '',
                        this.handleSensor11OnOffSeverityChange.bind(this)
                      )}
                    </Fragment>
                  )}
                </FormGroup>
              )}
              {this.state.sensor12Exists && (
                <FormGroup controlId="Analog Sensor 6">
                  <ControlLabel>{this.state.sensor12Name}</ControlLabel>
                  <Switch checked={this.state.sensor12Enabled} />
                  {this.state.sensor12Enabled && (
                    <Fragment>
                      {this.renderSeveritySelect(
                        11,
                        this.props.t('alarm_config_low_flow_severity'),
                        this.state.sensor12LowFlowSeverity ?? '',
                        this.handleSensor12LowFlowSeverityChange.bind(this)
                      )}
                      {this.renderSeveritySelect(
                        11,
                        this.props.t('alarm_config_high_flow_severity'),
                        this.state.sensor12HighFlowSeverity ?? '',
                        this.handleSensor12HighFlowSeverityChange.bind(this)
                      )}
                      {this.renderSeveritySelect(
                        11,
                        this.props.t('alarm_config_high_consumption_severity'),
                        this.state.sensor12HighConsumptionSeverity ?? '',
                        this.handleSensor12HighConsumptionSeverityChange.bind(this)
                      )}
                      {this.renderSeveritySelect(
                        11,
                        this.props.t('alarm_config_all_zero_severity'),
                        this.state.sensor12AllZeroSeverity ?? '',
                        this.handleSensor12AllZeroSeverityChange.bind(this)
                      )}
                      {this.renderSeveritySelect(
                        11,
                        this.props.t('alarm_config_non_zero_consumption_severity'),
                        this.state.sensor12NonZeroConsumptionSeverity ?? '',
                        this.handleSensor12NonZeroConsumptionSeverityChange.bind(this)
                      )}
                      {this.renderSeveritySelect(
                        11,
                        this.props.t('alarm_config_on_off_severity'),
                        this.state.sensor12OnOffSeverity ?? '',
                        this.handleSensor12OnOffSeverityChange.bind(this)
                      )}
                    </Fragment>
                  )}
                </FormGroup>
              )}
              {this.state.sensor13Exists && (
                <FormGroup controlId="Analog Sensor 7">
                  <ControlLabel>{this.state.sensor13Name}</ControlLabel>
                  <Switch checked={this.state.sensor13Enabled} />
                  {this.state.sensor13Enabled && (
                    <Fragment>
                      {this.renderSeveritySelect(
                        12,
                        this.props.t('alarm_config_low_flow_severity'),
                        this.state.sensor13LowFlowSeverity ?? '',
                        this.handleSensor13LowFlowSeverityChange.bind(this)
                      )}
                      {this.renderSeveritySelect(
                        12,
                        this.props.t('alarm_config_high_flow_severity'),
                        this.state.sensor13HighFlowSeverity ?? '',
                        this.handleSensor13HighFlowSeverityChange.bind(this)
                      )}
                      {this.renderSeveritySelect(
                        12,
                        this.props.t('alarm_config_high_consumption_severity'),
                        this.state.sensor13HighConsumptionSeverity ?? '',
                        this.handleSensor13HighConsumptionSeverityChange.bind(this)
                      )}
                      {this.renderSeveritySelect(
                        12,
                        this.props.t('alarm_config_all_zero_severity'),
                        this.state.sensor13AllZeroSeverity ?? '',
                        this.handleSensor13AllZeroSeverityChange.bind(this)
                      )}
                      {this.renderSeveritySelect(
                        12,
                        this.props.t('alarm_config_non_zero_consumption_severity'),
                        this.state.sensor13NonZeroConsumptionSeverity ?? '',
                        this.handleSensor13NonZeroConsumptionSeverityChange.bind(this)
                      )}
                      {this.renderSeveritySelect(
                        12,
                        this.props.t('alarm_config_on_off_severity'),
                        this.state.sensor13OnOffSeverity ?? '',
                        this.handleSensor13OnOffSeverityChange.bind(this)
                      )}
                    </Fragment>
                  )}
                </FormGroup>
              )}
              {this.state.sensor14Exists && (
                <FormGroup controlId="Analog Sensor 8">
                  <ControlLabel>{this.state.sensor14Name}</ControlLabel>
                  <Switch checked={this.state.sensor14Enabled} />
                  {this.state.sensor14Enabled && (
                    <Fragment>
                      {this.renderSeveritySelect(
                        13,
                        this.props.t('alarm_config_low_flow_severity'),
                        this.state.sensor14LowFlowSeverity ?? '',
                        this.handleSensor14LowFlowSeverityChange.bind(this)
                      )}
                      {this.renderSeveritySelect(
                        13,
                        this.props.t('alarm_config_high_flow_severity'),
                        this.state.sensor14HighFlowSeverity ?? '',
                        this.handleSensor14HighFlowSeverityChange.bind(this)
                      )}
                      {this.renderSeveritySelect(
                        13,
                        this.props.t('alarm_config_high_consumption_severity'),
                        this.state.sensor14HighConsumptionSeverity ?? '',
                        this.handleSensor14HighConsumptionSeverityChange.bind(this)
                      )}
                      {this.renderSeveritySelect(
                        13,
                        this.props.t('alarm_config_all_zero_severity'),
                        this.state.sensor14AllZeroSeverity ?? '',
                        this.handleSensor14AllZeroSeverityChange.bind(this)
                      )}
                      {this.renderSeveritySelect(
                        13,
                        this.props.t('alarm_config_non_zero_consumption_severity'),
                        this.state.sensor14NonZeroConsumptionSeverity ?? '',
                        this.handleSensor14NonZeroConsumptionSeverityChange.bind(this)
                      )}
                      {this.renderSeveritySelect(
                        13,
                        this.props.t('alarm_config_on_off_severity'),
                        this.state.sensor14OnOffSeverity ?? '',
                        this.handleSensor14OnOffSeverityChange.bind(this)
                      )}
                    </Fragment>
                  )}
                </FormGroup>
              )}
              <FormGroup controlId="On/Off Sensor 1">
                <ControlLabel>{this.state.sensor5Name} </ControlLabel>
                <Switch checked={this.state.sensor5Enabeled} />
                {this.state.sensor5Enabeled && (
                  <Fragment>
                    {this.renderSeveritySelect(
                      4,
                      this.props.t('alarm_config_low_flow_severity'),
                      this.state.sensor5LowFlowSeverity ?? '',
                      this.handleSensor5LowFlowSeverityChange.bind(this)
                    )}
                    {this.renderSeveritySelect(
                      4,
                      this.props.t('alarm_config_high_flow_severity'),
                      this.state.sensor5HighFlowSeverity ?? '',
                      this.handleSensor5HighFlowSeverityChange.bind(this)
                    )}
                    {this.renderSeveritySelect(
                      4,
                      this.props.t('alarm_config_high_consumption_severity'),
                      this.state.sensor5HighConsumptionSeverity ?? '',
                      this.handleSensor5HighConsumptionSeverityChange.bind(this)
                    )}
                    {this.renderSeveritySelect(
                      4,
                      this.props.t('alarm_config_all_zero_severity'),
                      this.state.sensor5AllZeroSeverity ?? '',
                      this.handleSensor5AllZeroSeverityChange.bind(this)
                    )}
                    {this.renderSeveritySelect(
                      4,
                      this.props.t('alarm_config_non_zero_consumption_severity'),
                      this.state.sensor5NonZeroConsumptionSeverity ?? '',
                      this.handleSensor5NonZeroConsumptionSeverityChange.bind(this)
                    )}
                    {this.renderSeveritySelect(
                      4,
                      this.props.t('alarm_config_on_off_severity'),
                      this.state.sensor5OnOffSeverity ?? '',
                      this.handleSensor5OnOffSeverityChange.bind(this)
                    )}
                  </Fragment>
                )}
              </FormGroup>
              <FormGroup controlId="On/Off Sensor 2">
                <ControlLabel>{this.state.sensor6Name} </ControlLabel>
                <Switch checked={this.state.sensor6Enabeled} />
                {this.state.sensor6Enabeled && (
                  <Fragment>
                    {this.renderSeveritySelect(
                      5,
                      this.props.t('alarm_config_low_flow_severity'),
                      this.state.sensor6LowFlowSeverity ?? '',
                      this.handleSensor6LowFlowSeverityChange.bind(this)
                    )}
                    {this.renderSeveritySelect(
                      5,
                      this.props.t('alarm_config_high_flow_severity'),
                      this.state.sensor6HighFlowSeverity ?? '',
                      this.handleSensor6HighFlowSeverityChange.bind(this)
                    )}
                    {this.renderSeveritySelect(
                      5,
                      this.props.t('alarm_config_high_consumption_severity'),
                      this.state.sensor6HighConsumptionSeverity ?? '',
                      this.handleSensor6HighConsumptionSeverityChange.bind(this)
                    )}
                    {this.renderSeveritySelect(
                      5,
                      this.props.t('alarm_config_all_zero_severity'),
                      this.state.sensor6AllZeroSeverity ?? '',
                      this.handleSensor6AllZeroSeverityChange.bind(this)
                    )}
                    {this.renderSeveritySelect(
                      5,
                      this.props.t('alarm_config_non_zero_consumption_severity'),
                      this.state.sensor6NonZeroConsumptionSeverity ?? '',
                      this.handleSensor6NonZeroConsumptionSeverityChange.bind(this)
                    )}
                    {this.renderSeveritySelect(
                      5,
                      this.props.t('alarm_config_on_off_severity'),
                      this.state.sensor6OnOffSeverity ?? '',
                      this.handleSensor6OnOffSeverityChange.bind(this)
                    )}
                  </Fragment>
                )}
              </FormGroup>
              {this.state.sensor7Exists && (
                <FormGroup controlId="On/Off Relay 1">
                  <ControlLabel>{this.state.sensor7Name} </ControlLabel>
                  <Switch checked={this.state.sensor7Enabled} />
                  {this.state.sensor7Enabled && (
                    <Fragment>
                      {this.renderSeveritySelect(
                        6,
                        this.props.t('alarm_config_low_flow_severity'),
                        this.state.sensor7LowFlowSeverity ?? '',
                        this.handleSensor7LowFlowSeverityChange.bind(this)
                      )}
                      {this.renderSeveritySelect(
                        6,
                        this.props.t('alarm_config_high_flow_severity'),
                        this.state.sensor7HighFlowSeverity ?? '',
                        this.handleSensor7HighFlowSeverityChange.bind(this)
                      )}
                      {this.renderSeveritySelect(
                        6,
                        this.props.t('alarm_config_high_consumption_severity'),
                        this.state.sensor7HighConsumptionSeverity ?? '',
                        this.handleSensor7HighConsumptionSeverityChange.bind(this)
                      )}
                      {this.renderSeveritySelect(
                        6,
                        this.props.t('alarm_config_all_zero_severity'),
                        this.state.sensor7AllZeroSeverity ?? '',
                        this.handleSensor7AllZeroSeverityChange.bind(this)
                      )}
                      {this.renderSeveritySelect(
                        6,
                        this.props.t('alarm_config_non_zero_consumption_severity'),
                        this.state.sensor7NonZeroConsumptionSeverity ?? '',
                        this.handleSensor7NonZeroConsumptionSeverityChange.bind(this)
                      )}
                      {this.renderSeveritySelect(
                        6,
                        this.props.t('alarm_config_on_off_severity'),
                        this.state.sensor7OnOffSeverity ?? '',
                        this.handleSensor7OnOffSeverityChange.bind(this)
                      )}
                    </Fragment>
                  )}
                </FormGroup>
              )}
              {this.state.sensor8Exists && (
                <FormGroup controlId="On/Off Relay 2">
                  <ControlLabel>{this.state.sensor8Name} </ControlLabel>
                  <Switch checked={this.state.sensor8Enabled} />
                  {this.state.sensor8Enabled && (
                    <Fragment>
                      {this.renderSeveritySelect(
                        7,
                        this.props.t('alarm_config_low_flow_severity'),
                        this.state.sensor8LowFlowSeverity ?? '',
                        this.handleSensor8LowFlowSeverityChange.bind(this)
                      )}
                      {this.renderSeveritySelect(
                        7,
                        this.props.t('alarm_config_high_flow_severity'),
                        this.state.sensor8HighFlowSeverity ?? '',
                        this.handleSensor8HighFlowSeverityChange.bind(this)
                      )}
                      {this.renderSeveritySelect(
                        7,
                        this.props.t('alarm_config_high_consumption_severity'),
                        this.state.sensor8HighConsumptionSeverity ?? '',
                        this.handleSensor8HighConsumptionSeverityChange.bind(this)
                      )}
                      {this.renderSeveritySelect(
                        7,
                        this.props.t('alarm_config_all_zero_severity'),
                        this.state.sensor8AllZeroSeverity ?? '',
                        this.handleSensor8AllZeroSeverityChange.bind(this)
                      )}
                      {this.renderSeveritySelect(
                        7,
                        this.props.t('alarm_config_non_zero_consumption_severity'),
                        this.state.sensor8NonZeroConsumptionSeverity ?? '',
                        this.handleSensor8NonZeroConsumptionSeverityChange.bind(this)
                      )}
                      {this.renderSeveritySelect(
                        7,
                        this.props.t('alarm_config_on_off_severity'),
                        this.state.sensor8OnOffSeverity ?? '',
                        this.handleSensor8OnOffSeverityChange.bind(this)
                      )}
                    </Fragment>
                  )}
                </FormGroup>
              )}

              <Button
                variant="contained"
                onClick={this.handleSubmit}
                disabled={this.state.saveDisabled}
              >
                {this.props.t('button_save')}
              </Button>
              <Snackbar
                open={this.state.open}
                message={this.props.t('alarm_config_saved_msg')}
                onClose={this.handleRequestClose}
              />
            </form>
          </div>
        </Header>
      );
    } else {
      return (
        <Header>
          <div>
            <CircularProgress size={80} thickness={5} />
          </div>
        </Header>
      );
    }
  }

  componentDidMount() {
    this.loadDeviceConfigFromServer();
    this.intervalId = setInterval(this.loadConfiguredFlag, 5000);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.match.params.deviceNumber !== prevProps.match.params.deviceNumber) {
      this.setState({
        loaded: false,
      });
      this.loadDeviceConfigFromServer();
    }
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  timeToDate(str) {
    if (str === undefined || str === null) return null;
    let idx = str.indexOf(':');
    if (idx === -1) return null;
    let h = str.substring(0, idx);
    let m = str.substring(idx + 1);
    let d = new Date();
    d.setHours(h);
    d.setMinutes(m);
    return d;
  }

  loadDeviceConfigFromServer() {
    devicesClient.getDevice(
      this.props.match.params.deviceNumber,
      (device) => {
        sensorsConfigClient.getPortsConfig(
          this.props.match.params.deviceNumber,
          (portsConfig) => {
            const { t } = this.props;
            this.setState((prevState) => ({
              ...prevState,
              sensor1Name: portsConfig[0]?.sensor.name || t('pulse_sensor_1'),
              sensor2Name: portsConfig[1]?.sensor.name || t('pulse_sensor_2'),
              sensor3Name: portsConfig[2]?.sensor.name || t('analog_sensor_1'),
              sensor4Name: portsConfig[3]?.sensor.name || t('analog_sensor_2'),
              sensor5Name: portsConfig[4]?.sensor.name || t('on_off_sensor_1'),
              sensor6Name: portsConfig[5]?.sensor.name || t('on_off_sensor_2'),
              sensor7Name: portsConfig[6]?.sensor.name || t('on_off_sensor_3'),
              sensor8Name: portsConfig[7]?.sensor.name || t('on_off_sensor_4'),
              sensor9Name: portsConfig[8]?.sensor.name || t('analog_sensor_3'),
              sensor10Name: portsConfig[9]?.sensor.name || t('analog_sensor_4'),
              sensor11Name: portsConfig[10]?.sensor.name || t('analog_sensor_5'),
              sensor12Name: portsConfig[11]?.sensor.name || t('analog_sensor_6'),
              sensor13Name: portsConfig[12]?.sensor.name || t('analog_sensor_7'),
              sensor14Name: portsConfig[13]?.sensor.name || t('analog_sensor_8'),
            }));
            deviceConfigClient.getDeviceConfig(
              this.props.match.params.deviceNumber,
              (conf) => {
                const { recordPeriod, every, countsThreshold } = conf;

                const SECONDS_IN_DAY = 24 * 60 * 60;
                const SECONDS_IN_HOUR = 60 * 60;
                const SECONDS_IN_MINUTE = 60;

                let transmissionEveryInSeconds, transmissionPeriod, transmissionEvery;

                switch (recordPeriod) {
                  case 'DAYS':
                    transmissionEveryInSeconds = countsThreshold * every * SECONDS_IN_DAY;
                    break;
                  case 'HOURS':
                    transmissionEveryInSeconds = countsThreshold * every * SECONDS_IN_HOUR;
                    break;
                  case 'MINUTES':
                    transmissionEveryInSeconds = countsThreshold * every * SECONDS_IN_MINUTE;
                    break;
                  case 'SECONDS':
                    transmissionEveryInSeconds = countsThreshold * every;
                }

                if (transmissionEveryInSeconds / SECONDS_IN_DAY >= 1) {
                  transmissionEvery = transmissionEveryInSeconds / SECONDS_IN_DAY;
                  transmissionPeriod = t('day', { count: transmissionEvery });
                } else if (transmissionEveryInSeconds / SECONDS_IN_HOUR >= 1) {
                  transmissionEvery = transmissionEveryInSeconds / SECONDS_IN_HOUR;
                  transmissionPeriod = t('hour', { count: transmissionEvery });
                } else if (transmissionEveryInSeconds / SECONDS_IN_MINUTE >= 1) {
                  transmissionEvery = transmissionEveryInSeconds / SECONDS_IN_MINUTE;
                  transmissionPeriod = t('minute', { count: transmissionEvery });
                } else {
                  transmissionEvery = transmissionEveryInSeconds;
                  transmissionPeriod = t('second', { count: transmissionEvery });
                }

                alarmConfigClient.getSensorsConfig(
                  this.props.match.params.deviceNumber,
                  (sensorConf) => {
                    let sensor1AlarmConfig = this.getPortsNotificationsConfig(
                      portsConfig,
                      sensorConf,
                      0
                    );
                    let sensor2AlarmConfig = this.getPortsNotificationsConfig(
                      portsConfig,
                      sensorConf,
                      1
                    );

                    this.setState({
                      sensor1Enabeled: sensorConf[0].enabled,
                      sensor2Enabeled: sensorConf[1].enabled,
                      sensor3Enabeled: sensorConf[2].enabled,
                      sensor4Enabeled: sensorConf[3].enabled,
                      sensor5Enabeled: sensorConf[4]?.enabled ? sensorConf[4].enabled : false,
                      sensor6Enabeled: sensorConf[5]?.enabled ? sensorConf[5].enabled : false,
                      sensor7Exists: !!sensorConf[6],
                      sensor7Enabled: sensorConf[6]?.enabled ? sensorConf[6].enabled : false,
                      sensor8Exists: !!sensorConf[7],
                      sensor8Enabled: sensorConf[7]?.enabled ? sensorConf[7].enabled : false,
                      sensor9Exists: !!sensorConf[8],
                      sensor9Enabled: sensorConf[8]?.enabled ? sensorConf[8].enabled : false,
                      sensor10Exists: !!sensorConf[9],
                      sensor10Enabled: sensorConf[9]?.enabled ? sensorConf[9].enabled : false,
                      sensor11Exists: !!sensorConf[10],
                      sensor11Enabled: sensorConf[10]?.enabled ? sensorConf[10].enabled : false,
                      sensor12Exists: !!sensorConf[11],
                      sensor12Enabled: sensorConf[11]?.enabled ? sensorConf[11].enabled : false,
                      sensor13Exists: !!sensorConf[12],
                      sensor13Enabled: sensorConf[12]?.enabled ? sensorConf[12].enabled : false,
                      sensor14Exists: !!sensorConf[13],
                      sensor14Enabled: sensorConf[13]?.enabled ? sensorConf[13].enabled : false,
                      sensor1HighLowFlowStartTime:
                        this.timeToDate(sensorConf[0].parameters.high_low_flow_start_time) || null,
                      sensor1HighLowFlowEndTime:
                        this.timeToDate(sensorConf[0].parameters.high_low_flow_end_time) || null,
                      sensor2HighLowFlowStartTime:
                        this.timeToDate(sensorConf[1].parameters.high_low_flow_start_time) || null,
                      sensor2HighLowFlowEndTime:
                        this.timeToDate(sensorConf[1].parameters.high_low_flow_end_time) || null,
                      sensor1FlowTimeBase:
                        sensorConf[0].parameters.flow_time_base === undefined
                          ? every
                          : sensorConf[0].parameters.flow_time_base,
                      sensor2FlowTimeBase:
                        sensorConf[1].parameters.flow_time_base === undefined
                          ? every
                          : sensorConf[1].parameters.flow_time_base,
                      sensor1NonZeroConsumptionStartTime: this.timeToDate(
                        sensorConf[0].parameters.non_zero_consumption_start_time
                      ),
                      sensor1NonZeroConsumptionEndTime: this.timeToDate(
                        sensorConf[0].parameters.non_zero_consumption_end_time
                      ),
                      sensor2NonZeroConsumptionStartTime: this.timeToDate(
                        sensorConf[1].parameters.non_zero_consumption_start_time
                      ),
                      sensor2NonZeroConsumptionEndTime: this.timeToDate(
                        sensorConf[1].parameters.non_zero_consumption_end_time
                      ),

                      // Sensor 1
                      sensor1LowFlowSeverity: sensorConf[0].parameters?.low_flow_severity ?? null,
                      sensor1HighFlowSeverity: sensorConf[0].parameters?.high_flow_severity ?? null,
                      sensor1HighConsumptionSeverity:
                        sensorConf[0].parameters?.high_consumption_severity ?? null,
                      sensor1AllZeroSeverity:
                        sensorConf[0].parameters?.all_zero_consumption_severity ?? null,
                      sensor1NonZeroConsumptionSeverity:
                        sensorConf[0].parameters?.non_zero_consumption_severity ?? null,
                      sensor1OnOffSeverity: sensorConf[0].parameters?.on_off_severity ?? null,

                      // Sensor 2
                      sensor2LowFlowSeverity: sensorConf[1].parameters?.low_flow_severity ?? null,
                      sensor2HighFlowSeverity: sensorConf[1].parameters?.high_flow_severity ?? null,
                      sensor2HighConsumptionSeverity:
                        sensorConf[1].parameters?.high_consumption_severity ?? null,
                      sensor2AllZeroSeverity:
                        sensorConf[1].parameters?.all_zero_consumption_severity ?? null,
                      sensor2NonZeroConsumptionSeverity:
                        sensorConf[1].parameters?.non_zero_consumption_severity ?? null,
                      sensor2OnOffSeverity: sensorConf[1].parameters?.on_off_severity ?? null,

                      // Sensor 3
                      sensor3LowFlowSeverity: sensorConf[2].parameters?.low_flow_severity ?? null,
                      sensor3HighFlowSeverity: sensorConf[2].parameters?.high_flow_severity ?? null,
                      sensor3HighConsumptionSeverity:
                        sensorConf[2].parameters?.high_consumption_severity ?? null,
                      sensor3AllZeroSeverity:
                        sensorConf[2].parameters?.all_zero_consumption_severity ?? null,
                      sensor3NonZeroConsumptionSeverity:
                        sensorConf[2].parameters?.non_zero_consumption_severity ?? null,
                      sensor3OnOffSeverity: sensorConf[2].parameters?.on_off_severity ?? null,

                      // Sensor 4
                      sensor4LowFlowSeverity: sensorConf[3].parameters?.low_flow_severity ?? null,
                      sensor4HighFlowSeverity: sensorConf[3].parameters?.high_flow_severity ?? null,
                      sensor4HighConsumptionSeverity:
                        sensorConf[3].parameters?.high_consumption_severity ?? null,
                      sensor4AllZeroSeverity:
                        sensorConf[3].parameters?.all_zero_consumption_severity ?? null,
                      sensor4NonZeroConsumptionSeverity:
                        sensorConf[3].parameters?.non_zero_consumption_severity ?? null,
                      sensor4OnOffSeverity: sensorConf[3].parameters?.on_off_severity ?? null,

                      // Sensor 5
                      sensor5LowFlowSeverity: sensorConf[4].parameters?.low_flow_severity ?? null,
                      sensor5HighFlowSeverity: sensorConf[4].parameters?.high_flow_severity ?? null,
                      sensor5HighConsumptionSeverity:
                        sensorConf[4].parameters?.high_consumption_severity ?? null,
                      sensor5AllZeroSeverity:
                        sensorConf[4].parameters?.all_zero_consumption_severity ?? null,
                      sensor5NonZeroConsumptionSeverity:
                        sensorConf[4].parameters?.non_zero_consumption_severity ?? null,
                      sensor5OnOffSeverity: sensorConf[4].parameters?.on_off_severity ?? null,

                      // Sensor 6
                      sensor6LowFlowSeverity: sensorConf[5].parameters?.low_flow_severity ?? null,
                      sensor6HighFlowSeverity: sensorConf[5].parameters?.high_flow_severity ?? null,
                      sensor6HighConsumptionSeverity:
                        sensorConf[5].parameters?.high_consumption_severity ?? null,
                      sensor6AllZeroSeverity:
                        sensorConf[5].parameters?.all_zero_consumption_severity ?? null,
                      sensor6NonZeroConsumptionSeverity:
                        sensorConf[5].parameters?.non_zero_consumption_severity ?? null,
                      sensor6OnOffSeverity: sensorConf[5].parameters?.on_off_severity ?? null,

                      // Sensor 7
                      sensor7LowFlowSeverity:
                        sensorConf?.[6]?.parameters?.low_flow_severity ?? null,
                      sensor7HighFlowSeverity:
                        sensorConf?.[6]?.parameters?.high_flow_severity ?? null,
                      sensor7HighConsumptionSeverity:
                        sensorConf?.[6]?.parameters?.high_consumption_severity ?? null,
                      sensor7AllZeroSeverity:
                        sensorConf?.[6]?.parameters?.all_zero_consumption_severity ?? null,
                      sensor7NonZeroConsumptionSeverity:
                        sensorConf?.[6]?.parameters?.non_zero_consumption_severity ?? null,
                      sensor7OnOffSeverity: sensorConf?.[6]?.parameters?.on_off_severity ?? null,

                      // Sensor 8
                      sensor8LowFlowSeverity:
                        sensorConf?.[7]?.parameters?.low_flow_severity ?? null,
                      sensor8HighFlowSeverity:
                        sensorConf?.[7]?.parameters?.high_flow_severity ?? null,
                      sensor8HighConsumptionSeverity:
                        sensorConf?.[7]?.parameters?.high_consumption_severity ?? null,
                      sensor8AllZeroSeverity:
                        sensorConf?.[7]?.parameters?.all_zero_consumption_severity ?? null,
                      sensor8NonZeroConsumptionSeverity:
                        sensorConf?.[7]?.parameters?.non_zero_consumption_severity ?? null,
                      sensor8OnOffSeverity: sensorConf?.[7]?.parameters?.on_off_severity ?? null,

                      // Sensor 9
                      sensor9LowFlowSeverity:
                        sensorConf?.[8]?.parameters?.low_flow_severity ?? null,
                      sensor9HighFlowSeverity:
                        sensorConf?.[8]?.parameters?.high_flow_severity ?? null,
                      sensor9HighConsumptionSeverity:
                        sensorConf?.[8]?.parameters?.high_consumption_severity ?? null,
                      sensor9AllZeroSeverity:
                        sensorConf?.[8]?.parameters?.all_zero_consumption_severity ?? null,
                      sensor9NonZeroConsumptionSeverity:
                        sensorConf?.[8]?.parameters?.non_zero_consumption_severity ?? null,
                      sensor9OnOffSeverity: sensorConf?.[8]?.parameters?.on_off_severity ?? null,

                      // Sensor 10
                      sensor10LowFlowSeverity:
                        sensorConf?.[9]?.parameters?.low_flow_severity ?? null,
                      sensor10HighFlowSeverity:
                        sensorConf?.[9]?.parameters?.high_flow_severity ?? null,
                      sensor10HighConsumptionSeverity:
                        sensorConf?.[9]?.parameters?.high_consumption_severity ?? null,
                      sensor10AllZeroSeverity:
                        sensorConf?.[9]?.parameters?.all_zero_consumption_severity ?? null,
                      sensor10NonZeroConsumptionSeverity:
                        sensorConf?.[9]?.parameters?.non_zero_consumption_severity ?? null,
                      sensor10OnOffSeverity: sensorConf?.[9]?.parameters?.on_off_severity ?? null,

                      // Sensor 11
                      sensor11LowFlowSeverity:
                        sensorConf?.[10]?.parameters?.low_flow_severity ?? null,
                      sensor11HighFlowSeverity:
                        sensorConf?.[10]?.parameters?.high_flow_severity ?? null,
                      sensor11HighConsumptionSeverity:
                        sensorConf?.[10]?.parameters?.high_consumption_severity ?? null,
                      sensor11AllZeroSeverity:
                        sensorConf?.[10]?.parameters?.all_zero_consumption_severity ?? null,
                      sensor11NonZeroConsumptionSeverity:
                        sensorConf?.[10]?.parameters?.non_zero_consumption_severity ?? null,
                      sensor11OnOffSeverity: sensorConf?.[10]?.parameters?.on_off_severity ?? null,

                      // Sensor 12
                      sensor12LowFlowSeverity:
                        sensorConf?.[11]?.parameters?.low_flow_severity ?? null,
                      sensor12HighFlowSeverity:
                        sensorConf?.[11]?.parameters?.high_flow_severity ?? null,
                      sensor12HighConsumptionSeverity:
                        sensorConf?.[11]?.parameters?.high_consumption_severity ?? null,
                      sensor12AllZeroSeverity:
                        sensorConf?.[11]?.parameters?.all_zero_consumption_severity ?? null,
                      sensor12NonZeroConsumptionSeverity:
                        sensorConf?.[11]?.parameters?.non_zero_consumption_severity ?? null,
                      sensor12OnOffSeverity: sensorConf?.[11]?.parameters?.on_off_severity ?? null,

                      // Sensor 13
                      sensor13LowFlowSeverity:
                        sensorConf?.[12]?.parameters?.low_flow_severity ?? null,
                      sensor13HighFlowSeverity:
                        sensorConf?.[12]?.parameters?.high_flow_severity ?? null,
                      sensor13HighConsumptionSeverity:
                        sensorConf?.[12]?.parameters?.high_consumption_severity ?? null,
                      sensor13AllZeroSeverity:
                        sensorConf?.[12]?.parameters?.all_zero_consumption_severity ?? null,
                      sensor13NonZeroConsumptionSeverity:
                        sensorConf?.[12]?.parameters?.non_zero_consumption_severity ?? null,
                      sensor13OnOffSeverity: sensorConf?.[12]?.parameters?.on_off_severity ?? null,

                      // Sensor 14
                      sensor14LowFlowSeverity:
                        sensorConf?.[13]?.parameters?.low_flow_severity ?? null,
                      sensor14HighFlowSeverity:
                        sensorConf?.[13]?.parameters?.high_flow_severity ?? null,
                      sensor14HighConsumptionSeverity:
                        sensorConf?.[13]?.parameters?.high_consumption_severity ?? null,
                      sensor14AllZeroSeverity:
                        sensorConf?.[13]?.parameters?.all_zero_consumption_severity ?? null,
                      sensor14NonZeroConsumptionSeverity:
                        sensorConf?.[13]?.parameters?.non_zero_consumption_severity ?? null,
                      sensor14OnOffSeverity: sensorConf?.[13]?.parameters?.on_off_severity ?? null,

                      sensor1HighConsumptionEmailNotificationEnabled:
                        sensor1AlarmConfig.highConsumptionEmailNotificationEnabled,
                      sensor1HighConsumptionMobileNotificationEnabled:
                        sensor1AlarmConfig.highConsumptionMobileNotificationEnabled,
                      sensor1LowFlowEmailNotificationEnabled:
                        sensor1AlarmConfig.lowFlowEmailNotificationEnabled,
                      sensor1LowFlowMobileNotificationEnabled:
                        sensor1AlarmConfig.lowFlowMobileNotificationEnabled,
                      sensor1HighFlowEmailNotificationEnabled:
                        sensor1AlarmConfig.highFlowEmailNotificationEnabled,
                      sensor1HighFlowMobileNotificationEnabled:
                        sensor1AlarmConfig.highFlowMobileNotificationEnabled,
                      sensor1NonZeroConsumptionEmailNotificationEnabled:
                        sensor1AlarmConfig.nonZeroConsumptionEmailNotificationEnabled,
                      sensor1NonZeroConsumptionMobileNotificationEnabled:
                        sensor1AlarmConfig.nonZeroConsumptionMobileNotificationEnabled,
                      sensor1LowFlowAlarmThreshold: sensor1AlarmConfig.lowFlowAlarmThreshold,
                      sensor1HighFlowAlarmThreshold: sensor1AlarmConfig.highFlowAlarmThreshold,
                      sensor1HighConsumptionThreshold: sensor1AlarmConfig.highConsumptionThreshold,

                      sensor2HighConsumptionEmailNotificationEnabled:
                        sensor2AlarmConfig.highConsumptionEmailNotificationEnabled,
                      sensor2HighConsumptionMobileNotificationEnabled:
                        sensor2AlarmConfig.highConsumptionMobileNotificationEnabled,
                      sensor2LowFlowEmailNotificationEnabled:
                        sensor2AlarmConfig.lowFlowEmailNotificationEnabled,
                      sensor2LowFlowMobileNotificationEnabled:
                        sensor2AlarmConfig.lowFlowMobileNotificationEnabled,
                      sensor2HighFlowEmailNotificationEnabled:
                        sensor2AlarmConfig.highFlowEmailNotificationEnabled,
                      sensor2HighFlowMobileNotificationEnabled:
                        sensor2AlarmConfig.highFlowMobileNotificationEnabled,
                      sensor2NonZeroConsumptionEmailNotificationEnabled:
                        sensor2AlarmConfig.nonZeroConsumptionEmailNotificationEnabled,
                      sensor2NonZeroConsumptionMobileNotificationEnabled:
                        sensor2AlarmConfig.nonZeroConsumptionMobileNotificationEnabled,
                      sensor2LowFlowAlarmThreshold: sensor2AlarmConfig.lowFlowAlarmThreshold,
                      sensor2HighFlowAlarmThreshold: sensor2AlarmConfig.highFlowAlarmThreshold,
                      sensor2HighConsumptionThreshold: sensor2AlarmConfig.highConsumptionThreshold,

                      transmissionPeriod: transmissionPeriod,
                      transmissionEvery: transmissionEvery,
                      notifEmails: AlarmConfig.getCommonEmail(sensorConf),
                      missedTransmissionEmails: this.getMissedTransmissionEmail(sensorConf),
                      confEvery: every,
                      deviceName: device.name,
                      customerInfo: device.customerInfo,
                      portsConfig: portsConfig,
                      loaded: true,
                    });
                  },
                  this.errorCallback
                );
              },
              this.errorCallback
            );
          },
          this.errorCallback
        );
      },
      this.errorCallback
    );
  }

  static getCommonEmail(sensorConfs) {
    let commonEmail = '';
    sensorConfs.forEach((sensorConf) => {
      if (
        sensorConf.parameters !== undefined &&
        sensorConf.parameters != null &&
        sensorConf.parameters.notif_emails !== undefined &&
        sensorConf.parameters.notif_emails !== null &&
        sensorConf.parameters.notif_emails !== ''
      ) {
        commonEmail = sensorConf.parameters.notif_emails;
      }
    });

    return commonEmail;
  }

  getMissedTransmissionEmail = (sensorConfs) => {
    let commonEmail = '';
    sensorConfs.forEach((sensorConf) => {
      if (
        sensorConf.parameters !== undefined &&
        sensorConf.parameters != null &&
        sensorConf.parameters.missed_transmission_emails !== undefined &&
        sensorConf.parameters.missed_transmission_emails !== null &&
        sensorConf.parameters.missed_transmission_emails !== ''
      ) {
        commonEmail = sensorConf.parameters.missed_transmission_emails;
      }
    });

    return commonEmail;
  };
}

function mapStateToProps(state) {
  return { featureFlags: state.featureFlags };
}

export default withRouter(connect(mapStateToProps, actions)(withTranslation()(AlarmConfig)));
