import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { AxiosError } from 'axios';
import React, { ReactNode } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { editCommand, getCommand } from '../../../clients/Command/CommandClient';
import ErrorUtil from '../../../common/ErrorUtil';
import LoadingModalContentWrapper from '../../../components/LoadingModalContentWrapper/LoadingModalContentWrapper';
import Command, { CommandState, EditCommand } from '../../../model/API/Command/Command';
import Commands from '../Commands';
import { styles } from '../Commands.styles';
import { ICommandContextProps } from '../context/CommandPageContext';
import withCommandPageContext from '../context/withCommandPageContext';
import CommandFormatUtil from '../utils/CommandFormatUtil';

class EditCommandModal extends React.Component<EditCommandModalProps, EditCommandModalState> {
  public state: EditCommandModalState = {
    command: null,
    commandState: null,
    isLoaded: false,
  };

  public componentDidMount = async (): Promise<void> => {
    await this.fetchData();
  };

  public componentDidUpdate = (
    prevProps: EditCommandModalProps,
    prevState: EditCommandModalState
  ): void => {
    if (this.state.command && this.state.command.commandState !== CommandState.PENDING) {
      this.closeSelf();
    }
  };

  private fetchData = async (): Promise<void> => {
    this.setState({ isLoaded: false });
    try {
      const command = await getCommand(this.props.commandPageState.selectedCommandId!); //safe
      this.setState({
        command: command,
        commandState: command.commandState,
        isLoaded: true,
      });
    } catch (error) {
      ErrorUtil.handleError(error);
      this.closeSelf();
    }
  };

  private handleCommandStateChange = (
    e: SelectChangeEvent<CommandState | null>,
    child: ReactNode
  ): void => {
    this.setState({ commandState: e.target.value as CommandState });
  };

  private handleOnSubmit = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault();

    if (this.state.commandState === CommandState.CANCELED) {
      const body: EditCommand = {
        commandState: this.state.commandState,
      };

      try {
        await editCommand(this.props.deviceNumber, this.state.command!.id, body);
        this.closeSelf();
      } catch (error) {
        ErrorUtil.handleError(error);
        this.closeSelf();
      }
    } else {
      this.closeSelf();
    }
  };

  private closeSelf = (): void => {
    this.props.commandPageDispatch({ type: 'toggle_edit_modal', payload: false });
    this.props.commandPageDispatch({ type: 'change_selected_command_id', payload: null });
  };

  public render = (): React.ReactNode => {
    const { classes } = this.props;
    return (
      <LoadingModalContentWrapper
        isLoaded={this.state.isLoaded}
        title={this.props.t('commands_edit_command')}
        closeSelf={this.closeSelf}
      >
        <form autoComplete="off" method="put" onSubmit={this.handleOnSubmit}>
          <Grid container direction="column">
            <Grid item xs={12}>
              <FormControl className={classes.modalFormControl} variant="outlined" size="small">
                <InputLabel>{this.props.t('commands_command_type')}</InputLabel>
                <Select
                  value={this.state.commandState}
                  onChange={this.handleCommandStateChange}
                  label={this.props.t('commands_command_type')}
                >
                  <MenuItem key={0} value={CommandState.PENDING}>
                    {this.props.t(CommandFormatUtil.translateCommandState(CommandState.PENDING))}
                  </MenuItem>
                  <MenuItem key={1} value={CommandState.CANCELED}>
                    {this.props.t(CommandFormatUtil.translateCommandState(CommandState.CANCELED))}
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid xs={12}>
              <Button
                fullWidth
                color="primary"
                type="submit"
                variant="contained"
                className={classes.modalButton}
              >
                {this.props.t('commands_btn_submit')}
              </Button>
            </Grid>
          </Grid>
        </form>
      </LoadingModalContentWrapper>
    );
  };
}

interface EditCommandModalProps
  extends ICommandContextProps,
    WithStyles<typeof styles>,
    WithTranslation {
  deviceNumber: string;
}

interface EditCommandModalState {
  command: Command | null;
  commandState: CommandState | null;
  isLoaded: boolean;
}

export default withTranslation()(withStyles(styles)(withCommandPageContext(EditCommandModal)));
