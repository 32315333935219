import React, { Component } from 'react';
import linkDevicesClient from './LinkDevicesClient';
import { FormGroup, ControlLabel, FormControl } from 'react-bootstrap';
import Snackbar from '@mui/material/Snackbar';
import Button from '@mui/material/Button';
import Header from './components/header';

import { withRouter } from './common/withRouter';
import connect from 'react-redux/es/connect/connect';
import * as actions from './actions';
import { withTranslation } from 'react-i18next';
import GoogleAnalyticsService from './common/GoogleAnalyticsService';
import { GaEventCategory } from './common/GaEventCategory';
import { GaEventAction } from './common/GaEventAction';
import JwtValidator from './common/JwtValidator';
import { queryClient } from '@thingslog/queries';
import { QueryKeys } from '@thingslog/queries/src/enums/QueryKeys';

class LinkDevices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: '',
      open: false,
      buttonsDisabled: false,
      deviceNumber: '',
      companyId: '',
    };
    this.jwtValidator = new JwtValidator();
    this.handleLinkDevice = this.handleLinkDevice.bind(this);
    this.handleUnLinkDevice = this.handleUnLinkDevice.bind(this);
    this.handleDeviceLinked = this.handleDeviceLinked.bind(this);
    this.handleDeviceUnLinked = this.handleDeviceUnLinked.bind(this);
    this.handleMenuDevicesUpdate = this.handleMenuDevicesUpdate.bind(this);
    this.handleRequestClose = this.handleRequestClose.bind(this);
    this.handleDeviceNumberChange = this.handleDeviceNumberChange.bind(this);
    this.errorCallback = this.errorCallback.bind(this);
  }

  errorCallback(error) {
    if (error.response === undefined) {
      throw error;
    }
    if (error.response.status === 401) {
      this.props.history.push('/app/signout');
    } else if (error.response.status === 403) {
      this.setState({ message: 'You are not authorized', open: true, buttonsDisabled: false });
    } else if (error.response.data) {
      this.setState({
        message: 'Error: ' + error.response.data.message,
        open: true,
        buttonsDisabled: false,
      });
    } else {
      this.setState({
        message: 'Error: ' + error.response.data,
        open: true,
        buttonsDisabled: false,
      });
    }
  }

  handleDeviceNumberChange = (ev) => this.setState({ deviceNumber: ev.target.value });

  handleLinkDevice(ev) {
    GoogleAnalyticsService.triggerEvent(
      GaEventCategory.LINK_DEVICE_PAGE,
      GaEventAction.LINK_DEVICE_PAGE_LINK_DEVICE
    );
    if (this.state.companyId === null) {
      this.setState({ open: true, message: this.props.t('link_device_select_company_error') });
      return;
    } else if (this.state.deviceNumber === '') {
      this.setState({ open: true, message: this.props.t('link_device_enter_device_number_error') });
      return;
    }
    this.setState({
      open: true,
      message: this.props.t('link_device_with_device_number', {
        device_number: this.state.deviceNumber,
      }),
      buttonsDisabled: true,
    });
    linkDevicesClient.postLinkDevice(
      this.state.deviceNumber,
      this.state.companyId,
      this.handleDeviceLinked,
      this.errorCallback
    );
    ev.preventDefault();
  }

  handleUnLinkDevice(ev) {
    GoogleAnalyticsService.triggerEvent(
      GaEventCategory.LINK_DEVICE_PAGE,
      GaEventAction.LINK_DEVICE_PAGE_UNLINK_DEVICE
    );
    if (this.state.companyId === null) {
      this.setState({ open: true, message: this.props.t('link_device_select_company_error') });
      return;
    } else if (this.state.deviceNumber === '') {
      this.setState({ open: true, message: this.props.t('link_device_enter_device_number_error') });
      return;
    }
    this.setState({
      open: true,
      message: this.props.t('unlink_device_with_device_number', {
        device_number: this.state.deviceNumber,
      }),
      buttonsDisabled: true,
    });
    linkDevicesClient.deleteLinkDevice(
      this.state.deviceNumber,
      this.state.companyId,
      this.handleDeviceUnLinked,
      this.errorCallback
    );
    ev.preventDefault();
  }

  handleDeviceLinked() {
    this.handleMenuDevicesUpdate();
    this.setState({
      message: this.props.t('link_device_success_message'),
      open: true,
      buttonsDisabled: false,
    });
  }

  handleDeviceUnLinked() {
    this.handleMenuDevicesUpdate();
    this.setState({
      message: this.props.t('unlink_device_success_message'),
      open: true,
      buttonsDisabled: false,
    });
  }

  handleRequestClose = () => {
    this.setState({ open: false });
  };

  handleMenuDevicesUpdate = () => {
    queryClient.invalidateQueries([QueryKeys.UseDeviceData]);
  };

  render() {
    return (
      <Header>
        <div className="w-[400px] max-sm:w-full m-auto flex-col p-5 bg-neutral-100 border border-solid border-gray-200 rounded">
          <p>{this.props.t('link_device_link_device_to_my_account')}</p>
          <form>
            <FormGroup controlId="Device Number">
              <ControlLabel>{this.props.t('link_device_device_number')}</ControlLabel>
              <FormControl
                value={this.state.deviceNumber}
                type="text"
                placeholder={this.props.t('number')}
                onChange={this.handleDeviceNumberChange}
              />
            </FormGroup>
            <Button onClick={this.handleLinkDevice} disabled={this.state.buttonsDisabled}>
              {this.props.t('link_link_device')}
            </Button>
            <Button onClick={this.handleUnLinkDevice} disabled={this.state.buttonsDisabled}>
              {this.props.t('link_device_unlink_device')}
            </Button>
            <Snackbar
              open={this.state.open}
              message={this.state.message}
              autoHideDuration={4000}
              onClose={this.handleRequestClose}
            />
          </form>
        </div>
      </Header>
    );
  }

  componentDidMount() {
    if (this.props.company.id !== null) {
      this.setState({ companyId: this.props.company.id });
    } else if (this.jwtValidator.decodedToken.companyId !== '') {
      this.setState({ companyId: this.jwtValidator.decodedToken.companyId });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.company.id !== this.props.company.id && this.props.company.id !== null) {
      this.setState({ companyId: this.props.company.id });
    }
  }

  componentWillUnmount() {}
}
function mapStateToProps(state) {
  return {
    company: state.company,
    auth: state.auth,
  };
}

export default withRouter(connect(mapStateToProps, actions)(withTranslation()(LinkDevices)));
