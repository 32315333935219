import React, { FC, useState, MouseEvent, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, IconButton, Tooltip } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useToast } from '@thingslog/ui-components';
import TextField from '@mui/material/TextField';
import { UserDto } from '@thingslog/repositories';
import JwtValidator from '../../common/JwtValidator';

const UserInfo: FC<UserInfoProps> = ({ userInfo }: UserInfoProps) => {
  const { t } = useTranslation();
  const { toast } = useToast();
  const { hasRoles } = useMemo(() => new JwtValidator(), []);

  const [token, setToken] = useState<string>('');

  const copyToClipboard = (e: MouseEvent): void => {
    navigator.clipboard
      .writeText(token)
      .then(() => {
        toast({ type: 'success', message: 'Copied to clipboard.' });
      })
      .catch(() => {
        toast({ type: 'error', message: 'Failed to copy to clipboard.' });
      });
  };

  const generateToken = (): void => {
    const token = localStorage.getItem('token');
    if (token) {
      setToken(token);
    }
  };
  return (
    <div className="flex flex-col px-3 py-2">
      <h4 className="text-left font-bold w-4/5">{t('profile_page_user_info_label')}</h4>
      <div className="flex flex-col lg:flex-row w-full text-left text-lg">
        {/* Container for the first and second sections */}
        <div className="grid grid-cols-12  w-full lg:w-6/12 mb-10 gap-2">
          <div className="col-span-5 font-bold">
            <div>{t('profile_page_name')}</div>
            <div>{t('profile_page_email')}</div>
            <div>{t('profile_page_language')}</div>
            <div>{t('profile_page_password')}</div>
            {hasRoles(['ROLE_SUPER_ADMIN', 'ROLE_ADMIN']) && <div className="mt-6">Token</div>}
          </div>
          <div className="col-span-7 text-right">
            <div>{`${userInfo?.firstName} ${userInfo?.lastName}`}</div>
            <div>{userInfo?.email}</div>
            <div>
              <Link to={`/app/Language`} className="text-center">
                {t('profile_page_change_language')}
              </Link>
            </div>
            <div>
              <Link to={`/app/ChangePassword`} className="text-center">
                {t('profile_page_change_password')}
              </Link>
            </div>
            {hasRoles(['ROLE_SUPER_ADMIN', 'ROLE_ADMIN']) && (
              <div className="mt-6">
                <TextField value={token} size="small" label="Generate Token" />
                <Tooltip placement="top" title="Copy!" arrow>
                  <IconButton className="p-2" type="submit" onClick={copyToClipboard}>
                    <ContentCopyIcon />
                  </IconButton>
                </Tooltip>
                <Button onClick={generateToken}>{t('profile_page_generate')}</Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

interface UserInfoProps {
  userInfo: UserDto | null;
}

export default UserInfo;
