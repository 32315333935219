export const AUTH_USER = 'AUTH_USER';
export const SIGNED_UP = 'SIGNED_UP';
export const SIGN_OUT = 'SIGNED_OUT';
export const UNAUTH_USER = 'UNAUTH_USER';
export const AUTH_ERROR = 'AUTH_ERROR';
export const AUTH_RESTRICT_USER = 'AUTH_RESTRICT_USER';
export const LANGUAGE_CHANGED = 'LANGUAGE_CHANGED';
export const FETCH_MESSAGE = 'FETCH_MESSAGE';
export const DEV_SELECTED = 'DEV_SELECTED';
export const DEV_SELECTED_CHANGED = 'DEV_SELECTED_CHANGED';
export const FILTER_CHANGED = 'FILTER_CHANGED';
export const DEV_OPEN_CLOSE_MENU = 'DEV_OPEN_CLOSE_MENU';
export const COMPANY_SELECTED = 'COMPANY_SELECTED';
export const COMPANY_LOGO_SET = 'COMPANY_LOGO_SET';
export const PERIOD_FROM_DATE_CHANGED = 'PERIOD_FROM_DATE_CHANGED';
export const PERIOD_TO_DATE_CHANGED = 'PERIOD_TO_DATE_CHANGED';
export const PERIOD_AVG_FROM_DATE_CHANGED = 'PERIOD_AVG_FROM_DATE_CHANGED';
export const PERIOD_AVG_TO_DATE_CHANGED = 'PERIOD_AVG_TO_DATE_CHANGED';
export const PERIOD_TIMESPAN_MODE_CHANGED = 'PERIOD_TIMESPAN_MODE_CHANGED';
export const DEV_FROM_DATE_CHANGED = 'DEV_FROM_DATE_CHANGED';
export const DEV_TO_DATE_CHANGED = 'DEV_TO_DATE_CHANGED';
export const DEV_OPEN_CLOSE_DEV_MENU = 'DEV_OPEN_CLOSE_DEV_MENU';
export const DEV_SENSOR_INDEX_CHANGED = 'DEV_SENSOR_INDEX_CHANGED';
export const FEATURE_FLAGS_UPDATE = 'FEATURE_FLAGS_UPDATE';
export const SET_SOLUTION_SETTINGS = 'SET_SOLUTION_SETTINGS';
export const SET_SELECTED_PAGE = 'SET_SELECTED_PAGE';
export const SET_DEVICES = 'SET_DEVICES';
export const SET_COMPANY_LICENSES = 'SET_COMPANY_LICENSES';
export const SET_LICENSES_ACCESS = 'SET_LICENSES_ACCESS';
