import React, { Component, Fragment } from 'react';
import 'react-select/dist/react-select.css';
import deviceInitialConfigClient from './DeviceInitialConfigClient';
import timeZonesClient from './TimeZonesClient';
import devicesClient from './DevicesClient';
import { FormGroup, ControlLabel, FormControl } from 'react-bootstrap';
import connect from 'react-redux/es/connect/connect';
import * as actions from './actions';
import { t } from 'i18next';
import { withTranslation } from 'react-i18next';
import {
  FormControl as MuiFormControl,
  Select,
  InputLabel,
  MenuItem,
  Box,
  Typography,
  Checkbox,
  CircularProgress,
  Snackbar,
  Button,
  Autocomplete,
  TextField,
} from '@mui/material';
import TitleHeader from './components/TitleHeader/TitleHeader';
import Header from './components/header';
import { ROLE_SUPER_ADMIN, hasRole } from './common/role_utils';
import GoogleAnalyticsService from './common/GoogleAnalyticsService';
import { GaEventCategory } from './common/GaEventCategory';
import { GaEventAction } from './common/GaEventAction';
import { withRouter } from './common/withRouter';
import BarometerSymbol from './resources/sensor-icons/barometer-symbol.svg';
import DoorClosedSymbol from './resources/sensor-icons/door-closed-symbol.svg';
import DoorOpenSymbol from './resources/sensor-icons/door-open-symbol.svg';
import ElectricitySymbol from './resources/sensor-icons/electricity-symbol.svg';
import EnergyMeterSymbol from './resources/sensor-icons/energy-meter-symbol.svg';
import GasFlowMeterSymbol from './resources/sensor-icons/gas-flow-meter-symbol.svg';
import GasSymbol from './resources/sensor-icons/gas-symbol.svg';
import GenericParticleMeterSymbol from './resources/sensor-icons/generic-particulate-meter-symbol.svg';
import GenericSymbol from './resources/sensor-icons/generic-symbol.svg';
import HeatMeterSymbol from './resources/sensor-icons/heat-meter-symbol.svg';
import HumiditySymbol from './resources/sensor-icons/humidity-symbol.svg';
import LeafMoistureSymbol from './resources/sensor-icons/leaf-moisture-symbol.svg';
import LevelMeteringSymbol from './resources/sensor-icons/level-metering-symbol.svg';
import PivotSymbol from './resources/sensor-icons/pivot-symbol.svg';
import PressureSymbol from './resources/sensor-icons/pressure-symbol.svg';
import RadiationSymbol from './resources/sensor-icons/radiation-symbol.svg';
import RainSymbol from './resources/sensor-icons/rain-symbol.svg';
import SoilMoistureSymbol from './resources/sensor-icons/soil-moisture-symbol.svg';
import TemperatureSymbol from './resources/sensor-icons/temperature-symbol.svg';
import WaterMeterSymbol from './resources/sensor-icons/water-meter-symbol.svg';
import WaterSymbol from './resources/sensor-icons/water-symbol.svg';
import WindDirectionSymbol from './resources/sensor-icons/wind-direction-symbol.svg';
import WindSpeedSymbol from './resources/sensor-icons/wind-speed-symbol.svg';
import PumpSymbol from './resources/sensor-icons/pump-symbol.svg';
import ApartmentIcon from './resources/sensor-icons/apartment-building-symbol.svg';
import BaseStationIcon from './resources/sensor-icons/base-station-symbol.svg';
import ClinicIcon from './resources/sensor-icons/clinic-symbol.svg';
import HospitalIcon from './resources/sensor-icons/hospital-symbol.svg';
import HouseIcon from './resources/sensor-icons/house-symbol.svg';
import MayorOfficeIcon from './resources/sensor-icons/mayor-office-symbol.svg';
import PoliceStationIcon from './resources/sensor-icons/police-station-symbol.svg';
import PublicRestroomIcon from './resources/sensor-icons/public-restroom-symbol.svg';
import StadiumIcon from './resources/sensor-icons/stadium-symbol.svg';
import GasStationIcon from './resources/sensor-icons/gas-station-symbol.svg';
import ControllerIcon from './resources/sensor-icons/controller-symbol.svg';

class DeviceInitialConfig extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deviceName: null,
      customerInfo: null,
      conf: null,
      model: null,
      isMQTTChecked: false,
      featureFlags: null,
      slaveDevicesOptions: [],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.loadDeviceConfigFromServer = this.loadDeviceConfigFromServer.bind(this);
    this.handleConfigSaved = this.handleConfigSaved.bind(this);
    this.handleRequestClose = this.handleRequestClose.bind(this);
    this.errorCallback = this.errorCallback.bind(this);
  }

  errorCallback(error) {
    if (error.response === undefined) {
      throw error;
    }
    if (error.response.status === 401 || error.response.status === 403) {
      this.props.history.push('/app/signout');
    } else if (error.response.data) {
      if (error.response.data.message) {
        alert('Error: ' + error.response.data.message);
      } else {
        alert('Error: ' + error.response.data);
      }
    }
  }

  handleSubmit(event) {
    GoogleAnalyticsService.triggerEvent(
      GaEventCategory.INITIAL_CONFIG_PAGE,
      GaEventAction.INITIAL_CONFIG_SAVE
    );

    this.setState(
      {
        saveDisabled: true,
        conf: {
          ...this.state.conf,
          mqttConfig: this.state.isMQTTChecked ? this.state.conf.mqttConfig : null,
        },
      },
      () => {
        deviceInitialConfigClient.putDeviceInitialConfig(
          this.props.match.params.deviceNumber,
          this.state.conf,
          this.handleConfigSaved,
          this.errorCallback
        );
      }
    );

    event.preventDefault();
  }

  handleConfigSaved() {
    this.setState({ open: true, saveDisabled: false });
  }

  handleRequestClose = () => {
    this.setState({ open: false });
  };

  isFeatureFlagOn = (flagName) => {
    return this.props.featureFlags?.featureFlagsArray?.includes(flagName);
  };

  render() {
    const wellStyles = { maxWidth: 400, margin: '0 auto 10px' };
    if (this.state.conf != null) {
      return (
        <Header>
          <div className="well" style={wellStyles}>
            <TitleHeader
              className="mb-4"
              pageType="form"
              title={this.props.t('device_initial_config_header')}
              deviceNumber={this.props.match.params.deviceNumber}
              deviceName={this.state.deviceName}
              customerInfo={this.state.customerInfo}
            />
            <form>
              <FormGroup controlId="model">
                <ControlLabel>{this.props.t('device_initial_device_model')}</ControlLabel>
                <FormControl
                  value={this.state.model}
                  componentClass="select"
                  placeholder="select model"
                  disabled={true}
                  onChange={(event) => this.setState({ model: event.target.value })}
                >
                  <option value={'LPMDL_1101'}>LPMDL_1101</option>
                  <option value={'LPMDL_1102'}>LPMDL_1102</option>
                  <option value={'LPMDL_1103'}>LPMDL_1103</option>
                  <option value={'LPMDL_1104'}>LPMDL_1104</option>
                  <option value={'LPMDL_1105'}>LPMDL_1105</option>
                  <option value={'LPMDL_1106'}>LPMDL_1106</option>
                  <option value={'BL_100_L'}>BL_100_L</option>
                  <option value={'W1'}>W1</option>
                  <option value={'ZENNER_EDC'}>ZENNER_EDC</option>
                  <option value={'ZENNER_MICRO'}>ZENNER_MICRO</option>
                  <option value={'LPMDL_1103_OPENLORA'}>LPMDL_1103_OPENLORA</option>
                  <option value={'INNOTAS_WATERMETER'}>INNOTAS_WATERMETER</option>
                  <option value={'INNOTAS_HCA'}>INNOTAS_HCA</option>
                  <option value={'TLCTL_21A4S'}>TLCTL_21A4S</option>
                  <option value={'FINECO_EM735'}>FINECO_EM735</option>
                  <option value={'FINECO_EM4374'}>FINECO_EM4374</option>
                </FormControl>
              </FormGroup>
              {!this.isFeatureFlagOn('DEVICE_ICON_FEATURE_FLAG') && (
                <MuiFormControl fullWidth>
                  <InputLabel>{this.props.t('device_icon')}</InputLabel>
                  <Select
                    size="small"
                    value={this.state.conf.iconName}
                    label={this.props.t('device_icon')}
                    onChange={(event) =>
                      this.setState({ conf: { ...this.state.conf, iconName: event.target.value } })
                    }
                  >
                    <MenuItem value={null}>{this.props.t('icon_no_icon')}</MenuItem>
                    <MenuItem value={'barometer-symbol'}>
                      <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                        <Box component="img" sx={{ width: 27, height: 27 }} src={BarometerSymbol} />
                        <Typography style={{ paddingLeft: 10 }}>
                          {this.props.t('icon_barometer')}
                        </Typography>
                      </Box>
                    </MenuItem>
                    <MenuItem value={'door-closed-symbol'}>
                      <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                        <Box
                          component="img"
                          sx={{ width: 27, height: 27 }}
                          src={DoorClosedSymbol}
                        />
                        <Typography style={{ paddingLeft: 10 }}>
                          {this.props.t('icon_door_closed')}
                        </Typography>
                      </Box>
                    </MenuItem>
                    <MenuItem value={'door-open-symbol'}>
                      <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                        <Box component="img" sx={{ width: 27, height: 27 }} src={DoorOpenSymbol} />
                        <Typography style={{ paddingLeft: 10 }}>
                          {this.props.t('icon_door_open')}
                        </Typography>
                      </Box>
                    </MenuItem>
                    <MenuItem value={'electricity-symbol'}>
                      <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                        <Box
                          component="img"
                          sx={{ width: 27, height: 27 }}
                          src={ElectricitySymbol}
                        />
                        <Typography style={{ paddingLeft: 10 }}>
                          {this.props.t('icon_electricity')}
                        </Typography>
                      </Box>
                    </MenuItem>
                    <MenuItem value={'energy-meter-symbol'}>
                      <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                        <Box
                          component="img"
                          sx={{ width: 27, height: 27 }}
                          src={EnergyMeterSymbol}
                        />
                        <Typography style={{ paddingLeft: 10 }}>
                          {this.props.t('icon_energy_meter')}
                        </Typography>
                      </Box>
                    </MenuItem>
                    <MenuItem value={'gas-flow-meter-symbol'}>
                      <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                        <Box
                          component="img"
                          sx={{ width: 27, height: 27 }}
                          src={GasFlowMeterSymbol}
                        />
                        <Typography style={{ paddingLeft: 10 }}>
                          {this.props.t('icon_gas_flow_meter')}
                        </Typography>
                      </Box>
                    </MenuItem>
                    <MenuItem value={'gas-symbol'}>
                      <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                        <Box component="img" sx={{ width: 27, height: 27 }} src={GasSymbol} />
                        <Typography style={{ paddingLeft: 10 }}>
                          {this.props.t('icon_gas')}
                        </Typography>
                      </Box>
                    </MenuItem>
                    <MenuItem value={'generic-particulate-meter-symbol'}>
                      <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                        <Box
                          component="img"
                          sx={{ width: 27, height: 27 }}
                          src={GenericParticleMeterSymbol}
                        />
                        <Typography style={{ paddingLeft: 10 }}>
                          {this.props.t('icon_generic_particle_meter')}
                        </Typography>
                      </Box>
                    </MenuItem>
                    <MenuItem value={'generic-symbol'}>
                      <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                        <Box component="img" sx={{ width: 27, height: 27 }} src={GenericSymbol} />
                        <Typography style={{ paddingLeft: 10 }}>
                          {this.props.t('icon_generic')}
                        </Typography>
                      </Box>
                    </MenuItem>
                    <MenuItem value={'heat-meter-symbol'}>
                      <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                        <Box component="img" sx={{ width: 27, height: 27 }} src={HeatMeterSymbol} />
                        <Typography style={{ paddingLeft: 10 }}>
                          {this.props.t('icon_heat_meter')}
                        </Typography>
                      </Box>
                    </MenuItem>
                    <MenuItem value={'humidity-symbol'}>
                      <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                        <Box component="img" sx={{ width: 27, height: 27 }} src={HumiditySymbol} />
                        <Typography style={{ paddingLeft: 10 }}>
                          {this.props.t('icon_humidity')}
                        </Typography>
                      </Box>
                    </MenuItem>
                    <MenuItem value={'leaf-moisture-symbol'}>
                      <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                        <Box
                          component="img"
                          sx={{ width: 27, height: 27 }}
                          src={LeafMoistureSymbol}
                        />
                        <Typography style={{ paddingLeft: 10 }}>
                          {this.props.t('icon_leaf_moisture')}
                        </Typography>
                      </Box>
                    </MenuItem>
                    <MenuItem value={'level-metering-symbol'}>
                      <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                        <Box
                          component="img"
                          sx={{ width: 27, height: 27 }}
                          src={LevelMeteringSymbol}
                        />
                        <Typography style={{ paddingLeft: 10 }}>
                          {this.props.t('icon_level_metering')}
                        </Typography>
                      </Box>
                    </MenuItem>
                    <MenuItem value={'pivot-symbol'}>
                      <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                        <Box component="img" sx={{ width: 27, height: 27 }} src={PivotSymbol} />
                        <Typography style={{ paddingLeft: 10 }}>
                          {this.props.t('icon_pivot')}
                        </Typography>
                      </Box>
                    </MenuItem>
                    <MenuItem value={'pressure-symbol'}>
                      <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                        <Box component="img" sx={{ width: 27, height: 27 }} src={PressureSymbol} />
                        <Typography style={{ paddingLeft: 10 }}>
                          {this.props.t('icon_pressure')}
                        </Typography>
                      </Box>
                    </MenuItem>
                    <MenuItem value={'radiation-symbol'}>
                      <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                        <Box component="img" sx={{ width: 27, height: 27 }} src={RadiationSymbol} />
                        <Typography style={{ paddingLeft: 10 }}>
                          {this.props.t('icon_radiation')}
                        </Typography>
                      </Box>
                    </MenuItem>
                    <MenuItem value={'rain-symbol'}>
                      <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                        <Box component="img" sx={{ width: 27, height: 27 }} src={RainSymbol} />
                        <Typography style={{ paddingLeft: 10 }}>
                          {this.props.t('icon_rain')}
                        </Typography>
                      </Box>
                    </MenuItem>
                    <MenuItem value={'soil-moisture-symbol'}>
                      <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                        <Box
                          component="img"
                          sx={{ width: 27, height: 27 }}
                          src={SoilMoistureSymbol}
                        />
                        <Typography style={{ paddingLeft: 10 }}>
                          {this.props.t('icon_soil_moisture')}
                        </Typography>
                      </Box>
                    </MenuItem>
                    <MenuItem value={'temperature-symbol'}>
                      <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                        <Box
                          component="img"
                          sx={{ width: 27, height: 27 }}
                          src={TemperatureSymbol}
                        />
                        <Typography style={{ paddingLeft: 10 }}>
                          {this.props.t('icon_temperature')}
                        </Typography>
                      </Box>
                    </MenuItem>
                    <MenuItem value={'water-meter-symbol'}>
                      <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                        <Box
                          component="img"
                          sx={{ width: 27, height: 27 }}
                          src={WaterMeterSymbol}
                        />
                        <Typography style={{ paddingLeft: 10 }}>
                          {this.props.t('icon_water_meter')}
                        </Typography>
                      </Box>
                    </MenuItem>
                    <MenuItem value={'water-symbol'}>
                      <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                        <Box component="img" sx={{ width: 27, height: 27 }} src={WaterSymbol} />
                        <Typography style={{ paddingLeft: 10 }}>
                          {this.props.t('icon_water')}
                        </Typography>
                      </Box>
                    </MenuItem>
                    <MenuItem value={'wind-direction-symbol'}>
                      <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                        <Box
                          component="img"
                          sx={{ width: 27, height: 27 }}
                          src={WindDirectionSymbol}
                        />
                        <Typography style={{ paddingLeft: 10 }}>
                          {this.props.t('icon_wind_direction')}
                        </Typography>
                      </Box>
                    </MenuItem>
                    <MenuItem value={'wind-speed-symbol'}>
                      <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                        <Box component="img" sx={{ width: 27, height: 27 }} src={WindSpeedSymbol} />
                        <Typography style={{ paddingLeft: 10 }}>
                          {this.props.t('icon_wind_speed')}
                        </Typography>
                      </Box>
                    </MenuItem>
                    <MenuItem value={'pump-symbol'}>
                      <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                        <Box component="img" sx={{ width: 27, height: 27 }} src={PumpSymbol} />
                        <Typography style={{ paddingLeft: 10 }}>
                          {this.props.t('icon_pump')}
                        </Typography>
                      </Box>
                    </MenuItem>
                    <MenuItem value={'apartment-building-symbol'}>
                      <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                        <Box component="img" sx={{ width: 27, height: 27 }} src={ApartmentIcon} />
                        <Typography style={{ paddingLeft: 10 }}>{t('icon_apartment')}</Typography>
                      </Box>
                    </MenuItem>
                    <MenuItem value={'base-station-symbol'}>
                      <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                        <Box component="img" sx={{ width: 27, height: 27 }} src={BaseStationIcon} />
                        <Typography style={{ paddingLeft: 10 }}>
                          {t('icon_base_station')}
                        </Typography>
                      </Box>
                    </MenuItem>
                    <MenuItem value={'clinic-symbol'}>
                      <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                        <Box component="img" sx={{ width: 27, height: 27 }} src={ClinicIcon} />
                        <Typography style={{ paddingLeft: 10 }}>{t('icon_clinic')}</Typography>
                      </Box>
                    </MenuItem>
                    <MenuItem value={'hospital-symbol'}>
                      <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                        <Box component="img" sx={{ width: 27, height: 27 }} src={HospitalIcon} />
                        <Typography style={{ paddingLeft: 10 }}>{t('icon_hospital')}</Typography>
                      </Box>
                    </MenuItem>
                    <MenuItem value={'house-symbol'}>
                      <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                        <Box component="img" sx={{ width: 27, height: 27 }} src={HouseIcon} />
                        <Typography style={{ paddingLeft: 10 }}>{t('icon_house')}</Typography>
                      </Box>
                    </MenuItem>
                    <MenuItem value={'mayor-office-symbol'}>
                      <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                        <Box component="img" sx={{ width: 27, height: 27 }} src={MayorOfficeIcon} />
                        <Typography style={{ paddingLeft: 10 }}>
                          {t('icon_mayor_office')}
                        </Typography>
                      </Box>
                    </MenuItem>
                    <MenuItem value={'police-station-symbol'}>
                      <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                        <Box
                          component="img"
                          sx={{ width: 27, height: 27 }}
                          src={PoliceStationIcon}
                        />
                        <Typography style={{ paddingLeft: 10 }}>
                          {t('icon_police_station')}
                        </Typography>
                      </Box>
                    </MenuItem>
                    <MenuItem value={'public-restroom-symbol'}>
                      <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                        <Box
                          component="img"
                          sx={{ width: 27, height: 27 }}
                          src={PublicRestroomIcon}
                        />
                        <Typography style={{ paddingLeft: 10 }}>
                          {t('icon_public_restroom')}
                        </Typography>
                      </Box>
                    </MenuItem>
                    <MenuItem value={'stadium-symbol'}>
                      <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                        <Box component="img" sx={{ width: 27, height: 27 }} src={StadiumIcon} />
                        <Typography style={{ paddingLeft: 10 }}>{t('icon_stadium')}</Typography>
                      </Box>
                    </MenuItem>
                    <MenuItem value={'controller-symbol'}>
                      <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                        <Box component="img" sx={{ width: 27, height: 27 }} src={ControllerIcon} />
                        <Typography style={{ paddingLeft: 10 }}>{t('icon_controller')}</Typography>
                      </Box>
                    </MenuItem>
                    <MenuItem value={'gas-station-symbol'}>
                      <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                        <Box component="img" sx={{ width: 27, height: 27 }} src={GasStationIcon} />
                        <Typography style={{ paddingLeft: 10 }}>{t('icon_gas_station')}</Typography>
                      </Box>
                    </MenuItem>
                  </Select>
                </MuiFormControl>
              )}
              {(this.state.model === 'LPMDL_1101' ||
                this.state.model === 'LPMDL_1104' ||
                this.state.model === 'LPMDL_1106') && (
                <Fragment>
                  <FormGroup controlId="Address">
                    <ControlLabel>{this.props.t('device_initial_config_address')}</ControlLabel>
                    <FormControl
                      value={this.state.conf.address}
                      type="text"
                      placeholder={this.props.t('device_initial_config_address')}
                      onChange={(event) =>
                        this.setState({ conf: { ...this.state.conf, address: event.target.value } })
                      }
                    />
                  </FormGroup>
                  <FormGroup controlId="Port">
                    <ControlLabel>{this.props.t('device_initial_config_port')}</ControlLabel>
                    <FormControl
                      value={this.state.conf.port}
                      type="text"
                      placeholder={this.props.t('device_initial_config_port')}
                      onChange={(event) =>
                        this.setState({ conf: { ...this.state.conf, port: event.target.value } })
                      }
                    />
                  </FormGroup>
                  <FormGroup controlId="PIN">
                    <ControlLabel>PIN</ControlLabel>
                    <FormControl
                      value={this.state.conf.pin}
                      type="text"
                      placeholder="pin"
                      onChange={(event) =>
                        this.setState({ conf: { ...this.state.conf, pin: event.target.value } })
                      }
                    />
                  </FormGroup>
                  <FormGroup controlId="resolveAddress">
                    <ControlLabel>
                      {this.props.t('device_initial_config_resolve_address')}
                    </ControlLabel>
                    <FormControl
                      value={this.state.conf.resolveAddress}
                      componentClass="select"
                      placeholder={this.props.t('device_initial_config_resolve_address')}
                      onChange={(event) =>
                        this.setState({
                          conf: { ...this.state.conf, resolveAddress: event.target.value },
                        })
                      }
                    >
                      <option value={true}>{this.props.t('capital_yes')}</option>
                      <option value={false}>{this.props.t('capital_no')}</option>
                    </FormControl>
                  </FormGroup>
                  <FormGroup controlId="APN">
                    <ControlLabel>APN</ControlLabel>
                    <FormControl
                      value={this.state.conf.apn}
                      type="text"
                      placeholder="apn"
                      onChange={(event) =>
                        this.setState({ conf: { ...this.state.conf, apn: event.target.value } })
                      }
                    />
                  </FormGroup>
                  <FormGroup controlId="apnUsername">
                    <ControlLabel>
                      {this.props.t('device_initial_config_apn_username')}
                    </ControlLabel>
                    <FormControl
                      value={this.state.conf.apnUsername}
                      type="text"
                      placeholder={this.props.t('device_initial_config_apn_username')}
                      onChange={(event) =>
                        this.setState({
                          conf: { ...this.state.conf, apnUsername: event.target.value },
                        })
                      }
                    />
                  </FormGroup>
                  <FormGroup controlId="apnPassword">
                    <ControlLabel>
                      {this.props.t('device_initial_config_apn_password')}
                    </ControlLabel>
                    <FormControl
                      value={this.state.conf.apnPassword}
                      type="text"
                      placeholder={this.props.t('device_initial_config_apn_password')}
                      onChange={(event) =>
                        this.setState({
                          conf: { ...this.state.conf, apnPassword: event.target.value },
                        })
                      }
                    />
                  </FormGroup>
                  <FormGroup controlId="imei">
                    <ControlLabel>IMEI</ControlLabel>
                    <FormControl
                      value={this.state.conf.imei}
                      type="text"
                      placeholder="IMEI"
                      onChange={(event) =>
                        this.setState({
                          conf: { ...this.state.conf, imei: event.target.value },
                        })
                      }
                    />
                  </FormGroup>
                  <FormGroup controlId="netAttachedDelay">
                    <ControlLabel>
                      {this.props.t('device_initial_config_net_attached_delay')}
                    </ControlLabel>
                    <FormControl
                      value={this.state.conf.netAttachedDelay}
                      type="number"
                      placeholder={this.props.t('device_initial_config_net_attached_delay')}
                      onChange={(event) =>
                        this.setState({
                          conf: { ...this.state.conf, netAttachedDelay: event.target.value },
                        })
                      }
                    />
                  </FormGroup>
                  <FormGroup controlId="gprsRadioMode">
                    <ControlLabel>
                      {this.props.t('device_initial_config_gprs_radio_mode')}
                    </ControlLabel>
                    <FormControl
                      value={this.state.conf.gprsRadioMode}
                      componentClass="select"
                      placeholder={this.props.t('device_initial_config_gprs_radio_mode')}
                      onChange={(event) =>
                        this.setState({
                          conf: { ...this.state.conf, gprsRadioMode: event.target.value },
                        })
                      }
                    >
                      <option value={'AUTO'}>
                        {t('device_initial_config_grps_radio_mode_auto_option')}
                      </option>
                      <option value={'GSM2G'}>GSM2G</option>
                      <option value={'LTE4G'}>LTE4G</option>
                    </FormControl>
                  </FormGroup>
                  <FormGroup controlId="preferredNetworkType">
                    <ControlLabel>
                      {this.props.t('device_initial_config_preferred_network_type')}
                    </ControlLabel>
                    <FormControl
                      value={this.state.conf.preferredNetworkType}
                      componentClass="select"
                      placeholder={this.props.t('device_initial_config_preferred_network_type')}
                      onChange={(event) =>
                        this.setState({
                          conf: { ...this.state.conf, preferredNetworkType: event.target.value },
                        })
                      }
                    >
                      <option value={'GSM'}>GSM</option>
                      <option value={'LTE4G'}>LTE4G</option>
                      <option value={'NB_IOT'}>NB_IOT</option>
                      <option value={'CAT_M1'}>CAT_M1</option>
                      <option value={'DEFAULT'}>
                        {t('device_initial_config_preferred_network_default_option')}
                      </option>
                    </FormControl>
                  </FormGroup>
                  <FormGroup controlId="mccMnc">
                    <ControlLabel>{this.props.t('device_initial_config_mcc_mnc')}</ControlLabel>
                    <FormControl
                      value={this.state.conf.mccMnc}
                      type="text"
                      placeholder={this.props.t('device_initial_config_mcc_mnc')}
                      onChange={(event) =>
                        this.setState({
                          conf: { ...this.state.conf, mccMnc: event.target.value },
                        })
                      }
                    />
                  </FormGroup>
                  <FormGroup controlId="simCardProvider">
                    <ControlLabel>
                      {this.props.t('device_initial_config_simcard_provider')}
                    </ControlLabel>
                    <FormControl
                      value={this.state.conf.simCardProvider}
                      type="text"
                      placeholder={this.props.t('device_initial_config_simcard_provider')}
                      onChange={(event) =>
                        this.setState({
                          conf: { ...this.state.conf, simCardProvider: event.target.value },
                        })
                      }
                    />
                  </FormGroup>
                  <FormGroup controlId="simCardNumber">
                    <ControlLabel>
                      {this.props.t('device_initial_config_simcard_number')}
                    </ControlLabel>
                    <FormControl
                      value={this.state.conf.simCardNumber}
                      type="text"
                      placeholder={this.props.t('device_initial_config_simcard_number')}
                      onChange={(event) =>
                        this.setState({
                          conf: { ...this.state.conf, simCardNumber: event.target.value },
                        })
                      }
                    />
                  </FormGroup>
                </Fragment>
              )}
              {this.state.model === 'LPMDL_1102' && (
                <Fragment>
                  <FormGroup controlId="Address">
                    <ControlLabel>{this.props.t('device_initial_config_address')}</ControlLabel>
                    <FormControl
                      value={this.state.conf.address}
                      type="text"
                      placeholder={this.props.t('device_initial_config_address')}
                      onChange={(event) =>
                        this.setState({ conf: { ...this.state.conf, address: event.target.value } })
                      }
                    />
                  </FormGroup>
                  <FormGroup controlId="Port">
                    <ControlLabel>{this.props.t('device_initial_config_port')}</ControlLabel>
                    <FormControl
                      value={this.state.conf.port}
                      type="text"
                      placeholder={this.props.t('device_initial_config_port')}
                      onChange={(event) =>
                        this.setState({ conf: { ...this.state.conf, port: event.target.value } })
                      }
                    />
                  </FormGroup>
                  <FormGroup controlId="bands">
                    <ControlLabel>{this.props.t('device_initial_config_bands')}</ControlLabel>
                    <FormControl
                      value={this.state.conf.bands}
                      type="text"
                      placeholder={this.props.t('device_initial_config_bands')}
                      onChange={(event) =>
                        this.setState({ conf: { ...this.state.conf, bands: event.target.value } })
                      }
                    />
                  </FormGroup>
                  <FormGroup controlId="resolve address">
                    <ControlLabel>
                      {this.props.t('device_initial_config_resolve_address')}
                    </ControlLabel>
                    <FormControl
                      value={this.state.conf.resolveAddress}
                      componentClass="select"
                      placeholder={this.props.t('device_initial_config_resolve_address')}
                      onChange={(event) =>
                        this.setState({
                          conf: { ...this.state.conf, resolveAddress: event.target.value },
                        })
                      }
                    >
                      <option value={true}>{this.props.t('capital_yes')}</option>
                      <option value={false}>{this.props.t('capital_no')}</option>
                    </FormControl>
                  </FormGroup>
                  <FormGroup controlId="APN">
                    <ControlLabel>APN</ControlLabel>
                    <FormControl
                      value={this.state.conf.apn}
                      type="text"
                      placeholder="apn"
                      onChange={(event) =>
                        this.setState({ conf: { ...this.state.conf, apn: event.target.value } })
                      }
                    />
                  </FormGroup>
                  <FormGroup controlId="simCardProvider">
                    <ControlLabel>
                      {this.props.t('device_initial_config_simcard_provider')}
                    </ControlLabel>
                    <FormControl
                      value={this.state.conf.simCardProvider}
                      type="text"
                      placeholder={this.props.t('device_initial_config_simcard_provider')}
                      onChange={(event) =>
                        this.setState({
                          conf: { ...this.state.conf, simCardProvider: event.target.value },
                        })
                      }
                    />
                  </FormGroup>
                  <FormGroup controlId="simCardNumber">
                    <ControlLabel>
                      {this.props.t('device_initial_config_simcard_number')}
                    </ControlLabel>
                    <FormControl
                      value={this.state.conf.simCardNumber}
                      type="text"
                      placeholder={this.props.t('device_initial_config_simcard_number')}
                      onChange={(event) =>
                        this.setState({
                          conf: { ...this.state.conf, simCardNumber: event.target.value },
                        })
                      }
                    />
                  </FormGroup>
                </Fragment>
              )}
              {(this.state.model === 'LPMDL_1103' ||
                this.state.model === 'LPMDL_1103_OPENLORA' ||
                this.state.model === 'LPMDL_1105' ||
                this.state.model === 'BL_100_L' ||
                this.state.model === 'W1' ||
                this.state.model === 'ZENNER_EDC' ||
                this.state.model === 'ZENNER_MICRO' ||
                this.state.model === 'INNOTAS_WATERMETER') && (
                <Fragment>
                  <FormGroup controlId="appkey">
                    <ControlLabel>{this.props.t('device_initial_config_OTAA_appkey')}</ControlLabel>
                    <FormControl
                      value={this.state.conf.appConfig.appkey}
                      type="text"
                      placeholder="appkey"
                      onChange={(event) =>
                        this.setState({
                          conf: {
                            ...this.state.conf,
                            appConfig: {
                              ...this.state.conf.appConfig,
                              appkey: event.target.value,
                            },
                          },
                        })
                      }
                    />
                  </FormGroup>
                  <FormGroup controlId="deveui">
                    <ControlLabel>{this.props.t('device_initial_config_OTAA_deveui')}</ControlLabel>
                    <FormControl
                      value={this.state.conf.appConfig.deveui}
                      type="text"
                      placeholder="deveui"
                      onChange={(event) =>
                        this.setState({
                          conf: {
                            ...this.state.conf,
                            appConfig: {
                              ...this.state.conf.appConfig,
                              deveui: event.target.value,
                            },
                          },
                        })
                      }
                    />
                  </FormGroup>
                  <FormGroup controlId="appeui">
                    <ControlLabel>{this.props.t('device_initial_config_OTAA_appeui')}</ControlLabel>
                    <FormControl
                      value={this.state.conf.appConfig.appeui}
                      type="text"
                      placeholder="appeui"
                      onChange={(event) =>
                        this.setState({
                          conf: {
                            ...this.state.conf,
                            appConfig: {
                              ...this.state.conf.appConfig,
                              appeui: event.target.value,
                            },
                          },
                        })
                      }
                    />
                  </FormGroup>
                  <FormGroup controlId="appskey">
                    <ControlLabel>{this.props.t('device_initial_config_ABP_appskey')}</ControlLabel>
                    <FormControl
                      value={this.state.conf.appCollect.appskey}
                      type="text"
                      placeholder="appskey"
                      onChange={(event) =>
                        this.setState({
                          conf: {
                            ...this.state.conf,
                            appCollect: {
                              ...this.state.conf.appCollect,
                              appskey: event.target.value,
                            },
                          },
                        })
                      }
                    />
                  </FormGroup>
                  <FormGroup controlId="devEui">
                    <ControlLabel>{this.props.t('device_initial_config_ABP_deveui')}</ControlLabel>
                    <FormControl
                      value={this.state.conf.appCollect.devEui}
                      type="text"
                      placeholder="deveui"
                      onChange={(event) =>
                        this.setState({
                          conf: {
                            ...this.state.conf,
                            appCollect: {
                              ...this.state.conf.appCollect,
                              devEui: event.target.value,
                            },
                          },
                        })
                      }
                    />
                  </FormGroup>
                  <FormGroup controlId="nwkskey">
                    <ControlLabel>{this.props.t('device_initial_config_ABP_nwkskey')}</ControlLabel>
                    <FormControl
                      value={this.state.conf.appCollect.nwkskey}
                      type="text"
                      placeholder="nwkskey"
                      onChange={(event) =>
                        this.setState({
                          conf: {
                            ...this.state.conf,
                            appCollect: {
                              ...this.state.conf.appCollect,
                              nwkskey: event.target.value,
                            },
                          },
                        })
                      }
                    />
                  </FormGroup>
                  <FormGroup controlId="devaddr">
                    <ControlLabel>{this.props.t('device_initial_config_ABP_devaddr')}</ControlLabel>
                    <FormControl
                      value={this.state.conf.appCollect.devaddr}
                      type="text"
                      placeholder="devaddr"
                      onChange={(event) =>
                        this.setState({
                          conf: {
                            ...this.state.conf,
                            appCollect: {
                              ...this.state.conf.appCollect,
                              devaddr: event.target.value,
                            },
                          },
                        })
                      }
                    />
                  </FormGroup>
                  <FormGroup controlId="loraAppMode">
                    <ControlLabel>
                      {this.props.t('device_initial_config_lora_app_mode')}
                    </ControlLabel>
                    <FormControl
                      value={this.state.conf.mode}
                      componentClass="select"
                      placeholder={this.props.t('device_initial_config_lora_app_mode')}
                      onChange={(event) =>
                        this.setState({
                          conf: { ...this.state.conf, mode: event.target.value },
                        })
                      }
                    >
                      <option value={'OTAA'}>OTAA</option>
                      <option value={'ABP'}>ABP</option>
                    </FormControl>
                  </FormGroup>
                  <FormGroup controlId="adr">
                    <ControlLabel>adr</ControlLabel>
                    <FormControl
                      value={this.state.conf.adr}
                      type="text"
                      placeholder="adr"
                      onChange={(event) =>
                        this.setState({ conf: { ...this.state.conf, adr: event.target.value } })
                      }
                    />
                  </FormGroup>
                  <FormGroup controlId="dr">
                    <ControlLabel>dr</ControlLabel>
                    <FormControl
                      value={this.state.conf.dr}
                      type="text"
                      placeholder="dr"
                      onChange={(event) =>
                        this.setState({ conf: { ...this.state.conf, dr: event.target.value } })
                      }
                    />
                  </FormGroup>
                  <FormGroup controlId="freq">
                    <ControlLabel>freq</ControlLabel>
                    <FormControl
                      value={this.state.conf.freq}
                      type="text"
                      placeholder="freq"
                      onChange={(event) =>
                        this.setState({ conf: { ...this.state.conf, freq: event.target.value } })
                      }
                    />
                  </FormGroup>
                  <FormGroup controlId="rx2Chan">
                    <ControlLabel>rx2Chan</ControlLabel>
                    <FormControl
                      value={this.state.conf.rx2Chan}
                      type="text"
                      placeholder="rx2Chan"
                      onChange={(event) =>
                        this.setState({
                          conf: { ...this.state.conf, rx2Chan: event.target.value },
                        })
                      }
                    />
                  </FormGroup>
                  <FormGroup controlId="rx2Freq">
                    <ControlLabel>rx2Freq</ControlLabel>
                    <FormControl
                      value={this.state.conf.rx2Freq}
                      type="text"
                      placeholder="rx2Freq"
                      onChange={(event) =>
                        this.setState({
                          conf: { ...this.state.conf, rx2Freq: event.target.value },
                        })
                      }
                    />
                  </FormGroup>
                  <FormGroup controlId="platformName">
                    <ControlLabel>
                      {this.props.t('device_initial_config_platform_name')}
                    </ControlLabel>
                    <FormControl
                      value={this.state.conf.platformName}
                      type="text"
                      placeholder={this.props.t('device_initial_config_thingslog_lora_platform')}
                      onChange={(event) =>
                        this.setState({
                          conf: { ...this.state.conf, platformName: event.target.value },
                        })
                      }
                    />
                  </FormGroup>
                  <FormGroup controlId="downlink_api_url">
                    <ControlLabel>Downlink API Url</ControlLabel>
                    <FormControl
                      value={this.state.conf.downlinkApiUrl}
                      onChange={(event) =>
                        this.setState({
                          conf: { ...this.state.conf, downlinkApiUrl: event.target.value },
                        })
                      }
                      type="text"
                      placeholder="Downlink API Url"
                    />
                  </FormGroup>
                  <FormGroup controlId="downlink_api_key">
                    <ControlLabel>Downlink API Key</ControlLabel>
                    <FormControl
                      value={this.state.conf.downlinkApiKey}
                      type="text"
                      placeholder="Downlink API Key"
                      onChange={(event) =>
                        this.setState({
                          conf: { ...this.state.conf, downlinkApiKey: event.target.value },
                        })
                      }
                    />
                  </FormGroup>
                  <FormGroup controlId="appId">
                    <ControlLabel>{this.props.t('device_initial_config_app_id')}</ControlLabel>
                    <FormControl
                      value={this.state.conf.appId}
                      type="number"
                      placeholder={this.props.t('device_initial_config_app_id')}
                      onChange={(event) =>
                        this.setState({
                          conf: { ...this.state.conf, appId: event.target.value },
                        })
                      }
                    />
                  </FormGroup>
                  <FormGroup controlId="profileId">
                    <ControlLabel>{this.props.t('device_initial_config_profile_id')}</ControlLabel>
                    <FormControl
                      value={this.state.conf.profileId}
                      type="string"
                      placeholder={this.props.t('device_initial_config_profile_id')}
                      onChange={(event) =>
                        this.setState({
                          conf: { ...this.state.conf, profileId: event.target.value },
                        })
                      }
                    />
                  </FormGroup>
                </Fragment>
              )}
              {(this.state.model === 'TLCTL_21A4S' || this.state.model === 'LPMDL_1104') && (
                <Autocomplete
                  className="my-5"
                  multiple
                  freeSolo={hasRole(this.props.auth, ROLE_SUPER_ADMIN)}
                  size="small"
                  options={this.state.slaveDevicesOptions}
                  value={this.state.conf.slaveDevices}
                  onChange={(event, arr) => {
                    let slaveDevices = arr;

                    const inputValue = event.target.value;
                    const slaveDeviceEntries = inputValue ? inputValue.split(',') : [];

                    if (slaveDeviceEntries.length > 1) {
                      const deepCopySlaveDevices = structuredClone(this.state.conf.slaveDevices);
                      slaveDevices = deepCopySlaveDevices.concat(slaveDeviceEntries);
                    }

                    const validSlaveDevices = slaveDevices.map((slaveDevice) =>
                      slaveDevice.replace(/\s+/g, '')
                    );

                    this.setState({
                      conf: {
                        ...this.state.conf,
                        slaveDevices: validSlaveDevices,
                      },
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={this.props.t('device_initial_config_slave_devices')}
                    />
                  )}
                />
              )}
              {(this.state.model === 'FINECO_EM735' || this.state.model === 'FINECO_EM4374') && (
                <FormGroup>
                  <ControlLabel>
                    {this.props.t('device_initial_config_modbus_address')}
                  </ControlLabel>
                  <FormControl
                    value={
                      this.state.conf.modbusAddress < 0
                        ? this.state.conf.modbusAddress + 256
                        : this.state.conf.modbusAddress
                    }
                    type="text"
                    inputmode="numeric"
                    pattern="[0-9]*"
                    onChange={(event) => {
                      if (event.target.value >= 0 && event.target.value <= 255) {
                        this.setState({
                          conf: {
                            ...this.state.conf,
                            modbusAddress: Number(event.target.value),
                          },
                        });
                      }
                    }}
                  />
                </FormGroup>
              )}
              {this.state.conf && (
                <>
                  <MuiFormControl fullWidth className="mb-5">
                    <InputLabel>{this.props.t('device_receive_data_binary')}</InputLabel>
                    <Select
                      size="small"
                      label={this.props.t('device_receive_data_binary')}
                      value={this.state.conf.isReceiveDataBin}
                      onChange={(event) => {
                        this.setState({
                          conf: { ...this.state.conf, isReceiveDataBin: event.target.value },
                        });
                      }}
                    >
                      <MenuItem value={true}>{this.props.t('yes')}</MenuItem>
                      <MenuItem value={false}>{this.props.t('no')}</MenuItem>
                    </Select>
                  </MuiFormControl>
                  <MuiFormControl fullWidth>
                    <InputLabel>{this.props.t('device_send_data_binary')}</InputLabel>
                    <Select
                      size="small"
                      label={this.props.t('device_send_data_binary')}
                      value={this.state.conf.isSendDataBin}
                      onChange={(event) => {
                        this.setState({
                          conf: { ...this.state.conf, isSendDataBin: event.target.value },
                        });
                      }}
                    >
                      <MenuItem value={true}>{this.props.t('yes')}</MenuItem>
                      <MenuItem value={false}>{this.props.t('no')}</MenuItem>
                    </Select>
                  </MuiFormControl>
                </>
              )}

              {(this.state.model === 'LPMDL_1101' ||
                this.state.model === 'LPMDL_1104' ||
                this.state.model === 'LPMDL_1106') &&
                hasRole(this.props.auth, ROLE_SUPER_ADMIN) && (
                  <FormGroup controlId="mqtt_config">
                    <ControlLabel>
                      <Checkbox
                        checked={this.state.isMQTTChecked}
                        onChange={(event) => {
                          this.setState({
                            isMQTTChecked: event.target.checked,
                          });
                        }}
                      />
                      {this.props.t('device_mqtt_config')}
                    </ControlLabel>
                  </FormGroup>
                )}

              {this.state.isMQTTChecked && hasRole(this.props.auth, ROLE_SUPER_ADMIN) && (
                <>
                  <FormGroup controlId="quality_of_service">
                    <ControlLabel>{this.props.t('device_mqtt_config_qos')}</ControlLabel>
                    <FormControl
                      value={this.state.conf.mqttConfig?.qos || 0}
                      type="number"
                      onChange={(event) =>
                        this.setState({
                          conf: {
                            ...this.state.conf,
                            mqttConfig: {
                              ...this.state.conf.mqttConfig,
                              qos: event.target.value,
                            },
                          },
                        })
                      }
                    />
                  </FormGroup>
                  <FormGroup controlId="username">
                    <ControlLabel>{this.props.t('device_mqtt_config_username')}</ControlLabel>
                    <FormControl
                      value={this.state.conf.mqttConfig?.username || ''}
                      type="text"
                      onChange={(event) =>
                        this.setState({
                          conf: {
                            ...this.state.conf,
                            mqttConfig: {
                              ...this.state.conf.mqttConfig,
                              username: event.target.value,
                            },
                          },
                        })
                      }
                    />
                  </FormGroup>
                  <FormGroup controlId="password">
                    <ControlLabel>{this.props.t('device_mqtt_config_password')}</ControlLabel>
                    <FormControl
                      value={this.state.conf.mqttConfig?.password || ''}
                      type="password"
                      onChange={(event) =>
                        this.setState({
                          conf: {
                            ...this.state.conf,
                            mqttConfig: {
                              ...this.state.conf.mqttConfig,
                              password: event.target.value,
                            },
                          },
                        })
                      }
                    />
                  </FormGroup>
                  <FormGroup controlId="certificate">
                    <ControlLabel>{this.props.t('device_mqtt_config_certificate')}</ControlLabel>
                    <FormControl
                      value={this.state.conf.mqttConfig?.certificate || ''}
                      type="text"
                      componentClass={'textarea'}
                      rows={10}
                      onChange={(event) =>
                        this.setState({
                          conf: {
                            ...this.state.conf,
                            mqttConfig: {
                              ...this.state.conf.mqttConfig,
                              certificate: event.target.value,
                            },
                          },
                        })
                      }
                    />
                  </FormGroup>
                  <FormGroup controlId="rootTopic">
                    <ControlLabel>rootTopic</ControlLabel>
                    <FormControl
                      value={this.state.conf.mqttConfig?.rootTopic || ''}
                      type="text"
                      placeholder="thingslog"
                      onChange={(event) =>
                        this.setState({
                          conf: {
                            ...this.state.conf,
                            mqttConfig: {
                              ...this.state.conf.mqttConfig,
                              rootTopic: event.target.value,
                            },
                          },
                        })
                      }
                    ></FormControl>
                  </FormGroup>
                </>
              )}

              <Button onClick={this.handleSubmit.bind(this)} disabled={this.state.buttonsDisabled}>
                {this.props.t('button_save')}
              </Button>
              <Snackbar
                open={this.state.open}
                message={this.props.t('successfully_saved')}
                autoHideDuration={4000}
                onClose={this.handleRequestClose}
              />
            </form>
          </div>
        </Header>
      );
    } else {
      return (
        <Header>
          <div>
            <CircularProgress size={80} thickness={5} />
          </div>
        </Header>
      );
    }
  }

  componentDidMount() {
    this.loadDeviceConfigFromServer();
    this.intervalId = setInterval(this.loadConfiguredFlag, 5000);

    if (
      (this.state.model === 'TLCTL_21A4S' || this.state.model === 'LPMDL_1104') &&
      this.props.devices
    ) {
      const slaveDevices = this.props.devices
        .filter(
          (device) =>
            device.number !== this.props.match.params.deviceNumber &&
            (device.model === 'FINECO_EM735' || device.model === 'FINECO_EM4374')
        )
        .map((device) => device.number);

      this.setState({ slaveDevicesOptions: slaveDevices });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.match.params.deviceNumber !== prevProps.match.params.deviceNumber) {
      this.setState({
        conf: null,
      });
      this.loadDeviceConfigFromServer();
    }
    if (this.props.devices !== prevProps.devices || this.state.model !== prevState.model) {
      if (
        (this.state.model === 'TLCTL_21A4S' || this.state.model === 'LPMDL_1104') &&
        this.props.devices
      ) {
        const slaveDevices = this.props.devices
          .filter(
            (device) =>
              device.number !== this.props.match.params.deviceNumber &&
              (device.model === 'FINECO_EM735' || device.model === 'FINECO_EM4374')
          )
          .map((device) => device.number);

        this.setState({ slaveDevicesOptions: slaveDevices });
      }
    }
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  loadDeviceConfigFromServer() {
    timeZonesClient.getTimeZones((timezones) => {
      devicesClient.getDevice(
        this.props.match.params.deviceNumber,
        (device) => {
          deviceInitialConfigClient.getDeviceInitialConfig(
            this.props.match.params.deviceNumber,

            (conf) => {
              this.setState({
                deviceName: device.name,
                customerInfo: device.customerInfo,
                conf: conf,
                model: device.model,
              });

              if (this.state.conf && this.state.conf.mqttConfig) {
                this.setState({
                  isMQTTChecked: true,
                });
              }
            },
            this.errorCallback
          );
        },
        this.errorCallback
      );
    }, this.errorCallback);
  }
}
function mapStateToProps(state) {
  return { auth: state.auth, featureFlags: state.featureFlags, devices: state.dev.devicesArray };
}

export default withRouter(
  connect(mapStateToProps, actions)(withTranslation()(DeviceInitialConfig))
);
