import React from 'react';
import { Units } from '../../model/Units/Units';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Unit } from '@thingslog/repositories';

const UnitsDropdown: React.FC<UnitsDropdownProps> = ({
  allowedUnits,
  onChange,
  unit = Unit.NO_UNITS,
}: UnitsDropdownProps) => {
  const { t } = useTranslation();

  return (
    <FormControl size="small" variant="outlined" fullWidth>
      <InputLabel>Unit Type</InputLabel>
      <Select value={unit} label="Unit Type">
        {allowedUnits
          .sort((a: Unit, b: Unit) => {
            const unitA = Units[a];
            const unitB = Units[b];
            if (unitA === undefined && unitB === undefined) return 0;
            if (unitA === undefined) return -1;
            if (unitB === undefined) return 1;
            return unitA.localeCompare(unitB, 'en');
          })
          .map((unit: Unit, index: number) => {
            return (
              <MenuItem
                id={`unit-${index}`}
                key={`unit-${index}`}
                value={unit}
                onClick={(): void => {
                  onChange(unit);
                }}
              >
                {Units[unit] === Units.NO_UNITS ? t('no_unit') : Units[unit]}
              </MenuItem>
            );
          })}
      </Select>
    </FormControl>
  );
};

interface UnitsDropdownProps {
  allowedUnits: Unit[];
  unit: Unit | null;
  onChange: (unit: Unit) => void;
}

export default UnitsDropdown;
