import { JwtRole } from '@thingslog/repositories/src/jwt/JWT';
import { Action } from 'redux';

export enum AuthActionType {
  AUTH_USER = 'AUTH_USER',
  SIGNED_UP = 'SIGNED_UP',
  SIGN_OUT = 'SIGNED_OUT',
  UNAUTH_USER = 'UNAUTH_USER',
  AUTH_ERROR = 'AUTH_ERROR',
}

export type AuthAction = Action<AuthActionType>;

export interface AuthUserAction extends AuthAction {
  type: AuthActionType.AUTH_USER;
  payload: {
    username: string;
    roles: JwtRole[];
    companyName: string;
    email: string;
    language: string;
    authenticated: boolean;
    signedup: boolean;
    error: string | null;
    isDashboardEnabled: boolean;
  };
}

export interface UnauthUserAction extends AuthAction {
  type: AuthActionType.UNAUTH_USER;
}

export interface SignedUpUserAction extends AuthAction {
  type: AuthActionType.SIGNED_UP;
  payload: {
    username?: string;
  };
}

export interface AuthErrorAction extends AuthAction {
  type: AuthActionType.AUTH_ERROR;
  payload: string;
}

export interface SignOutUserAction extends AuthAction {
  type: AuthActionType.SIGN_OUT;
}
