import React, { Component } from 'react';
import 'react-select/dist/react-select.css';
import {
  sensorAllowedUnitsQueryClient,
  formulaValidatorQueryClient,
} from './clients/ReactQueryClients/ReactQueryClients';
import sensorsConfigClient from './SensorsConfigClient';
import deviceConfigClient from './DeviceConfigClient';
import timeZonesClient from './TimeZonesClient';
import devicesClient from './DevicesClient';
import moment from 'moment';
import { t } from 'i18next';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import { FormGroup, ControlLabel, FormControl } from 'react-bootstrap';
import {
  AccordionSummary as MuiExpansionPanelSummary,
  AccordionDetails as MuiExpansionPanelDetails,
  FormControl as MuiFormControl,
  CircularProgress,
  Autocomplete,
  Typography,
  InputLabel,
  TextField,
  Accordion,
  MenuItem,
  Snackbar,
  Switch,
  Button,
  Select,
  Grid,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import EmailIcon from '@mui/icons-material/Email';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import GoogleAnalyticsService from './common/GoogleAnalyticsService';
import { GaEventCategory } from './common/GaEventCategory';
import { GaEventAction } from './common/GaEventAction';
import { withRouter } from './common/withRouter';
import VersionUtil from './common/VersionUtil';
import NotificationCheckbox from './components/NotificationCheckbox/NotificationCheckbox';
import FractionSelector from './components/FractionSelector/FractionSelector';
import UnitsDropdown from './components/UnitsDropdown/UnitsDropdown';
import TitleHeader from './components/TitleHeader/TitleHeader';
import Header from './components/header';
import { Units } from './model/Units/Units';
import DeviceDigits from './DeviceDigits';

const styles = (theme) => ({
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  fromGroup: {
    width: '100%',
  },
});

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
  },

  expanded: {},
})(MuiExpansionPanelSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
  },
}))(MuiExpansionPanelDetails);

const analogSensorIndexes = [2, 3, 8, 9, 10, 11, 12, 13];
const MAXIMUM_COUNT_THRESHOLD = 600;
const notSupportedModelsInThisPage = ['TLCTL_21A4S', 'FINECO_EM735', 'FINECO_EM4374'];

class DeviceConfig extends Component {
  static MAX_REFRESH_PERIOD_IN_MINUTES = 10;
  static INITIAL_REFRESH_PERIOD_IN_SECONDS = 100;
  static REFRESH_PERIOD_IN_SECONDS_DEFAULT = 5;
  static REFRESH_PERIOD_IN_SECONDS_INITIAL = 5;

  constructor(props) {
    super(props);
    this.state = {
      sensor1Enabeled: false,
      sensor2Enabeled: false,
      sensor3Enabeled: false,
      sensor4Enabeled: false,
      sensor5Enabeled: false,
      sensor6Enabeled: false,
      sensor7Enabled: false,
      sensor8Enabled: false,
      sensor9Enabeled: false,
      sensor10Enabeled: false,
      sensor11Enabeled: false,
      sensor12Enabeled: false,
      sensor13Enabeled: false,
      sensor14Enabeled: false,
      sensor1Name: 'Pulse Sensor 1',
      sensor2Name: 'Pulse Sensor 2',
      sensor3Name: 'Analog Sensor 1',
      sensor4Name: 'Analog Sensor 4',
      sensor5Name: 'On/Off Sensor 1',
      sensor6Name: 'On/Off Sensor 2',
      sensor7Name: 'On/Off Relay 1',
      sensor8Name: 'On/Off Relay 2',
      sensor9Name: 'Analog Sensor 3',
      sensor10Name: 'Analog Sensor 4',
      sensor11Name: 'Analog Sensor 5',
      sensor12Name: 'Analog Sensor 6',
      sensor13Name: 'Analog Sensor 7',
      sensor14Name: 'Analog Sensor 8',
      counter1: null,
      counter2: null,
      digits1: null,
      digits2: null,
      digits3: null,
      digits4: null,
      digits9: null,
      digits10: null,
      digits11: null,
      digits12: null,
      digits13: null,
      digits14: null,
      fraction1: null,
      fraction2: null,
      fraction3: null,
      fraction4: null,
      fraction9: null,
      fraction10: null,
      fraction11: null,
      fraction12: null,
      fraction13: null,
      fraction14: null,
      countsThreshold: null,
      recordPeriod: 1,
      recordEvery: 1,
      transmissionPeriod: null,
      transmissionEvery: null,
      sensorType1: null,
      sensorType2: null,
      sensorType3: null,
      sensorType4: null,
      sensorType5: null,
      sensorType6: null,
      sensorType7: null,
      sensorType8: null,
      sensorType9: null,
      sensorType10: null,
      sensorType11: null,
      sensorType12: null,
      sensorType13: null,
      sensorType14: null,
      unitsType1: null,
      unitsType2: null,
      unitsType3: null,
      unitsType4: null,
      unitsType9: null,
      unitsType10: null,
      unitsType11: null,
      unitsType12: null,
      unitsType13: null,
      unitsType14: null,
      allowedUnits1: null,
      allowedUnits2: null,
      allowedUnits3: null,
      allowedUnits4: null,
      allowedUnits9: null,
      allowedUnits10: null,
      allowedUnits11: null,
      allowedUnits12: null,
      allowedUnits13: null,
      allowedUnits14: null,

      sensor3VeryLowLevelAlarm: 0,
      sensor3VeryLowLevelAlarmEnabled: false,
      sensor3VeryLowLevelEmailNotificationEnabled: false,
      sensor3VeryLowLevelMobileNotificationEnabled: false,
      sensor3LowLevelAlarm: 0,
      sensor3LowLevelAlarmEnabled: false,
      sensor3LowLevelEmailNotificationEnabled: false,
      sensor3LowLevelMobileNotificationEnabled: false,
      sensor3HighLevelAlarm: 0,
      sensor3HighLevelAlarmEnabled: false,
      sensor3HighLevelEmailNotificationEnabled: false,
      sensor3HighLevelMobileNotificationEnabled: false,
      sensor3VeryHighLevelAlarm: 0,
      sensor3VeryHighLevelAlarmEnabled: false,
      sensor3VeryHighLevelEmailNotificationEnabled: false,
      sensor3VeryHighLevelMobileNotificationEnabled: false,

      sensor4VeryLowLevelAlarm: 0,
      sensor4VeryLowLevelAlarmEnabled: false,
      sensor4VeryLowLevelEmailNotificationEnabled: false,
      sensor4VeryLowLevelMobileNotificationEnabled: false,
      sensor4LowLevelAlarm: 0,
      sensor4LowLevelAlarmEnabled: false,
      sensor4LowLevelEmailNotificationEnabled: false,
      sensor4LowLevelMobileNotificationEnabled: false,
      sensor4HighLevelAlarm: 0,
      sensor4HighLevelAlarmEnabled: false,
      sensor4HighLevelEmailNotificationEnabled: false,
      sensor4HighLevelMobileNotificationEnabled: false,
      sensor4VeryHighLevelAlarm: 0,
      sensor4VeryHighLevelAlarmEnabled: false,
      sensor4VeryHighLevelEmailNotificationEnabled: false,
      sensor4VeryHighLevelMobileNotificationEnabled: false,

      sensor9VeryLowLevelAlarm: 0,
      sensor9VeryLowLevelAlarmEnabled: false,
      sensor9VeryLowLevelEmailNotificationEnabled: false,
      sensor9VeryLowLevelMobileNotificationEnabled: false,
      sensor9LowLevelAlarm: 0,
      sensor9LowLevelAlarmEnabled: false,
      sensor9LowLevelEmailNotificationEnabled: false,
      sensor9LowLevelMobileNotificationEnabled: false,
      sensor9HighLevelAlarm: 0,
      sensor9HighLevelAlarmEnabled: false,
      sensor9HighLevelEmailNotificationEnabled: false,
      sensor9HighLevelMobileNotificationEnabled: false,
      sensor9VeryHighLevelAlarm: 0,
      sensor9VeryHighLevelAlarmEnabled: false,
      sensor9VeryHighLevelEmailNotificationEnabled: false,
      sensor9VeryHighLevelMobileNotificationEnabled: false,

      sensor10VeryLowLevelAlarm: 0,
      sensor10VeryLowLevelAlarmEnabled: false,
      sensor10VeryLowLevelEmailNotificationEnabled: false,
      sensor10VeryLowLevelMobileNotificationEnabled: false,
      sensor10LowLevelAlarm: 0,
      sensor10LowLevelAlarmEnabled: false,
      sensor10LowLevelEmailNotificationEnabled: false,
      sensor10LowLevelMobileNotificationEnabled: false,
      sensor10HighLevelAlarm: 0,
      sensor10HighLevelAlarmEnabled: false,
      sensor10HighLevelEmailNotificationEnabled: false,
      sensor10HighLevelMobileNotificationEnabled: false,
      sensor10VeryHighLevelAlarm: 0,
      sensor10VeryHighLevelAlarmEnabled: false,
      sensor10VeryHighLevelEmailNotificationEnabled: false,
      sensor10VeryHighLevelMobileNotificationEnabled: false,

      sensor11VeryLowLevelAlarm: 0,
      sensor11VeryLowLevelAlarmEnabled: false,
      sensor11VeryLowLevelEmailNotificationEnabled: false,
      sensor11VeryLowLevelMobileNotificationEnabled: false,
      sensor11LowLevelAlarm: 0,
      sensor11LowLevelAlarmEnabled: false,
      sensor11LowLevelEmailNotificationEnabled: false,
      sensor11LowLevelMobileNotificationEnabled: false,
      sensor11HighLevelAlarm: 0,
      sensor11HighLevelAlarmEnabled: false,
      sensor11HighLevelEmailNotificationEnabled: false,
      sensor11HighLevelMobileNotificationEnabled: false,
      sensor11VeryHighLevelAlarm: 0,
      sensor11VeryHighLevelAlarmEnabled: false,
      sensor11VeryHighLevelEmailNotificationEnabled: false,
      sensor11VeryHighLevelMobileNotificationEnabled: false,

      sensor12VeryLowLevelAlarm: 0,
      sensor12VeryLowLevelAlarmEnabled: false,
      sensor12VeryLowLevelMobileNotificationEnabled: false,
      sensor12VeryLowLevelEmailNotificationEnabled: false,
      sensor12LowLevelAlarm: 0,
      sensor12LowLevelMobileNotificationEnabled: false,
      sensor12LowLevelEmailNotificationEnabled: false,
      sensor12LowLevelAlarmEnabled: false,
      sensor12HighLevelAlarm: 0,
      sensor12HighLevelAlarmEnabled: false,
      sensor12HighLevelMobileNotificationEnabled: false,
      sensor12HighLevelEmailNotificationEnabled: false,
      sensor12VeryHighLevelAlarm: 0,
      sensor12VeryHighLevelAlarmEnabled: false,
      sensor12VeryHighLevelMobileNotificationEnabled: false,
      sensor12VeryHighLevelEmailNotificationEnabled: false,

      sensor13VeryLowLevelAlarm: 0,
      sensor13VeryLowLevelEmailNotificationEnabled: false,
      sensor13VeryLowLevelMobileNotificationEnabled: false,
      sensor13VeryLowLevelAlarmEnabled: false,
      sensor13LowLevelAlarm: 0,
      sensor13LowLevelEmailNotificationEnabled: false,
      sensor13LowLevelMobileNotificationEnabled: false,
      sensor13LowLevelAlarmEnabled: false,
      sensor13HighLevelAlarm: 0,
      sensor13HighLevelAlarmEnabled: false,
      sensor13HighLevelEmailNotificationEnabled: false,
      sensor13HighLevelMobileNotificationEnabled: false,
      sensor13VeryHighLevelAlarm: 0,
      sensor13VeryHighLevelAlarmEnabled: false,
      sensor13VeryHighLevelEmailNotificationEnabled: false,
      sensor13VeryHighLevelMobileNotificationEnabled: false,

      sensor14VeryLowLevelAlarm: 0,
      sensor14VeryLowLevelEmailNotificationEnabled: false,
      sensor14VeryLowLevelMobileNotificationEnabled: false,
      sensor14VeryLowLevelAlarmEnabled: false,
      sensor14LowLevelAlarm: 0,
      sensor14LowLevelAlarmEnabled: false,
      sensor14LowLevelEmailNotificationEnabled: false,
      sensor14LowLevelMobileNotificationEnabled: false,
      sensor14HighLevelAlarm: 0,
      sensor14HighLevelAlarmEnabled: false,
      sensor14HighLevelEmailNotificationEnabled: false,
      sensor14HighLevelMobileNotificationEnabled: false,
      sensor14VeryHighLevelAlarm: 0,
      sensor14VeryHighLevelAlarmEnabled: false,
      sensor14VeryHighLevelEmailNotificationEnabled: false,
      sensor14VeryHighLevelMobileNotificationEnabled: false,

      pulseCoef1: 0.01,
      pulseCoef2: 0.01,
      isPulseCoef1InitialChange: true,
      isPulseCoef2InitialChange: true,
      sensorFormula3: 'x',
      sensorFormula4: 'x',
      sensorFormula9: 'x',
      sensorFormula10: 'x',
      sensorFormula11: 'x',
      sensorFormula12: 'x',
      sensorFormula13: 'x',
      sensorFormula14: 'x',
      formulaValidity: new Map(),
      isFormulaValidated: true,
      hasInvalidFormula: false,
      model: null,
      configured: null,
      deviceName: null,
      customerInfo: null,
      deleteOldCounters: false,
      open: false,
      snackbarMsg: null,
      time: null,
      timeZone: null,
      missedTransmissionSeverity: null,
      configTimeElapsed: 0,
      configLastRquestTime: null,
      transmissionErrorMsg: null,
      transmissionErrorHighlight: false,
      recordErrorHighlight: false,
      submitPressed: false,
    };
    this.handlerSensor1Toggled = this.handlerSensor1Toggled.bind(this);
    this.handlerSensor2Toggled = this.handlerSensor2Toggled.bind(this);
    this.handlerSensor3Toggled = this.handlerSensor3Toggled.bind(this);
    this.handlerSensor4Toggled = this.handlerSensor4Toggled.bind(this);
    this.handlerSensor5Toggled = this.handlerSensor5Toggled.bind(this);
    this.handlerSensor6Toggled = this.handlerSensor6Toggled.bind(this);
    this.handlerSensor7Toggled = this.handlerSensor7Toggled.bind(this);
    this.handlerSensor8Toggled = this.handlerSensor8Toggled.bind(this);
    this.handlerSensor9Toggled = this.handlerSensor9Toggled.bind(this);
    this.handlerSensor10Toggled = this.handlerSensor10Toggled.bind(this);
    this.handlerSensor11Toggled = this.handlerSensor11Toggled.bind(this);
    this.handlerSensor12Toggled = this.handlerSensor12Toggled.bind(this);
    this.handlerSensor13Toggled = this.handlerSensor13Toggled.bind(this);
    this.handlerSensor14Toggled = this.handlerSensor14Toggled.bind(this);
    this.isPowerOfTenFraction = this.isPowerOfTenFraction.bind(this);
    this.alertPulseCoefNotChanged = this.alertPulseCoefNotChanged.bind(this);
    this.handleCounter1Change = this.handleCounter1Change.bind(this);
    this.handleCounter2Change = this.handleCounter2Change.bind(this);
    this.handleCountsThresholdChange = this.handleCountsThresholdChange.bind(this);
    this.handleRecordPeriodChange = this.handleRecordPeriodChange.bind(this);
    this.handleRecordEveryChange = this.handleRecordEveryChange.bind(this);
    this.handleTransmissionPeriodChange = this.handleTransmissionPeriodChange.bind(this);
    this.handleTransmissionEveryChange = this.handleTransmissionEveryChange.bind(this);
    this.handleTimeZoneChange = this.handleTimeZoneChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.loadDeviceConfigFromServer = this.loadDeviceConfigFromServer.bind(this);
    this.loadConfiguredFlag = this.loadConfiguredFlag.bind(this);
    this.handleConfigSaved = this.handleConfigSaved.bind(this);
    this.refreshTimer = this.refreshTimer.bind(this);
    this.handleRequestClose = this.handleRequestClose.bind(this);
    this.timerId = null;
    this.errorCallback = this.errorCallback.bind(this);
    this.calcCountThreshold = this.calcCountThreshold.bind(this);
    this.handleSensorFormulaValidation = this.handleSensorFormulaValidation.bind(this);
    this.handlerSensor3LowLevelAlarmEnabled = this.handlerSensor3LowLevelAlarmEnabled.bind(this);
    this.handlerSensor3VeryLowLevelAlarmEnabled =
      this.handlerSensor3VeryLowLevelAlarmEnabled.bind(this);
    this.handlerSensor3HighLevelAlarmEnabled = this.handlerSensor3HighLevelAlarmEnabled.bind(this);
    this.handlerSensor3VeryHighLevelAlarmEnabled =
      this.handlerSensor3VeryHighLevelAlarmEnabled.bind(this);
    this.handlerSensor4VeryLowLevelAlarmEnabled =
      this.handlerSensor4VeryLowLevelAlarmEnabled.bind(this);
    this.handlerSensor4LowLevelAlarmEnabled = this.handlerSensor4LowLevelAlarmEnabled.bind(this);
    this.handlerSensor4HighLevelAlarmEnabled = this.handlerSensor4HighLevelAlarmEnabled.bind(this);
    this.handlerSensor4VeryHighLevelAlarmEnabled =
      this.handlerSensor4VeryHighLevelAlarmEnabled.bind(this);
    this.handlerSensor9VeryLowLevelAlarmEnabled =
      this.handlerSensor9VeryLowLevelAlarmEnabled.bind(this);
    this.handlerSensor9LowLevelAlarmEnabled = this.handlerSensor9LowLevelAlarmEnabled.bind(this);
    this.handlerSensor9HighLevelAlarmEnabled = this.handlerSensor9HighLevelAlarmEnabled.bind(this);
    this.handlerSensor9VeryHighLevelAlarmEnabled =
      this.handlerSensor9VeryHighLevelAlarmEnabled.bind(this);
    this.handlerSensor10VeryLowLevelAlarmEnabled =
      this.handlerSensor10VeryLowLevelAlarmEnabled.bind(this);
    this.handlerSensor10LowLevelAlarmEnabled = this.handlerSensor10LowLevelAlarmEnabled.bind(this);
    this.handlerSensor10HighLevelAlarmEnabled =
      this.handlerSensor10HighLevelAlarmEnabled.bind(this);
    this.handlerSensor10VeryHighLevelAlarmEnabled =
      this.handlerSensor10VeryHighLevelAlarmEnabled.bind(this);
    this.handlerSensor11VeryLowLevelAlarmEnabled =
      this.handlerSensor11VeryLowLevelAlarmEnabled.bind(this);
    this.handlerSensor11LowLevelAlarmEnabled = this.handlerSensor11LowLevelAlarmEnabled.bind(this);
    this.handlerSensor11HighLevelAlarmEnabled =
      this.handlerSensor11HighLevelAlarmEnabled.bind(this);
    this.handlerSensor11VeryHighLevelAlarmEnabled =
      this.handlerSensor11VeryHighLevelAlarmEnabled.bind(this);
    this.handlerSensor12VeryLowLevelAlarmEnabled =
      this.handlerSensor12VeryLowLevelAlarmEnabled.bind(this);
    this.handlerSensor12LowLevelAlarmEnabled = this.handlerSensor12LowLevelAlarmEnabled.bind(this);
    this.handlerSensor12HighLevelAlarmEnabled =
      this.handlerSensor12HighLevelAlarmEnabled.bind(this);
    this.handlerSensor12VeryHighLevelAlarmEnabled =
      this.handlerSensor12VeryHighLevelAlarmEnabled.bind(this);
    this.handlerSensor13VeryLowLevelAlarmEnabled =
      this.handlerSensor13VeryLowLevelAlarmEnabled.bind(this);
    this.handlerSensor13LowLevelAlarmEnabled = this.handlerSensor13LowLevelAlarmEnabled.bind(this);
    this.handlerSensor13HighLevelAlarmEnabled =
      this.handlerSensor13HighLevelAlarmEnabled.bind(this);
    this.handlerSensor13VeryHighLevelAlarmEnabled =
      this.handlerSensor13VeryHighLevelAlarmEnabled.bind(this);
    this.handlerSensor14VeryLowLevelAlarmEnabled =
      this.handlerSensor14VeryLowLevelAlarmEnabled.bind(this);
    this.handlerSensor14LowLevelAlarmEnabled = this.handlerSensor14LowLevelAlarmEnabled.bind(this);
    this.handlerSensor14HighLevelAlarmEnabled =
      this.handlerSensor14HighLevelAlarmEnabled.bind(this);
    this.handlerSensor14VeryHighLevelAlarmEnabled =
      this.handlerSensor14VeryHighLevelAlarmEnabled.bind(this);

    this.handlerSensor3VeryLowLevelEmailNotificationEnabled =
      this.handlerSensor3VeryLowLevelEmailNotificationEnabled.bind(this);
    this.handlerSensor3VeryLowLevelMobileNotificationEnabled =
      this.handlerSensor3VeryLowLevelMobileNotificationEnabled.bind(this);
    this.handlerSensor3LowLevelEmailNotificationEnabled =
      this.handlerSensor3LowLevelEmailNotificationEnabled.bind(this);
    this.handlerSensor3LowLevelMobileNotificationEnabled =
      this.handlerSensor3LowLevelMobileNotificationEnabled.bind(this);
    this.handlerSensor3HighLevelEmailNotificationEnabled =
      this.handlerSensor3HighLevelEmailNotificationEnabled.bind(this);
    this.handlerSensor3HighLevelMobileNotificationEnabled =
      this.handlerSensor3HighLevelMobileNotificationEnabled.bind(this);
    this.handlerSensor3VeryHighLevelEmailNotificationEnabled =
      this.handlerSensor3VeryHighLevelEmailNotificationEnabled.bind(this);
    this.handlerSensor3VeryHighLevelMobileNotificationEnabled =
      this.handlerSensor3VeryHighLevelMobileNotificationEnabled.bind(this);

    this.handlerSensor4VeryLowLevelEmailNotificationEnabled =
      this.handlerSensor4VeryLowLevelEmailNotificationEnabled.bind(this);
    this.handlerSensor4VeryLowLevelMobileNotificationEnabled =
      this.handlerSensor4VeryLowLevelMobileNotificationEnabled.bind(this);
    this.handlerSensor4LowLevelEmailNotificationEnabled =
      this.handlerSensor4LowLevelEmailNotificationEnabled.bind(this);
    this.handlerSensor4LowLevelMobileNotificationEnabled =
      this.handlerSensor4LowLevelMobileNotificationEnabled.bind(this);
    this.handlerSensor4HighLevelEmailNotificationEnabled =
      this.handlerSensor4HighLevelEmailNotificationEnabled.bind(this);
    this.handlerSensor4HighLevelMobileNotificationEnabled =
      this.handlerSensor4HighLevelMobileNotificationEnabled.bind(this);
    this.handlerSensor4VeryHighLevelEmailNotificationEnabled =
      this.handlerSensor4VeryHighLevelEmailNotificationEnabled.bind(this);
    this.handlerSensor4VeryHighLevelMobileNotificationEnabled =
      this.handlerSensor4VeryHighLevelMobileNotificationEnabled.bind(this);

    this.handlerSensor9VeryLowLevelEmailNotificationEnabled =
      this.handlerSensor9VeryLowLevelEmailNotificationEnabled.bind(this);
    this.handlerSensor9VeryLowLevelMobileNotificationEnabled =
      this.handlerSensor9VeryLowLevelMobileNotificationEnabled.bind(this);
    this.handlerSensor9LowLevelEmailNotificationEnabled =
      this.handlerSensor9LowLevelEmailNotificationEnabled.bind(this);
    this.handlerSensor9LowLevelMobileNotificationEnabled =
      this.handlerSensor9LowLevelMobileNotificationEnabled.bind(this);
    this.handlerSensor9HighLevelEmailNotificationEnabled =
      this.handlerSensor9HighLevelEmailNotificationEnabled.bind(this);
    this.handlerSensor9HighLevelMobileNotificationEnabled =
      this.handlerSensor9HighLevelMobileNotificationEnabled.bind(this);
    this.handlerSensor9VeryHighLevelEmailNotificationEnabled =
      this.handlerSensor9VeryHighLevelEmailNotificationEnabled.bind(this);
    this.handlerSensor9VeryHighLevelMobileNotificationEnabled =
      this.handlerSensor9VeryHighLevelMobileNotificationEnabled.bind(this);

    this.handlerSensor10VeryLowLevelEmailNotificationEnabled =
      this.handlerSensor10VeryLowLevelEmailNotificationEnabled.bind(this);
    this.handlerSensor10VeryLowLevelMobileNotificationEnabled =
      this.handlerSensor10VeryLowLevelMobileNotificationEnabled.bind(this);
    this.handlerSensor10LowLevelEmailNotificationEnabled =
      this.handlerSensor10LowLevelEmailNotificationEnabled.bind(this);
    this.handlerSensor10LowLevelMobileNotificationEnabled =
      this.handlerSensor10LowLevelMobileNotificationEnabled.bind(this);
    this.handlerSensor10HighLevelEmailNotificationEnabled =
      this.handlerSensor10HighLevelEmailNotificationEnabled.bind(this);
    this.handlerSensor10HighLevelMobileNotificationEnabled =
      this.handlerSensor10HighLevelMobileNotificationEnabled.bind(this);
    this.handlerSensor10VeryHighLevelEmailNotificationEnabled =
      this.handlerSensor10VeryHighLevelEmailNotificationEnabled.bind(this);
    this.handlerSensor10VeryHighLevelMobileNotificationEnabled =
      this.handlerSensor10VeryHighLevelMobileNotificationEnabled.bind(this);

    this.handlerSensor11VeryLowLevelEmailNotificationEnabled =
      this.handlerSensor11VeryLowLevelEmailNotificationEnabled.bind(this);
    this.handlerSensor11VeryLowLevelMobileNotificationEnabled =
      this.handlerSensor11VeryLowLevelMobileNotificationEnabled.bind(this);
    this.handlerSensor11LowLevelEmailNotificationEnabled =
      this.handlerSensor11LowLevelEmailNotificationEnabled.bind(this);
    this.handlerSensor11LowLevelMobileNotificationEnabled =
      this.handlerSensor11LowLevelMobileNotificationEnabled.bind(this);
    this.handlerSensor11HighLevelEmailNotificationEnabled =
      this.handlerSensor11HighLevelEmailNotificationEnabled.bind(this);
    this.handlerSensor11HighLevelMobileNotificationEnabled =
      this.handlerSensor11HighLevelMobileNotificationEnabled.bind(this);
    this.handlerSensor11VeryHighLevelEmailNotificationEnabled =
      this.handlerSensor11VeryHighLevelEmailNotificationEnabled.bind(this);
    this.handlerSensor11VeryHighLevelMobileNotificationEnabled =
      this.handlerSensor11VeryHighLevelMobileNotificationEnabled.bind(this);

    this.handlerSensor12VeryLowLevelEmailNotificationEnabled =
      this.handlerSensor12VeryLowLevelEmailNotificationEnabled.bind(this);
    this.handlerSensor12VeryLowLevelMobileNotificationEnabled =
      this.handlerSensor12VeryLowLevelMobileNotificationEnabled.bind(this);
    this.handlerSensor12LowLevelEmailNotificationEnabled =
      this.handlerSensor12LowLevelEmailNotificationEnabled.bind(this);
    this.handlerSensor12LowLevelMobileNotificationEnabled =
      this.handlerSensor12LowLevelMobileNotificationEnabled.bind(this);
    this.handlerSensor12HighLevelEmailNotificationEnabled =
      this.handlerSensor12HighLevelEmailNotificationEnabled.bind(this);
    this.handlerSensor12HighLevelMobileNotificationEnabled =
      this.handlerSensor12HighLevelMobileNotificationEnabled.bind(this);
    this.handlerSensor12VeryHighLevelEmailNotificationEnabled =
      this.handlerSensor12VeryHighLevelEmailNotificationEnabled.bind(this);
    this.handlerSensor12VeryHighLevelMobileNotificationEnabled =
      this.handlerSensor12VeryHighLevelMobileNotificationEnabled.bind(this);

    this.handlerSensor13VeryLowLevelEmailNotificationEnabled =
      this.handlerSensor13VeryLowLevelEmailNotificationEnabled.bind(this);
    this.handlerSensor13VeryLowLevelMobileNotificationEnabled =
      this.handlerSensor13VeryLowLevelMobileNotificationEnabled.bind(this);
    this.handlerSensor13LowLevelEmailNotificationEnabled =
      this.handlerSensor13LowLevelEmailNotificationEnabled.bind(this);
    this.handlerSensor13LowLevelMobileNotificationEnabled =
      this.handlerSensor13LowLevelMobileNotificationEnabled.bind(this);
    this.handlerSensor13HighLevelEmailNotificationEnabled =
      this.handlerSensor13HighLevelEmailNotificationEnabled.bind(this);
    this.handlerSensor13HighLevelMobileNotificationEnabled =
      this.handlerSensor13HighLevelMobileNotificationEnabled.bind(this);
    this.handlerSensor13VeryHighLevelEmailNotificationEnabled =
      this.handlerSensor13VeryHighLevelEmailNotificationEnabled.bind(this);
    this.handlerSensor13VeryHighLevelMobileNotificationEnabled =
      this.handlerSensor13VeryHighLevelMobileNotificationEnabled.bind(this);

    this.handlerSensor14VeryLowLevelEmailNotificationEnabled =
      this.handlerSensor14VeryLowLevelEmailNotificationEnabled.bind(this);
    this.handlerSensor14VeryLowLevelMobileNotificationEnabled =
      this.handlerSensor14VeryLowLevelMobileNotificationEnabled.bind(this);
    this.handlerSensor14LowLevelEmailNotificationEnabled =
      this.handlerSensor14LowLevelEmailNotificationEnabled.bind(this);
    this.handlerSensor14LowLevelMobileNotificationEnabled =
      this.handlerSensor14LowLevelMobileNotificationEnabled.bind(this);
    this.handlerSensor14HighLevelEmailNotificationEnabled =
      this.handlerSensor14HighLevelEmailNotificationEnabled.bind(this);
    this.handlerSensor14HighLevelMobileNotificationEnabled =
      this.handlerSensor14HighLevelMobileNotificationEnabled.bind(this);
    this.handlerSensor14VeryHighLevelEmailNotificationEnabled =
      this.handlerSensor14VeryHighLevelEmailNotificationEnabled.bind(this);
    this.handlerSensor14VeryHighLevelMobileNotificationEnabled =
      this.handlerSensor14VeryHighLevelMobileNotificationEnabled.bind(this);

    this.handleUnitsType1Change = this.handleUnitsType1Change.bind(this);
    this.handleUnitsType2Change = this.handleUnitsType2Change.bind(this);
    this.handleUnitsType3Change = this.handleUnitsType3Change.bind(this);
    this.handleUnitsType4Change = this.handleUnitsType4Change.bind(this);
    this.handleUnitsType9Change = this.handleUnitsType9Change.bind(this);
    this.handleUnitsType10Change = this.handleUnitsType10Change.bind(this);
    this.handleUnitsType11Change = this.handleUnitsType11Change.bind(this);
    this.handleUnitsType12Change = this.handleUnitsType12Change.bind(this);
    this.handleUnitsType13Change = this.handleUnitsType13Change.bind(this);
    this.handleUnitsType14Change = this.handleUnitsType14Change.bind(this);
    this.updateSensorAlarmConfigList = this.updateSensorAlarmConfigList.bind(this);
    this.getSensorsAlarmsConfig = this.getSensorsAlarmsConfig.bind(this);
    this.getNameOfSensor = this.getNameOfSensor.bind(this);
    this.generateDefaultAnalogPortConfigIfMissing =
      this.generateDefaultAnalogPortConfigIfMissing.bind(this);
    this.getRecordPeriodOptionsForTransmissionPeriod =
      this.getRecordPeriodOptionsForTransmissionPeriod.bind(this);
    this.calculatePeriodInSeconds = this.calculatePeriodInSeconds.bind(this);
    this.handleSensorTypeChange = this.handleSensorTypeChange.bind(this);
  }

  errorCallback(error) {
    if (error.response === undefined) {
      throw error;
    }
    if (error.response.status === 401 || error.response.status === 403) {
      this.props.history.push('/app/signout');
    } else if (error.response.data) {
      if (error.response.data.message) {
        alert('Error: ' + error.response.data.message);
      } else {
        alert('Error: ' + error.response.data);
      }
    }
  }

  async handleSubmit(event) {
    GoogleAnalyticsService.triggerEvent(
      GaEventCategory.CONFIG_PAGE,
      GaEventAction.CONFIG_START_SETUP
    );
    this.setState({
      saveDisabled: true,
      submitPressed: true,
      configured: false,
      configTimeElapsed: 0,
      configLastRquestTime: moment(),
    });
    if (this.timerId != null) clearInterval(this.timerId);
    this.setState({ ...{ time: 0 } });
    this.timerId = setInterval(this.refreshTimer.bind(this), 1000);

    let countsThreshold = this.calcCountThreshold(
      this.state.transmissionEvery,
      this.state.transmissionPeriod,
      this.state.recordEvery,
      this.state.recordPeriod
    );

    if (countsThreshold >= 600) {
      alert(
        'Too big transmission period. Calculated: readings for transmit: ' +
          countsThreshold +
          ', maximum allowed 600 readings'
      );
      this.setState({ saveDisabled: false });
      return false;
    }

    let sensorConfigs = [
      {
        index: 0,
        enabled: this.state.sensor1Enabeled,
        parameters: {
          digits: this.state.digits1,
          fraction: this.state.fraction1,
          initial_counter: this.state.counter1,
          sensor_type: this.state.sensorType1,
          units_type: this.state.unitsType1,
          pulse_coef: this.state.pulseCoef1,
          notif_emails: this.state.notificationEmails1,
        },
      },
      {
        index: 1,
        enabled: this.state.sensor2Enabeled,
        parameters: {
          digits: this.state.digits2,
          fraction: this.state.fraction2,
          initial_counter: this.state.counter2,
          sensor_type: this.state.sensorType2,
          units_type: this.state.unitsType2,
          pulse_coef: this.state.pulseCoef2,
          notif_emails: this.state.notificationEmails2,
        },
      },
      {
        index: 2,
        enabled: this.state.sensor3Enabeled,
        parameters: {
          digits: this.state.digits3,
          fraction: this.state.fraction3,
          sensor_type: this.state.sensorType3,
          units_type: this.state.unitsType3,
          formula: this.state.sensorFormula3,
          low_level_alarm_enabled: this.state.sensor3LowLevelAlarmEnabled,
          low_level_alarm: this.state.sensor3LowLevelAlarm,
          high_level_alarm_enabled: this.state.sensor3HighLevelAlarmEnabled,
          high_level_alarm: this.state.sensor3HighLevelAlarm,
          notif_emails: this.state.notificationEmails3,
        },
      },
      {
        index: 3,
        enabled: this.state.sensor4Enabeled,
        parameters: {
          digits: this.state.digits4,
          fraction: this.state.fraction4,
          sensor_type: this.state.sensorType4,
          units_type: this.state.unitsType4,
          formula: this.state.sensorFormula4,
          low_level_alarm_enabled: this.state.sensor4LowLevelAlarmEnabled,
          low_level_alarm: this.state.sensor4LowLevelAlarm,
          high_level_alarm_enabled: this.state.sensor4HighLevelAlarmEnabled,
          high_level_alarm: this.state.sensor4HighLevelAlarm,
          notif_emails: this.state.notificationEmails4,
        },
      },
      {
        index: 4,
        enabled: this.state.sensor5Enabeled,
        parameters: {
          sensor_type: this.state.sensorType5,
          notif_emails: this.state.notificationEmails5 ? this.state.notificationEmails5 : '',
        },
      },
      {
        index: 5,
        enabled: this.state.sensor6Enabeled,
        parameters: {
          sensor_type: this.state.sensorType6,
          notif_emails: this.state.notificationEmails6 ? this.state.notificationEmails6 : '',
        },
      },
    ];

    if (VersionUtil.areExtraSensorsVisible(this.state.hwVersion, this.state.model)) {
      sensorConfigs.push(
        {
          index: 6,
          enabled: this.state.sensor7Enabeled,
          parameters: {
            sensor_type: this.state.sensorType7,
            notif_emails: this.state.notificationEmails7 ? this.state.notificationEmails7 : '',
          },
        },
        {
          index: 7,
          enabled: this.state.sensor8Enabeled,
          parameters: {
            sensor_type: this.state.sensorType8,
            notif_emails: this.state.notificationEmails8 ? this.state.notificationEmails8 : '',
          },
        },
        {
          index: 8,
          enabled: this.state.sensor9Enabeled,
          parameters: {
            digits: this.state.digits9,
            fraction: this.state.fraction9,
            sensor_type: this.state.sensorType9,
            units_type: this.state.unitsType9,
            formula: this.state.sensorFormula9,
            notif_emails: this.state.notificationEmails9,
          },
        },
        {
          index: 9,
          enabled: this.state.sensor10Enabeled,
          parameters: {
            digits: this.state.digits10,
            fraction: this.state.fraction10,
            sensor_type: this.state.sensorType10,
            units_type: this.state.unitsType10,
            formula: this.state.sensorFormula10,
            notif_emails: this.state.notificationEmails10,
          },
        },
        {
          index: 10,
          enabled: this.state.sensor11Enabeled,
          parameters: {
            digits: this.state.digits11,
            fraction: this.state.fraction11,
            sensor_type: this.state.sensorType11,
            units_type: this.state.unitsType11,
            formula: this.state.sensorFormula11,
            notif_emails: this.state.notificationEmails11,
          },
        },
        {
          index: 11,
          enabled: this.state.sensor12Enabeled,
          parameters: {
            digits: this.state.digits12,
            fraction: this.state.fraction12,
            sensor_type: this.state.sensorType12,
            units_type: this.state.unitsType12,
            formula: this.state.sensorFormula12,
            notif_emails: this.state.notificationEmails12,
          },
        },
        {
          index: 12,
          enabled: this.state.sensor13Enabeled,
          parameters: {
            digits: this.state.digits13,
            fraction: this.state.fraction13,
            sensor_type: this.state.sensorType13,
            units_type: this.state.unitsType13,
            formula: this.state.sensorFormula13,
            notif_emails: this.state.notificationEmails13,
          },
        },
        {
          index: 13,
          enabled: this.state.sensor14Enabeled,
          parameters: {
            digits: this.state.digits14,
            fraction: this.state.fraction14,
            sensor_type: this.state.sensorType14,
            units_type: this.state.unitsType14,
            formula: this.state.sensorFormula14,
            notif_emails: this.state.notificationEmails14,
          },
        }
      );
    }

    deviceConfigClient.putDeviceConfig(
      this.props.match.params.deviceNumber,
      {
        sensorConfigs: sensorConfigs,
        countsThreshold: countsThreshold,
        recordPeriod: this.state.recordPeriod,
        every: this.state.recordEvery,
        configured: false,
        saveDisabled: false,
        timeZone: this.state.timeZone,
        deleteOldCounters: this.state.deleteOldCounters,
        missedTransmissionSeverity: this.state.missedTransmissionSeverity,
      },
      () => {
        sensorsConfigClient.getPortsConfig(
          this.props.match.params.deviceNumber,
          (currPortsConfig) => {
            this.setState(
              (prevState) => ({ ...prevState, portsConfig: currPortsConfig }),
              () => {
                this.updateSensorAlarmConfigList(this.state.portsConfig);
                sensorsConfigClient.putPortsConfig(
                  this.props.match.params.deviceNumber,
                  this.state.portsConfig,
                  this.handleConfigSaved,
                  this.errorCallback
                );
              }
            );
          },
          this.errorCallback
        );
      },

      this.errorCallback
    );

    event.preventDefault();
  }

  async handleSensorTypeChange(sensorPosition, sensorType) {
    const allowedUnits = await sensorAllowedUnitsQueryClient.getSensorAllowedUnits(sensorType);

    switch (sensorPosition) {
      case 1:
        this.setState({
          sensorType1: sensorType,
          allowedUnits1: allowedUnits,
          unitsType1: allowedUnits[0],
        });
        break;
      case 2:
        this.setState({
          sensorType2: sensorType,
          allowedUnits2: allowedUnits,
          unitsType2: allowedUnits[0],
        });
        break;
      case 3:
        this.setState({
          sensorType3: sensorType,
          allowedUnits3: allowedUnits,
          unitsType3: allowedUnits[0],
        });
        break;
      case 4:
        this.setState({
          sensorType4: sensorType,
          allowedUnits4: allowedUnits,
          unitsType4: allowedUnits[0],
        });
        break;
      case 9:
        this.setState({
          sensorType9: sensorType,
          allowedUnits9: allowedUnits,
          unitsType9: allowedUnits[0],
        });
        break;
      case 10:
        this.setState({
          sensorType10: sensorType,
          allowedUnits10: allowedUnits,
          unitsType10: allowedUnits[0],
        });
        break;

      case 11:
        this.setState({
          sensorType11: sensorType,
          allowedUnits11: allowedUnits,
          unitsType11: allowedUnits[0],
        });
        break;
      case 12:
        this.setState({
          sensorType12: sensorType,
          allowedUnits12: allowedUnits,
          unitsType12: allowedUnits[0],
        });
        break;
      case 13:
        this.setState({
          sensorType13: sensorType,
          allowedUnits13: allowedUnits,
          unitsType13: allowedUnits[0],
        });
        break;
      case 14:
        this.setState({
          sensorType14: sensorType,
          allowedUnits14: allowedUnits,
          unitsType14: allowedUnits[0],
        });
        break;
    }
  }

  getAlarmStatusForPort(portIndex) {
    let alarmStatus = {
      veryLowLevelAlarmEnabled: false,
      veryLowLevelAlarmThreshold: 0,
      veryLowLevelAlarmEmailNotificationEnabled: false,
      veryLowLevelAlarmMobileNotificationEnabled: false,
      lowLevelAlarmEnabled: false,
      lowLevelAlarmThreshold: 0,
      lowLevelAlarmEmailNotificationEnabled: false,
      lowLevelAlarmMobileNotificationEnabled: false,
      highLevelAlarmEnabled: false,
      highLevelAlarmThreshold: 0,
      highLevelAlarmEmailNotificationEnabled: false,
      highLevelAlarmMobileNotificationEnabled: false,
      veryHighLevelAlarmEnabled: false,
      veryHighLevelAlarmThreshold: 0,
      veryHighLevelAlarmEmailNotificationEnabled: false,
      veryHighLevelAlarmMobileNotificationEnabled: false,
    };
    switch (portIndex) {
      case 2:
        alarmStatus.veryLowLevelAlarmEnabled = this.state.sensor3VeryLowLevelAlarmEnabled;
        alarmStatus.veryLowLevelAlarmThreshold = this.state.sensor3VeryLowLevelAlarm;
        alarmStatus.veryLowLevelAlarmEmailNotificationEnabled =
          this.state.sensor3VeryLowLevelEmailNotificationEnabled;
        alarmStatus.veryLowLevelAlarmMobileNotificationEnabled =
          this.state.sensor3VeryLowLevelMobileNotificationEnabled;
        alarmStatus.lowLevelAlarmEnabled = this.state.sensor3LowLevelAlarmEnabled;
        alarmStatus.lowLevelAlarmThreshold = this.state.sensor3LowLevelAlarm;
        alarmStatus.lowLevelAlarmEmailNotificationEnabled =
          this.state.sensor3LowLevelEmailNotificationEnabled;
        alarmStatus.lowLevelAlarmMobileNotificationEnabled =
          this.state.sensor3LowLevelMobileNotificationEnabled;
        alarmStatus.highLevelAlarmEnabled = this.state.sensor3HighLevelAlarmEnabled;
        alarmStatus.highLevelAlarmThreshold = this.state.sensor3HighLevelAlarm;
        alarmStatus.highLevelAlarmEmailNotificationEnabled =
          this.state.sensor3HighLevelEmailNotificationEnabled;
        alarmStatus.highLevelAlarmMobileNotificationEnabled =
          this.state.sensor3HighLevelMobileNotificationEnabled;
        alarmStatus.veryHighLevelAlarmEnabled = this.state.sensor3VeryHighLevelAlarmEnabled;
        alarmStatus.veryHighLevelAlarmThreshold = this.state.sensor3VeryHighLevelAlarm;
        alarmStatus.veryHighLevelAlarmEmailNotificationEnabled =
          this.state.sensor3VeryHighLevelEmailNotificationEnabled;
        alarmStatus.veryHighLevelAlarmMobileNotificationEnabled =
          this.state.sensor3VeryHighLevelMobileNotificationEnabled;
        return alarmStatus;
      case 3:
        alarmStatus.veryLowLevelAlarmEnabled = this.state.sensor4VeryLowLevelAlarmEnabled;
        alarmStatus.veryLowLevelAlarmThreshold = this.state.sensor4VeryLowLevelAlarm;
        alarmStatus.veryLowLevelAlarmEmailNotificationEnabled =
          this.state.sensor4VeryLowLevelEmailNotificationEnabled;
        alarmStatus.veryLowLevelAlarmMobileNotificationEnabled =
          this.state.sensor4VeryLowLevelMobileNotificationEnabled;
        alarmStatus.lowLevelAlarmEnabled = this.state.sensor4LowLevelAlarmEnabled;
        alarmStatus.lowLevelAlarmThreshold = this.state.sensor4LowLevelAlarm;
        alarmStatus.lowLevelAlarmEmailNotificationEnabled =
          this.state.sensor4LowLevelEmailNotificationEnabled;
        alarmStatus.lowLevelAlarmMobileNotificationEnabled =
          this.state.sensor4LowLevelMobileNotificationEnabled;
        alarmStatus.highLevelAlarmEnabled = this.state.sensor4HighLevelAlarmEnabled;
        alarmStatus.highLevelAlarmThreshold = this.state.sensor4HighLevelAlarm;
        alarmStatus.highLevelAlarmEmailNotificationEnabled =
          this.state.sensor4HighLevelEmailNotificationEnabled;
        alarmStatus.highLevelAlarmMobileNotificationEnabled =
          this.state.sensor4HighLevelMobileNotificationEnabled;
        alarmStatus.veryHighLevelAlarmEnabled = this.state.sensor4VeryHighLevelAlarmEnabled;
        alarmStatus.veryHighLevelAlarmThreshold = this.state.sensor4VeryHighLevelAlarm;
        alarmStatus.veryHighLevelAlarmEmailNotificationEnabled =
          this.state.sensor4VeryHighLevelEmailNotificationEnabled;
        alarmStatus.veryHighLevelAlarmMobileNotificationEnabled =
          this.state.sensor4VeryHighLevelMobileNotificationEnabled;
        return alarmStatus;
      case 8:
        alarmStatus.veryLowLevelAlarmEnabled = this.state.sensor9VeryLowLevelAlarmEnabled;
        alarmStatus.veryLowLevelAlarmThreshold = this.state.sensor9VeryLowLevelAlarm;
        alarmStatus.veryLowLevelAlarmEmailNotificationEnabled =
          this.state.sensor9VeryLowLevelEmailNotificationEnabled;
        alarmStatus.veryLowLevelAlarmMobileNotificationEnabled =
          this.state.sensor9VeryLowLevelMobileNotificationEnabled;
        alarmStatus.lowLevelAlarmEnabled = this.state.sensor9LowLevelAlarmEnabled;
        alarmStatus.lowLevelAlarmThreshold = this.state.sensor9LowLevelAlarm;
        alarmStatus.lowLevelAlarmEmailNotificationEnabled =
          this.state.sensor9LowLevelEmailNotificationEnabled;
        alarmStatus.lowLevelAlarmMobileNotificationEnabled =
          this.state.sensor9LowLevelMobileNotificationEnabled;
        alarmStatus.highLevelAlarmEnabled = this.state.sensor9HighLevelAlarmEnabled;
        alarmStatus.highLevelAlarmThreshold = this.state.sensor9HighLevelAlarm;
        alarmStatus.highLevelAlarmEmailNotificationEnabled =
          this.state.sensor9HighLevelEmailNotificationEnabled;
        alarmStatus.highLevelAlarmMobileNotificationEnabled =
          this.state.sensor9HighLevelMobileNotificationEnabled;
        alarmStatus.veryHighLevelAlarmEnabled = this.state.sensor9VeryHighLevelAlarmEnabled;
        alarmStatus.veryHighLevelAlarmThreshold = this.state.sensor9VeryHighLevelAlarm;
        alarmStatus.veryHighLevelAlarmEmailNotificationEnabled =
          this.state.sensor9VeryHighLevelEmailNotificationEnabled;
        alarmStatus.veryHighLevelAlarmMobileNotificationEnabled =
          this.state.sensor9VeryHighLevelMobileNotificationEnabled;
        return alarmStatus;
      case 9:
        alarmStatus.veryLowLevelAlarmEnabled = this.state.sensor10VeryLowLevelAlarmEnabled;
        alarmStatus.veryLowLevelAlarmThreshold = this.state.sensor10VeryLowLevelAlarm;
        alarmStatus.veryLowLevelAlarmEmailNotificationEnabled =
          this.state.sensor10VeryLowLevelEmailNotificationEnabled;
        alarmStatus.veryLowLevelAlarmMobileNotificationEnabled =
          this.state.sensor10VeryLowLevelMobileNotificationEnabled;
        alarmStatus.lowLevelAlarmEnabled = this.state.sensor10LowLevelAlarmEnabled;
        alarmStatus.lowLevelAlarmThreshold = this.state.sensor10LowLevelAlarm;
        alarmStatus.lowLevelAlarmEmailNotificationEnabled =
          this.state.sensor10LowLevelEmailNotificationEnabled;
        alarmStatus.lowLevelAlarmMobileNotificationEnabled =
          this.state.sensor10LowLevelMobileNotificationEnabled;
        alarmStatus.highLevelAlarmEnabled = this.state.sensor10HighLevelAlarmEnabled;
        alarmStatus.highLevelAlarmThreshold = this.state.sensor10HighLevelAlarm;
        alarmStatus.highLevelAlarmEmailNotificationEnabled =
          this.state.sensor10HighLevelEmailNotificationEnabled;
        alarmStatus.highLevelAlarmMobileNotificationEnabled =
          this.state.sensor10HighLevelMobileNotificationEnabled;
        alarmStatus.veryHighLevelAlarmEnabled = this.state.sensor10VeryHighLevelAlarmEnabled;
        alarmStatus.veryHighLevelAlarmThreshold = this.state.sensor10VeryHighLevelAlarm;
        alarmStatus.veryHighLevelAlarmEmailNotificationEnabled =
          this.state.sensor10VeryHighLevelEmailNotificationEnabled;
        alarmStatus.veryHighLevelAlarmMobileNotificationEnabled =
          this.state.sensor10VeryHighLevelMobileNotificationEnabled;
        return alarmStatus;
      case 10:
        alarmStatus.veryLowLevelAlarmEnabled = this.state.sensor11VeryLowLevelAlarmEnabled;
        alarmStatus.veryLowLevelAlarmThreshold = this.state.sensor11VeryLowLevelAlarm;
        alarmStatus.veryLowLevelAlarmEmailNotificationEnabled =
          this.state.sensor11VeryLowLevelEmailNotificationEnabled;
        alarmStatus.veryLowLevelAlarmMobileNotificationEnabled =
          this.state.sensor11VeryLowLevelMobileNotificationEnabled;
        alarmStatus.lowLevelAlarmEnabled = this.state.sensor11LowLevelAlarmEnabled;
        alarmStatus.lowLevelAlarmThreshold = this.state.sensor11LowLevelAlarm;
        alarmStatus.lowLevelAlarmEmailNotificationEnabled =
          this.state.sensor11LowLevelEmailNotificationEnabled;
        alarmStatus.lowLevelAlarmMobileNotificationEnabled =
          this.state.sensor11LowLevelMobileNotificationEnabled;
        alarmStatus.highLevelAlarmEnabled = this.state.sensor11HighLevelAlarmEnabled;
        alarmStatus.highLevelAlarmThreshold = this.state.sensor11HighLevelAlarm;
        alarmStatus.highLevelAlarmEmailNotificationEnabled =
          this.state.sensor11HighLevelEmailNotificationEnabled;
        alarmStatus.highLevelAlarmMobileNotificationEnabled =
          this.state.sensor11HighLevelMobileNotificationEnabled;
        alarmStatus.veryHighLevelAlarmEnabled = this.state.sensor11VeryHighLevelAlarmEnabled;
        alarmStatus.veryHighLevelAlarmThreshold = this.state.sensor11VeryHighLevelAlarm;
        alarmStatus.veryHighLevelAlarmEmailNotificationEnabled =
          this.state.sensor11VeryHighLevelEmailNotificationEnabled;
        alarmStatus.veryHighLevelAlarmMobileNotificationEnabled =
          this.state.sensor11VeryHighLevelMobileNotificationEnabled;
        return alarmStatus;
      case 11:
        alarmStatus.veryLowLevelAlarmEnabled = this.state.sensor12VeryLowLevelAlarmEnabled;
        alarmStatus.veryLowLevelAlarmThreshold = this.state.sensor12VeryLowLevelAlarm;
        alarmStatus.veryLowLevelAlarmEmailNotificationEnabled =
          this.state.sensor12VeryLowLevelEmailNotificationEnabled;
        alarmStatus.veryLowLevelAlarmMobileNotificationEnabled =
          this.state.sensor12VeryLowLevelMobileNotificationEnabled;
        alarmStatus.lowLevelAlarmEnabled = this.state.sensor12LowLevelAlarmEnabled;
        alarmStatus.lowLevelAlarmThreshold = this.state.sensor12LowLevelAlarm;
        alarmStatus.lowLevelAlarmEmailNotificationEnabled =
          this.state.sensor12LowLevelEmailNotificationEnabled;
        alarmStatus.lowLevelAlarmMobileNotificationEnabled =
          this.state.sensor12LowLevelMobileNotificationEnabled;
        alarmStatus.highLevelAlarmEnabled = this.state.sensor12HighLevelAlarmEnabled;
        alarmStatus.highLevelAlarmThreshold = this.state.sensor12HighLevelAlarm;
        alarmStatus.highLevelAlarmEmailNotificationEnabled =
          this.state.sensor12HighLevelEmailNotificationEnabled;
        alarmStatus.highLevelAlarmMobileNotificationEnabled =
          this.state.sensor12HighLevelMobileNotificationEnabled;
        alarmStatus.veryHighLevelAlarmEnabled = this.state.sensor12VeryHighLevelAlarmEnabled;
        alarmStatus.veryHighLevelAlarmThreshold = this.state.sensor12VeryHighLevelAlarm;
        alarmStatus.veryHighLevelAlarmEmailNotificationEnabled =
          this.state.sensor12VeryHighLevelEmailNotificationEnabled;
        alarmStatus.veryHighLevelAlarmMobileNotificationEnabled =
          this.state.sensor12VeryHighLevelMobileNotificationEnabled;
        return alarmStatus;
      case 12:
        alarmStatus.veryLowLevelAlarmEnabled = this.state.sensor13VeryLowLevelAlarmEnabled;
        alarmStatus.veryLowLevelAlarmThreshold = this.state.sensor13VeryLowLevelAlarm;
        alarmStatus.veryLowLevelAlarmEmailNotificationEnabled =
          this.state.sensor13VeryLowLevelEmailNotificationEnabled;
        alarmStatus.veryLowLevelAlarmMobileNotificationEnabled =
          this.state.sensor13VeryLowLevelMobileNotificationEnabled;
        alarmStatus.lowLevelAlarmEnabled = this.state.sensor13LowLevelAlarmEnabled;
        alarmStatus.lowLevelAlarmThreshold = this.state.sensor13LowLevelAlarm;
        alarmStatus.lowLevelAlarmEmailNotificationEnabled =
          this.state.sensor13LowLevelEmailNotificationEnabled;
        alarmStatus.lowLevelAlarmMobileNotificationEnabled =
          this.state.sensor13LowLevelMobileNotificationEnabled;
        alarmStatus.highLevelAlarmEnabled = this.state.sensor13HighLevelAlarmEnabled;
        alarmStatus.highLevelAlarmThreshold = this.state.sensor13HighLevelAlarm;
        alarmStatus.highLevelAlarmEmailNotificationEnabled =
          this.state.sensor13HighLevelEmailNotificationEnabled;
        alarmStatus.highLevelAlarmMobileNotificationEnabled =
          this.state.sensor13HighLevelMobileNotificationEnabled;
        alarmStatus.veryHighLevelAlarmEnabled = this.state.sensor13VeryHighLevelAlarmEnabled;
        alarmStatus.veryHighLevelAlarmThreshold = this.state.sensor13VeryHighLevelAlarm;
        alarmStatus.veryHighLevelAlarmEmailNotificationEnabled =
          this.state.sensor13VeryHighLevelEmailNotificationEnabled;
        alarmStatus.veryHighLevelAlarmMobileNotificationEnabled =
          this.state.sensor13VeryHighLevelMobileNotificationEnabled;
        return alarmStatus;
      case 13:
        alarmStatus.veryLowLevelAlarmEnabled = this.state.sensor14VeryLowLevelAlarmEnabled;
        alarmStatus.veryLowLevelAlarmThreshold = this.state.sensor14VeryLowLevelAlarm;
        alarmStatus.veryLowLevelAlarmEmailNotificationEnabled =
          this.state.sensor14VeryLowLevelEmailNotificationEnabled;
        alarmStatus.veryLowLevelAlarmMobileNotificationEnabled =
          this.state.sensor14VeryLowLevelMobileNotificationEnabled;
        alarmStatus.lowLevelAlarmEnabled = this.state.sensor14LowLevelAlarmEnabled;
        alarmStatus.lowLevelAlarmThreshold = this.state.sensor14LowLevelAlarm;
        alarmStatus.lowLevelAlarmEmailNotificationEnabled =
          this.state.sensor14LowLevelEmailNotificationEnabled;
        alarmStatus.lowLevelAlarmMobileNotificationEnabled =
          this.state.sensor14LowLevelMobileNotificationEnabled;
        alarmStatus.highLevelAlarmEnabled = this.state.sensor14HighLevelAlarmEnabled;
        alarmStatus.highLevelAlarmThreshold = this.state.sensor14HighLevelAlarm;
        alarmStatus.highLevelAlarmEmailNotificationEnabled =
          this.state.sensor14HighLevelEmailNotificationEnabled;
        alarmStatus.highLevelAlarmMobileNotificationEnabled =
          this.state.sensor14HighLevelMobileNotificationEnabled;
        alarmStatus.veryHighLevelAlarmEnabled = this.state.sensor14VeryHighLevelAlarmEnabled;
        alarmStatus.veryHighLevelAlarmThreshold = this.state.sensor14VeryHighLevelAlarm;
        alarmStatus.veryHighLevelAlarmEmailNotificationEnabled =
          this.state.sensor14VeryHighLevelEmailNotificationEnabled;
        alarmStatus.veryHighLevelAlarmMobileNotificationEnabled =
          this.state.sensor14VeryHighLevelMobileNotificationEnabled;
        return alarmStatus;

      default:
        return null;
    }
  }

  updateSensorAlarmConfigList(portsConfig) {
    analogSensorIndexes.map((analogPortIndex) => {
      if (portsConfig && portsConfig[analogPortIndex] && portsConfig[analogPortIndex].sensor) {
        const alarms = this.getAlarmStatusForPort(analogPortIndex);
        let alarmConfigList = [];
        if (alarms?.veryLowLevelAlarmEnabled) {
          alarmConfigList.push({
            type: 'VERY_LOW_LEVEL',
            threshold: alarms.veryLowLevelAlarmThreshold,
            enableEmailNotification: alarms.veryLowLevelAlarmEmailNotificationEnabled,
            enableMobileNotification: alarms.veryLowLevelAlarmMobileNotificationEnabled,
          });
        }
        if (alarms?.lowLevelAlarmEnabled) {
          alarmConfigList.push({
            type: 'LOW_LEVEL',
            threshold: alarms.lowLevelAlarmThreshold,
            enableEmailNotification: alarms.lowLevelAlarmEmailNotificationEnabled,
            enableMobileNotification: alarms.lowLevelAlarmMobileNotificationEnabled,
          });
        }
        if (alarms?.highLevelAlarmEnabled) {
          alarmConfigList.push({
            type: 'HIGH_LEVEL',
            threshold: alarms.highLevelAlarmThreshold,
            enableEmailNotification: alarms.highLevelAlarmEmailNotificationEnabled,
            enableMobileNotification: alarms.highLevelAlarmMobileNotificationEnabled,
          });
        }
        if (alarms?.veryHighLevelAlarmEnabled) {
          alarmConfigList.push({
            type: 'VERY_HIGH_LEVEL',
            threshold: alarms.veryHighLevelAlarmThreshold,
            enableEmailNotification: alarms.veryHighLevelAlarmEmailNotificationEnabled,
            enableMobileNotification: alarms.veryHighLevelAlarmMobileNotificationEnabled,
          });
        }
        portsConfig[analogPortIndex].sensor.alarmConfigList = alarmConfigList;
      }
    });
  }

  calculatePeriodInSeconds(every, period) {
    let result = null;
    if (period === 'SECONDS') {
      result = every;
    } else if (period === 'MINUTES') {
      result = every * 60;
    } else if (period === 'HOURS') {
      result = every * 60 * 60;
    } else if (period === 'DAYS') {
      result = every * 60 * 60 * 24;
    }
    return result;
  }

  calcCountThreshold(transmissionEvery, transmissionPeriod, recordEvery, recordPeriod) {
    const transmissionPeriodInSec = this.calculatePeriodInSeconds(
      transmissionEvery,
      transmissionPeriod
    );
    const recordPeriodInSec = this.calculatePeriodInSeconds(recordEvery, recordPeriod);
    return transmissionPeriodInSec / recordPeriodInSec;
  }

  handleConfigSaved() {
    this.setState({
      open: true,
      snackbarMsg: this.props.t('device_config_setup_message'),
      saveDisabled: false,
    });
  }

  alertPulseCoefNotChanged() {
    this.setState({
      open: true,
      snackbarMsg: this.props.t('device_config_pulse_coef_not_changed_message'),
    });
  }

  refreshTimer() {
    this.setState({ ...{ time: this.state.time + 1 } });
  }

  handlerSensor1Toggled = (event, inputChecked) => {
    const portsConfig = { ...this.state.portsConfig };
    portsConfig[0].enabled = inputChecked;
    if (inputChecked) {
      this.setState({
        sensor1Enabeled: true,
        fraction1: this.state.fraction1 == null ? 2 : this.state.fraction1,
        digits1: this.state.digits1 == null ? 8 : this.state.digits1,
        counter1: this.state.counter1 == null ? '0' : this.state.counter1,
        portsConfig,
      });
    } else {
      this.setState({ sensor1Enabeled: false, portsConfig });
    }
  };

  handlerSensor2Toggled = (event, inputChecked) => {
    const portsConfig = { ...this.state.portsConfig };
    portsConfig[1].enabled = inputChecked;

    if (inputChecked) {
      this.setState({
        sensor2Enabeled: true,
        fraction2: this.state.fraction2 == null ? 2 : this.state.fraction2,
        digits2: this.state.digits2 == null ? 8 : this.state.digits2,
        counter2: this.state.counter2 == null ? '0' : this.state.counter2,
      });
      this.setState({ sensor2Enabeled: true, portsConfig });
    } else {
      this.setState({ sensor2Enabeled: false, portsConfig });
    }
  };

  handlerSensor3Toggled = (event, inputChecked) => {
    const portsConfig = { ...this.state.portsConfig };
    portsConfig[2].enabled = inputChecked;
    this.setState({ sensor3Enabeled: inputChecked, portsConfig });
  };
  handlerSensor4Toggled = (event, inputChecked) => {
    const portsConfig = { ...this.state.portsConfig };
    portsConfig[3].enabled = inputChecked;
    this.setState({ sensor4Enabeled: inputChecked, portsConfig });
  };
  handlerSensor5Toggled = (event, inputChecked) => this.setState({ sensor5Enabeled: inputChecked });
  handlerSensor6Toggled = (event, inputChecked) => this.setState({ sensor6Enabeled: inputChecked });
  handlerSensor7Toggled = (event, inputChecked) => this.setState({ sensor7Enabeled: inputChecked });
  handlerSensor8Toggled = (event, inputChecked) => this.setState({ sensor8Enabeled: inputChecked });
  handlerSensor9Toggled = (event, inputChecked) => {
    let portsConfig = this.generateDefaultAnalogPortConfigIfMissing(8);
    if (!portsConfig) portsConfig = { ...this.state.portsConfig };
    this.handleSensorTypeChange(9, portsConfig[8].sensor['@type']);
    portsConfig[8].enabled = inputChecked;
    this.setState({ sensor9Enabeled: inputChecked, portsConfig });
  };

  handlerSensor10Toggled = (event, inputChecked) => {
    let portsConfig = this.generateDefaultAnalogPortConfigIfMissing(9);
    if (!portsConfig) portsConfig = { ...this.state.portsConfig };
    this.handleSensorTypeChange(10, portsConfig[9].sensor['@type']);
    portsConfig[9].enabled = inputChecked;
    this.setState({ sensor10Enabeled: inputChecked, portsConfig });
  };
  handlerSensor11Toggled = (event, inputChecked) => {
    let portsConfig = this.generateDefaultAnalogPortConfigIfMissing(10);
    if (!portsConfig) portsConfig = { ...this.state.portsConfig };
    this.handleSensorTypeChange(11, portsConfig[10].sensor['@type']);
    portsConfig[10].enabled = inputChecked;
    this.setState({ sensor11Enabeled: inputChecked, portsConfig });
  };
  handlerSensor12Toggled = (event, inputChecked) => {
    let portsConfig = this.generateDefaultAnalogPortConfigIfMissing(11);
    if (!portsConfig) portsConfig = { ...this.state.portsConfig };
    this.handleSensorTypeChange(12, portsConfig[11].sensor['@type']);
    portsConfig[11].enabled = inputChecked;
    this.setState({ sensor12Enabeled: inputChecked, portsConfig });
  };
  handlerSensor13Toggled = (event, inputChecked) => {
    let portsConfig = this.generateDefaultAnalogPortConfigIfMissing(12);
    if (!portsConfig) portsConfig = { ...this.state.portsConfig };
    this.handleSensorTypeChange(13, portsConfig[12].sensor['@type']);
    portsConfig[12].enabled = inputChecked;
    this.setState({ sensor13Enabeled: inputChecked, portsConfig });
  };
  handlerSensor14Toggled = (event, inputChecked) => {
    let portsConfig = this.generateDefaultAnalogPortConfigIfMissing(13);
    if (!portsConfig) portsConfig = { ...this.state.portsConfig };
    this.handleSensorTypeChange(14, portsConfig[13].sensor['@type']);
    portsConfig[13].enabled = inputChecked;
    this.setState({ sensor14Enabeled: inputChecked, portsConfig });
  };

  handleSensorFormulaValidation = _.debounce(async (i, sensorFormula) => {
    const formulaValidationResult = await formulaValidatorQueryClient.validateFormula(
      sensorFormula
    );

    if (formulaValidationResult.valid === false) {
      this.setState({
        ...this.state,
        open: true,
        snackbarMsg: formulaValidationResult.errorMessage,
      });
    }

    this.setState((prevState) => {
      const formulaValidity = new Map(prevState.formulaValidity);
      formulaValidity.set(i, formulaValidationResult.valid);

      return {
        ...prevState,
        formulaValidity,
        isFormulaValidated: true,
      };
    });
  }, 1000);

  handlerSensor3VeryLowLevelAlarmEnabled = (event, inputChecked) =>
    this.setState({ sensor3VeryLowLevelAlarmEnabled: inputChecked });
  handlerSensor4VeryLowLevelAlarmEnabled = (event, inputChecked) =>
    this.setState({ sensor4VeryLowLevelAlarmEnabled: inputChecked });
  handlerSensor9VeryLowLevelAlarmEnabled = (event, inputChecked) => {
    const portsConfig = this.generateDefaultAnalogPortConfigIfMissing(8);
    if (portsConfig) this.setState({ portsConfig });
    this.setState({ sensor9VeryLowLevelAlarmEnabled: inputChecked });
  };
  handlerSensor10VeryLowLevelAlarmEnabled = (event, inputChecked) => {
    const portsConfig = this.generateDefaultAnalogPortConfigIfMissing(9);
    if (portsConfig) this.setState({ portsConfig });
    this.setState({ sensor10VeryLowLevelAlarmEnabled: inputChecked });
  };
  handlerSensor11VeryLowLevelAlarmEnabled = (event, inputChecked) => {
    const portsConfig = this.generateDefaultAnalogPortConfigIfMissing(10);
    if (portsConfig) this.setState({ portsConfig });
    this.setState({ sensor11VeryLowLevelAlarmEnabled: inputChecked });
  };
  handlerSensor12VeryLowLevelAlarmEnabled = (event, inputChecked) => {
    const portsConfig = this.generateDefaultAnalogPortConfigIfMissing(11);
    if (portsConfig) this.setState({ portsConfig });
    this.setState({ sensor12VeryLowLevelAlarmEnabled: inputChecked });
  };
  handlerSensor13VeryLowLevelAlarmEnabled = (event, inputChecked) => {
    const portsConfig = this.generateDefaultAnalogPortConfigIfMissing(12);
    if (portsConfig) this.setState({ portsConfig });
    this.setState({ sensor13VeryLowLevelAlarmEnabled: inputChecked });
  };
  handlerSensor14VeryLowLevelAlarmEnabled = (event, inputChecked) => {
    const portsConfig = this.generateDefaultAnalogPortConfigIfMissing(13);
    if (portsConfig) this.setState({ portsConfig });
    this.setState({ sensor14VeryLowLevelAlarmEnabled: inputChecked });
  };

  handlerSensor3LowLevelAlarmEnabled = (event, inputChecked) =>
    this.setState({ sensor3LowLevelAlarmEnabled: inputChecked });
  handlerSensor4LowLevelAlarmEnabled = (event, inputChecked) =>
    this.setState({ sensor4LowLevelAlarmEnabled: inputChecked });
  handlerSensor9LowLevelAlarmEnabled = (event, inputChecked) => {
    const portsConfig = this.generateDefaultAnalogPortConfigIfMissing(8);
    if (portsConfig) this.setState({ portsConfig });
    this.setState({ sensor9LowLevelAlarmEnabled: inputChecked });
  };
  handlerSensor10LowLevelAlarmEnabled = (event, inputChecked) => {
    const portsConfig = this.generateDefaultAnalogPortConfigIfMissing(9);
    if (portsConfig) this.setState({ portsConfig });
    this.setState({ sensor10LowLevelAlarmEnabled: inputChecked });
  };
  handlerSensor11LowLevelAlarmEnabled = (event, inputChecked) => {
    const portsConfig = this.generateDefaultAnalogPortConfigIfMissing(10);
    if (portsConfig) this.setState({ portsConfig });
    this.setState({ sensor11LowLevelAlarmEnabled: inputChecked });
  };
  handlerSensor12LowLevelAlarmEnabled = (event, inputChecked) => {
    const portsConfig = this.generateDefaultAnalogPortConfigIfMissing(11);
    if (portsConfig) this.setState({ portsConfig });
    this.setState({ sensor12LowLevelAlarmEnabled: inputChecked });
  };
  handlerSensor13LowLevelAlarmEnabled = (event, inputChecked) => {
    const portsConfig = this.generateDefaultAnalogPortConfigIfMissing(12);
    if (portsConfig) this.setState({ portsConfig });
    this.setState({ sensor13LowLevelAlarmEnabled: inputChecked });
  };
  handlerSensor14LowLevelAlarmEnabled = (event, inputChecked) => {
    const portsConfig = this.generateDefaultAnalogPortConfigIfMissing(13);
    if (portsConfig) this.setState({ portsConfig });
    this.setState({ sensor14LowLevelAlarmEnabled: inputChecked });
  };

  handlerSensor3HighLevelAlarmEnabled = (event, inputChecked) =>
    this.setState({ sensor3HighLevelAlarmEnabled: inputChecked });
  handlerSensor4HighLevelAlarmEnabled = (event, inputChecked) =>
    this.setState({ sensor4HighLevelAlarmEnabled: inputChecked });
  handlerSensor9HighLevelAlarmEnabled = (event, inputChecked) => {
    const portsConfig = this.generateDefaultAnalogPortConfigIfMissing(8);
    if (portsConfig) this.setState({ portsConfig });
    this.setState({ sensor9HighLevelAlarmEnabled: inputChecked });
  };

  handlerSensor10HighLevelAlarmEnabled = (event, inputChecked) => {
    const portsConfig = this.generateDefaultAnalogPortConfigIfMissing(9);
    if (portsConfig) this.setState({ portsConfig });
    this.setState({ sensor10HighLevelAlarmEnabled: inputChecked });
  };
  handlerSensor11HighLevelAlarmEnabled = (event, inputChecked) => {
    const portsConfig = this.generateDefaultAnalogPortConfigIfMissing(10);
    if (portsConfig) this.setState({ portsConfig });
    this.setState({ sensor11HighLevelAlarmEnabled: inputChecked });
  };
  handlerSensor12HighLevelAlarmEnabled = (event, inputChecked) => {
    const portsConfig = this.generateDefaultAnalogPortConfigIfMissing(11);
    if (portsConfig) this.setState({ portsConfig });
    this.setState({ sensor12HighLevelAlarmEnabled: inputChecked });
  };
  handlerSensor13HighLevelAlarmEnabled = (event, inputChecked) => {
    const portsConfig = this.generateDefaultAnalogPortConfigIfMissing(12);
    if (portsConfig) this.setState({ portsConfig });
    this.setState({ sensor13HighLevelAlarmEnabled: inputChecked });
  };
  handlerSensor14HighLevelAlarmEnabled = (event, inputChecked) => {
    const portsConfig = this.generateDefaultAnalogPortConfigIfMissing(13);
    if (portsConfig) this.setState({ portsConfig });
    this.setState({ sensor14HighLevelAlarmEnabled: inputChecked });
  };

  handlerSensor3VeryHighLevelAlarmEnabled = (event, inputChecked) =>
    this.setState({ sensor3VeryHighLevelAlarmEnabled: inputChecked });
  handlerSensor4VeryHighLevelAlarmEnabled = (event, inputChecked) =>
    this.setState({ sensor4VeryHighLevelAlarmEnabled: inputChecked });
  handlerSensor9VeryHighLevelAlarmEnabled = (event, inputChecked) => {
    const portsConfig = this.generateDefaultAnalogPortConfigIfMissing(8);
    if (portsConfig) this.setState({ portsConfig });
    this.setState({ sensor9VeryHighLevelAlarmEnabled: inputChecked });
  };
  handlerSensor10VeryHighLevelAlarmEnabled = (event, inputChecked) => {
    const portsConfig = this.generateDefaultAnalogPortConfigIfMissing(9);
    if (portsConfig) this.setState({ portsConfig });
    this.setState({ sensor10VeryHighLevelAlarmEnabled: inputChecked });
  };
  handlerSensor11VeryHighLevelAlarmEnabled = (event, inputChecked) => {
    const portsConfig = this.generateDefaultAnalogPortConfigIfMissing(10);
    if (portsConfig) this.setState({ portsConfig });
    this.setState({ sensor11VeryHighLevelAlarmEnabled: inputChecked });
  };
  handlerSensor12VeryHighLevelAlarmEnabled = (event, inputChecked) => {
    const portsConfig = this.generateDefaultAnalogPortConfigIfMissing(11);
    if (portsConfig) this.setState({ portsConfig });
    this.setState({ sensor12VeryHighLevelAlarmEnabled: inputChecked });
  };
  handlerSensor13VeryHighLevelAlarmEnabled = (event, inputChecked) => {
    const portsConfig = this.generateDefaultAnalogPortConfigIfMissing(12);
    if (portsConfig) this.setState({ portsConfig });
    this.setState({ sensor13VeryHighLevelAlarmEnabled: inputChecked });
  };
  handlerSensor14VeryHighLevelAlarmEnabled = (event, inputChecked) => {
    const portsConfig = this.generateDefaultAnalogPortConfigIfMissing(13);
    if (portsConfig) this.setState({ portsConfig });
    this.setState({ sensor14VeryHighLevelAlarmEnabled: inputChecked });
  };

  handlerSensor3VeryLowLevelEmailNotificationEnabled = (event) =>
    this.setState({ sensor3VeryLowLevelEmailNotificationEnabled: event.target.checked });
  handlerSensor3VeryLowLevelMobileNotificationEnabled = (event) =>
    this.setState({ sensor3VeryLowLevelMobileNotificationEnabled: event.target.checked });
  handlerSensor3LowLevelEmailNotificationEnabled = (event) =>
    this.setState({ sensor3LowLevelEmailNotificationEnabled: event.target.checked });
  handlerSensor3LowLevelMobileNotificationEnabled = (event) =>
    this.setState({ sensor3LowLevelMobileNotificationEnabled: event.target.checked });
  handlerSensor3HighLevelEmailNotificationEnabled = (event) =>
    this.setState({ sensor3HighLevelEmailNotificationEnabled: event.target.checked });
  handlerSensor3HighLevelMobileNotificationEnabled = (event) =>
    this.setState({ sensor3HighLevelMobileNotificationEnabled: event.target.checked });
  handlerSensor3VeryHighLevelEmailNotificationEnabled = (event) =>
    this.setState({ sensor3VeryHighLevelEmailNotificationEnabled: event.target.checked });
  handlerSensor3VeryHighLevelMobileNotificationEnabled = (event) =>
    this.setState({ sensor3VeryHighLevelMobileNotificationEnabled: event.target.checked });

  handlerSensor4VeryLowLevelEmailNotificationEnabled = (event) =>
    this.setState({ sensor4VeryLowLevelEmailNotificationEnabled: event.target.checked });
  handlerSensor4VeryLowLevelMobileNotificationEnabled = (event) =>
    this.setState({ sensor4VeryLowLevelMobileNotificationEnabled: event.target.checked });
  handlerSensor4LowLevelEmailNotificationEnabled = (event) =>
    this.setState({ sensor4LowLevelEmailNotificationEnabled: event.target.checked });
  handlerSensor4LowLevelMobileNotificationEnabled = (event) =>
    this.setState({ sensor4LowLevelMobileNotificationEnabled: event.target.checked });
  handlerSensor4HighLevelEmailNotificationEnabled = (event) => {
    this.setState({ sensor4HighLevelEmailNotificationEnabled: event.target.checked });
  };
  handlerSensor4HighLevelMobileNotificationEnabled = (event) =>
    this.setState({ sensor4HighLevelMobileNotificationEnabled: event.target.checked });
  handlerSensor4VeryHighLevelEmailNotificationEnabled = (event) =>
    this.setState({ sensor4VeryHighLevelEmailNotificationEnabled: event.target.checked });
  handlerSensor4VeryHighLevelMobileNotificationEnabled = (event) =>
    this.setState({ sensor4VeryHighLevelMobileNotificationEnabled: event.target.checked });

  handlerSensor9VeryLowLevelEmailNotificationEnabled = (event) => {
    this.setState({ sensor9VeryLowLevelEmailNotificationEnabled: event.target.checked });
  };
  handlerSensor9VeryLowLevelMobileNotificationEnabled = (event) => {
    this.setState({ sensor9VeryLowLevelMobileNotificationEnabled: event.target.checked });
  };
  handlerSensor9LowLevelEmailNotificationEnabled = (event) => {
    this.setState({ sensor9LowLevelEmailNotificationEnabled: event.target.checked });
  };
  handlerSensor9LowLevelMobileNotificationEnabled = (event) => {
    this.setState({ sensor9LowLevelMobileNotificationEnabled: event.target.checked });
  };
  handlerSensor9HighLevelEmailNotificationEnabled = (event) => {
    this.setState({ sensor9HighLevelEmailNotificationEnabled: event.target.checked });
  };
  handlerSensor9HighLevelMobileNotificationEnabled = (event) => {
    this.setState({ sensor9HighLevelMobileNotificationEnabled: event.target.checked });
  };
  handlerSensor9VeryHighLevelEmailNotificationEnabled = (event) => {
    this.setState({ sensor9VeryHighLevelEmailNotificationEnabled: event.target.checked });
  };
  handlerSensor9VeryHighLevelMobileNotificationEnabled = (event) => {
    this.setState({ sensor9VeryHighLevelMobileNotificationEnabled: event.target.checked });
  };

  handlerSensor10VeryLowLevelEmailNotificationEnabled = (event) => {
    this.setState({ sensor10VeryLowLevelEmailNotificationEnabled: event.target.checked });
  };
  handlerSensor10VeryLowLevelMobileNotificationEnabled = (event) => {
    this.setState({ sensor10VeryLowLevelMobileNotificationEnabled: event.target.checked });
  };
  handlerSensor10LowLevelEmailNotificationEnabled = (event) => {
    this.setState({ sensor10LowLevelEmailNotificationEnabled: event.target.checked });
  };
  handlerSensor10LowLevelMobileNotificationEnabled = (event) => {
    this.setState({ sensor10LowLevelMobileNotificationEnabled: event.target.checked });
  };
  handlerSensor10HighLevelEmailNotificationEnabled = (event) => {
    this.setState({ sensor10HighLevelEmailNotificationEnabled: event.target.checked });
  };
  handlerSensor10HighLevelMobileNotificationEnabled = (event) => {
    this.setState({ sensor10HighLevelMobileNotificationEnabled: event.target.checked });
  };
  handlerSensor10VeryHighLevelEmailNotificationEnabled = (event) => {
    this.setState({ sensor10VeryHighLevelEmailNotificationEnabled: event.target.checked });
  };
  handlerSensor10VeryHighLevelMobileNotificationEnabled = (event) => {
    this.setState({ sensor10VeryHighLevelMobileNotificationEnabled: event.target.checked });
  };

  handlerSensor11VeryLowLevelEmailNotificationEnabled = (event) => {
    this.setState({ sensor11VeryLowLevelEmailNotificationEnabled: event.target.checked });
  };
  handlerSensor11VeryLowLevelMobileNotificationEnabled = (event) => {
    this.setState({ sensor11VeryLowLevelMobileNotificationEnabled: event.target.checked });
  };
  handlerSensor11LowLevelEmailNotificationEnabled = (event) => {
    this.setState({ sensor11LowLevelEmailNotificationEnabled: event.target.checked });
  };
  handlerSensor11LowLevelMobileNotificationEnabled = (event) => {
    this.setState({ sensor11LowLevelMobileNotificationEnabled: event.target.checked });
  };
  handlerSensor11HighLevelEmailNotificationEnabled = (event) => {
    this.setState({ sensor11HighLevelEmailNotificationEnabled: event.target.checked });
  };
  handlerSensor11HighLevelMobileNotificationEnabled = (event) => {
    this.setState({ sensor11HighLevelMobileNotificationEnabled: event.target.checked });
  };
  handlerSensor11VeryHighLevelEmailNotificationEnabled = (event) => {
    this.setState({ sensor11VeryHighLevelEmailNotificationEnabled: event.target.checked });
  };
  handlerSensor11VeryHighLevelMobileNotificationEnabled = (event) => {
    this.setState({ sensor11VeryHighLevelMobileNotificationEnabled: event.target.checked });
  };

  handlerSensor12VeryLowLevelEmailNotificationEnabled = (event) => {
    this.setState({ sensor12VeryLowLevelEmailNotificationEnabled: event.target.checked });
  };
  handlerSensor12VeryLowLevelMobileNotificationEnabled = (event) => {
    this.setState({ sensor12VeryLowLevelMobileNotificationEnabled: event.target.checked });
  };
  handlerSensor12LowLevelEmailNotificationEnabled = (event) => {
    this.setState({ sensor12LowLevelEmailNotificationEnabled: event.target.checked });
  };
  handlerSensor12LowLevelMobileNotificationEnabled = (event) => {
    this.setState({ sensor12LowLevelMobileNotificationEnabled: event.target.checked });
  };
  handlerSensor12HighLevelEmailNotificationEnabled = (event) => {
    this.setState({ sensor12HighLevelEmailNotificationEnabled: event.target.checked });
  };
  handlerSensor12HighLevelMobileNotificationEnabled = (event) => {
    this.setState({ sensor12HighLevelMobileNotificationEnabled: event.target.checked });
  };
  handlerSensor12VeryHighLevelEmailNotificationEnabled = (event) => {
    this.setState({ sensor12VeryHighLevelEmailNotificationEnabled: event.target.checked });
  };
  handlerSensor12VeryHighLevelMobileNotificationEnabled = (event) => {
    this.setState({ sensor12VeryHighLevelMobileNotificationEnabled: event.target.checked });
  };

  handlerSensor13VeryLowLevelEmailNotificationEnabled = (event) => {
    this.setState({ sensor13VeryLowLevelEmailNotificationEnabled: event.target.checked });
  };
  handlerSensor13VeryLowLevelMobileNotificationEnabled = (event) => {
    this.setState({ sensor13VeryLowLevelMobileNotificationEnabled: event.target.checked });
  };
  handlerSensor13LowLevelEmailNotificationEnabled = (event) => {
    this.setState({ sensor13LowLevelEmailNotificationEnabled: event.target.checked });
  };
  handlerSensor13LowLevelMobileNotificationEnabled = (event) => {
    this.setState({ sensor13LowLevelMobileNotificationEnabled: event.target.checked });
  };
  handlerSensor13HighLevelEmailNotificationEnabled = (event) => {
    this.setState({ sensor13HighLevelEmailNotificationEnabled: event.target.checked });
  };
  handlerSensor13HighLevelMobileNotificationEnabled = (event) => {
    this.setState({ sensor13HighLevelMobileNotificationEnabled: event.target.checked });
  };
  handlerSensor13VeryHighLevelEmailNotificationEnabled = (event) => {
    this.setState({ sensor13VeryHighLevelEmailNotificationEnabled: event.target.checked });
  };
  handlerSensor13VeryHighLevelMobileNotificationEnabled = (event) => {
    this.setState({ sensor13VeryHighLevelMobileNotificationEnabled: event.target.checked });
  };

  handlerSensor14VeryLowLevelEmailNotificationEnabled = (event) => {
    this.setState({ sensor14VeryLowLevelEmailNotificationEnabled: event.target.checked });
  };
  handlerSensor14VeryLowLevelMobileNotificationEnabled = (event) => {
    this.setState({ sensor14VeryLowLevelMobileNotificationEnabled: event.target.checked });
  };
  handlerSensor14LowLevelEmailNotificationEnabled = (event) => {
    this.setState({ sensor14LowLevelEmailNotificationEnabled: event.target.checked });
  };
  handlerSensor14LowLevelMobileNotificationEnabled = (event) => {
    this.setState({ sensor14LowLevelMobileNotificationEnabled: event.target.checked });
  };
  handlerSensor14HighLevelEmailNotificationEnabled = (event) => {
    this.setState({ sensor14HighLevelEmailNotificationEnabled: event.target.checked });
  };
  handlerSensor14HighLevelMobileNotificationEnabled = (event) => {
    this.setState({ sensor14HighLevelMobileNotificationEnabled: event.target.checked });
  };
  handlerSensor14VeryHighLevelEmailNotificationEnabled = (event) => {
    this.setState({ sensor14VeryHighLevelEmailNotificationEnabled: event.target.checked });
  };
  handlerSensor14VeryHighLevelMobileNotificationEnabled = (event) => {
    this.setState({ sensor14VeryHighLevelMobileNotificationEnabled: event.target.checked });
  };

  handleUnitsType1Change = (unit) => this.setState({ unitsType1: unit });
  handleUnitsType2Change = (unit) => this.setState({ unitsType2: unit });
  handleUnitsType3Change = (unit) => this.setState({ unitsType3: unit });
  handleUnitsType4Change = (unit) => this.setState({ unitsType4: unit });
  handleUnitsType9Change = (unit) => this.setState({ unitsType9: unit });
  handleUnitsType10Change = (unit) => this.setState({ unitsType10: unit });
  handleUnitsType11Change = (unit) => this.setState({ unitsType11: unit });
  handleUnitsType12Change = (unit) => this.setState({ unitsType12: unit });
  handleUnitsType13Change = (unit) => this.setState({ unitsType13: unit });
  handleUnitsType14Change = (unit) => this.setState({ unitsType14: unit });

  generateDefaultAnalogPortConfigIfMissing = (index) => {
    let portsConfig = { ...this.state.portsConfig };
    if (portsConfig[index]) return null;
    if (!analogSensorIndexes.includes(index)) return null;
    portsConfig[index] = {
      '@type': 'analog_input_port',
      direction: null,
      enabled: false,
      sensor: {
        '@type': 'generic_analog_sensor',
        name: this.getNameOfSensor(index),
        customerInfo: null,
        precision: 4,
        scale: 2,
        units: 'MILLIAMPER',
        flowUnit: null,
        flowConversionCoefficient: null,
        model: null,
        serialNumber: null,
        manufacturer: null,
        alarmConfigList: null,
        valueRanges: null,
        iconName: null,
        orderNumber: null,
        visible: true,
        useInSensorsAggregation: true,
        formula: 'x',
        virtual: false,
      },
      adcResolution: 0,
      settlingTime: 0,
      formula: 'x',
    };
    return portsConfig;
  };

  getNameOfSensor = (index) => {
    switch (index) {
      case 8:
        return this.state.sensor9Name;
      case 9:
        return this.state.sensor10Name;
      case 10:
        return this.state.sensor11Name;
      case 11:
        return this.state.sensor12Name;
      case 12:
        return this.state.sensor13Name;
      case 13:
        return this.state.sensor14Name;
      default:
        '';
    }
  };

  isPowerOfTenFraction = (num) => {
    if (num <= 0) return false;
    const log10 = Math.log10(num);
    return Number.isInteger(log10);
  };

  handleCounter1Change = (ev) => {
    let pulseCoef1 = this.state.pulseCoef1;
    if (ev.fraction !== Number(this.state.fraction1)) {
      if (this.isPowerOfTenFraction(pulseCoef1)) {
        pulseCoef1 = 1 / Math.pow(10, ev.fraction);
      } else if (this.state.isPulseCoef1InitialChange) {
        this.alertPulseCoefNotChanged();
        this.setState({ isPulseCoef1InitialChange: false });
      }
    }
    this.setState({
      counter1: ev.counter,
      digits1: ev.digits,
      fraction1: ev.fraction,
      pulseCoef1: pulseCoef1,
    });
  };
  handleCounter2Change = (ev) => {
    let pulseCoef2 = this.state.pulseCoef2;
    if (ev.fraction !== Number(this.state.fraction2)) {
      if (this.isPowerOfTenFraction(pulseCoef2)) {
        pulseCoef2 = 1 / Math.pow(10, ev.fraction);
      } else if (this.state.isPulseCoef2InitialChange) {
        this.alertPulseCoefNotChanged();
        this.setState({ isPulseCoef2InitialChange: false });
      }
    }
    this.setState({
      counter2: ev.counter,
      digits2: ev.digits,
      fraction2: ev.fraction,
      pulseCoef2: pulseCoef2,
    });
  };
  handleRecordPeriodChange = (event) => {
    this.setState({ recordPeriod: event.target.value });
  };
  handleRecordEveryChange = (event) => {
    this.setState({ recordEvery: event.target.value });
  };

  getRecordPeriodOptionsForTransmissionPeriod = (transmissionPeriod) => {
    let dropdownProps = [];
    const optionSeconds = { value: 'SECONDS', children: this.props.t('seconds') };
    const optionMinutes = { value: 'MINUTES', children: this.props.t('minutes') };
    const optionHours = { value: 'HOURS', children: this.props.t('hours') };
    const optionDays = { value: 'DAYS', children: this.props.t('days') };

    if (transmissionPeriod === 'DAYS') {
      dropdownProps.push(optionSeconds);
      dropdownProps.push(optionMinutes);
      dropdownProps.push(optionHours);
      dropdownProps.push(optionDays);
    } else if (transmissionPeriod === 'HOURS') {
      dropdownProps.push(optionSeconds);
      dropdownProps.push(optionMinutes);
      dropdownProps.push(optionHours);
    } else if (transmissionPeriod === 'MINUTES') {
      dropdownProps.push(optionSeconds);
      dropdownProps.push(optionMinutes);
    } else if (transmissionPeriod === 'SECONDS') {
      dropdownProps.push(optionSeconds);
    }

    return dropdownProps;
  };

  handleTimeZoneChange = (event) => {
    this.setState({ timeZone: event.target.textContent });
  };
  handleTransmissionPeriodChange = (event) => {
    let newPeriod = event.target.value;

    this.setState({
      recordEvery: 1,
      recordPeriod: newPeriod,
      transmissionEvery: 1,
      transmissionPeriod: newPeriod,
    });
  };

  handleTransmissionEveryChange = (event) =>
    this.setState({ transmissionEvery: event.target.value });

  handleCountsThresholdChange = (event) => this.setState({ countsThreshold: event.target.value });
  handleDeleteOldCountersChange = (event) =>
    this.setState({ deleteOldCounters: event.target.value });

  handleMissingTransmissionSeverityChange = (event) => {
    this.setState({
      missedTransmissionSeverity: event.target.value === '' ? null : parseInt(event.target.value),
    });
  };

  handleRequestClose = () => {
    this.setState({ open: false });
  };

  isFifoBasedDevice = (swVersion) => {
    const allFifo = [
      '2.5.0-fifo',
      '2.6.0-fifo',
      '2.8.0-fifo',
      '2.8.1-J11-FIFO-BASE_BL1.4',
      '2.8.1-J11-FIFO-ON_OFF-NO_HIGH_LOW-NO_EEPROM_BL1.4',
      '2.8.1-K22-FIFO-BASE_BL1.4',
      '2.8.1-K22-FIFO-ON_OFF-HIGH_LOW-NO_EEPROM_BL1.4',
      '2.8.1-K22-FIFO-ON_OFF-NO_HIGH_LOW-EEPROM_BL1.4',
      '2.8.2-J11-FIFO-BASE_BL1.4',
      '2.8.2-J11-FIFO-ON_OFF-NO_HIGH_LOW-NO_EEPROM_BL1.4',
      '2.8.2-K22-FIFO-BASE_BL1.4',
      '2.8.2-K22-FIFO-ON_OFF-HIGH_LOW-NO_EEPROM_BL1.4',
      '2.8.2-K22-FIFO-ON_OFF-NO_HIGH_LOW-EEPROM_BL1.4',
      '4.0.6_GPRS_M66_PIC18F26J11_FIFO_ADC_1115_4CHAN_BL1.4',
      '4.0.6_GPRS_M66_PIC18F26J11_FIFO_HIGH_LOW_ALARMS_ADC_1115_4CHAN',
      '4.0.6_GPRS_M66_PIC18F26J11_FIFO_ON_OFF_HIGH_LOW_ALARMS_ADC_1115_4CHAN',
    ];

    return allFifo.includes(swVersion) || swVersion.indexOf('NO_FIFO') === -1;
  };

  getSensorsAlarmsConfig = (deviceConfig, portsConfig, sensorIndex) => {
    const sensorConfig = deviceConfig.sensorConfigs[sensorIndex];

    let alarmsConfig = {
      veryLowLevelAlarmEnabled: false,
      veryLowLevelAlarm: 0,
      veryLowLevelMobileNotificationEnabled: false,
      veryLowLevelEmailNotificationEnabled: false,
      lowLevelAlarmEnabled: sensorConfig?.parameters.low_level_alarm_enabled
        ? sensorConfig.parameters.low_level_alarm_enabled === 'true'
        : false,
      lowLevelAlarm: sensorConfig?.parameters.low_level_alarm
        ? sensorConfig.parameters.low_level_alarm
        : 0,
      lowLevelMobileNotificationEnabled: false,
      lowLevelEmailNotificationEnabled:
        sensorConfig?.parameters.low_level_alarm_enabled === 'true' &&
        !!sensorConfig?.parameters.low_level_alarm,
      highLevelAlarmEnabled: sensorConfig?.parameters.high_level_alarm_enabled
        ? sensorConfig.parameters.high_level_alarm_enabled === 'true'
        : false,
      highLevelMobileNotificationEnabled: false,
      highLevelEmailNotificationEnabled:
        sensorConfig?.parameters.high_level_alarm_enabled === 'true' &&
        !!sensorConfig?.parameters.high_level_alarm,
      highLevelAlarm: sensorConfig?.parameters.high_level_alarm
        ? sensorConfig.parameters.high_level_alarm
        : 0,
      veryHighLevelAlarmEnabled: false,
      veryHighLevelAlarm: 0,
      veryHighLevelMobileNotificationEnabled: false,
      veryHighLevelEmailNotificationEnabled: false,
    };

    const alarmsConfigList = portsConfig[sensorIndex]?.sensor.alarmConfigList;

    if (alarmsConfigList && alarmsConfigList.length > 0) {
      for (const alarm of alarmsConfigList) {
        if (alarm.type === 'VERY_LOW_LEVEL') {
          alarmsConfig.veryLowLevelAlarmEnabled = true;
          alarmsConfig.veryLowLevelAlarm = alarm.threshold;
          alarmsConfig.veryLowLevelMobileNotificationEnabled = alarm.enableMobileNotification;
          alarmsConfig.veryLowLevelEmailNotificationEnabled = alarm.enableEmailNotification;
        }
        if (alarm.type === 'LOW_LEVEL') {
          alarmsConfig.lowLevelAlarmEnabled = true;
          alarmsConfig.lowLevelAlarm = alarm.threshold;
          alarmsConfig.lowLevelMobileNotificationEnabled = alarm.enableMobileNotification;
          alarmsConfig.lowLevelEmailNotificationEnabled = alarm.enableEmailNotification;
        }
        if (alarm.type === 'HIGH_LEVEL') {
          alarmsConfig.highLevelAlarmEnabled = true;
          alarmsConfig.highLevelAlarm = alarm.threshold;
          alarmsConfig.highLevelMobileNotificationEnabled = alarm.enableMobileNotification;
          alarmsConfig.highLevelEmailNotificationEnabled = alarm.enableEmailNotification;
        }
        if (alarm.type === 'VERY_HIGH_LEVEL') {
          alarmsConfig.veryHighLevelAlarmEnabled = true;
          alarmsConfig.veryHighLevelAlarm = alarm.threshold;
          alarmsConfig.veryHighLevelMobileNotificationEnabled = alarm.enableMobileNotification;
          alarmsConfig.veryHighLevelEmailNotificationEnabled = alarm.enableEmailNotification;
        }
      }
    }
    return alarmsConfig;
  };

  render() {
    const { classes } = this.props;

    const wellStyles = { maxWidth: 450, margin: '0 auto 10px' };
    if (this.state.configured != null) {
      return (
        <Header>
          <div className="well" style={wellStyles}>
            <TitleHeader
              className="mb-4"
              pageType="form"
              title={this.props.t('device_config_header')}
              deviceNumber={this.props.match.params.deviceNumber}
              deviceName={this.state.deviceName}
              customerInfo={this.state.customerInfo}
            />
            <p>{this.props.t('device_config_config_date', { date: this.state.date })}</p>
            <p>
              {this.props.t('device_config_config_status')}:{' '}
              <span style={{ color: this.state.configured ? 'green' : 'red' }}>
                {this.state.configured
                  ? this.props.t('device_config_config_status_configured')
                  : this.props.t('device_config_config_status_not_configured')}
              </span>
              {this.state.time == null ? '' : ', Timer: ' + this.state.time + ' sec'}
            </p>
            <form>
              <Accordion defaultExpanded={true}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <ControlLabel>
                    {this.props.t('device_config_record_and_transmission_period')}
                  </ControlLabel>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container justifyContent={'center'} direction="column">
                    <Grid
                      container
                      justifyContent={'center'}
                      alignItems={'center'}
                      direction="column"
                    >
                      <Grid item>
                        <ControlLabel>
                          {this.props.t('device_config_transmission_period')}
                        </ControlLabel>
                      </Grid>
                      <Grid
                        container
                        justifyContent={'center'}
                        alignItems={'center'}
                        direction="row"
                      >
                        <Grid item>
                          <TextField
                            error={this.state.transmissionErrorHighlight}
                            value={this.state.transmissionEvery}
                            size="small"
                            type="number"
                            label={this.props.t('device_config_every')}
                            sx={{ width: 75 }}
                            onChange={this.handleTransmissionEveryChange.bind(this)}
                          />
                        </Grid>
                        <Grid item>
                          <MuiFormControl error={this.state.transmissionErrorHighlight}>
                            <InputLabel>{this.props.t('device_config_period')}</InputLabel>
                            <Select
                              value={this.state.transmissionPeriod}
                              label={this.props.t('device_config_period')}
                              size="small"
                              onChange={this.handleTransmissionPeriodChange.bind(this)}
                            >
                              <MenuItem value={'SECONDS'}>{this.props.t('seconds')}</MenuItem>
                              <MenuItem value={'MINUTES'}>{this.props.t('minutes')}</MenuItem>
                              <MenuItem value={'HOURS'}>{this.props.t('hours')}</MenuItem>
                              <MenuItem value={'DAYS'}>{this.props.t('days')}</MenuItem>
                            </Select>
                          </MuiFormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      justifyContent={'center'}
                      alignItems={'center'}
                      direction="column"
                    >
                      <Grid item>
                        <ControlLabel>{this.props.t('device_config_record_period')}</ControlLabel>
                      </Grid>
                      <Grid
                        container
                        justifyContent={'center'}
                        alignItems={'center'}
                        direction="row"
                      >
                        <Grid item>
                          <TextField
                            error={this.state.recordErrorHighlight}
                            value={this.state.recordEvery}
                            size="small"
                            type="number"
                            label={this.props.t('device_config_every')}
                            sx={{ width: 75 }}
                            onChange={this.handleRecordEveryChange.bind(this)}
                          />
                        </Grid>
                        <Grid item>
                          <MuiFormControl error={this.state.recordErrorHighlight}>
                            <InputLabel>{this.props.t('device_config_period')}</InputLabel>
                            <Select
                              value={this.state.recordPeriod}
                              label={this.props.t('device_config_period')}
                              size="small"
                              onChange={this.handleRecordPeriodChange.bind(this)}
                            >
                              {this.getRecordPeriodOptionsForTransmissionPeriod(
                                this.state.transmissionPeriod
                              ).map((menuItemProps) => (
                                <MenuItem {...menuItemProps} />
                              ))}
                            </Select>
                          </MuiFormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                    {this.state.transmissionErrorMsg && (
                      <div className="rounded-md bg-red-50 p-1 flex-1 mt-3 ">
                        <span className="text-sm font-semibold text-red-800">
                          {this.state.transmissionErrorMsg}
                        </span>
                      </div>
                    )}
                  </Grid>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Grid
                    container
                    justifyContent={'flex-start'}
                    alignItems={'center'}
                    direction="column"
                  >
                    <Grid container justifyContent={'flex-start'} alignItems={'center'}>
                      <Switch
                        label={this.state.sensor1Name}
                        checked={this.state.sensor1Enabeled}
                        onChange={this.handlerSensor1Toggled.bind(this)}
                      />
                      <ControlLabel>{this.state.sensor1Name}</ControlLabel>
                    </Grid>
                    <Grid container justifyContent={'center'} alignItems={'center'}>
                      {this.state.sensor1Enabeled && (
                        <Typography className={classes.secondaryHeading}>
                          {this.props.t('device_digits_initial_reading')}:
                          {(this.state.counter1 / Math.pow(10, this.state.fraction1)).toFixed(
                            this.state.fraction1
                          )}{' '}
                          {Units[this.state.unitsType1]}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </AccordionSummary>

                <div style={{ display: this.state.sensor1Enabeled ? 'block' : 'none' }}>
                  {this.state.sensor1Enabeled && (
                    <AccordionDetails>
                      <Grid
                        container
                        justifyContent={'flex-start'}
                        alignItems={'center'}
                        direction="column"
                      >
                        <DeviceDigits
                          counter={this.state.counter1}
                          digits={Number(this.state.digits1)}
                          fraction={Number(this.state.fraction1)}
                          counterChanged={this.handleCounter1Change.bind(this)}
                        />
                        <FormGroup className={classes.fromGroup} controlId="pulseRate">
                          <ControlLabel>
                            {this.props.t('device_config_pulse_per', {
                              x: Units[this.state.unitsType1],
                            })}
                          </ControlLabel>
                          <FormControl
                            value={this.state.pulseCoef1}
                            type="text"
                            placeholder={this.props.t('device_config_pulse_per', {
                              x: Units[this.state.unitsType1],
                            })}
                            onChange={(event) => this.setState({ pulseCoef1: event.target.value })}
                          />
                        </FormGroup>
                        <FormGroup className={classes.fromGroup} controlId="Sensor Type">
                          <ControlLabel>{this.props.t('device_config_meter_type')}</ControlLabel>
                          <FormControl
                            value={this.state.sensorType1}
                            componentClass="select"
                            placeholder={this.props.t('device_config_meter_type')}
                            onChange={(event) => {
                              this.handleSensorTypeChange(1, event.target.value);
                            }}
                          >
                            <option value={'generic_digital_sensor'}>Generic Digital Sensor</option>
                            <option value={'water_meter'}>Water Meter</option>
                            <option value={'power_meter'}>Power Meter</option>
                            <option value={'gas_flow_meter'}>Gas Flow Meter</option>
                            <option value={'rain_sensor'}>Rain Sensor</option>
                            <option value={'wind_speed_sensor'}>Wind Speed Sensor</option>
                            <option value={'heat_meter'}>Heat Meter</option>
                            <option value={'geiger_mueller_counter'}>Geiger Mueller Counter</option>
                          </FormControl>
                        </FormGroup>
                        <UnitsDropdown
                          allowedUnits={this.state.allowedUnits1 || []}
                          unit={this.state.unitsType1}
                          onChange={this.handleUnitsType1Change}
                        />
                      </Grid>
                    </AccordionDetails>
                  )}
                </div>
              </Accordion>
              <Accordion disabled={this.state.model === 'W1' || this.state.model === 'BL_100_L'}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Grid
                    container
                    justifyContent={'flex-start'}
                    alignItems={'center'}
                    direction="column"
                  >
                    <Grid container justifyContent={'flex-start'} alignItems={'center'}>
                      <Switch
                        label={this.state.sensor2Name}
                        checked={this.state.sensor2Enabeled}
                        onChange={this.handlerSensor2Toggled.bind(this)}
                      />
                      <ControlLabel>{this.state.sensor2Name}</ControlLabel>
                    </Grid>
                    <Grid container justifyContent={'center'} alignItems={'center'}>
                      {this.state.sensor2Enabeled && (
                        <Typography className={classes.secondaryHeading}>
                          {this.props.t('device_digits_initial_reading')}:
                          {(this.state.counter2 / Math.pow(10, this.state.fraction2)).toFixed(
                            this.state.fraction2
                          )}{' '}
                          {Units[this.state.unitsType2]}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </AccordionSummary>

                <div style={{ display: this.state.sensor2Enabeled ? 'block' : 'none' }}>
                  {this.state.sensor2Enabeled && (
                    <AccordionDetails>
                      <Grid
                        container
                        justifyContent={'center'}
                        alignItems={'center'}
                        direction="column"
                      >
                        <DeviceDigits
                          counter={this.state.counter2}
                          digits={Number(this.state.digits2)}
                          fraction={Number(this.state.fraction2)}
                          counterChanged={this.handleCounter2Change.bind(this)}
                        />
                        <FormGroup className={classes.fromGroup} controlId="pulseRate">
                          <ControlLabel>
                            {this.props.t('device_config_pulse_per', {
                              x: Units[this.state.unitsType2],
                            })}
                          </ControlLabel>
                          <FormControl
                            value={this.state.pulseCoef2}
                            type="text"
                            placeholder={this.props.t('device_config_pulse_per', {
                              x: Units[this.state.unitsType2],
                            })}
                            onChange={(event) => this.setState({ pulseCoef2: event.target.value })}
                          />
                        </FormGroup>
                        <FormGroup className={classes.fromGroup} controlId="Sensor Type">
                          <ControlLabel>{this.props.t('device_config_meter_type')}</ControlLabel>
                          <FormControl
                            value={this.state.sensorType2}
                            componentClass="select"
                            placeholder={this.props.t('device_config_meter_type')}
                            onChange={(event) => {
                              this.handleSensorTypeChange(2, event.target.value);
                            }}
                          >
                            <option value={'generic_digital_sensor'}>Generic Digital Sensor</option>
                            <option value={'water_meter'}>Water Meter</option>
                            <option value={'power_meter'}>Power Meter</option>
                            <option value={'gas_flow_meter'}>Gas Flow Meter</option>
                            <option value={'rain_sensor'}>Rain Sensor</option>
                            <option value={'wind_speed_sensor'}>Wind Speed Sensor</option>
                            <option value={'heat_meter'}>Heat Meter</option>
                            <option value={'geiger_mueller_counter'}>Geiger Mueller Counter</option>
                          </FormControl>
                        </FormGroup>
                        <UnitsDropdown
                          allowedUnits={this.state.allowedUnits2 || []}
                          unit={this.state.unitsType2}
                          onChange={this.handleUnitsType2Change}
                        />
                      </Grid>
                    </AccordionDetails>
                  )}
                </div>
              </Accordion>
              <Accordion disabled={this.state.model === 'W1' || this.state.model === 'BL_100_L'}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <FormGroup controlId="Analog Sensor 1">
                    <Switch
                      label={this.state.sensor3Name}
                      checked={this.state.sensor3Enabeled}
                      onChange={this.handlerSensor3Toggled.bind(this)}
                    />
                    <ControlLabel>{this.state.sensor3Name}</ControlLabel>
                  </FormGroup>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container justifyContent={'center'} direction="column">
                    <div className="mb-10">
                      <FractionSelector
                        className="mb-2 m-auto"
                        fraction={Number(this.state.fraction3)}
                        onFractionChange={(event, value) =>
                          value !== null && this.setState({ fraction3: value, digits3: value + 2 })
                        }
                      />
                      <Typography className={classes.secondaryHeading}>
                        {(0).toFixed(this.state.fraction3)} {Units[this.state.unitsType3]}
                      </Typography>
                    </div>
                    <FormGroup className={classes.fromGroup} controlId="Sensor Type">
                      <ControlLabel>{this.props.t('device_config_sensor_type')}</ControlLabel>
                      <FormControl
                        value={this.state.sensorType3}
                        componentClass="select"
                        placeholder={this.props.t('device_config_sensor_type')}
                        onChange={(event) => {
                          this.handleSensorTypeChange(3, event.target.value);
                        }}
                      >
                        <option value={'generic_analog_sensor'}>Generic Analog Sensor</option>
                        <option value={'generic_virtual_sensor'}>Generic Virtual Sensor</option>
                        <option value={'pressure_sensor'}>Pressure Sensor</option>
                        <option value={'temperature_sensor'}>Temperature Sensor</option>
                        <option value={'humidity_sensor'}>Humidity Sensor</option>
                        <option value={'level_sensor'}>Level Sensor</option>
                        <option value={'weight_meter'}>Weight Meter</option>
                        <option value={'weight_sensor'}>Weight Sensor</option>
                        <option value={'load_cells'}>Load Cells</option>
                        <option value={'wind_direction_sensor'}>Wind Direction Sensor</option>
                        <option value={'barometric_pressure_sensor'}>
                          Barometric Pressure Sensor
                        </option>
                        <option value={'soil_moisture_sensor'}>Soil moisture Sensor</option>
                        <option value={'dew_point_sensor'}>Dew Point Sensor</option>
                        <option value={'soil_temperature_sensor'}>Soil Temperature Sensor</option>
                        <option value={'soil_potential_sensor'}>Soil Potential Sensor</option>
                        <option value={'particulate_matter_sensor'}>
                          Particulate Matter Sensor
                        </option>
                        <option value={'leaf_wetness_sensor'}>Leaf Wetness</option>
                      </FormControl>
                    </FormGroup>
                    <FormGroup
                      className={classes.fromGroup}
                      controlId="formula"
                      validationState={this.state.formulaValidity.get(2) === false ? 'error' : null}
                    >
                      <ControlLabel>{this.props.t('sensor_conversion_formula')}</ControlLabel>
                      <FormControl
                        value={this.state.sensorFormula3}
                        type="text"
                        placeholder={this.props.t('sensor_conversion_formula')}
                        onChange={(event) => {
                          const formula = event.target.value;
                          this.setState({
                            sensorFormula3: formula,
                            isFormulaValidated: false,
                          });
                          this.handleSensorFormulaValidation(2, formula);
                          if (this.state.portsConfig && this.state.portsConfig[2]) {
                            let portsConfig = { ...this.state.portsConfig };
                            portsConfig[2].sensor.formula = formula;
                            this.setState({ portsConfig: portsConfig });
                          }
                        }}
                      />
                    </FormGroup>
                    <UnitsDropdown
                      allowedUnits={this.state.allowedUnits3 || []}
                      unit={this.state.unitsType3}
                      onChange={this.handleUnitsType3Change}
                    />
                    <FormGroup
                      className="flex justify-between items-start"
                      controlId="Very Low level alarm"
                    >
                      <div className="flex text-left">
                        <Switch
                          label={this.state.sensor3VeryLowLevelAlarmEnabled}
                          checked={this.state.sensor3VeryLowLevelAlarmEnabled}
                          onChange={this.handlerSensor3VeryLowLevelAlarmEnabled.bind(this)}
                        />
                        <ControlLabel className="mt-2">
                          {this.props.t('very_low_level_alarm')}
                        </ControlLabel>
                      </div>
                      <NotificationCheckbox
                        notificationCheckboxes={[
                          {
                            icon: <EmailIcon />,
                            checked: this.state.sensor3VeryLowLevelEmailNotificationEnabled,
                            onChange: this.handlerSensor3VeryLowLevelEmailNotificationEnabled,
                          },
                          {
                            icon: <PhoneIphoneIcon />,
                            checked: this.state.sensor3VeryLowLevelMobileNotificationEnabled,
                            onChange: this.handlerSensor3VeryLowLevelMobileNotificationEnabled,
                          },
                        ]}
                      />
                    </FormGroup>
                    {this.state.sensor3VeryLowLevelAlarmEnabled && (
                      <FormGroup className={classes.fromGroup} controlId="very_low_level_alarm3">
                        <ControlLabel>{this.props.t('very_low_level_alarm')}</ControlLabel>
                        <FormControl
                          value={this.state.sensor3VeryLowLevelAlarm}
                          type="text"
                          placeholder={this.props.t('very_low_level_alarm')}
                          onChange={(event) => {
                            this.setState({ sensor3VeryLowLevelAlarm: event.target.value });
                          }}
                        />
                      </FormGroup>
                    )}

                    <FormGroup
                      className="flex justify-between items-start"
                      controlId="Low level alarm"
                    >
                      <div className="flex text-left">
                        <Switch
                          label={this.state.sensor3LowLevelAlarmEnabled}
                          checked={this.state.sensor3LowLevelAlarmEnabled}
                          onChange={this.handlerSensor3LowLevelAlarmEnabled.bind(this)}
                        />
                        <ControlLabel className="mt-2">
                          {this.props.t('low_level_alarm')}
                        </ControlLabel>
                      </div>
                      <NotificationCheckbox
                        notificationCheckboxes={[
                          {
                            icon: <EmailIcon />,
                            checked: this.state.sensor3LowLevelEmailNotificationEnabled,
                            onChange: this.handlerSensor3LowLevelEmailNotificationEnabled,
                          },
                          {
                            icon: <PhoneIphoneIcon />,
                            checked: this.state.sensor3LowLevelMobileNotificationEnabled,
                            onChange: this.handlerSensor3LowLevelMobileNotificationEnabled,
                          },
                        ]}
                      />
                    </FormGroup>
                    {this.state.sensor3LowLevelAlarmEnabled && (
                      <FormGroup className={classes.fromGroup} controlId="low_level_alarm3">
                        <ControlLabel>{this.props.t('low_level_alarm')}</ControlLabel>
                        <FormControl
                          value={this.state.sensor3LowLevelAlarm}
                          type="text"
                          placeholder={this.props.t('low_level_alarm')}
                          onChange={(event) => {
                            this.setState({ sensor3LowLevelAlarm: event.target.value });
                          }}
                        />
                      </FormGroup>
                    )}
                    <FormGroup
                      className="flex justify-between items-start"
                      controlId="High level alarm"
                    >
                      <div className="flex text-left">
                        <Switch
                          label={this.state.sensor3HighLevelAlarmEnabled}
                          checked={this.state.sensor3HighLevelAlarmEnabled}
                          onChange={this.handlerSensor3HighLevelAlarmEnabled.bind(this)}
                        />
                        <ControlLabel className="mt-2">
                          {this.props.t('high_level_alarm')}
                        </ControlLabel>
                      </div>
                      <NotificationCheckbox
                        notificationCheckboxes={[
                          {
                            icon: <EmailIcon />,
                            checked: this.state.sensor3HighLevelEmailNotificationEnabled,
                            onChange: this.handlerSensor3HighLevelEmailNotificationEnabled,
                          },
                          {
                            icon: <PhoneIphoneIcon />,
                            checked: this.state.sensor3HighLevelMobileNotificationEnabled,
                            onChange: this.handlerSensor3HighLevelMobileNotificationEnabled,
                          },
                        ]}
                      />
                    </FormGroup>
                    {this.state.sensor3HighLevelAlarmEnabled && (
                      <FormGroup className={classes.fromGroup} controlId="high_level_alarm3">
                        <ControlLabel>{this.props.t('high_level_alarm')}</ControlLabel>
                        <FormControl
                          value={this.state.sensor3HighLevelAlarm}
                          type="text"
                          placeholder={this.props.t('high_level_alarm')}
                          onChange={(event) => {
                            this.setState({ sensor3HighLevelAlarm: event.target.value });
                          }}
                        />
                      </FormGroup>
                    )}

                    <FormGroup
                      className="flex justify-between items-start"
                      controlId="Very High level alarm"
                    >
                      <div className="flex text-left">
                        <Switch
                          label={this.state.sensor3VeryHighLevelAlarmEnabled}
                          checked={this.state.sensor3VeryHighLevelAlarmEnabled}
                          onChange={this.handlerSensor3VeryHighLevelAlarmEnabled.bind(this)}
                        />
                        <ControlLabel className="mt-2">
                          {this.props.t('very_high_level_alarm')}
                        </ControlLabel>
                      </div>
                      <NotificationCheckbox
                        notificationCheckboxes={[
                          {
                            icon: <EmailIcon />,
                            checked: this.state.sensor3VeryHighLevelEmailNotificationEnabled,
                            onChange: this.handlerSensor3VeryHighLevelEmailNotificationEnabled,
                          },
                          {
                            icon: <PhoneIphoneIcon />,
                            checked: this.state.sensor3VeryHighLevelMobileNotificationEnabled,
                            onChange: this.handlerSensor3VeryHighLevelMobileNotificationEnabled,
                          },
                        ]}
                      />
                    </FormGroup>
                    {this.state.sensor3VeryHighLevelAlarmEnabled && (
                      <FormGroup className={classes.fromGroup} controlId="very_high_level_alarm3">
                        <ControlLabel>{this.props.t('very_high_level_alarm')}</ControlLabel>
                        <FormControl
                          value={this.state.sensor3VeryHighLevelAlarm}
                          type="text"
                          placeholder={this.props.t('very_high_level_alarm')}
                          onChange={(event) => {
                            this.setState({ sensor3VeryHighLevelAlarm: event.target.value });
                          }}
                        />
                      </FormGroup>
                    )}
                  </Grid>
                </AccordionDetails>
              </Accordion>
              <Accordion disabled={this.state.model === 'W1' || this.state.model === 'BL_100_L'}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <FormGroup controlId="Analog Sensor 2">
                    <Switch
                      label={this.state.sensor4Name}
                      checked={this.state.sensor4Enabeled}
                      onChange={this.handlerSensor4Toggled.bind(this)}
                    />
                    <ControlLabel>{this.state.sensor4Name}</ControlLabel>
                  </FormGroup>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container justifyContent={'center'} direction="column">
                    <div className="mb-10">
                      <FractionSelector
                        className="mb-2 m-auto"
                        fraction={Number(this.state.fraction4)}
                        onFractionChange={(event, value) =>
                          value !== null && this.setState({ fraction4: value, digits4: value + 2 })
                        }
                      />
                      <Typography className={classes.secondaryHeading}>
                        {(0).toFixed(this.state.fraction4)} {Units[this.state.unitsType4]}
                      </Typography>
                    </div>
                    <FormGroup className={classes.fromGroup} controlId="Sensor Type">
                      <ControlLabel>{this.props.t('device_config_sensor_type')}</ControlLabel>
                      <FormControl
                        value={this.state.sensorType4}
                        componentClass="select"
                        placeholder={this.props.t('device_config_sensor_type')}
                        onChange={(event) => {
                          this.handleSensorTypeChange(4, event.target.value);
                        }}
                      >
                        <option value={'generic_analog_sensor'}>Generic Analog Sensor</option>
                        <option value={'generic_virtual_sensor'}>Generic Virtual Sensor</option>
                        <option value={'pressure_sensor'}>Pressure Sensor</option>
                        <option value={'temperature_sensor'}>Temperature Sensor</option>
                        <option value={'humidity_sensor'}>Humidity Sensor</option>
                        <option value={'level_sensor'}>Level Sensor</option>
                        <option value={'weight_meter'}>Weight Meter</option>
                        <option value={'weight_sensor'}>Weight Sensor</option>
                        <option value={'load_cells'}>Load Cells</option>
                        <option value={'wind_direction_sensor'}>Wind Direction Sensor</option>
                        <option value={'barometric_pressure_sensor'}>
                          Barometric Pressure Sensor
                        </option>
                        <option value={'soil_moisture_sensor'}>Soil moisture Sensor</option>
                        <option value={'dew_point_sensor'}>Dew Point Sensor</option>
                        <option value={'soil_temperature_sensor'}>Soil Temperature Sensor</option>
                        <option value={'soil_potential_sensor'}>Soil Potential Sensor</option>
                        <option value={'particulate_matter_sensor'}>
                          Particulate Matter Sensor
                        </option>
                        <option value={'leaf_wetness_sensor'}>Leaf Wetness</option>
                      </FormControl>
                    </FormGroup>
                    <FormGroup
                      className={classes.fromGroup}
                      controlId="formula"
                      validationState={this.state.formulaValidity.get(3) === false ? 'error' : null}
                    >
                      <ControlLabel>{this.props.t('sensor_conversion_formula')}</ControlLabel>
                      <FormControl
                        value={this.state.sensorFormula4}
                        type="text"
                        placeholder={this.props.t('sensor_conversion_formula')}
                        onChange={(event) => {
                          const formula = event.target.value;
                          this.setState({
                            sensorFormula4: formula,
                            isFormulaValidated: false,
                          });
                          this.handleSensorFormulaValidation(3, formula);
                          if (this.state.portsConfig && this.state.portsConfig[3]) {
                            let portsConfig = { ...this.state.portsConfig };
                            portsConfig[3].sensor.formula = formula;
                            this.setState({ portsConfig: portsConfig });
                          }
                        }}
                      />
                    </FormGroup>
                    <UnitsDropdown
                      allowedUnits={this.state.allowedUnits4 || []}
                      unit={this.state.unitsType4}
                      onChange={this.handleUnitsType4Change}
                    />
                    <FormGroup
                      className="flex justify-between items-start"
                      controlId="Very Low level alarm"
                    >
                      <div className="flex text-left">
                        <Switch
                          label={this.state.sensor4VeryLowLevelAlarmEnabled}
                          checked={this.state.sensor4VeryLowLevelAlarmEnabled}
                          onChange={this.handlerSensor4VeryLowLevelAlarmEnabled.bind(this)}
                        />
                        <ControlLabel className="mt-2">
                          {this.props.t('very_low_level_alarm')}
                        </ControlLabel>
                      </div>
                      <NotificationCheckbox
                        notificationCheckboxes={[
                          {
                            icon: <EmailIcon />,
                            checked: this.state.sensor4VeryLowLevelEmailNotificationEnabled,
                            onChange: this.handlerSensor4VeryLowLevelEmailNotificationEnabled,
                          },
                          {
                            icon: <PhoneIphoneIcon />,
                            checked: this.state.sensor4VeryLowLevelMobileNotificationEnabled,
                            onChange: this.handlerSensor4VeryLowLevelMobileNotificationEnabled,
                          },
                        ]}
                      />
                    </FormGroup>
                    {this.state.sensor4VeryLowLevelAlarmEnabled && (
                      <FormGroup className={classes.fromGroup} controlId="very_low_level_alarm4">
                        <ControlLabel>{this.props.t('very_low_level_alarm')}</ControlLabel>
                        <FormControl
                          value={this.state.sensor4VeryLowLevelAlarm}
                          type="text"
                          placeholder={this.props.t('very_low_level_alarm')}
                          onChange={(event) => {
                            this.setState({ sensor4VeryLowLevelAlarm: event.target.value });
                          }}
                        />
                      </FormGroup>
                    )}
                    <FormGroup
                      className="flex justify-between items-start"
                      controlId="Low level alarm"
                    >
                      <div className="flex text-left">
                        <Switch
                          label={this.state.sensor4LowLevelAlarmEnabled}
                          checked={this.state.sensor4LowLevelAlarmEnabled}
                          onChange={this.handlerSensor4LowLevelAlarmEnabled.bind(this)}
                        />
                        <ControlLabel className="mt-2">
                          {this.props.t('low_level_alarm')}
                        </ControlLabel>
                      </div>
                      <NotificationCheckbox
                        notificationCheckboxes={[
                          {
                            icon: <EmailIcon />,
                            checked: this.state.sensor4LowLevelEmailNotificationEnabled,
                            onChange: this.handlerSensor4LowLevelEmailNotificationEnabled,
                          },
                          {
                            icon: <PhoneIphoneIcon />,
                            checked: this.state.sensor4LowLevelMobileNotificationEnabled,
                            onChange: this.handlerSensor4LowLevelMobileNotificationEnabled,
                          },
                        ]}
                      />
                    </FormGroup>
                    {this.state.sensor4LowLevelAlarmEnabled && (
                      <FormGroup className={classes.fromGroup} controlId="low_level_alarm4">
                        <ControlLabel>{this.props.t('low_level_alarm')}</ControlLabel>
                        <FormControl
                          value={this.state.sensor4LowLevelAlarm}
                          type="text"
                          placeholder={this.props.t('low_level_alarm')}
                          onChange={(event) => {
                            this.setState({ sensor4LowLevelAlarm: event.target.value });
                          }}
                        />
                      </FormGroup>
                    )}
                    <FormGroup
                      className="flex justify-between items-start"
                      controlId="High level alarm"
                    >
                      <div className="flex text-left">
                        <Switch
                          label={this.state.sensor4HighLevelAlarmEnabled}
                          checked={this.state.sensor4HighLevelAlarmEnabled}
                          onChange={this.handlerSensor4HighLevelAlarmEnabled.bind(this)}
                        />
                        <ControlLabel className="mt-2">
                          {this.props.t('high_level_alarm')}
                        </ControlLabel>
                      </div>
                      <NotificationCheckbox
                        notificationCheckboxes={[
                          {
                            icon: <EmailIcon />,
                            checked: this.state.sensor4HighLevelEmailNotificationEnabled,
                            onChange: this.handlerSensor4HighLevelEmailNotificationEnabled,
                          },
                          {
                            icon: <PhoneIphoneIcon />,
                            checked: this.state.sensor4HighLevelMobileNotificationEnabled,
                            onChange: this.handlerSensor4HighLevelMobileNotificationEnabled,
                          },
                        ]}
                      />
                    </FormGroup>
                    {this.state.sensor4HighLevelAlarmEnabled && (
                      <FormGroup className={classes.fromGroup} controlId="low_level_alarm4">
                        <ControlLabel>{this.props.t('high_level_alarm')}</ControlLabel>
                        <FormControl
                          value={this.state.sensor4HighLevelAlarm}
                          type="text"
                          placeholder={this.props.t('high_level_alarm')}
                          onChange={(event) => {
                            this.setState({ sensor4HighLevelAlarm: event.target.value });
                          }}
                        />
                      </FormGroup>
                    )}
                    <FormGroup
                      className="flex justify-between items-start"
                      controlId="Very High level alarm"
                    >
                      <div className="flex text-left">
                        <Switch
                          label={this.state.sensor4VeryHighLevelAlarmEnabled}
                          checked={this.state.sensor4VeryHighLevelAlarmEnabled}
                          onChange={this.handlerSensor4VeryHighLevelAlarmEnabled.bind(this)}
                        />
                        <ControlLabel className="mt-2">
                          {this.props.t('very_high_level_alarm')}
                        </ControlLabel>
                      </div>
                      <NotificationCheckbox
                        notificationCheckboxes={[
                          {
                            icon: <EmailIcon />,
                            checked: this.state.sensor4VeryHighLevelEmailNotificationEnabled,
                            onChange: this.handlerSensor4VeryHighLevelEmailNotificationEnabled,
                          },
                          {
                            icon: <PhoneIphoneIcon />,
                            checked: this.state.sensor4VeryHighLevelMobileNotificationEnabled,
                            onChange: this.handlerSensor4VeryHighLevelMobileNotificationEnabled,
                          },
                        ]}
                      />
                    </FormGroup>
                    {this.state.sensor4VeryHighLevelAlarmEnabled && (
                      <FormGroup className={classes.fromGroup} controlId="very_high_level_alarm4">
                        <ControlLabel>{this.props.t('very_high_level_alarm')}</ControlLabel>
                        <FormControl
                          value={this.state.sensor4VeryHighLevelAlarm}
                          type="text"
                          placeholder={this.props.t('very_high_level_alarm')}
                          onChange={(event) => {
                            this.setState({ sensor4VeryHighLevelAlarm: event.target.value });
                          }}
                        />
                      </FormGroup>
                    )}
                  </Grid>
                </AccordionDetails>
              </Accordion>
              {VersionUtil.areExtraSensorsVisible(this.state.hwVersion, this.state.model) && (
                <>
                  <Accordion
                    disabled={this.state.model === 'W1' || this.state.model === 'BL_100_L'}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <FormGroup controlId="Analog Sensor 9">
                        <Switch
                          label={this.state.sensor9Name}
                          checked={this.state.sensor9Enabeled}
                          onChange={this.handlerSensor9Toggled.bind(this)}
                        />
                        <ControlLabel>{this.state.sensor9Name}</ControlLabel>
                      </FormGroup>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container justifyContent={'center'} direction="column">
                        <div className="mb-10">
                          <FractionSelector
                            className="mb-2 m-auto"
                            fraction={Number(this.state.fraction9)}
                            onFractionChange={(event, value) =>
                              value !== null &&
                              this.setState({ fraction9: value, digits9: value + 2 })
                            }
                          />
                          <Typography className={classes.secondaryHeading}>
                            {(0).toFixed(this.state.fraction9)} {Units[this.state.unitsType9]}
                          </Typography>
                        </div>
                        <FormGroup className={classes.fromGroup} controlId="Sensor Type">
                          <ControlLabel>{this.props.t('device_config_sensor_type')}</ControlLabel>
                          <FormControl
                            value={this.state.sensorType9}
                            componentClass="select"
                            placeholder={this.props.t('device_config_sensor_type')}
                            onChange={(event) => {
                              this.handleSensorTypeChange(9, event.target.value);
                            }}
                          >
                            <option value={'generic_analog_sensor'}>Generic Analog Sensor</option>
                            <option value={'generic_virtual_sensor'}>Generic Virtual Sensor</option>
                            <option value={'pressure_sensor'}>Pressure Sensor</option>
                            <option value={'temperature_sensor'}>Temperature Sensor</option>
                            <option value={'humidity_sensor'}>Humidity Sensor</option>
                            <option value={'level_sensor'}>Level Sensor</option>
                            <option value={'weight_meter'}>Weight Meter</option>
                            <option value={'weight_sensor'}>Weight Sensor</option>
                            <option value={'load_cells'}>Load Cells</option>
                            <option value={'wind_direction_sensor'}>Wind Direction Sensor</option>
                            <option value={'barometric_pressure_sensor'}>
                              Barometric Pressure Sensor
                            </option>
                            <option value={'soil_moisture_sensor'}>Soil moisture Sensor</option>
                            <option value={'dew_point_sensor'}>Dew Point Sensor</option>
                            <option value={'soil_temperature_sensor'}>
                              Soil Temperature Sensor
                            </option>
                            <option value={'soil_potential_sensor'}>Soil Potential Sensor</option>
                            <option value={'particulate_matter_sensor'}>
                              Particulate Matter Sensor
                            </option>
                            <option value={'leaf_wetness_sensor'}>Leaf Wetness</option>
                          </FormControl>
                        </FormGroup>

                        <FormGroup
                          className={classes.fromGroup}
                          controlId="formula"
                          validationState={
                            this.state.formulaValidity.get(8) === false ? 'error' : null
                          }
                        >
                          <ControlLabel>{this.props.t('sensor_conversion_formula')}</ControlLabel>
                          <FormControl
                            value={this.state.sensorFormula9}
                            type="text"
                            placeholder={this.props.t('sensor_conversion_formula')}
                            onChange={(event) => {
                              const formula = event.target.value;
                              this.setState({
                                sensorFormula9: formula,
                                isFormulaValidated: false,
                              });
                              this.handleSensorFormulaValidation(8, formula);
                              if (this.state.portsConfig && this.state.portsConfig[8]) {
                                let portsConfig = { ...this.state.portsConfig };
                                portsConfig[8].sensor.formula = formula;
                                this.setState({ portsConfig: portsConfig });
                              }
                            }}
                          />
                        </FormGroup>
                        <UnitsDropdown
                          allowedUnits={this.state.allowedUnits9 || []}
                          unit={this.state.unitsType9}
                          onChange={this.handleUnitsType9Change}
                        />
                        <FormGroup
                          className="flex justify-between items-start"
                          controlId="Very Low level alarm"
                        >
                          <div className="flex text-left">
                            <Switch
                              label={this.state.sensor9VeryLowLevelAlarmEnabled}
                              checked={this.state.sensor9VeryLowLevelAlarmEnabled}
                              onChange={this.handlerSensor9VeryLowLevelAlarmEnabled.bind(this)}
                            />
                            <ControlLabel className="mt-2">
                              {this.props.t('very_low_level_alarm')}
                            </ControlLabel>
                          </div>
                          <NotificationCheckbox
                            notificationCheckboxes={[
                              {
                                icon: <EmailIcon />,
                                checked: this.state.sensor9VeryLowLevelEmailNotificationEnabled,
                                onChange: this.handlerSensor9VeryLowLevelEmailNotificationEnabled,
                              },
                              {
                                icon: <PhoneIphoneIcon />,
                                checked: this.state.sensor9VeryLowLevelMobileNotificationEnabled,
                                onChange: this.handlerSensor9VeryLowLevelMobileNotificationEnabled,
                              },
                            ]}
                          />
                        </FormGroup>
                        {this.state.sensor9VeryLowLevelAlarmEnabled && (
                          <FormGroup
                            className={classes.fromGroup}
                            controlId="very_low_level_alarm9"
                          >
                            <ControlLabel>{this.props.t('very_low_level_alarm')}</ControlLabel>
                            <FormControl
                              value={this.state.sensor9VeryLowLevelAlarm}
                              type="text"
                              placeholder={this.props.t('very_low_level_alarm')}
                              onChange={(event) => {
                                this.setState({ sensor9VeryLowLevelAlarm: event.target.value });
                              }}
                            />
                          </FormGroup>
                        )}
                        <FormGroup
                          className="flex justify-between items-start"
                          controlId="Low level alarm"
                        >
                          <div className="flex text-left">
                            <Switch
                              label={this.state.sensor9LowLevelAlarmEnabled}
                              checked={this.state.sensor9LowLevelAlarmEnabled}
                              onChange={this.handlerSensor9LowLevelAlarmEnabled.bind(this)} // TODO: CREATE
                            />
                            <ControlLabel className="mt-2">
                              {this.props.t('low_level_alarm')}
                            </ControlLabel>
                          </div>
                          <NotificationCheckbox
                            notificationCheckboxes={[
                              {
                                icon: <EmailIcon />,
                                checked: this.state.sensor9LowLevelEmailNotificationEnabled,
                                onChange: this.handlerSensor9LowLevelEmailNotificationEnabled,
                              },
                              {
                                icon: <PhoneIphoneIcon />,
                                checked: this.state.sensor9LowLevelMobileNotificationEnabled,
                                onChange: this.handlerSensor9LowLevelMobileNotificationEnabled,
                              },
                            ]}
                          />
                        </FormGroup>
                        {this.state.sensor9LowLevelAlarmEnabled && (
                          <FormGroup className={classes.fromGroup} controlId="low_level_alarm9">
                            <ControlLabel>{this.props.t('low_level_alarm')}</ControlLabel>
                            <FormControl
                              value={this.state.sensor9LowLevelAlarm}
                              type="text"
                              placeholder={this.props.t('low_level_alarm')}
                              onChange={(event) => {
                                this.setState({ sensor9LowLevelAlarm: event.target.value });
                              }}
                            />
                          </FormGroup>
                        )}
                        <FormGroup
                          className="flex justify-between items-start"
                          controlId="High level alarm"
                        >
                          <div className="flex text-left">
                            <Switch
                              label={this.state.sensor9HighLevelAlarmEnabled}
                              checked={this.state.sensor9HighLevelAlarmEnabled}
                              onChange={this.handlerSensor9HighLevelAlarmEnabled.bind(this)} // TODO: CREATE
                            />
                            <ControlLabel className="mt-2">
                              {this.props.t('high_level_alarm')}
                            </ControlLabel>
                          </div>
                          <NotificationCheckbox
                            notificationCheckboxes={[
                              {
                                icon: <EmailIcon />,
                                checked: this.state.sensor9HighLevelEmailNotificationEnabled,
                                onChange: this.handlerSensor9HighLevelEmailNotificationEnabled,
                              },
                              {
                                icon: <PhoneIphoneIcon />,
                                checked: this.state.sensor9HighLevelMobileNotificationEnabled,
                                onChange: this.handlerSensor9HighLevelMobileNotificationEnabled,
                              },
                            ]}
                          />
                        </FormGroup>
                        {this.state.sensor9HighLevelAlarmEnabled && (
                          <FormGroup className={classes.fromGroup} controlId="low_level_alarm9">
                            <ControlLabel>{this.props.t('high_level_alarm')}</ControlLabel>
                            <FormControl
                              value={this.state.sensor9HighLevelAlarm}
                              type="text"
                              placeholder={this.props.t('high_level_alarm')}
                              onChange={(event) => {
                                this.setState({ sensor9HighLevelAlarm: event.target.value });
                              }}
                            />
                          </FormGroup>
                        )}

                        <FormGroup
                          className="flex justify-between items-start"
                          controlId="Very High level alarm"
                        >
                          <div className="flex text-left">
                            <Switch
                              label={this.state.sensor9VeryHighLevelAlarmEnabled}
                              checked={this.state.sensor9VeryHighLevelAlarmEnabled}
                              onChange={this.handlerSensor9VeryHighLevelAlarmEnabled.bind(this)}
                            />
                            <ControlLabel className="mt-2">
                              {this.props.t('very_high_level_alarm')}
                            </ControlLabel>
                          </div>
                          <NotificationCheckbox
                            notificationCheckboxes={[
                              {
                                icon: <EmailIcon />,
                                checked: this.state.sensor9VeryHighLevelEmailNotificationEnabled,
                                onChange: this.handlerSensor9VeryHighLevelEmailNotificationEnabled,
                              },
                              {
                                icon: <PhoneIphoneIcon />,
                                checked: this.state.sensor9VeryHighLevelMobileNotificationEnabled,
                                onChange: this.handlerSensor9VeryHighLevelMobileNotificationEnabled,
                              },
                            ]}
                          />
                        </FormGroup>
                        {this.state.sensor9VeryHighLevelAlarmEnabled && (
                          <FormGroup
                            className={classes.fromGroup}
                            controlId="very_high_level_alarm9"
                          >
                            <ControlLabel>{this.props.t('very_high_level_alarm')}</ControlLabel>
                            <FormControl
                              value={this.state.sensor9VeryHighLevelAlarm}
                              type="text"
                              placeholder={this.props.t('very_high_level_alarm')}
                              onChange={(event) => {
                                this.setState({ sensor9VeryHighLevelAlarm: event.target.value });
                              }}
                            />
                          </FormGroup>
                        )}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                    disabled={this.state.model === 'W1' || this.state.model === 'BL_100_L'}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <FormGroup controlId="Analog Sensor 10">
                        <Switch
                          label={this.state.sensor10Name}
                          checked={this.state.sensor10Enabeled}
                          onChange={this.handlerSensor10Toggled.bind(this)}
                        />
                        <ControlLabel>{this.state.sensor10Name}</ControlLabel>
                      </FormGroup>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container justifyContent={'center'} direction="column">
                        <div className="mb-10">
                          <FractionSelector
                            className="mb-2 m-auto"
                            fraction={Number(this.state.fraction10)}
                            onFractionChange={(event, value) =>
                              value !== null &&
                              this.setState({ fraction10: value, digits10: value + 2 })
                            }
                          />
                          <Typography className={classes.secondaryHeading}>
                            {(0).toFixed(this.state.fraction10)} {Units[this.state.unitsType10]}
                          </Typography>
                        </div>
                        <FormGroup className={classes.fromGroup} controlId="Sensor Type">
                          <ControlLabel>{this.props.t('device_config_sensor_type')}</ControlLabel>
                          <FormControl
                            value={this.state.sensorType10}
                            componentClass="select"
                            placeholder={this.props.t('device_config_sensor_type')}
                            onChange={(event) => {
                              this.handleSensorTypeChange(10, event.target.value);
                            }}
                          >
                            <option value={'generic_analog_sensor'}>Generic Analog Sensor</option>
                            <option value={'generic_virtual_sensor'}>Generic Virtual Sensor</option>
                            <option value={'pressure_sensor'}>Pressure Sensor</option>
                            <option value={'temperature_sensor'}>Temperature Sensor</option>
                            <option value={'humidity_sensor'}>Humidity Sensor</option>
                            <option value={'level_sensor'}>Level Sensor</option>
                            <option value={'weight_meter'}>Weight Meter</option>
                            <option value={'weight_sensor'}>Weight Sensor</option>
                            <option value={'load_cells'}>Load Cells</option>
                            <option value={'wind_direction_sensor'}>Wind Direction Sensor</option>
                            <option value={'barometric_pressure_sensor'}>
                              Barometric Pressure Sensor
                            </option>
                            <option value={'soil_moisture_sensor'}>Soil moisture Sensor</option>
                            <option value={'dew_point_sensor'}>Dew Point Sensor</option>
                            <option value={'soil_temperature_sensor'}>
                              Soil Temperature Sensor
                            </option>
                            <option value={'soil_potential_sensor'}>Soil Potential Sensor</option>
                            <option value={'particulate_matter_sensor'}>
                              Particulate Matter Sensor
                            </option>
                            <option value={'leaf_wetness_sensor'}>Leaf Wetness</option>
                          </FormControl>
                        </FormGroup>

                        <FormGroup
                          className={classes.fromGroup}
                          controlId="formula"
                          validationState={
                            this.state.formulaValidity.get(9) === false ? 'error' : null
                          }
                        >
                          <ControlLabel>{this.props.t('sensor_conversion_formula')}</ControlLabel>
                          <FormControl
                            value={this.state.sensorFormula10}
                            type="text"
                            placeholder={this.props.t('sensor_conversion_formula')}
                            onChange={(event) => {
                              const formula = event.target.value;
                              this.setState({
                                sensorFormula10: formula,
                                isFormulaValidated: false,
                              });
                              this.handleSensorFormulaValidation(9, formula);
                              if (this.state.portsConfig && this.state.portsConfig[9]) {
                                let portsConfig = { ...this.state.portsConfig };
                                portsConfig[9].sensor.formula = formula;
                                this.setState({ portsConfig: portsConfig });
                              }
                            }}
                          />
                        </FormGroup>
                        <UnitsDropdown
                          allowedUnits={this.state.allowedUnits10 || []}
                          unit={this.state.unitsType10}
                          onChange={this.handleUnitsType10Change}
                        />
                        <FormGroup
                          className="flex justify-between items-start"
                          controlId="Very Low level alarm"
                        >
                          <div className="flex text-left">
                            <Switch
                              label={this.state.sensor10VeryLowLevelAlarmEnabled}
                              checked={this.state.sensor10VeryLowLevelAlarmEnabled}
                              onChange={this.handlerSensor10VeryLowLevelAlarmEnabled.bind(this)}
                            />
                            <ControlLabel className="mt-2">
                              {this.props.t('very_low_level_alarm')}
                            </ControlLabel>
                          </div>
                          <NotificationCheckbox
                            notificationCheckboxes={[
                              {
                                icon: <EmailIcon />,
                                checked: this.state.sensor10VeryLowLevelEmailNotificationEnabled,
                                onChange: this.handlerSensor10VeryLowLevelEmailNotificationEnabled,
                              },
                              {
                                icon: <PhoneIphoneIcon />,
                                checked: this.state.sensor10VeryLowLevelMobileNotificationEnabled,
                                onChange: this.handlerSensor10VeryLowLevelMobileNotificationEnabled,
                              },
                            ]}
                          />
                        </FormGroup>
                        {this.state.sensor10VeryLowLevelAlarmEnabled && (
                          <FormGroup
                            className={classes.fromGroup}
                            controlId="very_low_level_alarm10"
                          >
                            <ControlLabel>{this.props.t('very_low_level_alarm')}</ControlLabel>
                            <FormControl
                              value={this.state.sensor10VeryLowLevelAlarm}
                              type="text"
                              placeholder={this.props.t('very_low_level_alarm')}
                              onChange={(event) => {
                                this.setState({ sensor10VeryLowLevelAlarm: event.target.value });
                              }}
                            />
                          </FormGroup>
                        )}

                        <FormGroup
                          className="flex justify-between items-start"
                          controlId="Low level alarm"
                        >
                          <div className="flex text-left">
                            <Switch
                              label={this.state.sensor10LowLevelAlarmEnabled}
                              checked={this.state.sensor10LowLevelAlarmEnabled}
                              onChange={this.handlerSensor10LowLevelAlarmEnabled.bind(this)} // TODO: CREATE
                            />
                            <ControlLabel className="mt-2">
                              {this.props.t('low_level_alarm')}
                            </ControlLabel>
                          </div>
                          <NotificationCheckbox
                            notificationCheckboxes={[
                              {
                                icon: <EmailIcon />,
                                checked: this.state.sensor10LowLevelEmailNotificationEnabled,
                                onChange: this.handlerSensor10LowLevelEmailNotificationEnabled,
                              },
                              {
                                icon: <PhoneIphoneIcon />,
                                checked: this.state.sensor10LowLevelMobileNotificationEnabled,
                                onChange: this.handlerSensor10LowLevelMobileNotificationEnabled,
                              },
                            ]}
                          />
                        </FormGroup>
                        {this.state.sensor10LowLevelAlarmEnabled && (
                          <FormGroup className={classes.fromGroup} controlId="low_level_alarm1">
                            <ControlLabel>{this.props.t('low_level_alarm')}</ControlLabel>
                            <FormControl
                              value={this.state.sensor10LowLevelAlarm}
                              type="text"
                              placeholder={this.props.t('low_level_alarm')}
                              onChange={(event) => {
                                this.setState({ sensor10LowLevelAlarm: event.target.value });
                              }}
                            />
                          </FormGroup>
                        )}
                        <FormGroup
                          className="flex justify-between items-start"
                          controlId="High level alarm"
                        >
                          <div className="flex text-left">
                            <Switch
                              label={this.state.sensor10HighLevelAlarmEnabled}
                              checked={this.state.sensor10HighLevelAlarmEnabled}
                              onChange={this.handlerSensor10HighLevelAlarmEnabled.bind(this)} // TODO: CREATE
                            />
                            <ControlLabel className="mt-2">
                              {this.props.t('high_level_alarm')}
                            </ControlLabel>
                          </div>
                          <NotificationCheckbox
                            notificationCheckboxes={[
                              {
                                icon: <EmailIcon />,
                                checked: this.state.sensor10HighLevelEmailNotificationEnabled,
                                onChange: this.handlerSensor10HighLevelEmailNotificationEnabled,
                              },
                              {
                                icon: <PhoneIphoneIcon />,
                                checked: this.state.sensor10HighLevelMobileNotificationEnabled,
                                onChange: this.handlerSensor10HighLevelMobileNotificationEnabled,
                              },
                            ]}
                          />
                        </FormGroup>
                        {this.state.sensor10HighLevelAlarmEnabled && (
                          <FormGroup className={classes.fromGroup} controlId="low_level_alarm10">
                            <ControlLabel>{this.props.t('high_level_alarm')}</ControlLabel>
                            <FormControl
                              value={this.state.sensor10HighLevelAlarm}
                              type="text"
                              placeholder={this.props.t('high_level_alarm')}
                              onChange={(event) => {
                                this.setState({ sensor10HighLevelAlarm: event.target.value });
                              }}
                            />
                          </FormGroup>
                        )}

                        <FormGroup
                          className="flex justify-between items-start"
                          controlId="Very High level alarm"
                        >
                          <div className="flex text-left">
                            <Switch
                              label={this.state.sensor10VeryHighLevelAlarmEnabled}
                              checked={this.state.sensor10VeryHighLevelAlarmEnabled}
                              onChange={this.handlerSensor10VeryHighLevelAlarmEnabled.bind(this)}
                            />
                            <ControlLabel className="mt-2">
                              {this.props.t('very_high_level_alarm')}
                            </ControlLabel>
                          </div>
                          <NotificationCheckbox
                            notificationCheckboxes={[
                              {
                                icon: <EmailIcon />,
                                checked: this.state.sensor10VeryHighLevelEmailNotificationEnabled,
                                onChange: this.handlerSensor10VeryHighLevelEmailNotificationEnabled,
                              },
                              {
                                icon: <PhoneIphoneIcon />,
                                checked: this.state.sensor10VeryHighLevelMobileNotificationEnabled,
                                onChange:
                                  this.handlerSensor10VeryHighLevelMobileNotificationEnabled,
                              },
                            ]}
                          />
                        </FormGroup>
                        {this.state.sensor10VeryHighLevelAlarmEnabled && (
                          <FormGroup
                            className={classes.fromGroup}
                            controlId="very_high_level_alarm10"
                          >
                            <ControlLabel>{this.props.t('very_high_level_alarm')}</ControlLabel>
                            <FormControl
                              value={this.state.sensor10VeryHighLevelAlarm}
                              type="text"
                              placeholder={this.props.t('very_high_level_alarm')}
                              onChange={(event) => {
                                this.setState({ sensor10VeryHighLevelAlarm: event.target.value });
                              }}
                            />
                          </FormGroup>
                        )}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                    disabled={this.state.model === 'W1' || this.state.model === 'BL_100_L'}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <FormGroup controlId="Analog Sensor 11">
                        <Switch
                          label={this.state.sensor11Name}
                          checked={this.state.sensor11Enabeled}
                          onChange={this.handlerSensor11Toggled.bind(this)}
                        />
                        <ControlLabel>{this.state.sensor11Name}</ControlLabel>
                      </FormGroup>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container justifyContent={'center'} direction="column">
                        <div className="mb-10">
                          <FractionSelector
                            className="mb-2 m-auto"
                            fraction={Number(this.state.fraction11)}
                            onFractionChange={(event, value) =>
                              value !== null &&
                              this.setState({ fraction11: value, digits11: value + 2 })
                            }
                          />
                          <Typography className={classes.secondaryHeading}>
                            {(0).toFixed(this.state.fraction11)} {Units[this.state.unitsType11]}
                          </Typography>
                        </div>
                        <FormGroup className={classes.fromGroup} controlId="Sensor Type">
                          <ControlLabel>{this.props.t('device_config_sensor_type')}</ControlLabel>
                          <FormControl
                            value={this.state.sensorType11}
                            componentClass="select"
                            placeholder={this.props.t('device_config_sensor_type')}
                            onChange={(event) => {
                              this.handleSensorTypeChange(11, event.target.value);
                            }}
                          >
                            <option value={'generic_analog_sensor'}>Generic Analog Sensor</option>
                            <option value={'generic_virtual_sensor'}>Generic Virtual Sensor</option>
                            <option value={'pressure_sensor'}>Pressure Sensor</option>
                            <option value={'temperature_sensor'}>Temperature Sensor</option>
                            <option value={'humidity_sensor'}>Humidity Sensor</option>
                            <option value={'level_sensor'}>Level Sensor</option>
                            <option value={'weight_meter'}>Weight Meter</option>
                            <option value={'weight_sensor'}>Weight Sensor</option>
                            <option value={'load_cells'}>Load Cells</option>
                            <option value={'wind_direction_sensor'}>Wind Direction Sensor</option>
                            <option value={'barometric_pressure_sensor'}>
                              Barometric Pressure Sensor
                            </option>
                            <option value={'soil_moisture_sensor'}>Soil moisture Sensor</option>
                            <option value={'dew_point_sensor'}>Dew Point Sensor</option>
                            <option value={'soil_temperature_sensor'}>
                              Soil Temperature Sensor
                            </option>
                            <option value={'soil_potential_sensor'}>Soil Potential Sensor</option>
                            <option value={'particulate_matter_sensor'}>
                              Particulate Matter Sensor
                            </option>
                            <option value={'leaf_wetness_sensor'}>Leaf Wetness</option>
                          </FormControl>
                        </FormGroup>

                        <FormGroup
                          className={classes.fromGroup}
                          controlId="formula"
                          validationState={
                            this.state.formulaValidity.get(10) === false ? 'error' : null
                          }
                        >
                          <ControlLabel>{this.props.t('sensor_conversion_formula')}</ControlLabel>
                          <FormControl
                            value={this.state.sensorFormula11}
                            type="text"
                            placeholder={this.props.t('sensor_conversion_formula')}
                            onChange={(event) => {
                              const formula = event.target.value;
                              this.setState({
                                sensorFormula11: formula,
                                isFormulaValidated: false,
                              });
                              this.handleSensorFormulaValidation(10, formula);
                              if (this.state.portsConfig && this.state.portsConfig[10]) {
                                let portsConfig = { ...this.state.portsConfig };
                                portsConfig[10].sensor.formula = formula;
                                this.setState({ portsConfig: portsConfig });
                              }
                            }}
                          />
                        </FormGroup>
                        <UnitsDropdown
                          allowedUnits={this.state.allowedUnits11 || []}
                          unit={this.state.unitsType11}
                          onChange={this.handleUnitsType11Change}
                        />
                        <FormGroup
                          className="flex justify-between items-start"
                          controlId="Very Low level alarm"
                        >
                          <div className="flex text-left">
                            <Switch
                              label={this.state.sensor11VeryLowLevelAlarmEnabled}
                              checked={this.state.sensor11VeryLowLevelAlarmEnabled}
                              onChange={this.handlerSensor11VeryLowLevelAlarmEnabled.bind(this)}
                            />
                            <ControlLabel className="mt-2">
                              {this.props.t('very_low_level_alarm')}
                            </ControlLabel>
                          </div>
                          <NotificationCheckbox
                            notificationCheckboxes={[
                              {
                                icon: <EmailIcon />,
                                checked: this.state.sensor11VeryLowLevelEmailNotificationEnabled,
                                onChange: this.handlerSensor11VeryLowLevelEmailNotificationEnabled,
                              },
                              {
                                icon: <PhoneIphoneIcon />,
                                checked: this.state.sensor11VeryLowLevelMobileNotificationEnabled,
                                onChange: this.handlerSensor11VeryLowLevelMobileNotificationEnabled,
                              },
                            ]}
                          />
                        </FormGroup>
                        {this.state.sensor11VeryLowLevelAlarmEnabled && (
                          <FormGroup
                            className={classes.fromGroup}
                            controlId="very_low_level_alarm11"
                          >
                            <ControlLabel>{this.props.t('very_low_level_alarm')}</ControlLabel>
                            <FormControl
                              value={this.state.sensor11VeryLowLevelAlarm}
                              type="text"
                              placeholder={this.props.t('very_low_level_alarm')}
                              onChange={(event) => {
                                this.setState({ sensor11VeryLowLevelAlarm: event.target.value });
                              }}
                            />
                          </FormGroup>
                        )}
                        <FormGroup
                          className="flex justify-between items-start"
                          controlId="Low level alarm"
                        >
                          <div className="flex text-left">
                            <Switch
                              label={this.state.sensor11LowLevelAlarmEnabled}
                              checked={this.state.sensor11LowLevelAlarmEnabled}
                              onChange={this.handlerSensor11LowLevelAlarmEnabled.bind(this)} // TODO: CREATE
                            />
                            <ControlLabel className="mt-2">
                              {this.props.t('low_level_alarm')}
                            </ControlLabel>
                          </div>
                          <NotificationCheckbox
                            notificationCheckboxes={[
                              {
                                icon: <EmailIcon />,
                                checked: this.state.sensor11LowLevelEmailNotificationEnabled,
                                onChange: this.handlerSensor11LowLevelEmailNotificationEnabled,
                              },
                              {
                                icon: <PhoneIphoneIcon />,
                                checked: this.state.sensor11LowLevelMobileNotificationEnabled,
                                onChange: this.handlerSensor11LowLevelMobileNotificationEnabled,
                              },
                            ]}
                          />
                        </FormGroup>
                        {this.state.sensor11LowLevelAlarmEnabled && (
                          <FormGroup className={classes.fromGroup} controlId="low_level_alarm11">
                            <ControlLabel>{this.props.t('low_level_alarm')}</ControlLabel>
                            <FormControl
                              value={this.state.sensor11LowLevelAlarm}
                              type="text"
                              placeholder={this.props.t('low_level_alarm')}
                              onChange={(event) => {
                                this.setState({ sensor11LowLevelAlarm: event.target.value });
                              }}
                            />
                          </FormGroup>
                        )}
                        <FormGroup
                          className="flex justify-between items-start"
                          controlId="High level alarm"
                        >
                          <div className="flex text-left">
                            <Switch
                              label={this.state.sensor11HighLevelAlarmEnabled}
                              checked={this.state.sensor11HighLevelAlarmEnabled}
                              onChange={this.handlerSensor11HighLevelAlarmEnabled.bind(this)} // TODO: CREATE
                            />
                            <ControlLabel className="mt-2">
                              {this.props.t('high_level_alarm')}
                            </ControlLabel>
                          </div>
                          <NotificationCheckbox
                            notificationCheckboxes={[
                              {
                                icon: <EmailIcon />,
                                checked: this.state.sensor11HighLevelEmailNotificationEnabled,
                                onChange: this.handlerSensor11HighLevelEmailNotificationEnabled,
                              },
                              {
                                icon: <PhoneIphoneIcon />,
                                checked: this.state.sensor11HighLevelMobileNotificationEnabled,
                                onChange: this.handlerSensor11HighLevelMobileNotificationEnabled,
                              },
                            ]}
                          />
                        </FormGroup>
                        {this.state.sensor11HighLevelAlarmEnabled && (
                          <FormGroup className={classes.fromGroup} controlId="low_level_alarm11">
                            <ControlLabel>{this.props.t('high_level_alarm')}</ControlLabel>
                            <FormControl
                              value={this.state.sensor11HighLevelAlarm}
                              type="text"
                              placeholder={this.props.t('high_level_alarm')}
                              onChange={(event) => {
                                this.setState({ sensor11HighLevelAlarm: event.target.value });
                              }}
                            />
                          </FormGroup>
                        )}
                        <FormGroup
                          className="flex justify-between items-start"
                          controlId="Very High level alarm"
                        >
                          <div className="flex text-left">
                            <Switch
                              label={this.state.sensor11VeryHighLevelAlarmEnabled}
                              checked={this.state.sensor11VeryHighLevelAlarmEnabled}
                              onChange={this.handlerSensor11VeryHighLevelAlarmEnabled.bind(this)}
                            />
                            <ControlLabel className="mt-2">
                              {this.props.t('very_high_level_alarm')}
                            </ControlLabel>
                          </div>
                          <NotificationCheckbox
                            notificationCheckboxes={[
                              {
                                icon: <EmailIcon />,
                                checked: this.state.sensor11VeryHighLevelEmailNotificationEnabled,
                                onChange: this.handlerSensor11VeryHighLevelEmailNotificationEnabled,
                              },
                              {
                                icon: <PhoneIphoneIcon />,
                                checked: this.state.sensor11VeryHighLevelMobileNotificationEnabled,
                                onChange:
                                  this.handlerSensor11VeryHighLevelMobileNotificationEnabled,
                              },
                            ]}
                          />
                        </FormGroup>
                        {this.state.sensor11VeryHighLevelAlarmEnabled && (
                          <FormGroup
                            className={classes.fromGroup}
                            controlId="very_high_level_alarm11"
                          >
                            <ControlLabel>{this.props.t('very_high_level_alarm')}</ControlLabel>
                            <FormControl
                              value={this.state.sensor11VeryHighLevelAlarm}
                              type="text"
                              placeholder={this.props.t('very_high_level_alarm')}
                              onChange={(event) => {
                                this.setState({ sensor11VeryHighLevelAlarm: event.target.value });
                              }}
                            />
                          </FormGroup>
                        )}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                    disabled={this.state.model === 'W1' || this.state.model === 'BL_100_L'}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <FormGroup controlId="Analog Sensor 12">
                        <Switch
                          label={this.state.sensor12Name}
                          checked={this.state.sensor12Enabeled}
                          onChange={this.handlerSensor12Toggled.bind(this)}
                        />
                        <ControlLabel>{this.state.sensor12Name}</ControlLabel>
                      </FormGroup>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container justifyContent={'center'} direction="column">
                        <div className="mb-10">
                          <FractionSelector
                            className="mb-2 m-auto"
                            fraction={Number(this.state.fraction12)}
                            onFractionChange={(event, value) =>
                              value !== null &&
                              this.setState({ fraction12: value, digits12: value + 2 })
                            }
                          />
                          <Typography className={classes.secondaryHeading}>
                            {(0).toFixed(this.state.fraction12)} {Units[this.state.unitsType12]}
                          </Typography>
                        </div>
                        <FormGroup className={classes.fromGroup} controlId="Sensor Type">
                          <ControlLabel>{this.props.t('device_config_sensor_type')}</ControlLabel>
                          <FormControl
                            value={this.state.sensorType12}
                            componentClass="select"
                            placeholder={this.props.t('device_config_sensor_type')}
                            onChange={(event) => {
                              this.handleSensorTypeChange(12, event.target.value);
                            }}
                          >
                            <option value={'generic_analog_sensor'}>Generic Analog Sensor</option>
                            <option value={'generic_virtual_sensor'}>Generic Virtual Sensor</option>
                            <option value={'pressure_sensor'}>Pressure Sensor</option>
                            <option value={'temperature_sensor'}>Temperature Sensor</option>
                            <option value={'humidity_sensor'}>Humidity Sensor</option>
                            <option value={'level_sensor'}>Level Sensor</option>
                            <option value={'weight_meter'}>Weight Meter</option>
                            <option value={'weight_sensor'}>Weight Sensor</option>
                            <option value={'load_cells'}>Load Cells</option>
                            <option value={'wind_direction_sensor'}>Wind Direction Sensor</option>
                            <option value={'barometric_pressure_sensor'}>
                              Barometric Pressure Sensor
                            </option>
                            <option value={'soil_moisture_sensor'}>Soil moisture Sensor</option>
                            <option value={'dew_point_sensor'}>Dew Point Sensor</option>
                            <option value={'soil_temperature_sensor'}>
                              Soil Temperature Sensor
                            </option>
                            <option value={'soil_potential_sensor'}>Soil Potential Sensor</option>
                            <option value={'particulate_matter_sensor'}>
                              Particulate Matter Sensor
                            </option>
                            <option value={'leaf_wetness_sensor'}>Leaf Wetness</option>
                          </FormControl>
                        </FormGroup>

                        <FormGroup
                          className={classes.fromGroup}
                          controlId="formula"
                          validationState={
                            this.state.formulaValidity.get(11) === false ? 'error' : null
                          }
                        >
                          <ControlLabel>{this.props.t('sensor_conversion_formula')}</ControlLabel>
                          <FormControl
                            value={this.state.sensorFormula12}
                            type="text"
                            placeholder={this.props.t('sensor_conversion_formula')}
                            onChange={(event) => {
                              const formula = event.target.value;
                              this.setState({
                                sensorFormula12: formula,
                                isFormulaValidated: false,
                              });
                              this.handleSensorFormulaValidation(11, formula);
                              if (this.state.portsConfig && this.state.portsConfig[11]) {
                                let portsConfig = { ...this.state.portsConfig };
                                portsConfig[11].sensor.formula = formula;
                                this.setState({ portsConfig: portsConfig });
                              }
                            }}
                          />
                        </FormGroup>
                        <UnitsDropdown
                          allowedUnits={this.state.allowedUnits12 || []}
                          unit={this.state.unitsType12}
                          onChange={this.handleUnitsType12Change}
                        />
                        <FormGroup
                          className="flex justify-between items-start"
                          controlId="Very Low level alarm"
                        >
                          <div className="flex text-left">
                            <Switch
                              label={this.state.sensor12VeryLowLevelAlarmEnabled}
                              checked={this.state.sensor12VeryLowLevelAlarmEnabled}
                              onChange={this.handlerSensor12VeryLowLevelAlarmEnabled.bind(this)}
                            />
                            <ControlLabel className="mt-2">
                              {this.props.t('very_low_level_alarm')}
                            </ControlLabel>
                          </div>
                          <NotificationCheckbox
                            notificationCheckboxes={[
                              {
                                icon: <EmailIcon />,
                                checked: this.state.sensor12VeryLowLevelEmailNotificationEnabled,
                                onChange: this.handlerSensor12VeryLowLevelEmailNotificationEnabled,
                              },
                              {
                                icon: <PhoneIphoneIcon />,
                                checked: this.state.sensor12VeryLowLevelMobileNotificationEnabled,
                                onChange: this.handlerSensor12VeryLowLevelMobileNotificationEnabled,
                              },
                            ]}
                          />
                        </FormGroup>
                        {this.state.sensor12VeryLowLevelAlarmEnabled && (
                          <FormGroup
                            className={classes.fromGroup}
                            controlId="very_low_level_alarm12"
                          >
                            <ControlLabel>{this.props.t('very_low_level_alarm')}</ControlLabel>
                            <FormControl
                              value={this.state.sensor12VeryLowLevelAlarm}
                              type="text"
                              placeholder={this.props.t('very_low_level_alarm')}
                              onChange={(event) => {
                                this.setState({ sensor12VeryLowLevelAlarm: event.target.value });
                              }}
                            />
                          </FormGroup>
                        )}
                        <FormGroup
                          className="flex justify-between items-start"
                          controlId="Low level alarm"
                        >
                          <div className="flex text-left">
                            <Switch
                              label={this.state.sensor12LowLevelAlarmEnabled}
                              checked={this.state.sensor12LowLevelAlarmEnabled}
                              onChange={this.handlerSensor12LowLevelAlarmEnabled.bind(this)} // TODO: CREATE
                            />
                            <ControlLabel className="mt-2">
                              {this.props.t('low_level_alarm')}
                            </ControlLabel>
                          </div>
                          <NotificationCheckbox
                            notificationCheckboxes={[
                              {
                                icon: <EmailIcon />,
                                checked: this.state.sensor12LowLevelEmailNotificationEnabled,
                                onChange: this.handlerSensor12LowLevelEmailNotificationEnabled,
                              },
                              {
                                icon: <PhoneIphoneIcon />,
                                checked: this.state.sensor12LowLevelMobileNotificationEnabled,
                                onChange: this.handlerSensor12LowLevelMobileNotificationEnabled,
                              },
                            ]}
                          />
                        </FormGroup>
                        {this.state.sensor12LowLevelAlarmEnabled && (
                          <FormGroup className={classes.fromGroup} controlId="low_level_alarm12">
                            <ControlLabel>{this.props.t('low_level_alarm')}</ControlLabel>
                            <FormControl
                              value={this.state.sensor12LowLevelAlarm}
                              type="text"
                              placeholder={this.props.t('low_level_alarm')}
                              onChange={(event) => {
                                this.setState({ sensor12LowLevelAlarm: event.target.value });
                              }}
                            />
                          </FormGroup>
                        )}
                        <FormGroup
                          className="flex justify-between items-start"
                          controlId="High level alarm"
                        >
                          <div className="flex text-left">
                            <Switch
                              label={this.state.sensor12HighLevelAlarmEnabled}
                              checked={this.state.sensor12HighLevelAlarmEnabled}
                              onChange={this.handlerSensor12HighLevelAlarmEnabled.bind(this)} // TODO: CREATE
                            />
                            <ControlLabel className="mt-2">
                              {this.props.t('high_level_alarm')}
                            </ControlLabel>
                          </div>
                          <NotificationCheckbox
                            notificationCheckboxes={[
                              {
                                icon: <EmailIcon />,
                                checked: this.state.sensor12HighLevelEmailNotificationEnabled,
                                onChange: this.handlerSensor12HighLevelEmailNotificationEnabled,
                              },
                              {
                                icon: <PhoneIphoneIcon />,
                                checked: this.state.sensor12HighLevelMobileNotificationEnabled,
                                onChange: this.handlerSensor12HighLevelMobileNotificationEnabled,
                              },
                            ]}
                          />
                        </FormGroup>
                        {this.state.sensor12HighLevelAlarmEnabled && (
                          <FormGroup className={classes.fromGroup} controlId="low_level_alarm12">
                            <ControlLabel>{this.props.t('high_level_alarm')}</ControlLabel>
                            <FormControl
                              value={this.state.sensor12HighLevelAlarm}
                              type="text"
                              placeholder={this.props.t('high_level_alarm')}
                              onChange={(event) => {
                                this.setState({ sensor12HighLevelAlarm: event.target.value });
                              }}
                            />
                          </FormGroup>
                        )}
                        <FormGroup
                          className="flex justify-between items-start"
                          controlId="Very High level alarm"
                        >
                          <div className="flex text-left">
                            <Switch
                              label={this.state.sensor12VeryHighLevelAlarmEnabled}
                              checked={this.state.sensor12VeryHighLevelAlarmEnabled}
                              onChange={this.handlerSensor12VeryHighLevelAlarmEnabled.bind(this)}
                            />
                            <ControlLabel className="mt-2">
                              {this.props.t('very_high_level_alarm')}
                            </ControlLabel>
                          </div>
                          <NotificationCheckbox
                            notificationCheckboxes={[
                              {
                                icon: <EmailIcon />,
                                checked: this.state.sensor12VeryHighLevelEmailNotificationEnabled,
                                onChange: this.handlerSensor12VeryHighLevelEmailNotificationEnabled,
                              },
                              {
                                icon: <PhoneIphoneIcon />,
                                checked: this.state.sensor12VeryHighLevelMobileNotificationEnabled,
                                onChange:
                                  this.handlerSensor12VeryHighLevelMobileNotificationEnabled,
                              },
                            ]}
                          />
                        </FormGroup>
                        {this.state.sensor12VeryHighLevelAlarmEnabled && (
                          <FormGroup
                            className={classes.fromGroup}
                            controlId="very_high_level_alarm12"
                          >
                            <ControlLabel>{this.props.t('very_high_level_alarm')}</ControlLabel>
                            <FormControl
                              value={this.state.sensor12VeryHighLevelAlarm}
                              type="text"
                              placeholder={this.props.t('very_high_level_alarm')}
                              onChange={(event) => {
                                this.setState({ sensor12VeryHighLevelAlarm: event.target.value });
                              }}
                            />
                          </FormGroup>
                        )}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                    disabled={this.state.model === 'W1' || this.state.model === 'BL_100_L'}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <FormGroup controlId="Analog Sensor 13">
                        <Switch
                          label={this.state.sensor13Name}
                          checked={this.state.sensor13Enabeled}
                          onChange={this.handlerSensor13Toggled.bind(this)}
                        />
                        <ControlLabel>{this.state.sensor13Name}</ControlLabel>
                      </FormGroup>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container justifyContent={'center'} direction="column">
                        <div className="mb-10">
                          <FractionSelector
                            className="mb-2 m-auto"
                            fraction={Number(this.state.fraction13)}
                            onFractionChange={(event, value) =>
                              value !== null &&
                              this.setState({ fraction13: value, digits13: value + 2 })
                            }
                          />
                          <Typography className={classes.secondaryHeading}>
                            {(0).toFixed(this.state.fraction13)} {Units[this.state.unitsType13]}
                          </Typography>
                        </div>
                        <FormGroup className={classes.fromGroup} controlId="Sensor Type">
                          <ControlLabel>{this.props.t('device_config_sensor_type')}</ControlLabel>
                          <FormControl
                            value={this.state.sensorType13}
                            componentClass="select"
                            placeholder={this.props.t('device_config_sensor_type')}
                            onChange={(event) => {
                              this.handleSensorTypeChange(13, event.target.value);
                            }}
                          >
                            <option value={'generic_analog_sensor'}>Generic Analog Sensor</option>
                            <option value={'generic_virtual_sensor'}>Generic Virtual Sensor</option>
                            <option value={'pressure_sensor'}>Pressure Sensor</option>
                            <option value={'temperature_sensor'}>Temperature Sensor</option>
                            <option value={'humidity_sensor'}>Humidity Sensor</option>
                            <option value={'level_sensor'}>Level Sensor</option>
                            <option value={'weight_meter'}>Weight Meter</option>
                            <option value={'weight_sensor'}>Weight Sensor</option>
                            <option value={'load_cells'}>Load Cells</option>
                            <option value={'wind_direction_sensor'}>Wind Direction Sensor</option>
                            <option value={'barometric_pressure_sensor'}>
                              Barometric Pressure Sensor
                            </option>
                            <option value={'soil_moisture_sensor'}>Soil moisture Sensor</option>
                            <option value={'dew_point_sensor'}>Dew Point Sensor</option>
                            <option value={'soil_temperature_sensor'}>
                              Soil Temperature Sensor
                            </option>
                            <option value={'soil_potential_sensor'}>Soil Potential Sensor</option>
                            <option value={'particulate_matter_sensor'}>
                              Particulate Matter Sensor
                            </option>
                            <option value={'leaf_wetness_sensor'}>Leaf Wetness</option>
                          </FormControl>
                        </FormGroup>

                        <FormGroup
                          className={classes.fromGroup}
                          controlId="formula"
                          validationState={
                            this.state.formulaValidity.get(12) === false ? 'error' : null
                          }
                        >
                          <ControlLabel>{this.props.t('sensor_conversion_formula')}</ControlLabel>
                          <FormControl
                            value={this.state.sensorFormula13}
                            type="text"
                            placeholder={this.props.t('sensor_conversion_formula')}
                            onChange={(event) => {
                              const formula = event.target.value;
                              this.setState({
                                sensorFormula13: formula,
                                isFormulaValidated: false,
                              });
                              this.handleSensorFormulaValidation(12, formula);
                              if (this.state.portsConfig && this.state.portsConfig[12]) {
                                let portsConfig = { ...this.state.portsConfig };
                                portsConfig[12].sensor.formula = formula;
                                this.setState({ portsConfig: portsConfig });
                              }
                            }}
                          />
                        </FormGroup>
                        <UnitsDropdown
                          allowedUnits={this.state.allowedUnits13 || []}
                          unit={this.state.unitsType13}
                          onChange={this.handleUnitsType13Change}
                        />
                        <FormGroup
                          className="flex justify-between items-start"
                          controlId="Very Low level alarm"
                        >
                          <div className="flex text-left">
                            <Switch
                              label={this.state.sensor13VeryLowLevelAlarmEnabled}
                              checked={this.state.sensor13VeryLowLevelAlarmEnabled}
                              onChange={this.handlerSensor13VeryLowLevelAlarmEnabled.bind(this)}
                            />
                            <ControlLabel className="mt-2">
                              {this.props.t('very_low_level_alarm')}
                            </ControlLabel>
                          </div>
                          <NotificationCheckbox
                            notificationCheckboxes={[
                              {
                                icon: <EmailIcon />,
                                checked: this.state.sensor13VeryLowLevelEmailNotificationEnabled,
                                onChange: this.handlerSensor13VeryLowLevelEmailNotificationEnabled,
                              },
                              {
                                icon: <PhoneIphoneIcon />,
                                checked: this.state.sensor13VeryLowLevelMobileNotificationEnabled,
                                onChange: this.handlerSensor13VeryLowLevelMobileNotificationEnabled,
                              },
                            ]}
                          />
                        </FormGroup>
                        {this.state.sensor13VeryLowLevelAlarmEnabled && (
                          <FormGroup
                            className={classes.fromGroup}
                            controlId="very_low_level_alarm13"
                          >
                            <ControlLabel>{this.props.t('very_low_level_alarm')}</ControlLabel>
                            <FormControl
                              value={this.state.sensor13VeryLowLevelAlarm}
                              type="text"
                              placeholder={this.props.t('very_low_level_alarm')}
                              onChange={(event) => {
                                this.setState({ sensor13VeryLowLevelAlarm: event.target.value });
                              }}
                            />
                          </FormGroup>
                        )}

                        <FormGroup
                          className="flex justify-between items-start"
                          controlId="Low level alarm"
                        >
                          <div className="flex text-left">
                            <Switch
                              label={this.state.sensor13LowLevelAlarmEnabled}
                              checked={this.state.sensor13LowLevelAlarmEnabled}
                              onChange={this.handlerSensor13LowLevelAlarmEnabled.bind(this)} // TODO: CREATE
                            />
                            <ControlLabel className="mt-2">
                              {this.props.t('low_level_alarm')}
                            </ControlLabel>
                          </div>
                          <NotificationCheckbox
                            notificationCheckboxes={[
                              {
                                icon: <EmailIcon />,
                                checked: this.state.sensor13LowLevelEmailNotificationEnabled,
                                onChange: this.handlerSensor13LowLevelEmailNotificationEnabled,
                              },
                              {
                                icon: <PhoneIphoneIcon />,
                                checked: this.state.sensor13LowLevelMobileNotificationEnabled,
                                onChange: this.handlerSensor13LowLevelMobileNotificationEnabled,
                              },
                            ]}
                          />
                        </FormGroup>
                        {this.state.sensor13LowLevelAlarmEnabled && (
                          <FormGroup className={classes.fromGroup} controlId="low_level_alarm13">
                            <ControlLabel>{this.props.t('low_level_alarm')}</ControlLabel>
                            <FormControl
                              value={this.state.sensor13LowLevelAlarm}
                              type="text"
                              placeholder={this.props.t('low_level_alarm')}
                              onChange={(event) => {
                                this.setState({ sensor13LowLevelAlarm: event.target.value });
                              }}
                            />
                          </FormGroup>
                        )}
                        <FormGroup
                          className="flex justify-between items-start"
                          controlId="High level alarm"
                        >
                          <div className="flex text-left">
                            <Switch
                              label={this.state.sensor13HighLevelAlarmEnabled}
                              checked={this.state.sensor13HighLevelAlarmEnabled}
                              onChange={this.handlerSensor13HighLevelAlarmEnabled.bind(this)} // TODO: CREATE
                            />
                            <ControlLabel className="mt-2">
                              {this.props.t('high_level_alarm')}
                            </ControlLabel>
                          </div>
                          <NotificationCheckbox
                            notificationCheckboxes={[
                              {
                                icon: <EmailIcon />,
                                checked: this.state.sensor13HighLevelEmailNotificationEnabled,
                                onChange: this.handlerSensor13HighLevelEmailNotificationEnabled,
                              },
                              {
                                icon: <PhoneIphoneIcon />,
                                checked: this.state.sensor13HighLevelMobileNotificationEnabled,
                                onChange: this.handlerSensor13HighLevelMobileNotificationEnabled,
                              },
                            ]}
                          />
                        </FormGroup>
                        {this.state.sensor13HighLevelAlarmEnabled && (
                          <FormGroup className={classes.fromGroup} controlId="low_level_alarm13">
                            <ControlLabel>{this.props.t('high_level_alarm')}</ControlLabel>
                            <FormControl
                              value={this.state.sensor13HighLevelAlarm}
                              type="text"
                              placeholder={this.props.t('high_level_alarm')}
                              onChange={(event) => {
                                this.setState({ sensor13HighLevelAlarm: event.target.value });
                              }}
                            />
                          </FormGroup>
                        )}
                        <FormGroup
                          className="flex justify-between items-start"
                          controlId="Very High level alarm"
                        >
                          <div className="flex text-left">
                            <Switch
                              label={this.state.sensor13VeryHighLevelAlarmEnabled}
                              checked={this.state.sensor13VeryHighLevelAlarmEnabled}
                              onChange={this.handlerSensor13VeryHighLevelAlarmEnabled.bind(this)}
                            />
                            <ControlLabel className="mt-2">
                              {this.props.t('very_high_level_alarm')}
                            </ControlLabel>
                          </div>
                          <NotificationCheckbox
                            notificationCheckboxes={[
                              {
                                icon: <EmailIcon />,
                                checked: this.state.sensor13VeryHighLevelEmailNotificationEnabled,
                                onChange: this.handlerSensor13VeryHighLevelEmailNotificationEnabled,
                              },
                              {
                                icon: <PhoneIphoneIcon />,
                                checked: this.state.sensor13VeryHighLevelMobileNotificationEnabled,
                                onChange:
                                  this.handlerSensor13VeryHighLevelMobileNotificationEnabled,
                              },
                            ]}
                          />
                        </FormGroup>
                        {this.state.sensor13VeryHighLevelAlarmEnabled && (
                          <FormGroup
                            className={classes.fromGroup}
                            controlId="very_high_level_alarm13"
                          >
                            <ControlLabel>{this.props.t('very_high_level_alarm')}</ControlLabel>
                            <FormControl
                              value={this.state.sensor13VeryHighLevelAlarm}
                              type="text"
                              placeholder={this.props.t('very_high_level_alarm')}
                              onChange={(event) => {
                                this.setState({ sensor13VeryHighLevelAlarm: event.target.value });
                              }}
                            />
                          </FormGroup>
                        )}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                    disabled={this.state.model === 'W1' || this.state.model === 'BL_100_L'}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <FormGroup controlId="Analog Sensor 14">
                        <Switch
                          label={this.state.sensor14Name}
                          checked={this.state.sensor14Enabeled}
                          onChange={this.handlerSensor14Toggled.bind(this)}
                        />
                        <ControlLabel>{this.state.sensor14Name}</ControlLabel>
                      </FormGroup>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container justifyContent={'center'} direction="column">
                        <div className="mb-10">
                          <FractionSelector
                            className="mb-2 m-auto"
                            fraction={Number(this.state.fraction14)}
                            onFractionChange={(event, value) =>
                              value !== null &&
                              this.setState({ fraction14: value, digits14: value + 2 })
                            }
                          />
                          <Typography className={classes.secondaryHeading}>
                            {(0).toFixed(this.state.fraction14)} {Units[this.state.unitsType14]}
                          </Typography>
                        </div>
                        <FormGroup className={classes.fromGroup} controlId="Sensor Type">
                          <ControlLabel>{this.props.t('device_config_sensor_type')}</ControlLabel>
                          <FormControl
                            value={this.state.sensorType14}
                            componentClass="select"
                            placeholder={this.props.t('device_config_sensor_type')}
                            onChange={(event) => {
                              this.handleSensorTypeChange(14, event.target.value);
                            }}
                          >
                            <option value={'generic_analog_sensor'}>Generic Analog Sensor</option>
                            <option value={'generic_virtual_sensor'}>Generic Virtual Sensor</option>
                            <option value={'pressure_sensor'}>Pressure Sensor</option>
                            <option value={'temperature_sensor'}>Temperature Sensor</option>
                            <option value={'humidity_sensor'}>Humidity Sensor</option>
                            <option value={'level_sensor'}>Level Sensor</option>
                            <option value={'weight_meter'}>Weight Meter</option>
                            <option value={'weight_sensor'}>Weight Sensor</option>
                            <option value={'load_cells'}>Load Cells</option>
                            <option value={'wind_direction_sensor'}>Wind Direction Sensor</option>
                            <option value={'barometric_pressure_sensor'}>
                              Barometric Pressure Sensor
                            </option>
                            <option value={'soil_moisture_sensor'}>Soil moisture Sensor</option>
                            <option value={'dew_point_sensor'}>Dew Point Sensor</option>
                            <option value={'soil_temperature_sensor'}>
                              Soil Temperature Sensor
                            </option>
                            <option value={'soil_potential_sensor'}>Soil Potential Sensor</option>
                            <option value={'particulate_matter_sensor'}>
                              Particulate Matter Sensor
                            </option>
                            <option value={'leaf_wetness_sensor'}>Leaf Wetness</option>
                          </FormControl>
                        </FormGroup>

                        <FormGroup
                          className={classes.fromGroup}
                          controlId="formula"
                          validationState={
                            this.state.formulaValidity.get(13) === false ? 'error' : null
                          }
                        >
                          <ControlLabel>{this.props.t('sensor_conversion_formula')}</ControlLabel>
                          <FormControl
                            value={this.state.sensorFormula14}
                            type="text"
                            placeholder={this.props.t('sensor_conversion_formula')}
                            onChange={(event) => {
                              const formula = event.target.value;
                              this.setState({
                                sensorFormula14: formula,
                                isFormulaValidated: false,
                              });
                              this.handleSensorFormulaValidation(13, formula);
                              if (this.state.portsConfig && this.state.portsConfig[13]) {
                                let portsConfig = { ...this.state.portsConfig };
                                portsConfig[13].sensor.formula = formula;
                                this.setState({ portsConfig: portsConfig });
                              }
                            }}
                          />
                        </FormGroup>
                        <UnitsDropdown
                          allowedUnits={this.state.allowedUnits14 || []}
                          unit={this.state.unitsType14}
                          onChange={this.handleUnitsType14Change}
                        />
                        <FormGroup
                          className="flex justify-between items-start"
                          controlId="Very Low level alarm"
                        >
                          <div className="flex text-left">
                            <Switch
                              label={this.state.sensor14VeryLowLevelAlarmEnabled}
                              checked={this.state.sensor14VeryLowLevelAlarmEnabled}
                              onChange={this.handlerSensor14VeryLowLevelAlarmEnabled.bind(this)}
                            />
                            <ControlLabel className="mt-2">
                              {this.props.t('very_low_level_alarm')}
                            </ControlLabel>
                          </div>
                          <NotificationCheckbox
                            notificationCheckboxes={[
                              {
                                icon: <EmailIcon />,
                                checked: this.state.sensor14VeryLowLevelEmailNotificationEnabled,
                                onChange: this.handlerSensor14VeryLowLevelEmailNotificationEnabled,
                              },
                              {
                                icon: <PhoneIphoneIcon />,
                                checked: this.state.sensor14VeryLowLevelMobileNotificationEnabled,
                                onChange: this.handlerSensor14VeryLowLevelMobileNotificationEnabled,
                              },
                            ]}
                          />
                        </FormGroup>
                        {this.state.sensor14VeryLowLevelAlarmEnabled && (
                          <FormGroup
                            className={classes.fromGroup}
                            controlId="very_low_level_alarm14"
                          >
                            <ControlLabel>{this.props.t('very_low_level_alarm')}</ControlLabel>
                            <FormControl
                              value={this.state.sensor14VeryLowLevelAlarm}
                              type="text"
                              placeholder={this.props.t('very_low_level_alarm')}
                              onChange={(event) => {
                                this.setState({ sensor14VeryLowLevelAlarm: event.target.value });
                              }}
                            />
                          </FormGroup>
                        )}
                        <FormGroup
                          className="flex justify-between items-start"
                          controlId="Low level alarm"
                        >
                          <div className="flex text-left">
                            <Switch
                              label={this.state.sensor14LowLevelAlarmEnabled}
                              checked={this.state.sensor14LowLevelAlarmEnabled}
                              onChange={this.handlerSensor14LowLevelAlarmEnabled.bind(this)} // TODO: CREATE
                            />
                            <ControlLabel className="mt-2">
                              {this.props.t('low_level_alarm')}
                            </ControlLabel>
                          </div>
                          <NotificationCheckbox
                            notificationCheckboxes={[
                              {
                                icon: <EmailIcon />,
                                checked: this.state.sensor14LowLevelEmailNotificationEnabled,
                                onChange: this.handlerSensor14LowLevelEmailNotificationEnabled,
                              },
                              {
                                icon: <PhoneIphoneIcon />,
                                checked: this.state.sensor14LowLevelMobileNotificationEnabled,
                                onChange: this.handlerSensor14LowLevelMobileNotificationEnabled,
                              },
                            ]}
                          />
                        </FormGroup>
                        {this.state.sensor14LowLevelAlarmEnabled && (
                          <FormGroup className={classes.fromGroup} controlId="low_level_alarm14">
                            <ControlLabel>{this.props.t('low_level_alarm')}</ControlLabel>
                            <FormControl
                              value={this.state.sensor14LowLevelAlarm}
                              type="text"
                              placeholder={this.props.t('low_level_alarm')}
                              onChange={(event) => {
                                this.setState({ sensor14LowLevelAlarm: event.target.value });
                              }}
                            />
                          </FormGroup>
                        )}
                        <FormGroup
                          className="flex justify-between items-start"
                          controlId="High level alarm"
                        >
                          <div className="flex text-left">
                            <Switch
                              label={this.state.sensor14HighLevelAlarmEnabled}
                              checked={this.state.sensor14HighLevelAlarmEnabled}
                              onChange={this.handlerSensor14HighLevelAlarmEnabled.bind(this)} // TODO: CREATE
                            />
                            <ControlLabel className="mt-2">
                              {this.props.t('high_level_alarm')}
                            </ControlLabel>
                          </div>
                          <NotificationCheckbox
                            notificationCheckboxes={[
                              {
                                icon: <EmailIcon />,
                                checked: this.state.sensor14HighLevelEmailNotificationEnabled,
                                onChange: this.handlerSensor14HighLevelEmailNotificationEnabled,
                              },
                              {
                                icon: <PhoneIphoneIcon />,
                                checked: this.state.sensor14HighLevelMobileNotificationEnabled,
                                onChange: this.handlerSensor14HighLevelMobileNotificationEnabled,
                              },
                            ]}
                          />
                        </FormGroup>
                        {this.state.sensor14HighLevelAlarmEnabled && (
                          <FormGroup className={classes.fromGroup} controlId="low_level_alarm14">
                            <ControlLabel>{this.props.t('high_level_alarm')}</ControlLabel>
                            <FormControl
                              value={this.state.sensor14HighLevelAlarm}
                              type="text"
                              placeholder={this.props.t('high_level_alarm')}
                              onChange={(event) => {
                                this.setState({ sensor14HighLevelAlarm: event.target.value });
                              }}
                            />
                          </FormGroup>
                        )}

                        <FormGroup
                          className="flex justify-between items-start"
                          controlId="Very High level alarm"
                        >
                          <div className="flex text-left">
                            <Switch
                              label={this.state.sensor14VeryHighLevelAlarmEnabled}
                              checked={this.state.sensor14VeryHighLevelAlarmEnabled}
                              onChange={this.handlerSensor14VeryHighLevelAlarmEnabled.bind(this)}
                            />
                            <ControlLabel className="mt-2">
                              {this.props.t('very_high_level_alarm')}
                            </ControlLabel>
                          </div>
                          <NotificationCheckbox
                            notificationCheckboxes={[
                              {
                                icon: <EmailIcon />,
                                checked: this.state.sensor14VeryHighLevelEmailNotificationEnabled,
                                onChange: this.handlerSensor14VeryHighLevelEmailNotificationEnabled,
                              },
                              {
                                icon: <PhoneIphoneIcon />,
                                checked: this.state.sensor14VeryHighLevelMobileNotificationEnabled,
                                onChange:
                                  this.handlerSensor14VeryHighLevelMobileNotificationEnabled,
                              },
                            ]}
                          />
                        </FormGroup>
                        {this.state.sensor14VeryHighLevelAlarmEnabled && (
                          <FormGroup
                            className={classes.fromGroup}
                            controlId="very_high_level_alarm14"
                          >
                            <ControlLabel>{this.props.t('very_high_level_alarm')}</ControlLabel>
                            <FormControl
                              value={this.state.sensor14VeryHighLevelAlarm}
                              type="text"
                              placeholder={this.props.t('very_high_level_alarm')}
                              onChange={(event) => {
                                this.setState({ sensor14VeryHighLevelAlarm: event.target.value });
                              }}
                            />
                          </FormGroup>
                        )}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </>
              )}
              <Accordion disabled={this.state.model === 'W1' || this.state.model === 'BL_100_L'}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <FormGroup controlId="On/Off Sensor 1">
                    <Switch
                      label={this.state.sensor5Name}
                      checked={this.state.sensor5Enabeled}
                      onChange={this.handlerSensor5Toggled.bind(this)}
                    />
                    <ControlLabel>{this.state.sensor5Name}</ControlLabel>
                  </FormGroup>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid
                    container
                    justifyContent={'center'}
                    alignItems={'center'}
                    direction="column"
                  >
                    <FormGroup className={classes.fromGroup} controlId="Sensor Type">
                      <ControlLabel>{this.props.t('device_config_sensor_type')}</ControlLabel>
                      <FormControl
                        value={this.state.sensorType5}
                        componentClass="select"
                        placeholder={this.props.t('device_config_sensor_type')}
                        onChange={(event) => {
                          this.setState({ sensorType5: event.target.value });
                        }}
                      >
                        <option value={'on_off_input_sensor'}>On/Off Input Sensor</option>
                        <option value={'on_off_sensor'}>On/Off Sensor</option>
                      </FormControl>
                    </FormGroup>
                  </Grid>
                </AccordionDetails>
              </Accordion>
              <Accordion disabled={this.state.model === 'W1' || this.state.model === 'BL_100_L'}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <FormGroup controlId="On/Off Sensor 2">
                    <Switch
                      label={this.state.sensor6Name}
                      checked={this.state.sensor6Enabeled}
                      onChange={this.handlerSensor6Toggled.bind(this)}
                    />
                    <ControlLabel>{this.state.sensor6Name}</ControlLabel>
                  </FormGroup>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container justifyContent={'center'} direction="column">
                    <FormGroup className={classes.fromGroup} controlId="Sensor Type">
                      <ControlLabel>{this.props.t('device_config_sensor_type')}</ControlLabel>
                      <FormControl
                        value={this.state.sensorType6}
                        componentClass="select"
                        placeholder={this.props.t('device_config_sensor_type')}
                        onChange={(event) => {
                          this.setState({ sensorType6: event.target.value });
                        }}
                      >
                        <option value={'on_off_input_sensor'}>On/Off Input Sensor</option>
                        <option value={'on_off_sensor'}>On/Off Sensor</option>
                      </FormControl>
                    </FormGroup>
                  </Grid>
                </AccordionDetails>
              </Accordion>
              {VersionUtil.areExtraSensorsVisible(this.state.hwVersion, this.state.model) && (
                <>
                  <Accordion
                    disabled={this.state.model === 'W1' || this.state.model === 'BL_100_L'}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <FormGroup controlId="On/Off Relay 1">
                        <Switch
                          label={this.state.sensor7Name}
                          checked={this.state.sensor7Enabeled}
                          onChange={this.handlerSensor7Toggled.bind(this)}
                        />
                        <ControlLabel>{this.state.sensor7Name}</ControlLabel>
                      </FormGroup>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid
                        container
                        justifyContent={'center'}
                        alignItems={'center'}
                        direction="column"
                      >
                        <FormGroup className={classes.fromGroup} controlId="Sensor Type">
                          <ControlLabel>{this.props.t('device_config_sensor_type')}</ControlLabel>
                          <FormControl
                            value={this.state.sensorType7}
                            componentClass="select"
                            placeholder={this.props.t('device_config_sensor_type')}
                            onChange={(event) => {
                              this.setState({ sensorType7: event.target.value });
                            }}
                          >
                            <option value={'on_off_output_sensor'}>On/Off Output Sensor</option>
                            <option value={'on_off_auto_output_sensor'}>
                              On/Off Auto Output Sensor
                            </option>
                            <option value={'on_off_sensor'}>On/Off Sensor</option>
                          </FormControl>
                        </FormGroup>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    disabled={this.state.model === 'W1' || this.state.model === 'BL_100_L'}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <FormGroup controlId="On/Off Relay 2">
                        <Switch
                          label={this.state.sensor8Name}
                          checked={this.state.sensor8Enabeled}
                          onChange={this.handlerSensor8Toggled.bind(this)}
                        />
                        <ControlLabel>{this.state.sensor8Name}</ControlLabel>
                      </FormGroup>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid
                        container
                        justifyContent={'center'}
                        alignItems={'center'}
                        direction="column"
                      >
                        <FormGroup className={classes.fromGroup} controlId="Sensor Type">
                          <ControlLabel>{this.props.t('device_config_sensor_type')}</ControlLabel>
                          <FormControl
                            value={this.state.sensorType8}
                            componentClass="select"
                            placeholder={this.props.t('device_config_sensor_type')}
                            onChange={(event) => {
                              this.setState({ sensorType8: event.target.value });
                            }}
                          >
                            <option value={'on_off_output_sensor'}>On/Off Output Sensor</option>
                            <option value={'on_off_auto_output_sensor'}>
                              On/Off Auto Output Sensor
                            </option>
                            <option value={'on_off_sensor'}>On/Off Sensor</option>
                          </FormControl>
                        </FormGroup>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </>
              )}
              <FormGroup style={{ paddingTop: 20 }} controlId="timezone">
                <ControlLabel>{this.props.t('device_config_time_zone')}</ControlLabel>
                <Autocomplete
                  value={this.state.timeZone}
                  size="small"
                  disablePortal
                  options={this.state.timeZones}
                  onChange={this.handleTimeZoneChange.bind(this)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t('device_config_select_time_zone')}
                      style={{ backgroundColor: 'white' }}
                    />
                  )}
                />
              </FormGroup>
              <FormGroup controlId="deleteold">
                <ControlLabel>{this.props.t('delete_old_counters')}</ControlLabel>
                <FormControl
                  value={this.state.deleteOldCounters}
                  componentClass="select"
                  placeholder={this.props.t('delete_old_counters')}
                  onChange={this.handleDeleteOldCountersChange.bind(this)}
                >
                  <option value={false}>{this.props.t('capital_no')}</option>
                  <option value={true}>{this.props.t('capital_yes')}</option>
                </FormControl>
              </FormGroup>
              <FormGroup controlId="missing_transmission_severity">
                <ControlLabel>
                  {this.props.t('device_config_missing_transmission_severity')}
                </ControlLabel>
                <FormControl
                  value={`${this.state.missedTransmissionSeverity}` ?? ''}
                  componentClass="select"
                  placeholder={this.props.t('device_config_missing_transmission_severity')}
                  onChange={this.handleMissingTransmissionSeverityChange.bind(this)}
                >
                  <option value={''}>{this.props.t('none')}</option>
                  {[...Array(10).keys()].map((num) => (
                    <option key={`severity-option-${num}`} value={`${num}`}>
                      {num}
                    </option>
                  ))}
                </FormControl>
              </FormGroup>
              <Button
                variant="contained"
                onClick={this.handleSubmit}
                disabled={
                  this.state.saveDisabled ||
                  this.state.transmissionErrorMsg ||
                  this.state.hasInvalidFormula ||
                  !this.state.isFormulaValidated
                }
              >
                {this.props.t('device_config_start_setup')}
              </Button>
              <Snackbar
                open={this.state.open}
                message={this.state.snackbarMsg}
                onClose={this.handleRequestClose}
                autoHideDuration={5000}
              />
            </form>
          </div>
        </Header>
      );
    } else if (notSupportedModelsInThisPage.includes(this.state.model)) {
      return (
        <Header>
          <div className="text-xl">
            <p>{this.props.t('device_config_does_not_support_this_model')}</p>
          </div>
        </Header>
      );
    } else {
      return (
        <Header>
          <div>
            <CircularProgress size={80} thickness={5} />
          </div>
        </Header>
      );
    }
  }

  componentDidMount() {
    this.loadDeviceConfigFromServer();
    this.intervalId = setInterval(
      this.loadConfiguredFlag,
      DeviceConfig.REFRESH_PERIOD_IN_SECONDS_INITIAL * 1000
    );
  }

  componentDidUpdate(prevProps, prevState) {
    const currRecordPeriod = this.state.recordPeriod;
    const currRecordEvery = this.state.recordEvery;
    const currTransmissionPeriod = this.state.transmissionPeriod;
    const currTransmissionEvery = this.state.transmissionEvery;

    const prevRecordPeriod = prevState.recordPeriod;
    const prevRecordEvery = prevState.recordEvery;
    const prevTransmissionPeriod = prevState.transmissionPeriod;
    const prevTransmissionEvery = prevState.transmissionEvery;

    const recordPeriodChanged = currRecordPeriod !== prevRecordPeriod;
    const recordEveryChanged = currRecordEvery !== prevRecordEvery;
    const transmissionPeriodChanged = currTransmissionPeriod !== prevTransmissionPeriod;
    const transmissionEveryChanged = currTransmissionEvery !== prevTransmissionEvery;

    if (
      recordPeriodChanged ||
      recordEveryChanged ||
      transmissionPeriodChanged ||
      transmissionEveryChanged
    ) {
      const countsThreshold = this.calcCountThreshold(
        currTransmissionEvery,
        currTransmissionPeriod,
        currRecordEvery,
        currRecordPeriod
      );
      const recordPeriodInSec = this.calculatePeriodInSeconds(currRecordEvery, currRecordPeriod);
      const transmissionPeriodInSec = this.calculatePeriodInSeconds(
        currTransmissionEvery,
        currTransmissionPeriod
      );

      let transmissionErrorMsg = null;
      let recordErrorHighlight = false;
      let transmissionErrorHighlight = false;
      if (currRecordEvery <= 0) {
        transmissionErrorMsg = this.props.t('device_config_error_record_period_postiive');
        recordErrorHighlight = true;
      } else if (currTransmissionEvery <= 0) {
        transmissionErrorMsg = this.props.t('device_config_error_transmission_period_positive');
        transmissionErrorHighlight = true;
      } else if (Number(recordPeriodInSec) > Number(transmissionPeriodInSec)) {
        transmissionErrorMsg = this.props.t(
          'device_config_error_record_must_be_greater_than_transmission'
        );
        recordErrorHighlight = true;
      } else if (countsThreshold % 1 !== 0) {
        transmissionErrorMsg = this.props.t('device_config_error_not_divisible');
        recordErrorHighlight = true;
        transmissionErrorHighlight = true;
      } else if (countsThreshold > MAXIMUM_COUNT_THRESHOLD) {
        transmissionErrorMsg = this.props.t('device_config_error_max_threshold_reached', {
          curr: countsThreshold,
          max: MAXIMUM_COUNT_THRESHOLD,
        });
        recordErrorHighlight = true;
      }
      this.setState({
        transmissionErrorMsg: transmissionErrorMsg,
        transmissionErrorHighlight: transmissionErrorHighlight,
        recordErrorHighlight: recordErrorHighlight,
      });
    }

    if (this.props.match.params.deviceNumber !== prevProps.match.params.deviceNumber) {
      this.setState({
        configured: null,
      });
      this.loadDeviceConfigFromServer();
    }

    if (this.state.formulaValidity !== prevState.formulaValidity) {
      this.setState({
        ...this.state,
        hasInvalidFormula: Array.from(this.state.formulaValidity.values()).some(
          (sensorFormulaValidity) => sensorFormulaValidity === false
        ),
      });
    }
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  loadConfiguredFlag() {
    // Records how much time has passed since intially trying to get the device config (IN SECONDS)
    this.setState({
      ...{
        configTimeElapsed: (this.state.configTimeElapsed +=
          DeviceConfig.REFRESH_PERIOD_IN_SECONDS_INITIAL),
      },
    });

    if (
      !this.state.configured &&
      this.state.configTimeElapsed <= DeviceConfig.MAX_REFRESH_PERIOD_IN_MINUTES * 60 &&
      this.state.submitPressed &&
      (this.state.configTimeElapsed <= DeviceConfig.INITIAL_REFRESH_PERIOD_IN_SECONDS ||
        moment().diff(this.state.configLastRquestTime) >=
          DeviceConfig.REFRESH_PERIOD_IN_SECONDS_DEFAULT * 1000)
    ) {
      this.setState({
        ...{ configLastRquestTime: moment() },
      });

      if (!this.state.saveDisabled) {
        deviceConfigClient.getDeviceConfig(
          this.props.match.params.deviceNumber,
          (conf) => {
            this.setState({ ...{ configured: conf.configured } });
            if (conf.configured) {
              this.setState({
                ...{ saveDisabled: false, submitPressed: false },
              });
              clearInterval(this.timerId);
            }
          },
          this.errorCallback
        );
      }
    }
  }

  loadDeviceConfigFromServer() {
    timeZonesClient.getTimeZones((timeZones) => {
      devicesClient.getDevice(
        this.props.match.params.deviceNumber,
        (device) => {
          if (notSupportedModelsInThisPage.includes(device.model)) {
            this.setState({
              model: device.model,
            });
            return;
          }
          sensorsConfigClient.getPortsConfig(
            this.props.match.params.deviceNumber,
            (portsConfig) => {
              deviceConfigClient.getDeviceConfig(
                this.props.match.params.deviceNumber,
                (conf) => {
                  let transmissionPeriod;
                  let transmissionEvery = 1;

                  let recordPeriod = conf.recordPeriod;
                  let recordEvery = conf.every;
                  const countsThreshold = conf.countsThreshold;

                  transmissionEvery = recordEvery * countsThreshold;
                  transmissionPeriod = recordPeriod;

                  if (
                    transmissionPeriod === 'SECONDS' &&
                    transmissionEvery % 60 === 0 &&
                    transmissionEvery > 0
                  ) {
                    transmissionEvery /= 60;
                    transmissionPeriod = 'MINUTES';
                  }
                  if (
                    transmissionPeriod === 'SECONDS' &&
                    transmissionEvery % (60 * 60) === 0 &&
                    transmissionEvery > 0
                  ) {
                    transmissionEvery /= 60 * 60;
                    transmissionPeriod = 'HOUR';
                  }
                  if (
                    transmissionPeriod === 'SECONDS' &&
                    transmissionEvery % (60 * 60 * 24) === 0 &&
                    transmissionEvery > 0
                  ) {
                    transmissionEvery /= 60 * 60 * 24;
                    transmissionPeriod = 'DAYS';
                  }
                  if (
                    transmissionPeriod === 'MINUTES' &&
                    transmissionEvery % 60 === 0 &&
                    transmissionEvery > 0
                  ) {
                    transmissionEvery /= 60;
                    transmissionPeriod = 'HOURS';
                  }
                  if (
                    transmissionPeriod === 'MINUTES' &&
                    transmissionEvery % (24 * 60) === 0 &&
                    transmissionEvery > 0
                  ) {
                    transmissionEvery /= 24 * 60;
                    transmissionPeriod = 'DAYS';
                  }
                  if (
                    transmissionPeriod === 'HOURS' &&
                    transmissionEvery % 24 === 0 &&
                    transmissionEvery > 0
                  ) {
                    transmissionEvery /= 24;
                    transmissionPeriod = 'DAYS';
                  }

                  if (this.isFifoBasedDevice(device.swVersion)) {
                    // Fifo based devices have the following options for record:
                    // MINUTES: 1,2,3,5,10,15,20,30
                    // HOURS: 1,2,3,5,10,15,20,30
                    // If recordEvery that is divisible by 60 MINUTES, it should be converted to HOURS
                    if (recordPeriod === 'MINUTES' && recordEvery > 0 && recordEvery % 60 === 0) {
                      recordEvery /= 60;
                      recordPeriod = 'HOURS';
                    }
                  } else {
                    // NON Fifo based devices have the following options for record:
                    // MINUTES: 1,2,3,5,10,15,20,30,60
                    // recordPeriod should never be HOURS
                    if (recordPeriod === 'HOURS') {
                      recordPeriod = 'MINUTES';
                    }
                  }

                  let sensor3AlarmsConfig = this.getSensorsAlarmsConfig(conf, portsConfig, 2);
                  let sensor4AlarmsConfig = this.getSensorsAlarmsConfig(conf, portsConfig, 3);
                  let sensor9AlarmsConfig = this.getSensorsAlarmsConfig(conf, portsConfig, 8);
                  let sensor10AlarmsConfig = this.getSensorsAlarmsConfig(conf, portsConfig, 9);
                  let sensor11AlarmsConfig = this.getSensorsAlarmsConfig(conf, portsConfig, 10);
                  let sensor12AlarmsConfig = this.getSensorsAlarmsConfig(conf, portsConfig, 11);
                  let sensor13AlarmsConfig = this.getSensorsAlarmsConfig(conf, portsConfig, 12);
                  let sensor14AlarmsConfig = this.getSensorsAlarmsConfig(conf, portsConfig, 13);

                  this.setState({
                    model: device.model,
                    portsConfig: portsConfig,
                    swVersion: device.swVersion,
                    hwVersion: device.hwVersion,
                    sensor1Enabeled: conf.sensorConfigs[0].enabled,
                    sensor2Enabeled: conf.sensorConfigs[1].enabled,
                    sensor3Enabeled: conf.sensorConfigs[2].enabled,
                    sensor4Enabeled: conf.sensorConfigs[3].enabled,
                    sensor5Enabeled:
                      conf.sensorConfigs[4] == null ? false : conf.sensorConfigs[4].enabled,
                    sensor6Enabeled:
                      conf.sensorConfigs[5] == null ? false : conf.sensorConfigs[5].enabled,
                    sensor7Enabeled:
                      conf.sensorConfigs[6]?.enabled == null
                        ? false
                        : conf.sensorConfigs[6].enabled,
                    sensor8Enabeled:
                      conf.sensorConfigs[7]?.enabled == null
                        ? false
                        : conf.sensorConfigs[7].enabled,
                    sensor9Enabeled:
                      conf.sensorConfigs[8]?.enabled == null
                        ? false
                        : conf.sensorConfigs[8].enabled,
                    sensor10Enabeled:
                      conf.sensorConfigs[9]?.enabled == null
                        ? false
                        : conf.sensorConfigs[9].enabled,
                    sensor11Enabeled:
                      conf.sensorConfigs[10]?.enabled == null
                        ? false
                        : conf.sensorConfigs[10].enabled,
                    sensor12Enabeled:
                      conf.sensorConfigs[11]?.enabled == null
                        ? false
                        : conf.sensorConfigs[11].enabled,
                    sensor13Enabeled:
                      conf.sensorConfigs[12]?.enabled == null
                        ? false
                        : conf.sensorConfigs[12].enabled,
                    sensor14Enabeled:
                      conf.sensorConfigs[13]?.enabled == null
                        ? false
                        : conf.sensorConfigs[13].enabled,
                    sensor1Name:
                      portsConfig[0]?.sensor.name === null ||
                      portsConfig[0]?.sensor.name === undefined
                        ? this.props.t('pulse_sensor_1')
                        : portsConfig[0].sensor.name,
                    sensor2Name:
                      portsConfig[1]?.sensor.name === null ||
                      portsConfig[1]?.sensor.name === undefined
                        ? this.props.t('pulse_sensor_2')
                        : portsConfig[1].sensor.name,
                    sensor3Name:
                      portsConfig[2]?.sensor.name === null ||
                      portsConfig[2]?.sensor.name === undefined
                        ? this.props.t('analog_sensor_1')
                        : portsConfig[2].sensor.name,
                    sensor4Name:
                      portsConfig[3]?.sensor.name === null ||
                      portsConfig[3]?.sensor.name === undefined
                        ? this.props.t('analog_sensor_2')
                        : portsConfig[3].sensor.name,
                    sensor5Name:
                      portsConfig[4]?.sensor.name === null ||
                      portsConfig[4]?.sensor.name === undefined
                        ? this.props.t('on_off_sensor_1')
                        : portsConfig[4].sensor.name,
                    sensor6Name:
                      portsConfig[5]?.sensor.name === null ||
                      portsConfig[5]?.sensor.name === undefined
                        ? this.props.t('on_off_sensor_2')
                        : portsConfig[5].sensor.name,
                    sensor7Name:
                      portsConfig[6]?.sensor.name === null ||
                      portsConfig[6]?.sensor.name === undefined
                        ? this.props.t('on_off_sensor_3')
                        : portsConfig[6].sensor.name,
                    sensor8Name:
                      portsConfig[7]?.sensor.name === null ||
                      portsConfig[7]?.sensor.name === undefined
                        ? this.props.t('on_off_sensor_4')
                        : portsConfig[7].sensor.name,
                    sensor9Name:
                      portsConfig[8]?.sensor.name === null ||
                      portsConfig[8]?.sensor.name === undefined
                        ? this.props.t('analog_sensor_3')
                        : portsConfig[8].sensor.name,
                    sensor10Name:
                      portsConfig[9]?.sensor.name === null ||
                      portsConfig[9]?.sensor.name === undefined
                        ? this.props.t('analog_sensor_4')
                        : portsConfig[9].sensor.name,
                    sensor11Name:
                      portsConfig[10]?.sensor.name === undefined ||
                      portsConfig[10]?.sensor.name === null
                        ? this.props.t('analog_sensor_5')
                        : portsConfig[10].sensor.name,
                    sensor12Name:
                      portsConfig[11]?.sensor.name === undefined ||
                      portsConfig[11]?.sensor.name === null
                        ? this.props.t('analog_sensor_6')
                        : portsConfig[11].sensor.name,
                    sensor13Name:
                      portsConfig[12]?.sensor.name === undefined ||
                      portsConfig[12]?.sensor.name === null
                        ? this.props.t('analog_sensor_7')
                        : portsConfig[12].sensor.name,
                    sensor14Name:
                      portsConfig[13]?.sensor.name === undefined ||
                      portsConfig[13]?.sensor.name === null
                        ? this.props.t('analog_sensor_8')
                        : portsConfig[13].sensor.name,
                    notificationEmails5:
                      conf.sensorConfigs[4] && conf.sensorConfigs[4].parameters
                        ? conf.sensorConfigs[4].parameters.notif_emails
                        : undefined,

                    notificationEmails6:
                      conf.sensorConfigs[5] && conf.sensorConfigs[5].parameters
                        ? conf.sensorConfigs[5].parameters.notif_emails
                        : undefined,
                    notificationEmails7:
                      conf.sensorConfigs[6]?.parameters &&
                      conf.sensorConfigs[6]?.parameters?.notif_email
                        ? conf.sensorConfigs[6].parameters.notif_emails
                        : undefined,
                    notificationEmails8:
                      conf.sensorConfigs[7]?.parameters &&
                      conf.sensorConfigs[7]?.parameters?.notif_email !== null
                        ? conf.sensorConfigs[7].parameters.notif_emails
                        : undefined,
                    counter1: conf.sensorConfigs[0].parameters.initial_counter,
                    digits1: conf.sensorConfigs[0].parameters.digits || 4,
                    fraction1: conf.sensorConfigs[0].parameters.fraction || 2,
                    notificationEmails1: conf.sensorConfigs[0].parameters.notif_emails,
                    sensorType1: portsConfig[0].sensor['@type']
                      ? portsConfig[0].sensor['@type']
                      : 'generic_digital_sensor',
                    unitsType1: portsConfig[0].sensor.units
                      ? portsConfig[0].sensor.units
                      : portsConfig[0]?.sensor.allowedUnits[0],
                    allowedUnits1: portsConfig[0]?.sensor.allowedUnits,
                    pulseCoef1: portsConfig[0].sensor.pulseRate
                      ? portsConfig[0].sensor.pulseRate
                      : 0.01,
                    counter2: conf.sensorConfigs[1].parameters.initial_counter,
                    digits2: conf.sensorConfigs[1].parameters.digits || 4,
                    fraction2: conf.sensorConfigs[1].parameters.fraction || 2,
                    notificationEmails2: conf.sensorConfigs[1].parameters.notif_emails,
                    sensorType2: portsConfig[1].sensor['@type']
                      ? portsConfig[1].sensor['@type']
                      : 'generic_digital_sensor',
                    unitsType2: portsConfig[1].sensor.units
                      ? portsConfig[1].sensor.units
                      : portsConfig[1]?.sensor.allowedUnits[0],
                    allowedUnits2: portsConfig[1]?.sensor.allowedUnits,
                    pulseCoef2: portsConfig[1].sensor.pulseRate
                      ? portsConfig[1].sensor.pulseRate
                      : 0.01,
                    digits3: conf.sensorConfigs[2].parameters.digits || 4,
                    fraction3: conf.sensorConfigs[2].parameters.fraction || 2,
                    sensorType3: portsConfig[2].sensor['@type']
                      ? portsConfig[2].sensor['@type']
                      : 'generic_analog_sensor',
                    unitsType3: portsConfig[2].sensor.units
                      ? portsConfig[2].sensor.units
                      : portsConfig[2]?.sensor.allowedUnits[0],
                    allowedUnits3: portsConfig[2]?.sensor.allowedUnits,
                    sensorFormula3: portsConfig[2].sensor.formula
                      ? portsConfig[2].sensor.formula
                      : 'x',
                    notificationEmails3: conf.sensorConfigs[2].notif_emails,

                    sensor3VeryLowLevelAlarmEnabled: sensor3AlarmsConfig.veryLowLevelAlarmEnabled,
                    sensor3VeryLowLevelAlarm: sensor3AlarmsConfig.veryLowLevelAlarm,
                    sensor3LowLevelAlarmEnabled: sensor3AlarmsConfig.lowLevelAlarmEnabled,
                    sensor3LowLevelAlarm: sensor3AlarmsConfig.lowLevelAlarm,
                    sensor3HighLevelAlarmEnabled: sensor3AlarmsConfig.highLevelAlarmEnabled,
                    sensor3HighLevelAlarm: sensor3AlarmsConfig.highLevelAlarm,
                    sensor3VeryHighLevelAlarmEnabled: sensor3AlarmsConfig.veryHighLevelAlarmEnabled,
                    sensor3VeryHighLevelAlarm: sensor3AlarmsConfig.veryHighLevelAlarm,
                    sensor3VeryLowLevelMobileNotificationEnabled:
                      sensor3AlarmsConfig.veryLowLevelMobileNotificationEnabled,
                    sensor3VeryLowLevelEmailNotificationEnabled:
                      sensor3AlarmsConfig.veryLowLevelEmailNotificationEnabled,
                    sensor3LowLevelMobileNotificationEnabled:
                      sensor3AlarmsConfig.lowLevelMobileNotificationEnabled,
                    sensor3LowLevelEmailNotificationEnabled:
                      sensor3AlarmsConfig.lowLevelEmailNotificationEnabled,
                    sensor3HighLevelMobileNotificationEnabled:
                      sensor3AlarmsConfig.highLevelMobileNotificationEnabled,
                    sensor3HighLevelEmailNotificationEnabled:
                      sensor3AlarmsConfig.highLevelEmailNotificationEnabled,
                    sensor3VeryHighLevelMobileNotificationEnabled:
                      sensor3AlarmsConfig.veryHighLevelMobileNotificationEnabled,
                    sensor3VeryHighLevelEmailNotificationEnabled:
                      sensor3AlarmsConfig.veryHighLevelEmailNotificationEnabled,

                    digits4: conf.sensorConfigs[3].parameters.digits || 4,
                    fraction4: conf.sensorConfigs[3].parameters.fraction || 2,
                    sensorType4: portsConfig[3].sensor['@type']
                      ? portsConfig[3].sensor['@type']
                      : 'generic_analog_sensor',
                    unitsType4: portsConfig[3].sensor.units
                      ? portsConfig[3].sensor.units
                      : portsConfig[3]?.sensor.allowedUnits[0],
                    allowedUnits4: portsConfig[3]?.sensor.allowedUnits,
                    sensorFormula4: portsConfig[3].sensor.formula
                      ? portsConfig[3].sensor.formula
                      : 'x',
                    notificationEmails4: conf.sensorConfigs[3].notif_emails,

                    sensor4VeryLowLevelAlarmEnabled: sensor4AlarmsConfig.veryLowLevelAlarmEnabled,
                    sensor4VeryLowLevelAlarm: sensor4AlarmsConfig.veryLowLevelAlarm,
                    sensor4LowLevelAlarmEnabled: sensor4AlarmsConfig.lowLevelAlarmEnabled,
                    sensor4LowLevelAlarm: sensor4AlarmsConfig.lowLevelAlarm,
                    sensor4HighLevelAlarmEnabled: sensor4AlarmsConfig.highLevelAlarmEnabled,
                    sensor4HighLevelAlarm: sensor4AlarmsConfig.highLevelAlarm,
                    sensor4VeryHighLevelAlarmEnabled: sensor4AlarmsConfig.veryHighLevelAlarmEnabled,
                    sensor4VeryHighLevelAlarm: sensor4AlarmsConfig.veryHighLevelAlarm,

                    sensor4VeryLowLevelMobileNotificationEnabled:
                      sensor4AlarmsConfig.veryLowLevelMobileNotificationEnabled,
                    sensor4VeryLowLevelEmailNotificationEnabled:
                      sensor4AlarmsConfig.veryLowLevelEmailNotificationEnabled,
                    sensor4LowLevelMobileNotificationEnabled:
                      sensor4AlarmsConfig.lowLevelMobileNotificationEnabled,
                    sensor4LowLevelEmailNotificationEnabled:
                      sensor4AlarmsConfig.lowLevelEmailNotificationEnabled,
                    sensor4HighLevelMobileNotificationEnabled:
                      sensor4AlarmsConfig.highLevelMobileNotificationEnabled,
                    sensor4HighLevelEmailNotificationEnabled:
                      sensor4AlarmsConfig.highLevelEmailNotificationEnabled,
                    sensor4VeryHighLevelMobileNotificationEnabled:
                      sensor4AlarmsConfig.veryHighLevelMobileNotificationEnabled,
                    sensor4VeryHighLevelEmailNotificationEnabled:
                      sensor4AlarmsConfig.veryHighLevelEmailNotificationEnabled,

                    sensorType5: portsConfig[4]?.sensor['@type']
                      ? portsConfig[4].sensor['@type']
                      : 'on_off_input_sensor',
                    sensorType6: portsConfig[5]?.sensor['@type']
                      ? portsConfig[5].sensor['@type']
                      : 'on_off_input_sensor',
                    sensorType7: portsConfig[6]?.sensor['@type']
                      ? portsConfig[6].sensor['@type']
                      : 'on_off_output_sensor',
                    sensorType8: portsConfig[7]?.sensor['@type']
                      ? portsConfig[7].sensor['@type']
                      : 'on_off_output_sensor',
                    digits9: conf.sensorConfigs[8]?.parameters.digits || 4,
                    fraction9: conf.sensorConfigs[8]?.parameters.fraction || 2,
                    sensorType9: portsConfig[8]?.sensor['@type']
                      ? portsConfig[8].sensor['@type']
                      : 'generic_analog_sensor',
                    unitsType9: portsConfig[8]?.sensor.units
                      ? portsConfig[8].sensor.units
                      : portsConfig[8]?.sensor.allowedUnits[0],
                    allowedUnits9: portsConfig[8]?.sensor.allowedUnits,
                    sensorFormula9: portsConfig[8]?.sensor.formula
                      ? portsConfig[8].sensor.formula
                      : 'x',
                    notificationEmails9: conf.sensorConfigs[8]?.notif_emails,

                    sensor9VeryLowLevelAlarmEnabled: sensor9AlarmsConfig.veryLowLevelAlarmEnabled,
                    sensor9VeryLowLevelAlarm: sensor9AlarmsConfig.veryLowLevelAlarm,
                    sensor9LowLevelAlarmEnabled: sensor9AlarmsConfig.lowLevelAlarmEnabled,
                    sensor9LowLevelAlarm: sensor9AlarmsConfig.lowLevelAlarm,
                    sensor9HighLevelAlarmEnabled: sensor9AlarmsConfig.highLevelAlarmEnabled,
                    sensor9HighLevelAlarm: sensor9AlarmsConfig.highLevelAlarm,
                    sensor9VeryHighLevelAlarmEnabled: sensor9AlarmsConfig.veryHighLevelAlarmEnabled,
                    sensor9VeryHighLevelAlarm: sensor9AlarmsConfig.veryHighLevelAlarm,
                    sensor9VeryLowLevelMobileNotificationEnabled:
                      sensor9AlarmsConfig.veryLowLevelMobileNotificationEnabled,
                    sensor9VeryLowLevelEmailNotificationEnabled:
                      sensor9AlarmsConfig.veryLowLevelEmailNotificationEnabled,
                    sensor9LowLevelMobileNotificationEnabled:
                      sensor9AlarmsConfig.lowLevelMobileNotificationEnabled,
                    sensor9LowLevelEmailNotificationEnabled:
                      sensor9AlarmsConfig.lowLevelEmailNotificationEnabled,
                    sensor9HighLevelMobileNotificationEnabled:
                      sensor9AlarmsConfig.highLevelMobileNotificationEnabled,
                    sensor9HighLevelEmailNotificationEnabled:
                      sensor9AlarmsConfig.highLevelEmailNotificationEnabled,
                    sensor9VeryHighLevelMobileNotificationEnabled:
                      sensor9AlarmsConfig.veryHighLevelMobileNotificationEnabled,
                    sensor9VeryHighLevelEmailNotificationEnabled:
                      sensor9AlarmsConfig.veryHighLevelEmailNotificationEnabled,

                    digits10: conf.sensorConfigs[9]?.parameters.digits || 4,
                    fraction10: conf.sensorConfigs[9]?.parameters.fraction || 2,
                    sensorType10: portsConfig[9]?.sensor['@type']
                      ? portsConfig[9].sensor['@type']
                      : 'generic_analog_sensor',
                    unitsType10: portsConfig[9]?.sensor.units
                      ? portsConfig[9].sensor.units
                      : portsConfig[9]?.sensor.allowedUnits[0],
                    allowedUnits10: portsConfig[9]?.sensor.allowedUnits,
                    sensorFormula10: portsConfig[9]?.sensor.formula
                      ? portsConfig[9].sensor.formula
                      : 'x',
                    notificationEmails10: conf.sensorConfigs[9]?.notif_emails,

                    sensor10VeryLowLevelAlarmEnabled: sensor10AlarmsConfig.veryLowLevelAlarmEnabled,
                    sensor10VeryLowLevelAlarm: sensor10AlarmsConfig.veryLowLevelAlarm,
                    sensor10LowLevelAlarmEnabled: sensor10AlarmsConfig.lowLevelAlarmEnabled,
                    sensor10LowLevelAlarm: sensor10AlarmsConfig.lowLevelAlarm,
                    sensor10HighLevelAlarmEnabled: sensor10AlarmsConfig.highLevelAlarmEnabled,
                    sensor10HighLevelAlarm: sensor10AlarmsConfig.highLevelAlarm,
                    sensor10VeryHighLevelAlarmEnabled:
                      sensor10AlarmsConfig.veryHighLevelAlarmEnabled,
                    sensor10VeryHighLevelAlarm: sensor10AlarmsConfig.veryHighLevelAlarm,
                    sensor10VeryLowLevelMobileNotificationEnabled:
                      sensor10AlarmsConfig.veryLowLevelMobileNotificationEnabled,
                    sensor10VeryLowLevelEmailNotificationEnabled:
                      sensor10AlarmsConfig.veryLowLevelEmailNotificationEnabled,
                    sensor10LowLevelMobileNotificationEnabled:
                      sensor10AlarmsConfig.lowLevelMobileNotificationEnabled,
                    sensor10LowLevelEmailNotificationEnabled:
                      sensor10AlarmsConfig.lowLevelEmailNotificationEnabled,
                    sensor10HighLevelMobileNotificationEnabled:
                      sensor10AlarmsConfig.highLevelMobileNotificationEnabled,
                    sensor10HighLevelEmailNotificationEnabled:
                      sensor10AlarmsConfig.highLevelEmailNotificationEnabled,
                    sensor10VeryHighLevelMobileNotificationEnabled:
                      sensor10AlarmsConfig.veryHighLevelMobileNotificationEnabled,
                    sensor10VeryHighLevelEmailNotificationEnabled:
                      sensor10AlarmsConfig.veryHighLevelEmailNotificationEnabled,

                    digits11: conf.sensorConfigs[10]?.parameters.digits || 4,
                    fraction11: conf.sensorConfigs[10]?.parameters.fraction || 2,
                    sensorType11: portsConfig[10]?.sensor['@type']
                      ? portsConfig[10].sensor['@type']
                      : 'generic_analog_sensor',
                    unitsType11: portsConfig[10]?.sensor.units
                      ? portsConfig[10].sensor.units
                      : portsConfig[10]?.sensor.allowedUnits[0],
                    allowedUnits11: portsConfig[10]?.sensor.allowedUnits,
                    sensorFormula11: portsConfig[10]?.sensor.formula
                      ? portsConfig[10].sensor.formula
                      : 'x',
                    notificationEmails11: conf.sensorConfigs[10]?.notif_emails,

                    sensor11VeryLowLevelAlarmEnabled: sensor11AlarmsConfig.veryLowLevelAlarmEnabled,
                    sensor11VeryLowLevelAlarm: sensor11AlarmsConfig.veryLowLevelAlarm,
                    sensor11LowLevelAlarmEnabled: sensor11AlarmsConfig.lowLevelAlarmEnabled,
                    sensor11LowLevelAlarm: sensor11AlarmsConfig.lowLevelAlarm,
                    sensor11HighLevelAlarmEnabled: sensor11AlarmsConfig.highLevelAlarmEnabled,
                    sensor11HighLevelAlarm: sensor11AlarmsConfig.highLevelAlarm,
                    sensor11VeryHighLevelAlarmEnabled:
                      sensor11AlarmsConfig.veryHighLevelAlarmEnabled,
                    sensor11VeryHighLevelAlarm: sensor11AlarmsConfig.veryHighLevelAlarm,
                    sensor11VeryLowLevelMobileNotificationEnabled:
                      sensor11AlarmsConfig.veryLowLevelMobileNotificationEnabled,
                    sensor11VeryLowLevelEmailNotificationEnabled:
                      sensor11AlarmsConfig.veryLowLevelEmailNotificationEnabled,
                    sensor11LowLevelMobileNotificationEnabled:
                      sensor11AlarmsConfig.lowLevelMobileNotificationEnabled,
                    sensor11LowLevelEmailNotificationEnabled:
                      sensor11AlarmsConfig.lowLevelEmailNotificationEnabled,
                    sensor11HighLevelMobileNotificationEnabled:
                      sensor11AlarmsConfig.highLevelMobileNotificationEnabled,
                    sensor11HighLevelEmailNotificationEnabled:
                      sensor11AlarmsConfig.highLevelEmailNotificationEnabled,
                    sensor11VeryHighLevelMobileNotificationEnabled:
                      sensor11AlarmsConfig.veryHighLevelMobileNotificationEnabled,
                    sensor11VeryHighLevelEmailNotificationEnabled:
                      sensor11AlarmsConfig.veryHighLevelEmailNotificationEnabled,

                    digits12: conf.sensorConfigs[11]?.parameters.digits || 4,
                    fraction12: conf.sensorConfigs[11]?.parameters.fraction || 2,
                    sensorType12: portsConfig[11]?.sensor['@type']
                      ? portsConfig[11].sensor['@type']
                      : 'generic_analog_sensor',
                    unitsType12: portsConfig[11]?.sensor.units
                      ? portsConfig[11].sensor.units
                      : portsConfig[11]?.sensor.allowedUnits[0],
                    allowedUnits12: portsConfig[11]?.sensor.allowedUnits,
                    sensorFormula12: portsConfig[11]?.sensor.formula
                      ? portsConfig[11].sensor.formula
                      : 'x',
                    notificationEmails12: conf.sensorConfigs[11]?.notif_emails,

                    sensor12VeryLowLevelAlarmEnabled: sensor12AlarmsConfig.veryLowLevelAlarmEnabled,
                    sensor12VeryLowLevelAlarm: sensor12AlarmsConfig.veryLowLevelAlarm,
                    sensor12LowLevelAlarmEnabled: sensor12AlarmsConfig.lowLevelAlarmEnabled,
                    sensor12LowLevelAlarm: sensor12AlarmsConfig.lowLevelAlarm,
                    sensor12HighLevelAlarmEnabled: sensor12AlarmsConfig.highLevelAlarmEnabled,
                    sensor12HighLevelAlarm: sensor12AlarmsConfig.highLevelAlarm,
                    sensor12VeryHighLevelAlarmEnabled:
                      sensor12AlarmsConfig.veryHighLevelAlarmEnabled,
                    sensor12VeryHighLevelAlarm: sensor12AlarmsConfig.veryHighLevelAlarm,
                    sensor12VeryLowLevelMobileNotificationEnabled:
                      sensor12AlarmsConfig.veryLowLevelMobileNotificationEnabled,
                    sensor12VeryLowLevelEmailNotificationEnabled:
                      sensor12AlarmsConfig.veryLowLevelEmailNotificationEnabled,
                    sensor12LowLevelMobileNotificationEnabled:
                      sensor12AlarmsConfig.lowLevelMobileNotificationEnabled,
                    sensor12LowLevelEmailNotificationEnabled:
                      sensor12AlarmsConfig.lowLevelEmailNotificationEnabled,
                    sensor12HighLevelMobileNotificationEnabled:
                      sensor12AlarmsConfig.highLevelMobileNotificationEnabled,
                    sensor12HighLevelEmailNotificationEnabled:
                      sensor12AlarmsConfig.highLevelEmailNotificationEnabled,
                    sensor12VeryHighLevelMobileNotificationEnabled:
                      sensor12AlarmsConfig.veryHighLevelMobileNotificationEnabled,
                    sensor12VeryHighLevelEmailNotificationEnabled:
                      sensor12AlarmsConfig.veryHighLevelEmailNotificationEnabled,

                    digits13: conf.sensorConfigs[12]?.parameters.digits || 4,
                    fraction13: conf.sensorConfigs[12]?.parameters.fraction || 2,
                    sensorType13: portsConfig[12]?.sensor['@type']
                      ? portsConfig[12].sensor['@type']
                      : 'generic_analog_sensor',
                    unitsType13: portsConfig[12]?.sensor.units
                      ? portsConfig[12].sensor.units
                      : portsConfig[12]?.sensor.allowedUnits[0],
                    allowedUnits13: portsConfig[12]?.sensor.allowedUnits,
                    sensorFormula13: portsConfig[12]?.sensor.formula
                      ? portsConfig[12].sensor.formula
                      : 'x',
                    notificationEmails13: conf.sensorConfigs[12]?.notif_emails,

                    sensor13VeryLowLevelAlarmEnabled: sensor13AlarmsConfig.veryLowLevelAlarmEnabled,
                    sensor13VeryLowLevelAlarm: sensor13AlarmsConfig.veryLowLevelAlarm,
                    sensor13LowLevelAlarmEnabled: sensor13AlarmsConfig.lowLevelAlarmEnabled,
                    sensor13LowLevelAlarm: sensor13AlarmsConfig.lowLevelAlarm,
                    sensor13HighLevelAlarmEnabled: sensor13AlarmsConfig.highLevelAlarmEnabled,
                    sensor13HighLevelAlarm: sensor13AlarmsConfig.highLevelAlarm,
                    sensor13VeryHighLevelAlarmEnabled:
                      sensor13AlarmsConfig.veryHighLevelAlarmEnabled,
                    sensor13VeryHighLevelAlarm: sensor13AlarmsConfig.veryHighLevelAlarm,
                    sensor13VeryLowLevelMobileNotificationEnabled:
                      sensor13AlarmsConfig.veryLowLevelMobileNotificationEnabled,
                    sensor13VeryLowLevelEmailNotificationEnabled:
                      sensor13AlarmsConfig.veryLowLevelEmailNotificationEnabled,
                    sensor13LowLevelMobileNotificationEnabled:
                      sensor13AlarmsConfig.lowLevelMobileNotificationEnabled,
                    sensor13LowLevelEmailNotificationEnabled:
                      sensor13AlarmsConfig.lowLevelEmailNotificationEnabled,
                    sensor13HighLevelMobileNotificationEnabled:
                      sensor13AlarmsConfig.highLevelMobileNotificationEnabled,
                    sensor13HighLevelEmailNotificationEnabled:
                      sensor13AlarmsConfig.highLevelEmailNotificationEnabled,
                    sensor13VeryHighLevelMobileNotificationEnabled:
                      sensor13AlarmsConfig.veryHighLevelMobileNotificationEnabled,
                    sensor13VeryHighLevelEmailNotificationEnabled:
                      sensor13AlarmsConfig.veryHighLevelEmailNotificationEnabled,

                    digits14: conf.sensorConfigs[13]?.parameters.digits || 4,
                    fraction14: conf.sensorConfigs[13]?.parameters.fraction || 2,
                    sensorType14: portsConfig[13]?.sensor['@type']
                      ? portsConfig[13].sensor['@type']
                      : 'generic_analog_sensor',
                    unitsType14: portsConfig[13]?.sensor.units
                      ? portsConfig[13].sensor.units
                      : portsConfig[13]?.sensor.allowedUnits[0],
                    allowedUnits14: portsConfig[13]?.sensor.allowedUnits,
                    sensorFormula14: portsConfig[13]?.sensor.formula
                      ? portsConfig[13].sensor.formula
                      : 'x',
                    notificationEmails14: conf.sensorConfigs[13]?.notif_emails,

                    sensor14VeryLowLevelAlarmEnabled: sensor14AlarmsConfig.veryLowLevelAlarmEnabled,
                    sensor14VeryLowLevelAlarm: sensor14AlarmsConfig.veryLowLevelAlarm,
                    sensor14LowLevelAlarmEnabled: sensor14AlarmsConfig.lowLevelAlarmEnabled,
                    sensor14LowLevelAlarm: sensor14AlarmsConfig.lowLevelAlarm,
                    sensor14HighLevelAlarmEnabled: sensor14AlarmsConfig.highLevelAlarmEnabled,
                    sensor14HighLevelAlarm: sensor14AlarmsConfig.highLevelAlarm,
                    sensor14VeryHighLevelAlarmEnabled:
                      sensor14AlarmsConfig.veryHighLevelAlarmEnabled,
                    sensor14VeryHighLevelAlarm: sensor14AlarmsConfig.veryHighLevelAlarm,
                    sensor14VeryLowLevelMobileNotificationEnabled:
                      sensor14AlarmsConfig.veryLowLevelMobileNotificationEnabled,
                    sensor14VeryLowLevelEmailNotificationEnabled:
                      sensor14AlarmsConfig.veryLowLevelEmailNotificationEnabled,
                    sensor14LowLevelMobileNotificationEnabled:
                      sensor14AlarmsConfig.lowLevelMobileNotificationEnabled,
                    sensor14LowLevelEmailNotificationEnabled:
                      sensor14AlarmsConfig.lowLevelEmailNotificationEnabled,
                    sensor14HighLevelMobileNotificationEnabled:
                      sensor14AlarmsConfig.highLevelMobileNotificationEnabled,
                    sensor14HighLevelEmailNotificationEnabled:
                      sensor14AlarmsConfig.highLevelEmailNotificationEnabled,
                    sensor14VeryHighLevelMobileNotificationEnabled:
                      sensor14AlarmsConfig.veryHighLevelMobileNotificationEnabled,
                    sensor14VeryHighLevelEmailNotificationEnabled:
                      sensor14AlarmsConfig.veryHighLevelEmailNotificationEnabled,

                    date: conf.date === null ? '' : conf.date.replace('T', ' ').substring(0, 19),
                    countsThreshold: conf.countsThreshold,
                    missedTransmissionSeverity:
                      conf.missedTransmissionSeverity !== null &&
                      conf.missedTransmissionSeverity !== undefined
                        ? conf.missedTransmissionSeverity
                        : null,
                    digits: conf.digits,
                    transmissionPeriod: transmissionPeriod,
                    transmissionEvery: transmissionEvery,
                    recordPeriod: recordPeriod,
                    recordEvery: recordEvery,
                    configured: conf.configured,
                    deviceName: device.name,
                    customerInfo: device.customerInfo,
                    timeZone: conf.timeZone,
                    timeZones: timeZones,
                  });
                },
                this.errorCallback
              );
            },
            this.errorCallback
          );
        },
        this.errorCallback
      );
    }, this.errorCallback);
  }
}

export default withRouter(withTranslation()(withStyles(styles)(DeviceConfig)));
