import React, { FC, useState, ChangeEvent, useEffect, MouseEvent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { setCompanyLogo } from '../../actions';
import JwtValidator from '../../common/JwtValidator';
import { useToast } from '@thingslog/ui-components';
import { useDispatch, useSelector } from 'react-redux';
import { CompanyDto, UpdateCompanyDto } from '@thingslog/repositories';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Button, Divider, IconButton, InputBase, Tooltip } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { ReduxState } from '../../reducers';

const AccountInfo: FC<AccountInfoProps> = ({
  company,
  onUpdateModalClicked,
  onUpdateCompany,
}: AccountInfoProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { hasRole } = useMemo(() => new JwtValidator(), []);
  const { toast } = useToast();

  const companyLogoBlob = useSelector((state: ReduxState) => state.company.companyLogoBlob);
  const [logo, setLogo] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (companyLogoBlob) {
      const logo = URL.createObjectURL(companyLogoBlob);
      setLogo(logo);

      return () => {
        URL.revokeObjectURL(logo);
      };
    }
  }, [companyLogoBlob]);

  const handleLogoChange = async (
    event: ChangeEvent<HTMLInputElement> | undefined
  ): Promise<void> => {
    const file = event?.target?.files ? event.target.files[0] : null;
    if (!file) return;

    // Check if the file is an image
    const validImageTypes = ['image/jpeg', 'image/png'];
    if (!validImageTypes.includes(file.type)) {
      toast({ type: 'error', message: t('profile_page_logo_file_format_error'), duration: 5000 });
      return;
    }

    // Check the size of the file (1MB = 1,048,576 bytes)
    const maxFileSize = 1048576; // 1MB in bytes
    if (file.size > maxFileSize) {
      toast({ type: 'error', message: t('profile_page_logo_size_error'), duration: 5000 });
      return;
    }

    const blob = new Blob([file], { type: file.type });

    const base64EncodedBlob = await new Promise<string>(
      (resolve: (value: string) => void, reject: (reason?: any) => void) => {
        const reader = new FileReader();
        reader.onload = (): void => {
          const base64Data = (reader.result as string).split(',')[1];
          resolve(base64Data);
        };
        reader.onerror = (error: ProgressEvent<FileReader>): void => reject(error);
        reader.readAsDataURL(blob);
      }
    );

    // PUT request
    let updatedCompany = company;
    if (updatedCompany && updatedCompany.companyInfo) {
      updatedCompany.companyInfo.logo = base64EncodedBlob;
      try {
        onUpdateCompany(updatedCompany);
        dispatch(setCompanyLogo(blob));
      } catch (error) {
        alert(error);
      }
    }
  };

  return (
    <div className="w-full px-3 py-2">
      <div className="flex flex-col lg:flex-row text-left text-lg justify-between">
        <div className="flex flex-col w-full lg:w-1/2">
          <div className="flex flex-row justify-between pb-5">
            <h4 className="text-left font-bold">{t('company_manager_account_info')}</h4>
            <Button onClick={onUpdateModalClicked} size="small" variant="contained">
              {t('profile_page_edit')}
            </Button>
          </div>
          <div className="grid grid-cols-12 lg:w-full mb-10">
            <div className="col-span-5 font-bold">
              <div className="col-span-1">{t('profile_page_country')}</div>
              <div className="col-span-1">{t('profile_page_timezone')}</div>
              <div className="col-span-1">{t('profile_page_address')}</div>
              <div className="col-span-1">{t('profile_page_city')}</div>
              <div className="col-span-1">{t('profile_page_municipality')}</div>
              <div className="col-span-1">{t('profile_page_area')}</div>
              <div className="col-span-1">{t('profile_page_zip_code')}</div>
              <div className="col-span-1">{t('profile_page_vat_number')}</div>
            </div>
            <div className="col-span-7 text-right">
              <div className="col-span-1">{company?.companyInfo?.country || '-'}</div>
              <div className="col-span-1">{company?.zoneId || '-'}</div>
              <div className="col-span-1">{company?.companyInfo?.address || '-'}</div>
              <div className="col-span-1">{company?.companyInfo?.city || '-'}</div>
              <div className="col-span-1">{company?.companyInfo?.municipality || '-'}</div>
              <div className="col-span-1">{company?.companyInfo?.area || '-'}</div>
              <div className="col-span-1">{company?.companyInfo?.zipCode || '-'}</div>
              <div className="col-span-1">{company?.companyInfo?.vatNumber || '-'}</div>
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-center items-center w-1/2">
          <img
            src={logo}
            alt="image"
            className="max-h-[90px] max-w-[90px] sm:max-h-[120px] sm:max-w-[120px]"
          />
          {(hasRole('ROLE_ADMIN') || hasRole('ROLE_SUPER_ADMIN')) && (
            <>
              <Button
                className=""
                variant="contained"
                component="label"
                startIcon={<CloudUploadIcon />}
              >
                <span>{t('profile_page_change_logo')}</span>
                <input className="hidden" type="file" onChange={handleLogoChange} />
              </Button>
              <div className="flex items-center gap-x-2 mt-4">
                <ErrorOutlineIcon className="text-red-500" />
                <div className="text-red-500">{t('profile_page_logo_formats_message')}</div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default AccountInfo;

interface AccountInfoProps {
  onUpdateCompany: (updateCompany: UpdateCompanyDto) => void;
  onUpdateModalClicked: () => void;
  company: CompanyDto | null;
}
