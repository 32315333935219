import React, { FC, useMemo, useState, ReactNode } from 'react';
import UserInfo from './UserInfo';
import LicenseInfo from './LicenseInfo';
import Header from '../../components/header';
import { Divider, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';
import {
  companyQueryClient,
  countriesQueryClient,
  licenseQueryClient,
  timezonesQueryClient,
  userQueryClient,
  versionInfoQueryClient,
} from '../../clients/ReactQueryClients/ReactQueryClients';
import { useModal, useToast } from '@thingslog/ui-components';
import {
  CompanyLicenseDto,
  LicenseDto,
  LicenseType,
  UserDto,
  DeviceLimits,
  UpdateCompanyDto,
} from '@thingslog/repositories';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../reducers';
import JwtValidator from '../../common/JwtValidator';
import { format } from 'date-fns';
import LicenseValidator from '../../common/LicenseValidator';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import ReadingsPieChart from './widgets/ReadingsPieChart';
import clsx from 'clsx';
import AccountInfo from './AccountInfo';
import UpdateCompanyModal from '../../components/UpdateCompanyModal/UpdateCompanyModal';
import { useQueryClient } from '@tanstack/react-query';
import { QueryKeys } from '@thingslog/queries';
import { global_env } from '../../App';

const ProfileV2: FC<ProfileV2Props> = (props: ProfileV2Props) => {
  const { t } = useTranslation();
  const { toast } = useToast();
  const { modal, closeModal } = useModal();

  const { useUser } = useMemo(() => userQueryClient, []);
  const { useGetCompany, useUpdateCompany } = useMemo(() => companyQueryClient, []);
  const { useLicenses, useChangeLicenseActivity, useCreateLicense, useDeviceLimits } = useMemo(
    () => licenseQueryClient,
    []
  );
  const queryClient = useQueryClient();
  const { useGetCountries } = useMemo(() => countriesQueryClient, []);
  const { useGetTimezones } = useMemo(() => timezonesQueryClient, []);
  const { useVersionInfo } = useMemo(() => versionInfoQueryClient, []);
  const jwtValidator = useMemo(() => new JwtValidator(), []);
  const username = useSelector((state: ReduxState) => state.auth.username);
  const company = useSelector((state: ReduxState) => state.company);

  const [userInfo, setUserInfo] = useState<UserDto | null>(null);
  const [currentlyLinkedDevices, setCurrentlyLinkedDevices] = useState<number | null>(null);
  const [maxDataLoggers, setMaxDataLoggers] = useState<number | null>(null);

  const getCompanyId = (): number | null => {
    if (company.id) {
      return company.id;
    }
    if (jwtValidator.decodedToken?.companyId) {
      return Number(jwtValidator.decodedToken.companyId);
    }

    return null;
  };

  // Call the getCompanyId function (will re-render if company or jwtValidator changes)
  const [companyId, setCompanyId] = useState<number | null>(getCompanyId());

  const getCompanyQuery = useGetCompany(companyId!, {
    enabled: companyId !== null,
  });

  const {
    data: licenses,
    isLoading,
    refetch: fetchLicenses,
  } = useLicenses(companyId, undefined, undefined, {
    refetchOnWindowFocus: false,
    enabled: companyId !== null,
  });

  const { data: versionInfo } = useVersionInfo({ refetchOnWindowFocus: false });

  const isPerDeviceLicenseAvailable = useMemo(() => {
    return (
      licenses?.some((license: LicenseDto) => license['@type'] === LicenseType.PER_DEVICE) ?? false
    );
  }, [licenses]);

  useDeviceLimits(companyId!, {
    refetchOnWindowFocus: false,
    enabled: companyId !== null && isPerDeviceLicenseAvailable,
    onSuccess: (data: DeviceLimits) => {
      if (data['@type'] === 'DEVICE_LIMITS') {
        if (data.linkedDevices !== null && data.maxDataLoggers !== null) {
          setCurrentlyLinkedDevices(data.linkedDevices);
          setMaxDataLoggers(data.maxDataLoggers);
        }
      }
    },
  });

  const {} = useUser(username || '', {
    enabled: username ? true : false,
    onSuccess: (data: UserDto) => {
      setUserInfo(data);
    },
    refetchOnWindowFocus: false,
  });

  const { mutate: updateLicenseActivityStatus } = useChangeLicenseActivity({
    onSuccess: () => {
      toast({ message: t('profile_page_deactivate_license_success_message'), type: 'success' });
      fetchLicenses();
    },
    onError: () => {
      toast({ message: t('profile_page_deactivate_license_error_message'), type: 'error' });
    },
  });

  const { mutate: addLicense } = useCreateLicense({
    onSuccess: () => {
      toast({ message: t('license_add_success_message'), type: 'success' });
      fetchLicenses();
      closeModal();
    },
    onError: () => {
      toast({ message: t('license_add_error_message'), type: 'error' });
    },
  });

  const updateCompanyQuery = useUpdateCompany({
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeys.GetCompany]);
    },
  });

  const timezonesQuery = useGetTimezones({
    onError: () => {
      toast({ message: t('company_manager_timezones_error'), type: 'error' });
    },
    refetchOnWindowFocus: false,
  });

  const countriesQuery = useGetCountries({
    onError: () => {
      toast({ message: t('company_manager_countries_error'), type: 'error' });
    },
    refetchOnWindowFocus: false,
  });

  const renderTranslatedLicenseType = (licenseType: LicenseType): string => {
    switch (licenseType) {
      case LicenseType.PER_DEVICE:
        return t('profile_page_license_per_device');
      case LicenseType.PER_READING:
        return t('profile_page_license_per_reading');
      case LicenseType.PER_SENSOR:
        return t('profile_page_license_per_sensor');
      case LicenseType.PER_POC:
        return t('profile_page_license_per_poc');
      case LicenseType.PER_REMOTE_NODE:
        return t('profile_page_license_per_remote_node');
      case LicenseType.COMPANY_PARTIAL_LICENSE:
        return t('profile_page_license_partial');
      default:
        return '';
    }
  };

  const renderLicensePeriod = (license: LicenseDto): string => {
    if (LicenseValidator.isCompanyNonPartialLicense(license)) {
      return `(${format(
        new Date((license as CompanyLicenseDto).validityPeriodFrom),
        'dd.MM.yyyy'
      )} / ${format(new Date((license as CompanyLicenseDto).validityPeriodTo), 'dd.MM.yyyy')})`;
    }
    return '';
  };

  const renderLicenseValidityIcon = (license: LicenseDto): ReactNode => {
    if (license.isValid) {
      return <CheckCircleIcon className="text-green-500" />;
    } else {
      return <CancelIcon className="text-red-500" />;
    }
  };

  const handleOnUpdateCompany = async (company: UpdateCompanyDto): Promise<void> => {
    updateCompanyQuery.mutate(company, {
      onSuccess: () => {
        toast({ message: t('profile_page_update_account_success'), type: 'success' });
      },
    });
    closeModal();
    queryClient.invalidateQueries([QueryKeys.GetCompany]);
  };

  const handleUpdateModalClicked = (): void => {
    if (getCompanyQuery.data) {
      modal({
        title: t('company_manager_update_company'),
        content: (
          <div className="md:w-[400px] overflow-y-auto max-h-[80vh]">
            <UpdateCompanyModal
              timezones={timezonesQuery.data || new Set()}
              countriesListDto={countriesQuery.data || null}
              company={getCompanyQuery.data}
              updateCompany={handleOnUpdateCompany}
              setDisableAccountRefCallback={(
                accountRefUpdater: (isDisabled: boolean) => void
              ): void => {
                accountRefUpdater(
                  licenses?.some(
                    (license: LicenseDto) => license['@type'] === LicenseType.PER_POC
                  ) ?? false
                );
              }}
            />
          </div>
        ),
      });
    }
  };

  return (
    <Header>
      <UserInfo userInfo={userInfo} />
      <Divider />
      <AccountInfo
        company={getCompanyQuery.data || null}
        onUpdateCompany={handleOnUpdateCompany}
        onUpdateModalClicked={handleUpdateModalClicked}
      />
      <Divider />
      {(jwtValidator.hasRole('ROLE_ADMIN') || jwtValidator.hasRole('ROLE_SUPER_ADMIN')) && (
        <div className="flex flex-col lg:flex-row w-full pl-5 overflow-hidden">
          <div
            className={clsx('w-full', {
              'lg:w-3/5': isPerDeviceLicenseAvailable,
              'lg:w-full': !isPerDeviceLicenseAvailable,
            })}
          >
            <h4 className="text-left font-bold pt-5">{t('profile_page_license_info')}</h4>
            {licenses?.map((license: LicenseDto) => (
              <Accordion className="mt-5 w-full">
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <h4 className="flex items-center space-x-4">
                    <div>
                      {t('profile_page_license_label')}
                      {renderTranslatedLicenseType(license['@type'])}
                    </div>
                    <div>{renderLicensePeriod(license)}</div>
                    <div>{renderLicenseValidityIcon(license)}</div>
                  </h4>
                </AccordionSummary>
                <AccordionDetails>
                  <LicenseInfo
                    updateLicenseActivityStatus={updateLicenseActivityStatus}
                    addLicense={addLicense}
                    license={license}
                    licenses={licenses}
                    deviceCountPerCompany={currentlyLinkedDevices || 0}
                    licenseType={license['@type']}
                    translatedLicenseType={renderTranslatedLicenseType(license['@type'])}
                  />
                </AccordionDetails>
                <Divider />
              </Accordion>
            ))}
          </div>
          {licenses?.some(
            (license: LicenseDto) =>
              license['@type'] === LicenseType.PER_DEVICE &&
              maxDataLoggers !== null &&
              currentlyLinkedDevices !== null
          ) && (
            <div className="w-full lg:w-2/5">
              {maxDataLoggers !== null && currentlyLinkedDevices !== null && (
                <ReadingsPieChart
                  licenseType={LicenseType.PER_DEVICE}
                  usedCount={currentlyLinkedDevices}
                  leftCount={maxDataLoggers - currentlyLinkedDevices}
                />
              )}
            </div>
          )}
        </div>
      )}
      <Divider />
      <h5 className="text-left font-bold pt-5">{t('profile_page_version_info')}</h5>
      <div className="font-bold text-left ml-10">
        <div>Backend: {versionInfo && <span>{versionInfo.version}</span>}</div>
        <div>Web UI: {global_env.version}</div>
      </div>
    </Header>
  );
};

interface ProfileV2Props {}

export default ProfileV2;
