import Axios from '../clients/Axios/Axios';
import { setDevices } from '../state_management/actions/DevicesActionCreator';
import {
  AUTH_USER,
  SIGNED_UP,
  AUTH_ERROR,
  COMPANY_SELECTED,
  COMPANY_LOGO_SET,
  DEV_SENSOR_INDEX_CHANGED,
  DEV_SELECTED,
  DEV_SELECTED_CHANGED,
  FILTER_CHANGED,
  DEV_OPEN_CLOSE_MENU,
  DEV_OPEN_CLOSE_DEV_MENU,
  PERIOD_FROM_DATE_CHANGED,
  PERIOD_TO_DATE_CHANGED,
  PERIOD_AVG_FROM_DATE_CHANGED,
  PERIOD_AVG_TO_DATE_CHANGED,
  FEATURE_FLAGS_UPDATE,
  SET_SOLUTION_SETTINGS,
  SIGN_OUT,
  SET_SELECTED_PAGE,
  LANGUAGE_CHANGED,
  AUTH_RESTRICT_USER,
  SET_COMPANY_LICENSES,
  SET_LICENSES_ACCESS,
} from './types';
import jwtDecode from 'jwt-decode';

export function signinUser({ username, password, recaptchaToken }) {
  return function (dispatch) {
    const request = Axios.post(`/api/rlogin`, { username, password, recaptchaToken });
    request
      .then((response) => {
        let token = response.headers.authorization;
        localStorage.setItem('token', token);
        let decoded = jwtDecode(token);
        localStorage.setItem('decoded_token', JSON.stringify(decoded));
        dispatch({
          type: AUTH_USER,
          payload: {
            username: decoded.sub,
            roles: decoded.roles,
            companyName: decoded.companyName,
            language: decoded.language,
            email: decoded.email,
          },
        });
      })

      .catch(({ response }) => {
        if (response.status === 403) {
          dispatch(authError('Invalid username or password'));
        }
      });
  };
}

export function signoutUser() {
  localStorage.removeItem('useOldMenu');
  localStorage.removeItem('token');
  localStorage.removeItem('decoded_token');
  localStorage.removeItem('selectedCompany');
  delete Axios.defaults.headers.common['Authorization'];
  return function (dispatch) {
    dispatch({ type: SIGN_OUT });
    dispatch(setDevices([]));
  };
}

export function signupUser({
  firstName,
  lastName,
  email,
  username,
  password,
  passwordConfirmation,
  companyName,
  roles,
  isCompany,
  isDashboardEnabled,
}) {
  return function (dispatch) {
    let companyDto = {
      firstName,
      lastName,
      email,
      username,
      password,
      passwordConfirmation,
      roles,
      dashboardEnabled: isDashboardEnabled,
    };

    if (isCompany && companyName) {
      companyDto.companyName = companyName;
    }

    Axios.post(`/api/signup`, companyDto)
      .then((response) => {
        dispatch({ type: SIGNED_UP, payload: { username: username } });
        //  localStorage.setItem('token', response.headers.authorization)
        localStorage.removeItem('token');
        localStorage.removeItem('decoded_token');
      })
      .catch(({ response }) => {
        if (response.data.message) {
          dispatch(authError(response.data.message));
        } else {
          dispatch(authError('Internal error'));
        }
      });
  };
}

export function authError(error) {
  return {
    type: AUTH_ERROR,
    payload: error,
  };
}

export function languageChanged(language) {
  return function (dispatch) {
    dispatch({
      type: LANGUAGE_CHANGED,
      payload: language,
    });
  };
}

export function setCompanyLicenses(licenses) {
  return function (dispatch) {
    dispatch({ type: SET_COMPANY_LICENSES, payload: licenses });
  };
}

export function setLicensesAccess(hasLicensesAccess) {
  return function (dispatch) {
    dispatch({ type: SET_LICENSES_ACCESS, payload: hasLicensesAccess });
  };
}

export function sensorIndexChanged(sensorIndex) {
  return function (dispatch) {
    dispatch({
      type: DEV_SENSOR_INDEX_CHANGED,
      sensorIndex: sensorIndex,
    });
  };
}

export function devOpenCloseDevMenu(menuOpen, devMenu) {
  return function (dispatch) {
    dispatch({
      type: DEV_OPEN_CLOSE_DEV_MENU,
      menuOpen: menuOpen,
      devMenu: devMenu,
    });
  };
}

export function devSelected(devices, selectMode) {
  return function (dispatch) {
    dispatch({
      type: DEV_SELECTED,
      devices: devices,
      selectMode: selectMode,
      menuOpen: selectMode === 'radio' && devices.length > 0,
      devMenu: devices.length > 0,
    });
  };
}

export function selectedDeviceChanged(selectedDevice) {
  return {
    type: DEV_SELECTED_CHANGED,
    selectedDevice: selectedDevice,
  };
}

export function filterChanged(dataGridFilters) {
  return function (dispatch) {
    dispatch({
      type: FILTER_CHANGED,
      dataGridFilters: dataGridFilters,
    });
  };
}

export function companySelected(company, selectMode) {
  return function (dispatch) {
    dispatch({
      type: COMPANY_SELECTED,
      company: company,
    });
  };
}

export function periodFromDateChanged(fromDate) {
  return function (dispatch) {
    dispatch({
      type: PERIOD_FROM_DATE_CHANGED,
      fromDate: fromDate,
    });
  };
}

export function periodToDateChanged(toDate) {
  return function (dispatch) {
    dispatch({
      type: PERIOD_TO_DATE_CHANGED,
      toDate: toDate,
    });
  };
}

export function periodAvgFromDateChanged(avgFromDate) {
  return function (dispatch) {
    dispatch({
      type: PERIOD_AVG_FROM_DATE_CHANGED,
      avgFromDate: avgFromDate,
    });
  };
}

export function periodAvgToDateChanged(avgToDate) {
  return function (dispatch) {
    dispatch({
      type: PERIOD_AVG_TO_DATE_CHANGED,
      avgToDate: avgToDate,
    });
  };
}

export function devOpenCloseMenu(menuOpen) {
  return function (dispatch) {
    dispatch({
      type: DEV_OPEN_CLOSE_MENU,
      menuOpen: menuOpen,
    });
  };
}

export function featureFlagsUpdate(featureFlags) {
  return function (dispatch) {
    dispatch({ type: FEATURE_FLAGS_UPDATE, featureFlags: featureFlags });
  };
}

export function setSolutionSettings(settings) {
  return function (dispatch) {
    dispatch({ type: SET_SOLUTION_SETTINGS, settings: settings });
  };
}

export function setCompanyLogo(companyLogoBlob) {
  return function (dispatch) {
    dispatch({ type: COMPANY_LOGO_SET, companyLogoBlob: companyLogoBlob });
  };
}

export function setSelectedPage(pageId) {
  return function (dispatch) {
    dispatch({ type: SET_SELECTED_PAGE, pageId: pageId });
  };
}

export function restrictUser() {
  return function (dispatch) {
    dispatch({ type: AUTH_RESTRICT_USER });
  };
}
