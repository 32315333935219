import React, { FC } from 'react';

import { FormulaValidationResult, SensorType } from '@thingslog/repositories';
import GenericAnalogConfig from './sensorConfigFields/GenericAnalogConfig';
import ModbusGenericAnalogConfig from './sensorConfigFields/ModbusGenericAnalogConfig';

const SensorConfigurationMapper: FC<SensorConfigurationMapperProps> = ({
  sensorType,
  index,
  validateFormula,
}: SensorConfigurationMapperProps) => {
  switch (sensorType) {
    case 'generic_analog_sensor':
      return <GenericAnalogConfig index={index} validateFormula={validateFormula} />;
    case 'modbus_generic_analog_sensor':
      return <ModbusGenericAnalogConfig index={index} validateFormula={validateFormula} />;
    default:
      return <></>;
  }
};

interface SensorConfigurationMapperProps {
  sensorType: SensorType;
  index: number;
  validateFormula: (formula: string) => Promise<FormulaValidationResult>;
}

export default SensorConfigurationMapper;
