import React, { Component } from 'react';
import 'react-select/dist/react-select.css';
import UserClient from './UserClient';
import Snackbar from '@mui/material/Snackbar';
import Button from '@mui/material/Button';
import Header from './components/header';
import { withRouter } from './common/withRouter';
import Grid from '@mui/material/Grid/Grid';
import Typography from '@mui/material/Typography/Typography';
import withStyles from '@mui/styles/withStyles';
import connect from 'react-redux/es/connect/connect';
import * as actions from './actions';
import { withTranslation } from 'react-i18next';
import GoogleAnalyticsService from './common/GoogleAnalyticsService';
import { GaEventCategory } from './common/GaEventCategory';
import { GaEventAction } from './common/GaEventAction';
import { FormControlLabel, MenuItem, Select, FormControl, InputLabel } from '@mui/material';

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(2),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: 200,
    maxWidth: 300,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
});

class Language extends Component {
  constructor(props) {
    super(props);
    this.state = { language: '', open: false };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleConfigSaved = this.handleConfigSaved.bind(this);
    this.handleRequestClose = this.handleRequestClose.bind(this);
    this.errorCallback = this.errorCallback.bind(this);
  }

  errorCallback(error) {
    if (error.response === undefined) {
      throw error;
    }
    if (error.response.status === 401 || error.response.status === 403) {
      this.props.history.push('/app/signout');
    } else if (error.response.status === 400) {
      alert('Error: ' + error.response.data.message);
      this.setState({ saveDisabled: false });
    } else {
      alert('Error: ' + error.response.data);
      this.setState({ saveDisabled: false });
    }
  }
  handleLanguageChange = (event) => {
    this.setState({ language: event.target.value });
    this.props.languageChanged(event.target.value);
  };
  handleRequestClose = () => {
    this.setState({ open: false });
  };
  handleSubmit(event) {
    GoogleAnalyticsService.triggerEvent(
      GaEventCategory.PROFILE_PAGE,
      GaEventAction.CHANGE_LANGUAGE_SAVE
    );
    this.setState({ saveDisabled: true });
    UserClient.putUser(
      this.props.auth.username,
      { language: this.state.language },
      this.handleConfigSaved,
      this.errorCallback
    );
    event.preventDefault();
  }

  handleConfigSaved() {
    this.setState({ open: true, saveDisabled: false });
    const decoded_token = localStorage.getItem('decoded_token');
    let decoded = JSON.parse(decoded_token);
    decoded.language = this.state.language;
    localStorage.setItem('decoded_token', JSON.stringify(decoded));
    this.props.i18n.changeLanguage(this.state.language);
  }

  render() {
    const wellStyles = { maxWidth: 400, margin: '0 auto 10px' };

    const { classes, t } = this.props;
    return (
      <Header>
        <div className="well" style={wellStyles}>
          <form className={classes.root} autoComplete="off">
            <Grid container justifyContent={'center'} direction="column" spacing={4}>
              <Grid item>
                <Typography variant="h5" component="h5" paragraph>
                  {t('change_language')}
                </Typography>
              </Grid>
              <Grid item>
                <FormControl fullWidth>
                  <InputLabel>{t('change_language')}</InputLabel>
                  <Select
                    size="small"
                    value={this.state.language}
                    label={t('change_language')}
                    fullWidth
                    placeholder="language"
                    onChange={this.handleLanguageChange.bind(this)}
                  >
                    <MenuItem value={'en'}>English</MenuItem>
                    <MenuItem value={'bg'}>Български</MenuItem>
                    <MenuItem value={'gr'}>Ελληνικά</MenuItem>
                    <MenuItem value={'de'}>Deutsch</MenuItem>
                    <MenuItem value={'es'}>Español</MenuItem>
                    <MenuItem value={'ru'}>Русский</MenuItem>
                    <MenuItem value={'ptBr'}>Português</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid container alignItems="flex-end" justifyContent={'center'} spacing={4}>
                <Grid item>
                  <Button
                    variant="contained"
                    className={classes.margin}
                    onClick={this.handleSubmit}
                    disabled={this.state.saveDisabled}
                  >
                    {this.props.t('button_save')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
          <Snackbar
            open={this.state.open}
            message={t('language_changed_message')}
            autoHideDuration={4000}
            onClose={this.handleRequestClose}
          />
        </div>
      </Header>
    );
  }

  componentDidMount() {
    this.setState({ language: this.props.auth.language });
  }

  componentWillUnmount() {}
}
function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default withRouter(
  withStyles(styles)(connect(mapStateToProps, actions)(withTranslation()(Language)))
);
