import React, { FC } from 'react';
import DevicesTable from './DevicesTable';
import { useMemo, useEffect, useState } from 'react';
import { devicesQueryClient } from '../../clients/ReactQueryClients/ReactQueryClients';
import { useSelector, useDispatch } from 'react-redux';
import { ReduxState } from '../../reducers';
import Header from '../../components/header';
import { Device, DeviceFilterAttributes } from '@thingslog/repositories';
import { Pagination } from '@thingslog/repositories';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { GridColumnVisibilityModel, GridRowId, useToast } from '@thingslog/ui-components';
import * as actions from './../../actions/index';
import { AxiosError } from 'axios';
import { GaEventAction } from '../../common/GaEventAction';
import { GaEventCategory } from '../../common/GaEventCategory';
import GoogleAnalyticsService from '../../common/GoogleAnalyticsService';
import JwtValidator from '../../common/JwtValidator';

const DevicesPage: FC<DevicesPageProps> = () => {
  const jwtValidator = new JwtValidator();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { toast } = useToast();
  const navigate = useNavigate();

  const { useDevicesData } = useMemo(() => devicesQueryClient, []);

  const [devices, setDevices] = useState<Device[]>([]);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const [filterValue, setFilterValue] = useState<string>('');
  const [attributes, setAttributes] = useState<DeviceFilterAttributes | null>(null);
  const [queryFilterValue, setQueryFilterValue] = useState<string | null>(null);
  const [queryAttributes, setQueryAttributes] = useState<DeviceFilterAttributes | null>(null);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({});

  const companyId = useSelector((state: ReduxState) => state.company.id);
  const selectedDevice: string[] = useSelector((state: ReduxState) => state.dev.devices);

  useEffect(() => {
    const timeoutFilterChange = setTimeout(() => setQueryFilterValue(filterValue), 1000);
    return (): void => clearTimeout(timeoutFilterChange);
  }, [filterValue]);

  useEffect(() => {
    const timeoutAttributesChange = setTimeout(() => setQueryAttributes(attributes), 1000);
    return (): void => clearTimeout(timeoutAttributesChange);
  }, [attributes]);

  const {
    isSuccess: isDevicesSuccessful,
    data: devicesResp,
    isFetching: isDevicesFetching,
  } = useDevicesData(companyId, page, size, queryAttributes, queryFilterValue, {
    onSuccess: (data: Pagination<Device>) => {
      setDevices(data.content);
      if (
        data.totalElements === 0 &&
        !queryFilterValue &&
        !queryAttributes &&
        (jwtValidator.hasRole('ROLE_SUPER_ADMIN') || jwtValidator.hasRole('ROLE_ADMIN'))
      ) {
        navigate('/app/LinkDevices');
      }
    },
    onError: (error: AxiosError) => {
      if (error.response?.status === 401 || error.response?.status === 403) {
        navigate('/app/signout');
      }
      toast({
        type: 'error',
        message: t('devices_table_error'),
        duration: 5000,
      });
    },
  });

  return (
    <Header>
      <DevicesTable
        loading={isDevicesFetching && devices.length === 0}
        pageSize={size}
        filterMode="server"
        devices={devices}
        rowsPerPageOptions={[10, 25, 50]}
        rowCount={isDevicesSuccessful ? devicesResp.totalElements : 0}
        onPageChange={(page: number): void => {
          setPage(page);
        }}
        onPageSizeChange={(pageSize: number): void => {
          setSize(pageSize);
          setPage(0);
        }}
        autoHeight={true}
        page={page}
        paginationMode="server"
        onFilterRemovedCallback={(): void => setPage(0)}
        disableSelectionOnClick={false}
        checkboxSelection
        headerHeight={120}
        disableColumnMenu={true}
        disableColumnFilter={true}
        setAttributes={setAttributes}
        setFilterValue={setFilterValue}
        filterValue={filterValue}
        hideFooterSelectedRowCount
        keepNonExistentRowsSelected={true}
        selectionModel={selectedDevice}
        onSelectionModelChange={(selectedRowId: GridRowId[]): void => {
          if (selectedRowId.length > 0) {
            let lastSelected = selectedRowId[selectedRowId.length - 1];
            dispatch(actions.devSelected([lastSelected], 'radio'));
            GoogleAnalyticsService.triggerEvent(
              GaEventCategory.DEVICES_PAGE,
              GaEventAction.DEVICES_PAGE_DEVICE_NUMBER_CLICK,
              String(lastSelected)
            );
          } else {
            dispatch(actions.devSelected([], 'radio'));
          }
        }}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={(columnModel: GridColumnVisibilityModel): void => {
          setColumnVisibilityModel(columnModel);
        }}
        components={{
          NoRowsOverlay: () => (
            <div className="h-full flex items-center justify-center">
              {t('devices_table_no_devices_found')}
            </div>
          ),
        }}
      />
    </Header>
  );
};

interface DevicesPageProps {}

export default DevicesPage;
