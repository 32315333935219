import { AxiosError } from 'axios';

export default class ErrorUtil {
  public static handleError = (error: AxiosError): void => {
    if (error.response === undefined) {
      throw error;
    }

    if (error.response && (error.response.status === 401 || error.response.status === 403)) {
      window.location.href = `${window.location.origin}/#/signout`;
    } else if (error.response.data) {
      alert(`Error: ${error.response.data.message || error.response.data}`);
    } else {
      throw error;
    }
  };
}
