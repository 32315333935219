import React, { Component, Fragment } from 'react';
import 'react-select/dist/react-select.css';
import flowGeneratorClient from './FlowGeneratorClient';
import deviceConfigClient from './DeviceConfigClient';
import devicesClient from './DevicesClient';
import { FormGroup, ControlLabel, FormControl } from 'react-bootstrap';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import Button from '@mui/material/Button';
import Header from './components/header';
import { withRouter } from './common/withRouter';
import { DatePicker } from '@mui/lab';
import { TextField } from '@mui/material';

class FlowGenerator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      sensorIndex: 0,
      battery: 4500,
      ber: 0,
      rssi: 27,
      generator: 'CONST',
      recordPeriod: 1,
      transmissionPeriod: 10,
      value: 20,
      description: null,
      open: false,
      loaded: false,
    };
    this.handleSensorIndexChange = this.handleSensorIndexChange.bind(this);
    this.handleBatteryChange = this.handleBatteryChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleBerChange = this.handleBerChange.bind(this);
    this.handleRssiChange = this.handleRssiChange.bind(this);
    this.handleGeneratorChange = this.handleGeneratorChange.bind(this);
    this.handleRecordPeriodChange = this.handleRecordPeriodChange.bind(this);
    this.handleTransmissionPeriodChange = this.handleTransmissionPeriodChange.bind(this);
    this.handleValueChange = this.handleValueChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.loadDeviceConfigFromServer = this.loadDeviceConfigFromServer.bind(this);
    this.handleConfigSaved = this.handleConfigSaved.bind(this);
    this.handleRequestClose = this.handleRequestClose.bind(this);
    this.errorCallback = this.errorCallback.bind(this);
  }

  errorCallback(error) {
    console.log(error);
    this.props.history.push('/app/signout');
  }

  handleSubmit(event) {
    this.setState({ saveDisabled: true });
    let flowGeneratorDto = {
      date: this.state.date,
      battery: this.state.battery,
      ber: this.state.ber,
      rssi: this.state.rssi,
      generator: this.state.generator,
      generatorOptions: {
        value: this.state.value,
      },
      recordPeriod: this.state.recordPeriod,
      transmissionPeriod: this.state.transmissionPeriod,
    };
    flowGeneratorClient.postFlowGenerator(
      this.props.match.params.deviceNumber,
      this.state.sensorIndex,
      flowGeneratorDto,
      this.handleConfigSaved,
      this.errorCallback
    );
    event.preventDefault();
  }

  handleConfigSaved() {
    this.setState({ open: true, saveDisabled: false });
  }

  handleSensorIndexChange = (event) => {
    this.setState({ sensorIndex: event.target.value });
  };

  handleBatteryChange = (event) => {
    this.setState({ battery: event.target.value });
  };

  handleDateChange = (value) => {
    this.setState({ date: value });
  };

  handleBerChange = (event) => {
    this.setState({ ber: event.target.value });
  };

  handleRssiChange = (event) => {
    this.setState({ rssi: event.target.value });
  };

  handleGeneratorChange = (event) => {
    this.setState({ generator: event.target.value });
  };

  handleRecordPeriodChange = (event) => {
    this.setState({ recordPeriod: event.target.value });
  };

  handleTransmissionPeriodChange = (event) => {
    this.setState({ transmissionPeriod: event.target.value });
  };

  handleValueChange = (event) => {
    this.setState({ value: event.target.value });
  };

  handleRequestClose = () => {
    this.setState({ open: false });
  };

  render() {
    const wellStyles = { maxWidth: 400, margin: '0 auto 10px' };
    if (this.state.loaded) {
      return (
        <Header>
          <div className="well" style={wellStyles}>
            <p>
              Configure alarms for device: {this.props.match.params.deviceNumber},{' '}
              {this.state.description}{' '}
            </p>
            <form>
              <Fragment>
                <FormGroup controlId="Date">
                  <DatePicker
                    autoOk
                    variant="inline"
                    label="Date"
                    value={this.state.date}
                    onChange={(date) => this.handleDateChange(date)}
                    format="dd/MM/yyyy"
                    renderInput={(params) => (
                      <TextField size="small" style={{ width: 175 }} {...params} />
                    )}
                  />
                </FormGroup>
                <FormGroup controlId="Sensor Index">
                  <ControlLabel>Sensor Index</ControlLabel>
                  <FormControl
                    value={this.state.sensorIndex}
                    componentClass="select"
                    placeholder="SensorIndex"
                    onChange={this.handleSensorIndexChange.bind(this)}
                  >
                    <option value={0}>1</option>
                    <option value={1}>2</option>
                  </FormControl>
                </FormGroup>
                <FormGroup controlId="Battery">
                  <ControlLabel>Battery [mV]</ControlLabel>
                  <FormControl
                    value={this.state.battery}
                    type="text"
                    placeholder="Battery"
                    onChange={this.handleBatteryChange}
                  />
                </FormGroup>
                <FormGroup controlId="Ber">
                  <ControlLabel>Ber</ControlLabel>
                  <FormControl
                    value={this.state.ber}
                    type="text"
                    placeholder="Ber"
                    onChange={this.handleBerChange}
                  />
                </FormGroup>
                <FormGroup controlId="Rssi">
                  <ControlLabel>Rssi</ControlLabel>
                  <FormControl
                    value={this.state.rssi}
                    type="text"
                    placeholder="Rssi"
                    onChange={this.handleRssiChange}
                  />
                </FormGroup>
                <FormGroup controlId="Generator">
                  <ControlLabel>Generator</ControlLabel>
                  <FormControl
                    value={this.state.generator}
                    componentClass="select"
                    placeholder="generator"
                    onChange={this.handleGeneratorChange.bind(this)}
                  >
                    <option value={'CONST'}>CONST</option>
                    <option value={'RANDOM'}>RANDOM</option>
                    <option value={'FILE'}>FILE</option>
                  </FormControl>
                </FormGroup>
                <FormGroup controlId="Record Period">
                  <ControlLabel>Record Period [min]</ControlLabel>
                  <FormControl
                    value={this.state.recordPeriod}
                    type="text"
                    placeholder="Record Period in min"
                    onChange={this.handleRecordPeriodChange}
                  />
                </FormGroup>
                <FormGroup controlId="Transmission Period">
                  <ControlLabel>Transmission Period [min]</ControlLabel>
                  <FormControl
                    value={this.state.transmissionPeriod}
                    type="text"
                    placeholder="Transmission Period in min"
                    onChange={this.handleTransmissionPeriodChange}
                  />
                </FormGroup>
                <FormGroup controlId="Flow Value">
                  <ControlLabel>Flow Value [l/s]</ControlLabel>
                  <FormControl
                    value={this.state.value}
                    type="text"
                    placeholder="value"
                    onChange={this.handleValueChange}
                  />
                </FormGroup>
              </Fragment>
              <Button
                variant="contained"
                onClick={this.handleSubmit}
                disabled={this.state.saveDisabled}
              >
                Save
              </Button>
              <Snackbar
                open={this.state.open}
                message="Alarm Configuration Saved."
                onClose={this.handleRequestClose}
              />
            </form>
          </div>
        </Header>
      );
    } else {
      return (
        <Header>
          <div>
            <CircularProgress size={80} thickness={5} />
          </div>
        </Header>
      );
    }
  }

  componentDidMount() {
    this.loadDeviceConfigFromServer();
    this.intervalId = setInterval(this.loadConfiguredFlag, 5000);
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  loadDeviceConfigFromServer() {
    devicesClient.getDevice(
      this.props.match.params.deviceNumber,
      (device) => {
        const description = [
          device.customerInfo?.name,
          device.customerInfo?.municipality,
          device.customerInfo?.quarter,
          device.customerInfo?.address,
        ]
          .filter(Boolean)
          .join(', ');

        deviceConfigClient.getDeviceConfig(
          this.props.match.params.deviceNumber,
          (conf) => {
            this.setState({
              description: description,
              loaded: true,
            });
          },
          this.errorCallback
        );
      },
      this.errorCallback
    );
  }
}

export default withRouter(FlowGenerator);
