import React, { FC, ReactElement, useCallback, useRef } from 'react';

import { useTranslation } from 'react-i18next';
import { Controller, UseControllerReturn, UseFormReturn, ValidateResult } from 'react-hook-form';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';

import BaseSensorConfig from './BaseSensorConfig';
import { analogSensorTypeOptions, SensorTypeOption } from './common/SensorTypeOptions';
import InitialConfigFormFields from '../../models/InitialConfigFormFields';
import ConnectForm from '../../../../common/reactHookForm/ConnectForm';
import FractionSelector from '../../../../components/FractionSelector/FractionSelector';
import { FormulaValidationResult } from '@thingslog/repositories';
import { HelperTextInputField } from '@thingslog/ui-components';
import { debounce as _debounce } from 'lodash';

const GenericAnalogConfig: FC<GenericAnalogConfigProps> = ({
  index,
  validateFormula,
}: GenericAnalogConfigProps) => {
  const { t } = useTranslation();

  const debouncedValidateFormulaRef = useRef(
    _debounce(async (formula: string, callback: (result: ValidateResult) => void) => {
      const formulaValidationResult = await validateFormula(formula);
      const result = formulaValidationResult.valid || formulaValidationResult.errorMessage || '';
      callback(result);
    }, 600)
  );

  const formulaValidation = useCallback((formula: string) => {
    return new Promise<ValidateResult>((resolve: (result: ValidateResult) => void) => {
      debouncedValidateFormulaRef.current(formula, resolve);
    });
  }, []);

  return (
    <ConnectForm<InitialConfigFormFields>>
      {({ getValues, control }: UseFormReturn<InitialConfigFormFields>): ReactElement => {
        return (
          <>
            <FractionSelector
              className="flex gap-7 items-center justify-center self-start"
              disabled={true}
              fraction={getValues(`ports.${index}.sensor.scale`)}
            />

            <Controller
              control={control}
              name={`ports.${index}.sensor.@type`}
              render={({
                field,
              }: UseControllerReturn<
                InitialConfigFormFields,
                `ports.${number}.sensor.@type`
              >): ReactElement => (
                <FormControl size="small">
                  <InputLabel>{t('device_config_sensor_type')}</InputLabel>
                  <Select {...field} label={t('device_config_sensor_type')}>
                    {analogSensorTypeOptions.map(({ value, label }: SensorTypeOption) => (
                      <MenuItem key={value} value={value}>
                        {t(label)}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText> </FormHelperText>
                </FormControl>
              )}
            />

            <Controller
              control={control}
              name={`ports.${index}.formula`}
              rules={{
                required: {
                  value: true,
                  message: t('sensors_and_network_config_required_field_error'),
                },
                validate: formulaValidation,
              }}
              render={({
                field,
                fieldState,
              }: UseControllerReturn<
                InitialConfigFormFields,
                `ports.${number}.formula`
              >): ReactElement => {
                return (
                  <HelperTextInputField
                    {...field}
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message || ' '}
                    label={t('sensor_config_port_conversion_formula')}
                    size="small"
                  />
                );
              }}
            />

            <Controller
              control={control}
              name={`ports.${index}.sensor.formula`}
              rules={{
                required: {
                  value: true,
                  message: t('sensors_and_network_config_required_field_error'),
                },
                validate: formulaValidation,
              }}
              render={({
                field,
                fieldState,
              }: UseControllerReturn<
                InitialConfigFormFields,
                `ports.${number}.sensor.formula`
              >): ReactElement => {
                return (
                  <TextField
                    {...field}
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message || ' '}
                    label={t('sensor_conversion_formula')}
                    size="small"
                  />
                );
              }}
            />

            <BaseSensorConfig index={index} />
          </>
        );
      }}
    </ConnectForm>
  );
};

interface GenericAnalogConfigProps {
  index: number;
  validateFormula: (formula: string) => Promise<FormulaValidationResult>;
}

export default GenericAnalogConfig;
