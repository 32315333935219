import React, { Component } from 'react';
import 'react-select/dist/react-select.css';

import Grid from '@mui/material/Grid';
import Header from './components/header';
import Typography from '@mui/material/Typography/Typography';
import { Link } from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';
import * as actions from './actions';
import userClient from './UserClient';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import { InputBase, Paper, IconButton, Divider, Tooltip, Button, Snackbar } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { getApiToken } from './clients/ApiToken/ApiTokenClient';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import default_logo from './resources/logos/logo-dark.png';
import { withRouter } from './common/withRouter';
import { withTranslation } from 'react-i18next';
import { companyInfoQueryClient } from './clients/ReactQueryClients/ReactQueryClients';

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profile: null,
      open: false,
      token: '',
      openToast: false,
      messageToast: '',
      logoSource: null,
      companyInfo: null,
    };
    this.errorCallback = this.errorCallback.bind(this);
    this.generateButtonClicked = this.generateButtonClicked.bind(this);
    this.copyToClipboard = this.copyToClipboard.bind(this);
  }

  errorCallback(error) {
    if (error.response === undefined) {
      throw error;
    }
    if (error.response.status === 401 || error.response.status === 403) {
      this.props.history.push('/app/signout');
    } else {
      alert('Error: ' + error.response.data);
      this.setState({ saveDisabled: false });
    }
  }

  handleRequestClose = () => {
    this.setState({ open: false });
  };

  handleConfigSaved() {
    this.setState({ open: true, saveDisabled: false });
  }

  generateButtonClicked = async (e) => {
    const token = await getApiToken(365 * 24, this.props.auth.username);
    this.setState({ token });
  };

  copyToClipboard = (e) => {
    navigator.clipboard
      .writeText(this.state.token)
      .then(() => {
        this.setState({
          openToast: true,
          messageToast: 'Copied to clipboard',
        });
      })
      .catch(() => {
        this.setState({
          openToast: true,
          messageToast: 'Failed to copy to clipboard',
        });
      });
  };

  handleLogoChange = async (event) => {
    const file = event.target.files[0];

    //If a file is selected
    if (file) {
      // Convert file to Blob
      const blob = new Blob([file], { type: file.type });
      const base64EncodedBlob = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          // Extract base64 content from the data URL.
          const base64Data = reader.result.split(',')[1];
          resolve(base64Data);
        };
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(blob);
      });

      // PUT request
      let updatedCompanyInfo = this.state.companyInfo;
      updatedCompanyInfo.logo = base64EncodedBlob;
      companyInfoQueryClient
        .updateCompanyInfo(this.props.company.id, updatedCompanyInfo)
        .then(() => {
          return this.props.setCompanyLogo(blob);
        })
        .catch((error) => {
          alert(error);
        });
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <Header>
        {this.state.user == null && <CircularProgress size={80} thickness={5} />}
        {this.state.user != null && (
          <div className={classes.container}>
            <Grid container direction={'column'} spacing={4} alignItems="center" justify="center">
              <Grid item>
                <Typography variant="h5">{this.props.t('profile_page_header')}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="h5">
                  {this.props.t('profile_page_name')}: {this.state.user.firstName}{' '}
                  {this.state.user.lastName}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h5">
                  {this.props.t('profile_page_email')}: {this.state.user.email}
                </Typography>
              </Grid>
              <Grid item>
                <Link to={`/app/Language`} className="text-center">
                  {this.props.t('profile_page_change_language')}
                </Link>
              </Grid>
              <Grid item>
                <Link to={`/app/LicenseManager`} className="text-center">
                  {this.props.t('profile_page_update_license')}
                </Link>
              </Grid>
              <Grid item>
                <Link to={`/app/ChangePassword`} className="text-center">
                  {this.props.t('profile_page_change_password')}
                </Link>
              </Grid>
              <Grid item className="w-full sm:w-[64%]">
                <div className="flex flex-col sm:flex-row justify-between items-center">
                  <Button
                    className="h-1/4 sm:h-1/3 mb-2 sm:mb-0 md:mr-2"
                    variant="contained"
                    component="label"
                    startIcon={<CloudUploadIcon />}
                  >
                    <span>{this.props.t('profile_page_change_logo')} </span>
                    <input className="hidden" type="file" onChange={this.handleLogoChange} />
                  </Button>
                  {!this.state.logoSource ? (
                    <CircularProgress />
                  ) : (
                    <img
                      src={this.state.logoSource}
                      alt="image"
                      className="max-h-[90px] max-w-[90px] sm:max-h-[120px] sm:max-w-[120px] mt-2 sm:mt-0"
                    />
                  )}
                </div>
              </Grid>

              <Grid item>
                <Paper component="form" className={classes.root}>
                  <InputBase
                    className={classes.input}
                    value={this.state.token}
                    placeholder="Generate Token"
                    disabled
                  />
                  <Tooltip placement="top" title="Copy!" arrow>
                    <IconButton
                      type="submit"
                      className={classes.iconButton}
                      onClick={this.copyToClipboard}
                    >
                      <ContentCopyIcon />
                    </IconButton>
                  </Tooltip>
                  <Divider className={classes.divider} orientation="vertical" />
                  <Button onClick={this.generateButtonClicked}>
                    {this.props.t('profile_page_generate')}
                  </Button>
                </Paper>
              </Grid>
            </Grid>
          </div>
        )}
        <Snackbar
          open={this.state.openToast}
          message={this.state.messageToast}
          autoHideDuration={4000}
          onClose={() => {
            this.setState({ openToast: false });
          }}
        />
      </Header>
    );
  }

  componentDidMount() {
    this.loadUserFromServer();
    let decodedToken = JSON.parse(localStorage.getItem('decoded_token'));
    let companyId = this.props.company.id !== null ? this.props.company.id : decodedToken.companyId;
    if (companyId) {
      this.loadCompanyInfo();
    }
    if (this.props.company.companyLogoBlob) {
      let objectUrlLogo = URL.createObjectURL(this.props.company.companyLogoBlob);
      this.setState({ logoSource: objectUrlLogo });
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.company.id && this.props.company.id) {
      this.loadCompanyInfo();
    }

    //Set logo using companyLogoBlob
    if (
      this.props.company.companyLogoBlob &&
      prevProps.company.companyLogoBlob !== this.props.company.companyLogoBlob
    ) {
      let objectUrlLogo = URL.createObjectURL(this.props.company.companyLogoBlob);
      this.setState({ logoSource: objectUrlLogo });
    }
  }

  componentWillUnmount() {
    // Revoke the object URL when the component is about to unmount
    if (this.state.logoSource) {
      URL.revokeObjectURL(this.state.logoSource);
    }
  }

  converBase64StringToBlob = (base64) => {
    const byteCharacters = atob(base64);
    const byteNumbers = Array.from(byteCharacters).map((char) => char.charCodeAt(0));
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray]);
  };

  loadUserFromServer() {
    let decodedToken = localStorage.getItem('decoded_token');
    let userName = '';
    if (decodedToken) {
      try {
        decodedToken = JSON.parse(decodedToken);
        userName = decodedToken.sub;
      } catch (e) {
        console.error(e);
      }
    }

    userClient.getUser(
      userName,
      (user) => {
        this.setState({
          user: user,
        });
      },
      this.errorCallback
    );
  }

  loadCompanyInfo = async () => {
    let decodedToken = JSON.parse(localStorage.getItem('decoded_token'));
    let companyId = this.props.company.id !== null ? this.props.company.id : decodedToken.companyId;

    if (companyId) {
      let companyInfo = await companyInfoQueryClient.getCompanyInfo(Number(companyId));
      this.setState({ companyInfo: companyInfo });
    }
  };
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    company: state.company,
  };
}

const styles = (theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  container: {
    maxWidth: 640,
    height: 615,
    margin: '0 auto 10px',
    backgroundColor: '#f5f5f5',
  },
});

export default withRouter(
  connect(
    mapStateToProps,
    actions
  )(withTranslation()(withStyles(styles, { withTheme: true })(Profile)))
);
