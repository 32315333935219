import React, { Component } from 'react';
import 'react-select/dist/react-select.css';
import UserClient from './UserClient';
import Snackbar from '@mui/material/Snackbar';
import Button from '@mui/material/Button';
import Header from './components/header';
import { withRouter, WithRouterProps } from './common/withRouter';
import Grid from '@mui/material/Grid/Grid';
import Typography from '@mui/material/Typography/Typography';
import TextField from '@mui/material/TextField/TextField';
import withStyles from '@mui/styles/withStyles';
import { Paper, Theme } from '@mui/material';
import { StyleRulesCallback } from '@mui/styles';
import { AxiosError } from 'axios';
import GoogleAnalyticsService from './common/GoogleAnalyticsService';
import { GaEventAction } from './common/GaEventAction';
import { GaEventCategory } from './common/GaEventCategory';
import { withTranslation, TFunction } from 'react-i18next';

const styles: StyleRulesCallback<Theme, PasswordChangeProps> = (theme: Theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(2),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
    maxWidth: 300,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  wellStyles: {
    maxWidth: 400,
    margin: '0 auto 10px',
  },
});

class PasswordChange extends Component<PasswordChangeProps, PasswordChangeState> {
  public state: PasswordChangeState = {
    currentPassword: '',
    newPassword1: '',
    newPassword2: '',
    open: false,
    saveDisabled: false,
  };

  private errorCallback = (error: AxiosError): void => {
    if (error.response === undefined) {
      throw error;
    }
    if (error.response.status === 401 || error.response.status === 403) {
      this.props.history.push('/app/signout');
    } else if (error.response.status === 400) {
      alert('Error: ' + error.response.data.message);
      this.setState({ saveDisabled: false });
    } else {
      alert('Error: ' + error.response.data);
      this.setState({ saveDisabled: false });
    }
  };
  private handleCurrentPasswordChange = (event: React.ChangeEvent<HTMLInputElement>): void =>
    this.setState({ currentPassword: event.target.value });
  private handleNewPassword1Change = (event: React.ChangeEvent<HTMLInputElement>): void =>
    this.setState({ newPassword1: event.target.value });
  private handleNewPassword2Change = (event: React.ChangeEvent<HTMLInputElement>): void =>
    this.setState({ newPassword2: event.target.value });
  private handleRequestClose = (): void => this.setState({ open: false });
  private handleSubmit = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void | boolean => {
    if (this.state.newPassword1 !== this.state.newPassword2) {
      alert('Password does not match');
      return false;
    }
    GoogleAnalyticsService.triggerEvent(
      GaEventCategory.PROFILE_PAGE,
      GaEventAction.CHANGE_PASSWORD_SAVE
    );
    this.setState({ saveDisabled: true });
    UserClient.putPasswordChange(
      this.state.currentPassword,
      this.state.newPassword1,
      this.handleConfigSaved,
      this.errorCallback
    );
    event.preventDefault();
  };

  private handleConfigSaved = (): void => {
    this.setState({ open: true, saveDisabled: false });
  };

  public render(): React.ReactNode {
    const { classes } = this.props;
    return (
      <Header>
        <Grid container direction="column" justifyContent="center" alignItems="center">
          <Grid item>
            <Typography variant="h5" component="h5" paragraph>
              {this.props.t('change_password')}
            </Typography>
          </Grid>
          <Grid item>
            <TextField
              id="currentPass"
              label={this.props.t('current_password')}
              type="password"
              className={classes.textField}
              value={this.state.currentPassword}
              onChange={this.handleCurrentPasswordChange}
              margin="normal"
            />
          </Grid>
          <Grid item>
            <TextField
              id="newPass"
              label={this.props.t('new_password')}
              type="password"
              className={classes.textField}
              value={this.state.newPassword1}
              onChange={this.handleNewPassword1Change}
              margin="normal"
            />
          </Grid>
          <Grid item>
            <TextField
              id="confirmPass"
              label={this.props.t('confirm_new_password')}
              type="password"
              className={classes.textField}
              value={this.state.newPassword2}
              onChange={this.handleNewPassword2Change}
              margin="normal"
            />
          </Grid>
          <Grid container alignItems="flex-end" justifyContent={'center'} spacing={4}>
            <Grid item>
              <Button
                variant="contained"
                className={classes.margin}
                onClick={this.handleSubmit}
                disabled={this.state.saveDisabled}
              >
                {this.props.t('button_save')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Snackbar
          open={this.state.open}
          message={this.props.t('customer_info_saved')}
          autoHideDuration={4000}
          onClose={this.handleRequestClose}
        />
      </Header>
    );
  }
}

interface PasswordChangeProps extends WithRouterProps {
  classes: { [key: string]: string };
  t: TFunction;
}

interface PasswordChangeState {
  currentPassword: string;
  newPassword1: string;
  newPassword2: string;
  open: boolean;
  saveDisabled: boolean;
}

export default withRouter(withStyles(styles)(withTranslation()(PasswordChange)));
