import {
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Tooltip,
} from '@mui/material';
import EventValueRangeFunction from '@thingslog/repositories/src/valueRanges/EventValueRangeFunction';
import { format, getDaysInMonth } from 'date-fns';
import React, { FC, useState, SyntheticEvent, ChangeEvent } from 'react';
import { IconDropdown, useModal } from '@thingslog/ui-components';
import EventNotificationType from '@thingslog/repositories/src/valueRanges/EventNotificationType';
import { eventValueRangeQueryClient } from '../../../clients/ReactQueryClients/ReactQueryClients';
import { EventValueRange, MutateEventValueRange, Port } from '@thingslog/repositories';
import { Styles } from '@thingslog/repositories/src/valueRanges/Styles';
import LineChartStyles from '@thingslog/repositories/src/valueRanges/LineChartStyles';
import { useQueryClient } from '@tanstack/react-query';
import ColorPickerButton from './ColorPickerButton';
import { useTranslation } from 'react-i18next';
import { translateEventFunction } from '../utils/ValueRangeTranslationUtil';
import { AxiosError } from 'axios';
import { iconsQueryClient } from '../../../clients/ReactQueryClients/ReactQueryClients';
import DeviceIconStyles from '@thingslog/repositories/src/valueRanges/DeviceIconStyles';
import SensorIconStyles from '@thingslog/repositories/src/valueRanges/SensorIconStyles';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../reducers';

const ValueRangeModal: FC<ValueRangeModalProps> = ({
  deviceNumber: deviceNumberProps,
  sensorIndex: sensorIndexProps,
  portsConfig,
  valueRangeName,
  onSuccess: onSuccessCallback,
}: ValueRangeModalProps) => {
  // #region State
  const queryClient = useQueryClient();
  const { closeModal } = useModal();
  const { t } = useTranslation();
  const eventValueRangeClient = eventValueRangeQueryClient;
  const { useGetIcons } = iconsQueryClient;
  const [isEdit] = useState<boolean>(!!valueRangeName);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const reduxCompanyId = useSelector((state: ReduxState) => state.company.id);

  // Base State
  const [deviceNumber] = useState(deviceNumberProps);
  const [sensorIndex, setSensorIndex] = useState(sensorIndexProps || 0);
  const [name, setName] = useState(valueRangeName);
  const [functionType, setFunctionType] = useState(EventValueRangeFunction.LAST);
  const [description, setDescription] = useState<string | null>(null);
  const [min, setMin] = useState<number | null>(null);
  const [max, setMax] = useState<number | null>(null);
  const [severity, setSeverity] = useState<number | null>(null);

  // Period State
  const [startDay, setStartDay] = useState<number | null>(null);
  const [startMonth, setStartMonth] = useState<number | null>(null);
  const [startTime, setStartTime] = useState<string>('');
  const [endDay, setEndDay] = useState<number | null>(null);
  const [endMonth, setEndMonth] = useState<number | null>(null);
  const [endTime, setEndTime] = useState<string>('');

  // Color State
  const [enableLineStyles, setEnableLineStyles] = useState(false);
  const [lineColor, setLineColor] = useState('#9ca3af');
  const [nodeColor, setNodeColor] = useState('#9ca3af');

  const [enableSensorIcon, setEnableSensorIcon] = useState(false);
  const [sensorIconId, setSensorIconId] = useState<number>(0);
  const [sensorIconBackgroundColor, setSensorIconBackgroundColor] = useState('#9ca3af');
  const [sensorIconForegroundColor, setSensorIconForegroundColor] = useState('#9ca3af');

  const [enableDeviceIcon, setEnableDeviceIcon] = useState(false);
  const [deviceIconId, setDeviceIconId] = useState<number>(0);
  const [deviceIconBackgroundColor, setDeviceIconBackgroundColor] = useState('#9ca3af');
  const [deviceIconForegroundColor, setDeviceIconForegroundColor] = useState('#9ca3af');

  // Event Notifications
  const [sendEmail, setSendEmail] = useState(false);
  const [sendPushNotification, setSendPushNotification] = useState(false);
  const [eventsEnabled, setEventsEnabled] = useState(false);
  // #endregion

  // #region HTTP Request
  const { isLoading: isInitialValueRangeLoading } = eventValueRangeClient.useEventValueRangeData(
    deviceNumber,
    sensorIndex,
    valueRangeName!,
    {
      enabled: valueRangeName !== null,
      onSuccess(data: EventValueRange) {
        setFunctionType(data.function);
        setDescription(data.description || null);
        setMin(data.min);
        setMax(data.max);
        setSeverity(data.severity);

        setStartDay(data.period?.startDay || null);
        setStartMonth(data.period?.startMonth || null);
        data.period?.startMinute !== null &&
          data.period?.startMinute !== undefined &&
          data.period?.startHour !== null &&
          data.period?.startHour !== undefined &&
          setStartTime(
            `${addLeadingZero(data.period!.startHour)}:${addLeadingZero(data.period!.startMinute)}`
          );
        setEndDay(data.period?.endDay || null);
        setEndMonth(data.period?.endMonth || null);
        data.period?.endMinute !== null &&
          data.period?.endMinute !== undefined &&
          data.period?.endHour !== null &&
          data.period?.endHour !== undefined &&
          setEndTime(
            `${addLeadingZero(data.period!.endHour)}:${addLeadingZero(data.period!.endMinute)}`
          );

        const lineColors = data.styles.filter(
          (style: Styles) => style['@type'] === 'line'
        ) as LineChartStyles[];
        if (lineColors.length > 0) {
          setEnableLineStyles(true);
          setLineColor(lineColors[0].lineColor);
          setNodeColor(lineColors[0].nodeColor);
        }

        const deviceIconStyles = data.styles.filter(
          (style: Styles) => style['@type'] === 'device-icon'
        ) as DeviceIconStyles[];
        if (deviceIconStyles.length > 0) {
          setEnableDeviceIcon(true);
          setDeviceIconId(deviceIconStyles[0].iconId);
          setDeviceIconBackgroundColor(deviceIconStyles[0].backgroundColor);
          setDeviceIconForegroundColor(deviceIconStyles[0].foregroundColor);
        }

        const sensorIconStyles = data.styles.filter(
          (style: Styles) => style['@type'] === 'sensor-icon'
        ) as SensorIconStyles[];
        if (sensorIconStyles.length > 0) {
          setEnableSensorIcon(true);
          setSensorIconId(sensorIconStyles[0].iconId);
          setSensorIconBackgroundColor(sensorIconStyles[0].backgroundColor);
          setSensorIconForegroundColor(sensorIconStyles[0].foregroundColor);
        }

        setSendEmail(data.eventNotificationTypes.includes(EventNotificationType.EMAIL));
        setSendPushNotification(
          data.eventNotificationTypes.includes(EventNotificationType.PUSH_NOTIFICATION)
        );
        setEventsEnabled(data.eventsEnabled);
      },
      onError: () => setErrorMsg(t('value_range_error_occured')),
    }
  );

  const { mutate: addValueRange, isLoading: isAddValueRangeLoading } =
    eventValueRangeClient.useAddValueRangeData(deviceNumber, sensorIndex, {
      onSuccess: () => {
        onSuccessCallback && onSuccessCallback();
        queryClient.invalidateQueries();
        closeModal();
      },
      onError: (error: AxiosError) => {
        if (error.response?.data.errorCode === 'EVENT_VALUE_RANGE_NAME_ALREADY_EXISTS') {
          setErrorMsg(t('value_range_name_already_exists'));
        } else {
          setErrorMsg(t('value_range_error_occured'));
        }
      },
    });

  const { mutate: updateValueRange, isLoading: isUpdateValueRangeLoading } =
    eventValueRangeClient.useUpdateValueRangeData(deviceNumber, sensorIndex, valueRangeName!, {
      onSuccess: () => {
        onSuccessCallback && onSuccessCallback();
        queryClient.invalidateQueries();
        closeModal();
      },
      onError: (error: AxiosError) => {
        if (error.response?.data.errorCode === 'EVENT_VALUE_RANGE_NAME_ALREADY_EXISTS') {
          setErrorMsg(t('value_range_name_already_exists'));
        } else {
          setErrorMsg(t('value_range_error_occured'));
        }
      },
    });

  const deviceIconQuery = useGetIcons(deviceNumber, null, reduxCompanyId, {
    refetchOnWindowFocus: false,
  });

  const sensorIconQuery = useGetIcons(deviceNumber, sensorIndex, reduxCompanyId, {
    refetchOnWindowFocus: false,
  });
  // #endregion

  // #region Helper Functions
  const addLeadingZero = (num: number): string => (num < 10 ? `0${num}` : `${num}`);

  const isTimeFormat = (time: string): boolean => {
    const timeFormat = /^([0-1][0-9]|2[0-3]):[0-5][0-9]$/;
    return timeFormat.test(time);
  };

  const hasMixedAlphabets = (input: string): boolean => {
    const patterns = {
      latin: /[A-Za-z]/,
      cyrillic: /[А-Яа-яЁё]/,
      chinese: /[\u4e00-\u9fff]/,
      arabic: /[\u0600-\u06FF]/,
      greek: /[\u0370-\u03FF]/,
    };

    let alphabetCount = 0;

    for (const pattern of Object.values(patterns)) {
      if (pattern.test(input)) {
        alphabetCount++;
        if (alphabetCount > 1) return true;
      }
    }

    return false;
  };

  // #endregion

  // #region Event Handlers
  const handleSubmit = async (): Promise<void> => {
    let error: string | null = null;

    if (!name) {
      error = t('value_range_name_is_required');
    } else if (hasMixedAlphabets(name)) {
      error = t('value_range_mixed_alphabets_error');
    }

    if (min === null && max === null) error = t('value_range_min_max_is_required');
    if (min !== null && max !== null && Number(min) > Number(max))
      error = t('value_range_min_greater_than_max');
    if (min !== null && max !== null && Number(min) === Number(max))
      error = t('value_range_min_equal_to_max');
    if (!!startTime && !isTimeFormat(startTime)) error = t('value_range_start_incorrect_time');
    if (!!endTime && !isTimeFormat(endTime)) error = t('value_range_end_incorrect_time');
    if (startDay && !startMonth) error = t('value_range_start_day_missing_start_month');
    if (endDay && !endMonth) error = t('value_range_end_day_missing_end_month');
    if (startMonth && !startDay) error = t('value_range_start_month_missing_start_day');
    if (endMonth && !endDay) error = t('value_range_end_month_missing_end_day');

    if (!!error) {
      setErrorMsg(error!);
      return;
    } else {
      setErrorMsg(null);
    }

    const startHour = startTime ? parseInt(startTime.split(':')[0]) : null;
    const startMinute = startTime ? parseInt(startTime.split(':')[1]) : null;
    const endHour = endTime ? parseInt(endTime.split(':')[0]) : null;
    const endMinute = endTime ? parseInt(endTime.split(':')[1]) : null;

    let styles: Styles[] = [];
    if (enableLineStyles) {
      styles.push({
        '@type': 'line',
        lineColor: lineColor,
        nodeColor: nodeColor,
      });
    }
    if (enableDeviceIcon) {
      styles.push({
        '@type': 'device-icon',
        iconId: deviceIconId,
        backgroundColor: deviceIconBackgroundColor,
        foregroundColor: deviceIconForegroundColor,
      });
    }

    if (enableSensorIcon) {
      styles.push({
        '@type': 'sensor-icon',
        iconId: sensorIconId,
        backgroundColor: sensorIconBackgroundColor,
        foregroundColor: sensorIconForegroundColor,
      });
    }

    let eventNotificationTypes: EventNotificationType[] = [];
    if (sendEmail) eventNotificationTypes.push(EventNotificationType.EMAIL);
    if (sendPushNotification) eventNotificationTypes.push(EventNotificationType.PUSH_NOTIFICATION);
    if (eventNotificationTypes.length === 0)
      eventNotificationTypes.push(EventNotificationType.NO_NOTIFICATION);

    const eventValueRange: MutateEventValueRange = {
      deviceNumber: deviceNumber,
      sensorIndex: sensorIndex,
      name: name!, // safe
      description: description,
      min: min,
      max: max,
      period: {
        startDay: startDay,
        startMonth: startMonth,
        startHour: startHour,
        startMinute: startMinute,
        endDay: endDay,
        endMonth: endMonth,
        endHour: endHour,
        endMinute: endMinute,
      },
      styles: styles,
      function: functionType,
      eventNotificationTypes: eventNotificationTypes,
      eventsEnabled: eventsEnabled,
      severity: severity,
    };

    isEdit ? updateValueRange(eventValueRange) : addValueRange(eventValueRange);
  };
  // #endregion

  // #region Render
  return (
    <section className="flex flex-col py-3">
      <div className="grid grid-cols-4 gap-x-2 gap-y-5">
        <span className="col-span-2">
          <TextField
            variant="outlined"
            label={t('value_range_form_name')}
            placeholder={t('value_range_form_name')}
            size="small"
            fullWidth
            required
            InputLabelProps={{ shrink: true }}
            value={name}
            onChange={(e: ChangeEvent): void => setName(e.target.value as string)}
          />
        </span>
        <span className="col-span-2">
          <FormControl fullWidth>
            <InputLabel>{t('value_range_form_function')}</InputLabel>
            <Select
              size="small"
              value={functionType}
              label={t('value_range_form_function')}
              onChange={(e: SelectChangeEvent<EventValueRangeFunction>): void =>
                setFunctionType(e.target.value as EventValueRangeFunction)
              }
            >
              {Object.keys(EventValueRangeFunction).map((key: string) => (
                <MenuItem key={key} value={EventValueRangeFunction[key]}>
                  {translateEventFunction(EventValueRangeFunction[key], t)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </span>
        {sensorIndexProps === null && portsConfig !== null && (
          <span className="col-span-4">
            <FormControl fullWidth>
              <InputLabel>{t('value_range_form_sensor')}</InputLabel>
              <Select
                size="small"
                value={sensorIndex}
                label={'Sensor'}
                onChange={(e: SelectChangeEvent<number>): void => {
                  setSensorIndex(e.target.value as number);
                }}
              >
                {Object.keys(portsConfig).map((key: string) => (
                  <MenuItem key={key} value={key}>
                    {portsConfig[key].sensor.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </span>
        )}
        <span className="col-span-4">
          <TextField
            variant="outlined"
            placeholder={t('value_range_form_description')}
            label={t('value_range_form_description')}
            size="small"
            fullWidth
            InputLabelProps={{ shrink: true }}
            value={description}
            onChange={(e: ChangeEvent): void => setDescription(e.target.value as string)}
          />
        </span>
        <span className="col-span-2">
          <TextField
            label={t('value_range_form_min')}
            placeholder={t('value_range_form_min')}
            variant="outlined"
            size="small"
            type="number"
            fullWidth
            value={min}
            InputLabelProps={{ shrink: true }}
            onChange={(e: ChangeEvent): void =>
              setMin(e.target.value ? (e.target.value as number) : null)
            }
          />
        </span>
        <span className="col-span-2">
          <TextField
            variant="outlined"
            label={t('value_range_form_max')}
            placeholder={t('value_range_form_max')}
            size="small"
            fullWidth
            type="number"
            value={max}
            InputLabelProps={{ shrink: true }}
            onChange={(e: ChangeEvent): void =>
              setMax(e.target.value ? (e.target.value as number) : null)
            }
          />
        </span>
        <span className="col-span-4">
          <Tooltip title={t('value_range_tooltip_severity') || ''} placement="top" arrow>
            <FormControl fullWidth size="small">
              <InputLabel>{t('value_range_form_severity')}</InputLabel>
              <Select<number | 'none'>
                value={severity ?? 'none'}
                label={t('value_range_form_severity')}
                onChange={(e: SelectChangeEvent<number | 'none'>): void =>
                  setSeverity(typeof e.target.value === 'number' ? e.target.value : null)
                }
              >
                <MenuItem value="none">{t('none')}</MenuItem>
                {[...Array(10).keys()].map((num: number) => (
                  <MenuItem key={`severity-option-${num}`} value={num}>
                    {num}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Tooltip>
        </span>

        <span className="col-span-4 py-1">
          <Divider />
        </span>
        <span className="col-span-1">
          <FormControl fullWidth size="small">
            <InputLabel>{t('value_range_form_start_day')}</InputLabel>
            <Select
              native={false}
              label={t('value_range_form_start_day')}
              value={`${startDay}`}
              onChange={(e: SelectChangeEvent): void => setStartDay(Number(e.target.value))}
            >
              {Array.from(
                Array(
                  getDaysInMonth(
                    new Date(new Date().getFullYear(), (startMonth || new Date().getMonth()) - 1)
                  )
                ).keys()
              ).map((day: number) => (
                <MenuItem key={day} value={day + 1}>
                  {day + 1}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </span>
        <span className="col-span-1">
          <FormControl fullWidth size="small">
            <InputLabel>{t('value_range_form_start_month')}</InputLabel>
            <Select
              label={t('value_range_form_start_month')}
              value={`${startMonth}`}
              onChange={(e: SelectChangeEvent): void => {
                setStartDay(1);
                setStartMonth(Number(e.target.value));
              }}
            >
              {Array.from({ length: 12 }, (_: unknown, i: number) => i).map((month: number) => (
                <MenuItem key={month} value={month + 1}>
                  {format(new Date(2022, month, 1), 'LLL')}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </span>
        <span className="col-span-2">
          <TextField
            variant="outlined"
            label={t('value_range_form_start_time')}
            placeholder={'HH:MM (e.g. 01:30)'}
            InputLabelProps={{ shrink: true }}
            size="small"
            fullWidth
            value={startTime}
            onChange={(e: ChangeEvent): void => setStartTime(e.target.value as string)}
          />
        </span>
        <span className="col-span-1">
          <FormControl fullWidth size="small">
            <InputLabel>{t('value_range_form_end_day')}</InputLabel>
            <Select
              label={t('value_range_form_end_day')}
              value={`${endDay}`}
              onChange={(e: SelectChangeEvent): void => setEndDay(Number(e.target.value))}
            >
              {Array.from(
                Array(
                  getDaysInMonth(
                    new Date(new Date().getFullYear(), (endMonth || new Date().getMonth()) - 1)
                  )
                ).keys()
              ).map((day: number) => (
                <MenuItem key={day} value={day + 1}>
                  {day + 1}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </span>
        <span className="col-span-1">
          <FormControl fullWidth size="small">
            <InputLabel>{t('value_range_form_end_month')}</InputLabel>
            <Select
              label={t('value_range_form_end_month')}
              value={`${endMonth}`}
              onChange={(e: SelectChangeEvent): void => {
                setEndDay(1);
                setEndMonth(Number(e.target.value));
              }}
            >
              {Array.from({ length: 12 }, (_: unknown, i: number) => i).map((month: number) => (
                <MenuItem key={month} value={month + 1}>
                  {format(new Date(2022, month, 1), 'LLL')}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </span>
        <span className="col-span-2">
          <TextField
            variant="outlined"
            placeholder={'HH:MM (e.g. 01:30)'}
            label={t('value_range_form_end_time')}
            size="small"
            fullWidth
            InputLabelProps={{ shrink: true }}
            value={endTime}
            onChange={(e: ChangeEvent): void => setEndTime(e.target.value as string)}
          />
        </span>
        <span className="col-span-4 py-1">
          <Divider />
        </span>
        {/* Line color */}
        <span className="col-span-4 flex">
          <span className="flex flex-col">
            <FormControlLabel
              control={<Checkbox />}
              label={t('value_range_form_line_custom_color')}
              checked={enableLineStyles}
              onChange={(e: SyntheticEvent, checked: boolean): void => setEnableLineStyles(checked)}
              labelPlacement="end"
            />
            <div className={`flex ${!enableLineStyles && 'hidden'}`}>
              <Tooltip title={`${t('value_ranges_line_color')}`} placement="top" arrow>
                <ColorPickerButton
                  hexColor={lineColor}
                  onChange={(hexColor: string): void => setLineColor(hexColor)}
                />
              </Tooltip>
              <Tooltip title={`${t('value_ranges_node_color')}`} placement="top" arrow>
                <ColorPickerButton
                  hexColor={nodeColor}
                  onChange={(hexColor: string): void => setNodeColor(hexColor)}
                />
              </Tooltip>
            </div>
          </span>
        </span>
        {/* Device Icon */}
        <span className="col-span-4 flex">
          <span className="flex flex-col">
            <FormControlLabel
              control={<Checkbox />}
              label={t('device_icon')}
              checked={enableDeviceIcon}
              onChange={(e: SyntheticEvent, checked: boolean): void => setEnableDeviceIcon(checked)}
              labelPlacement="end"
            />
            {enableDeviceIcon && (
              <div className={`flex`}>
                <Tooltip title={'Foreground Color'} placement="top" arrow>
                  <ColorPickerButton
                    hexColor={deviceIconForegroundColor}
                    onChange={(hexColor: string): void => setDeviceIconForegroundColor(hexColor)}
                  />
                </Tooltip>
                <Tooltip title={`Background Color`} placement="top" arrow>
                  <ColorPickerButton
                    hexColor={deviceIconBackgroundColor}
                    onChange={(hexColor: string): void => setDeviceIconBackgroundColor(hexColor)}
                  />
                </Tooltip>
              </div>
            )}
          </span>
        </span>
        {enableDeviceIcon && deviceIconQuery.isSuccess && (
          <span className="col-span-4 flex">
            <IconDropdown
              icons={deviceIconQuery.data.icons}
              selectedIconId={deviceIconId}
              onIconChange={setDeviceIconId}
              label={t('device_icon')}
            />
          </span>
        )}
        {/* Sensor Icon */}
        <span className="col-span-4 flex">
          <span className="flex flex-col">
            <FormControlLabel
              control={<Checkbox />}
              label={t('sensor_icon')}
              checked={enableSensorIcon}
              onChange={(e: SyntheticEvent, checked: boolean): void => setEnableSensorIcon(checked)}
              labelPlacement="end"
            />
            {enableSensorIcon && (
              <div className={`flex`}>
                <Tooltip title={'Foreground Color'} placement="top" arrow>
                  <ColorPickerButton
                    hexColor={sensorIconForegroundColor}
                    onChange={(hexColor: string): void => setSensorIconForegroundColor(hexColor)}
                  />
                </Tooltip>
                <Tooltip title={`Background Color`} placement="top" arrow>
                  <ColorPickerButton
                    hexColor={sensorIconBackgroundColor}
                    onChange={(hexColor: string): void => setSensorIconBackgroundColor(hexColor)}
                  />
                </Tooltip>
              </div>
            )}
          </span>
        </span>
        {enableSensorIcon && sensorIconQuery.isSuccess && (
          <span className="col-span-4 flex">
            <IconDropdown
              icons={sensorIconQuery.data.icons}
              selectedIconId={sensorIconId}
              onIconChange={setSensorIconId}
              label={t('sensor_icon')}
            />
          </span>
        )}
        <span className="col-span-4 py-1">
          <Divider />
        </span>
        <span className="col-span-4">
          <FormGroup row>
            <FormControlLabel
              control={<Checkbox />}
              label={t('value_range_form_enable_events')}
              labelPlacement="end"
              checked={eventsEnabled}
              onChange={(e: SyntheticEvent, checked: boolean): void => setEventsEnabled(checked)}
            />
            <FormControlLabel
              control={<Checkbox />}
              label={t('value_range_form_notification_email')}
              labelPlacement="end"
              checked={sendEmail}
              disabled={!eventsEnabled}
              onChange={(e: SyntheticEvent, checked: boolean): void => setSendEmail(checked)}
            />
            <FormControlLabel
              control={<Checkbox />}
              label={t('value_range_form_notification_push_notification')}
              labelPlacement="end"
              checked={sendPushNotification}
              disabled={!eventsEnabled}
              onChange={(e: SyntheticEvent, checked: boolean): void =>
                setSendPushNotification(checked)
              }
            />
          </FormGroup>
        </span>
        {!!errorMsg && (
          <span className="col-span-4 m-auto text-red-500 font-semibold">
            <span>{errorMsg}</span>
          </span>
        )}
        <span className="col-span-4">
          <Button
            fullWidth
            variant="contained"
            disableElevation
            onClick={handleSubmit}
            disabled={isAddValueRangeLoading || isUpdateValueRangeLoading}
          >
            {isEdit ? t('update') : t('create')}
          </Button>
        </span>
      </div>
    </section>
  );
  // #endregion render
};

interface ValueRangeModalProps {
  deviceNumber: string;
  sensorIndex: number | null;
  valueRangeName: string | null;
  portsConfig: Record<number, Port> | null;
  onSuccess?: () => void;
}

export default ValueRangeModal;
