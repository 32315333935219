import React, { Component } from 'react';
import { WithRouterProps, withRouter } from '../../common/withRouter';
import { withTranslation, WithTranslation } from 'react-i18next';
import deviceConfigClient from '../../DeviceConfigClient';
import sensorsConfigClient from '../../SensorsConfigClient';
import { eventValueRangeQueryClient } from '../../clients/ReactQueryClients/ReactQueryClients';
import Header from '../../components/header';
import TitleHeader from '../../components/TitleHeader/TitleHeader';
import { Box, Divider, FormControlLabel, Grid, Switch, Typography } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import SensorReadingsTable from './components/SensorReadingsTable';
import 'chartjs-plugin-zoom';
import 'chartjs-plugin-annotation';
import Chart from './components/Chart';
import { styles } from './Graph.styles';
import ErrorUtil from '../../common/ErrorUtil';
import { DeviceConfigResponse } from '../../model/API/DeviceConfig/DeviceConfigResponse';
import { GraphMode } from './model/GraphMode';
import { TimeSeriesChartData } from './model/TimeSeriesChartData';
import { GraphType } from './model/GraphType';
import PeriodPicker from '../../components/DatePicker/PeriodPicker';
import AveragePeriodPicker from '../../components/DatePicker/AveragePeriodPicker';
import { ConfPeriod, Device, EventValueRange, Port } from '@thingslog/repositories';
import { ValueRangeSelector } from '@thingslog/ui-components';
import { TimeUnits } from '../../model/TimeUnits/TimeUnits';
import { EverySelector } from '@thingslog/ui-components';
import { ReduxState } from '../../reducers';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import PortUtil from './utils/PortUtil';
import _ from 'lodash';

export class Graph extends Component<GraphProps, GraphState> {
  public constructor(props: GraphProps) {
    super(props);
    this.state = {
      graphMode: GraphMode.LINE,
      selectedAnalogSensorIndexes: [],
      selectedDigitalSensorIndexes: [],
      analogChartData: [],
      digitalChartData: [],
      valueRanges: [],
      valueRangeDropdownOptions: [],
      selectedValueRanges: [],
      analogSensorValueRanges: [],
      digitalSensorValueRanges: [],
      portsConfig: {},
      confEvery: 1,
      confPeriod: null,
      every: 1,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      showAverage: false,
      allDataLoaded: false,
      isCumulative: false,
      shouldDisplayReadingsTable: false,
    };

    this.loadDeviceConfig = this.loadDeviceConfig.bind(this);
    this.loadPortsConfig = this.loadPortsConfig.bind(this);
    this.loadValueRanges = this.loadValueRanges.bind(this);
    this.setSelectedAnalogSensorIndexes = this.setSelectedAnalogSensorIndexes.bind(this);
    this.setSelectedDigitalSensorIndexes = this.setSelectedDigitalSensorIndexes.bind(this);
    this.setPortsConfig = this.setPortsConfig.bind(this);
    this.toggleShowAverage = this.toggleShowAverage.bind(this);
    this.toggleReadingsTable = this.toggleReadingsTable.bind(this);
    this.setAnalogChartData = this.setAnalogChartData.bind(this);
    this.setDigitalChartData = this.setDigitalChartData.bind(this);
    this.setEvery = this.setEvery.bind(this);
    this.shouldDisplayReadingsSwitch = this.shouldDisplayReadingsSwitch.bind(this);
    this.getAnalogSensorValueRanges = this.getAnalogSensorValueRanges.bind(this);
    this.getDigitalSensorValueRanges = this.getDigitalSensorValueRanges.bind(this);
  }

  public componentDidMount = async (): Promise<void> => {
    await this.loadDeviceConfig(this.props.match.params.deviceNumber);
    await this.loadPortsConfig(this.props.match.params.deviceNumber);
    await this.loadValueRanges(this.props.match.params.deviceNumber);
    this.setState({ allDataLoaded: true });
  };

  public componentDidUpdate = async (
    prevProps: Readonly<GraphProps>,
    prevState: Readonly<GraphState>
  ): Promise<void> => {
    if (
      !_.isEqual(prevState.selectedAnalogSensorIndexes, this.state.selectedAnalogSensorIndexes) ||
      !_.isEqual(prevState.selectedDigitalSensorIndexes, this.state.selectedDigitalSensorIndexes)
    ) {
      const valueRangeDropdownOptions = this.state.valueRanges.filter(
        (valueRange: EventValueRange) =>
          this.state.selectedAnalogSensorIndexes.includes(valueRange.sensorIndex) ||
          this.state.selectedDigitalSensorIndexes.includes(valueRange.sensorIndex)
      );

      this.setState({ valueRangeDropdownOptions });
    }

    if (!_.isEqual(prevState.selectedValueRanges, this.state.selectedValueRanges)) {
      this.setState({
        analogSensorValueRanges: this.getAnalogSensorValueRanges(),
        digitalSensorValueRanges: this.getDigitalSensorValueRanges(),
      });
    }

    if (this.props.match.params.deviceNumber !== prevProps.match.params.deviceNumber) {
      this.setState({ allDataLoaded: false });
      await this.loadDeviceConfig(this.props.match.params.deviceNumber);
      await this.loadPortsConfig(this.props.match.params.deviceNumber);
      await this.loadValueRanges(this.props.match.params.deviceNumber);
      this.setState({ allDataLoaded: true });
    }
  };

  private loadDeviceConfig = async (deviceNumber: string): Promise<void> => {
    await deviceConfigClient.getDeviceConfig(
      deviceNumber,
      (config: DeviceConfigResponse): void => {
        let confPeriod: ConfPeriod | null = null;
        if (config.recordPeriod === TimeUnits.DAYS) confPeriod = 'DAYS';
        else if (config.recordPeriod === TimeUnits.HOURS) confPeriod = 'HOURS';
        else if (config.recordPeriod === TimeUnits.MINUTES) confPeriod = 'MINUTES';
        else if (config.recordPeriod === TimeUnits.SECONDS) confPeriod = 'SECONDS';
        this.setState({
          confEvery: config.every,
          timeZone: config.timeZone,
          confPeriod: confPeriod,
        });
      },
      ErrorUtil.handleError
    );
  };

  private loadPortsConfig = async (deviceNumber: string): Promise<void> => {
    await sensorsConfigClient.getPortsConfig(
      deviceNumber,
      (portsConfig: Record<number, Port>): void => {
        const portsConfigWithoutOnOff = Object.fromEntries(
          Object.entries(portsConfig).filter(
            ([index, port]: [string, Port]) => !PortUtil.isOnOffPort(port['@type'])
          )
        );
        this.setState({ portsConfig: portsConfigWithoutOnOff });
      },
      ErrorUtil.handleError
    );
  };

  private loadValueRanges = async (deviceNumber: string): Promise<void> => {
    try {
      let data = await eventValueRangeQueryClient.getEventValueRangesForDevice(deviceNumber);
      this.setState({
        valueRanges: data.rangeEventConfigDtoList,
        valueRangeDropdownOptions: data.rangeEventConfigDtoList,
      });
    } catch (error) {
      this.setState({ valueRanges: [], valueRangeDropdownOptions: [] });
    }
  };

  private setSelectedAnalogSensorIndexes = (selectedSensors: number[]): void => {
    this.setState({ selectedAnalogSensorIndexes: selectedSensors });
  };

  private setSelectedDigitalSensorIndexes = (selectedSensors: number[]): void => {
    this.setState({ selectedDigitalSensorIndexes: selectedSensors });
  };

  private setPortsConfig = (portsConfig: Record<number, Port>): void => {
    this.setState({ portsConfig });
  };

  private toggleShowAverage = (): void => {
    this.setState({ showAverage: !this.state.showAverage });
  };

  private toggleReadingsTable = async (): Promise<void> => {
    this.setState({ shouldDisplayReadingsTable: !this.state.shouldDisplayReadingsTable });
  };

  private setAnalogChartData = (analogChartData: TimeSeriesChartData[]): void => {
    this.setState({ analogChartData });
  };

  private setDigitalChartData = (digitalChartData: TimeSeriesChartData[]): void => {
    this.setState({ digitalChartData });
  };

  private setEvery = async (every: number): Promise<void> => {
    this.setState({ every });
  };

  private shouldDisplayReadingsSwitch = (): boolean => {
    const chartData = this.state.analogChartData.concat(this.state.digitalChartData);
    return chartData.some((chartData: TimeSeriesChartData) => chartData.data.length > 0);
  };

  private getAnalogSensorValueRanges = (): EventValueRange[] => {
    const analogSensorValueRanges = this.state.selectedValueRanges.filter(
      (valueRange: EventValueRange) =>
        this.state.selectedAnalogSensorIndexes.includes(valueRange.sensorIndex)
    );

    return analogSensorValueRanges;
  };

  private getDigitalSensorValueRanges = (): EventValueRange[] => {
    const digitalSensorValueRanges = this.state.selectedValueRanges.filter(
      (valueRange: EventValueRange) =>
        this.state.selectedDigitalSensorIndexes.includes(valueRange.sensorIndex)
    );

    return digitalSensorValueRanges;
  };

  private renderGraphs = (size: number): React.ReactNode => {
    const { classes } = this.props;
    return (
      <Grid item sm={size} className={classes.chartContainer}>
        <div>
          <Grid container direction="column" spacing={2}>
            <Grid item xs={12}>
              <div className={classes.graphWrapper}>
                {this.state.portsConfig && (
                  <Chart
                    deviceNumber={this.props.match.params.deviceNumber}
                    graphType={GraphType.DIGITAL}
                    every={this.state.every}
                    timezone={this.state.timeZone}
                    portsConfig={this.state.portsConfig}
                    showAverage={this.state.showAverage}
                    valueRanges={this.state.digitalSensorValueRanges}
                    setPortsConfig={this.setPortsConfig}
                    setChartData={this.setDigitalChartData}
                    onUpdateSelectedSensors={this.setSelectedDigitalSensorIndexes}
                  />
                )}
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.graphWrapper}>
                {this.state.portsConfig && (
                  <Chart
                    deviceNumber={this.props.match.params.deviceNumber}
                    graphType={GraphType.ANALOG}
                    every={this.state.every}
                    timezone={this.state.timeZone}
                    portsConfig={this.state.portsConfig}
                    showAverage={this.state.showAverage}
                    valueRanges={this.state.analogSensorValueRanges}
                    setPortsConfig={this.setPortsConfig}
                    setChartData={this.setAnalogChartData}
                    onUpdateSelectedSensors={this.setSelectedAnalogSensorIndexes}
                  />
                )}
              </div>
            </Grid>
          </Grid>
        </div>
      </Grid>
    );
  };

  public render = (): React.ReactNode => {
    const { classes } = this.props;
    const chartData = this.state.analogChartData.concat(this.state.digitalChartData);

    // TODO: Localization w/ i18n
    return (
      <Header>
        <Grid container direction="row" justifyContent="center" alignItems="center" rowSpacing={2}>
          {this.props.selectedDevice && (
            <Grid item xs={12}>
              <TitleHeader
                title={this.props.t('device_instant_graph_header')}
                deviceNumber={this.props.selectedDevice.number}
                deviceName={this.props.selectedDevice.name}
                customerInfo={this.props.selectedDevice.customerInfo}
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={3}
            >
              <Grid item xs={12}>
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                  <Grid item>
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="flex-end"
                      spacing={3}
                    >
                      <PeriodPicker />
                      <Grid item>
                        {this.state.confPeriod && (
                          <EverySelector
                            every={this.state.every}
                            onEveryChange={this.setEvery}
                            confEvery={this.state.confEvery}
                            confPeriod={this.state.confPeriod}
                            translation={{
                              day: this.props.t('day'),
                              days: this.props.t('days'),
                              hour: this.props.t('hour'),
                              hours: this.props.t('hours'),
                              minute: this.props.t('minute'),
                              minutes: this.props.t('minutes'),
                              second: this.props.t('second'),
                              seconds: this.props.t('seconds'),
                              label: this.props.t('every'),
                            }}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="center"
                      spacing={3}
                    >
                      <Grid item>
                        <ValueRangeSelector
                          label={this.props.t('value_ranges_header')}
                          className="min-w-[13rem]"
                          allValueRanges={this.state.valueRangeDropdownOptions}
                          onValueRangeChange={(values: EventValueRange[]): void => {
                            let tempValueRanges = [
                              ...this.state.valueRanges.filter((item: EventValueRange) =>
                                values.includes(item)
                              ),
                            ];
                            this.setState({
                              selectedValueRanges: tempValueRanges ?? [],
                            });
                          }}
                          selectedValueRanges={this.state.selectedValueRanges}
                        />
                      </Grid>
                      {this.state.showAverage && <AveragePeriodPicker />}
                      <Grid item>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={this.state.showAverage}
                              onChange={this.toggleShowAverage}
                            />
                          }
                          label={this.props.t('show_average')}
                          labelPlacement="top"
                        />
                      </Grid>
                      {this.shouldDisplayReadingsSwitch() && (
                        <Grid item>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={this.state.shouldDisplayReadingsTable}
                                onChange={this.toggleReadingsTable}
                              />
                            }
                            label={this.props.t('show_readings')}
                            labelPlacement="top"
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container alignItems="center" justifyContent="center">
                <Grid item xs={12}>
                  <Divider className={classes.divider} />
                </Grid>
              </Grid>
              {!this.state.allDataLoaded && (
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  className={classes.statusContainer}
                >
                  <Grid item xs={12}>
                    <Typography>
                      <Box fontWeight={600}>Loading...</Box>
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {this.state.allDataLoaded && (
                <Grid container spacing={3}>
                  {this.state.shouldDisplayReadingsTable && this.state.portsConfig && (
                    <>
                      {this.renderGraphs(8)}
                      <Grid item sm={4} className={classes.readingsTableContainer}>
                        <SensorReadingsTable
                          chartData={chartData}
                          portsConfig={this.state.portsConfig}
                        />
                      </Grid>
                    </>
                  )}
                  {!this.state.shouldDisplayReadingsTable && <>{this.renderGraphs(12)}</>}
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Header>
    );
  };
}

interface GraphProps extends WithRouterProps, WithTranslation, WithStyles<typeof styles> {
  classes: { [key: string]: string };
}

interface GraphState {
  selectedAnalogSensorIndexes: number[];
  selectedDigitalSensorIndexes: number[];
  analogChartData: TimeSeriesChartData[];
  digitalChartData: TimeSeriesChartData[];
  valueRanges: EventValueRange[];
  valueRangeDropdownOptions: EventValueRange[];
  selectedValueRanges: EventValueRange[];
  analogSensorValueRanges: EventValueRange[];
  digitalSensorValueRanges: EventValueRange[];
  portsConfig: Record<number, Port>;
  confEvery: number;
  confPeriod: 'SECONDS' | 'MINUTES' | 'HOURS' | 'DAYS' | null;
  every: number;
  timeZone: string;
  showAverage: boolean;
  allDataLoaded: boolean;
  isCumulative: boolean;
  graphMode: GraphMode;
  shouldDisplayReadingsTable: boolean;
}

const mapStateToProps = (state: ReduxState): { selectedDevice: Device } => {
  return {
    selectedDevice: state.dev.selectedDevice,
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    actions
  )(withStyles(styles, { withTheme: true })(withTranslation()(Graph)))
);
