import React, { Component } from 'react';
import 'react-select/dist/react-select.css';
import deviceCountersStatisticsClient from './DeviceCountersStatisticsClient';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import TextField from '@mui/material/TextField';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { FormGroup, ControlLabel, FormControl } from 'react-bootstrap';
import CircularProgress from '@mui/material/CircularProgress';
import * as actions from './actions';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';

class MultiDeviceCounterStatistics extends Component {
  constructor(props) {
    super(props);
    this.state = { data: null, description: null, every: 5 };
    this.loadDeviceCountersStatisticsFromServer =
      this.loadDeviceCountersStatisticsFromServer.bind(this);
    this.handleFromDateChanged = this.handleFromDateChanged.bind(this);
    this.handleToDateChanged = this.handleToDateChanged.bind(this);
    this.handleApplyButtonClick = this.handleApplyButtonClick.bind(this);
    this.handleEveryChange = this.handleEveryChange.bind(this);
    this.errorCallback = this.errorCallback.bind(this);
    this.orientationDidChange = this.orientationDidChange.bind(this);
    this.mql = window.matchMedia('(orientation: portrait)');
  }

  errorCallback(error) {
    if (error.response === undefined) {
      throw error;
    }
    if (error.response.status === 401 || error.response.status === 403) {
      this.props.history.push('/app/signout');
    } else {
      alert('Error: ' + error.response.data);
      this.setState({ data: [] });
    }
  }

  handleFromDateChanged(event, date) {
    this.props.periodFromDateChanged(date);
  }

  handleToDateChanged(event, date) {
    this.props.periodToDateChanged(date);
  }

  handleApplyButtonClick() {
    this.loadDeviceCountersStatisticsFromServer();
  }

  handleEveryChange = (event) => {
    let every = event.target.value;
    this.setState({
      every: every,
    });
    this.loadDeviceCountersStatisticsFromServer();
  };

  orientationDidChange(orientationQuery) {
    this.setState({ isLendscapeOrientation: !orientationQuery.matches });
  }
  render() {
    const wellStyles = { maxWidth: 1200, margin: '0 auto 10px' };
    let options = {};
    //let isLendscapeOrientation = orientationQuery.matches;
    //console.log("Device held " + ( isLendscapeOrientation ? "horizontally" : "vertically"));
    if (this.state.data != null) {
      var selectRowProp = {
        hideSelectColumn: true,
      };
      return (
        <div className="well" style={wellStyles}>
          <p>Device counters, id: {this.props.match.params.deviceNumber}</p>
          <p>{this.state.description}</p>
          <Grid>
            <Row>
              <Col sm={2} md={2} lg={2}>
                <FormGroup controlId="Every">
                  <ControlLabel>Every, min</ControlLabel>
                  <FormControl
                    value={this.state.every}
                    componentClass="select"
                    placeholder="every"
                    onChange={this.handleEveryChange.bind(this)}
                  >
                    <option value={1}>1</option>
                    <option value={5}>5</option>
                    <option value={10}>10</option>
                    <option value={15}>15</option>
                    <option value={30}>30</option>
                    <option value={60}>60</option>
                  </FormControl>
                </FormGroup>
              </Col>

              <Col sm={3} md={2} lg={2}>
                <ControlLabel>From Date</ControlLabel>
                <TextField
                  type="date"
                  onChange={this.handleFromDateChanged}
                  value={this.props.fromDate}
                />
              </Col>
              <Col sm={3} md={2} lg={2}>
                <ControlLabel>To Date</ControlLabel>
                <TextField
                  type="date"
                  onChange={this.handleToDateChanged}
                  value={this.props.toDate}
                />
              </Col>
              <Col sm={3} md={2} lg={2}>
                <Button variant="raised" onClick={this.handleApplyButtonClick.bind(this)}>
                  Apply
                </Button>
              </Col>
            </Row>
          </Grid>
          <BootstrapTable
            ref="table"
            data={this.state.data}
            selectRow={selectRowProp}
            striped={true}
            hover={true}
            options={options}
          >
            <TableHeaderColumn isKey={true} dataField="number" dataAlign="center" width="10%">
              Number
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="businessPartnerNumber"
              className="hidden-xs-portrait"
              columnClassName="hidden-xs-portrait"
              dataAlign="center"
              width="15%"
            >
              BusinessPartner
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="address"
              dataAlign="center"
              width="15%"
              className="hidden-xs-portrait"
              columnClassName="hidden-xs-portrait"
            >
              Address
            </TableHeaderColumn>
            <TableHeaderColumn dataField="avg" dataAlign="center" width="10%">
              Average
            </TableHeaderColumn>
            <TableHeaderColumn dataField="min" dataAlign="center" width="10%">
              Min
            </TableHeaderColumn>
            <TableHeaderColumn dataField="max" dataAlign="center" width="10%">
              Max
            </TableHeaderColumn>
            <TableHeaderColumn dataField="total" dataAlign="center" width="10%">
              Total
            </TableHeaderColumn>
            <TableHeaderColumn dataField="hours" dataAlign="center" width="10%">
              Hours
            </TableHeaderColumn>
            <TableHeaderColumn dataField="counters" dataAlign="center" width="10%">
              Counters
            </TableHeaderColumn>
          </BootstrapTable>
        </div>
      );
    } else {
      return (
        <div>
          <CircularProgress size={80} thickness={5} />
        </div>
      );
    }
  }

  componentWillMount() {
    var isPortraitOrientation = this.mql.matches;
    this.setState({ isLendscapeOrientation: !isPortraitOrientation });
  }
  componentDidMount() {
    this.mql.addListener(this.orientationDidChange);
    this.loadDeviceCountersStatisticsFromServer();
  }

  componentWillUnmount() {
    this.mql.removeListener(this.orientationDidChange);
  }

  loadDeviceCountersStatisticsFromServer() {
    let fromDate = this.props.fromDate;
    let toDate = this.props.toDate;
    let tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
    fromDate.setHours(0, 0, 0, 0);
    toDate.setHours(23, 59, 59, 999);
    let fromDateISO = new Date(fromDate - tzoffset).toISOString();
    let toDateISO = new Date(toDate - tzoffset).toISOString();
    deviceCountersStatisticsClient.getDevicesCountersStatisticsGeneral(
      this.props.devices,
      fromDateISO,
      toDateISO,
      this.state.every,
      (datas) => {
        let arr = Object.keys(datas).map(function (k) {
          let data = datas[k];
          return {
            number: data.device.number,
            businessPartnerNumber: data.device.customerInfo.businessPartnerNumber,
            address: data.device.customerInfo.address,
            min: data.value.min,
            max: data.value.max,
            avg: data.value.avg,
            total: data.value.total,
            hours: data.value.hours,
            counters: data.value.counters,
          };
        });

        this.setState({
          data: arr,
        });
      },
      this.errorCallback
    );
  }
}

function mapStateToProps(state) {
  return {
    devices: state.dev.devices,
    fromDate: state.period.fromDate,
    toDate: state.period.toDate,
  };
}

export default connect(mapStateToProps, actions)(MultiDeviceCounterStatistics);
