import { DisplayTimeUnit, DisplayUnit, Sensor } from '@thingslog/repositories';
import GenericDigitalSensor from '@thingslog/repositories/src/sensor/GenericDigitalSensor';
import GenericAnalogSensor from '@thingslog/repositories/src/sensor/GenericAnalogSensor';
import OnOffSensor from '@thingslog/repositories/src/sensor/OnOffSensor';

export class SensorsUtil {
  public static getSensorUnits = (sensor: Sensor): string => {
    let units: string;
    const isGenericDigitalSensor = this.isGenericDigitalSensor(sensor);
    const flowUnit = sensor.flowUnit;

    if (
      isGenericDigitalSensor &&
      flowUnit &&
      flowUnit.timeUnits !== null &&
      flowUnit.units !== null
    ) {
      units =
        DisplayUnit[flowUnit.units] +
        (flowUnit.timeUnits ? `/${DisplayTimeUnit[flowUnit.timeUnits]}` : '');
    } else {
      units = DisplayUnit[sensor.units] || '';
    }

    return units;
  };

  public static isGenericDigitalSensor = (sensor: Sensor): sensor is GenericDigitalSensor => {
    const sensorType = sensor['@type'];
    return (
      sensorType === 'generic_digital_sensor' ||
      sensorType === 'gas_flow_meter' ||
      sensorType === 'geiger_mueller_counter' ||
      sensorType === 'heat_meter' ||
      sensorType === 'power_meter' ||
      sensorType === 'rain_sensor' ||
      sensorType === 'water_meter' ||
      sensorType === 'wind_speed_sensor'
    );
  };

  public static isGenericAnalogSensor = (sensor: Sensor): sensor is GenericAnalogSensor => {
    const sensorType = sensor['@type'];
    return (
      sensorType === 'generic_analog_sensor' ||
      sensorType === 'barometric_pressure_sensor' ||
      sensorType === 'dew_point_sensor' ||
      sensorType === 'humidity_sensor' ||
      sensorType === 'leaf_wetness_sensor' ||
      sensorType === 'level_sensor' ||
      sensorType === 'load_cells' ||
      sensorType === 'particulate_matter_sensor' ||
      sensorType === 'pressure_sensor' ||
      sensorType === 'soil_moisture_sensor' ||
      sensorType === 'soil_potential_sensor' ||
      sensorType === 'soil_temperature_sensor' ||
      sensorType === 'temperature_sensor' ||
      sensorType === 'weight_meter' ||
      sensorType === 'weight_sensor' ||
      sensorType === 'wind_direction_sensor'
    );
  };

  public static isOnOffSensor = (sensor: Sensor): sensor is OnOffSensor => {
    const sensorType = sensor['@type'];
    return (
      sensorType === 'on_off_sensor' ||
      sensorType === 'on_off_auto_output_sensor' ||
      sensorType === 'on_off_input_sensor' ||
      sensorType === 'on_off_output_sensor'
    );
  };
}
