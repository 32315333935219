import React, { FC, useState } from 'react';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useTranslation } from 'react-i18next';
import { WarningStatus } from '../License';
import { Button } from '@mui/material';
import MailIcon from '@mui/icons-material/Mail';

const WarningBox: FC<WarningBoxProps> = ({ warningStatus }: WarningBoxProps) => {
  const { t } = useTranslation();

  return (
    <div className="flex w-1/2 bg-red-200 p-5 items-start">
      <div className="p-1">
        <ErrorOutlineIcon className="text-3xl text-red-600 mr-5" />
      </div>
      <div className="text-left">
        {warningStatus.noActiveLicense && <h4>{t('license_warning_access_hold')}</h4>}
        {warningStatus.noActiveLicense && <p>{t('license_warning_access_hold_description')}</p>}
        {warningStatus.noValidLicense && <h4>{t('license_warning_no_valid_license')}</h4>}
        {warningStatus.noValidLicense && <p>{t('license_warning_no_valid_license_description')}</p>}
        {warningStatus.devicesCountLimitReached && (
          <h4>{t('license_warning_devices_count_limit_reached')}</h4>
        )}
        {warningStatus.devicesCountLimitReached && (
          <p>{t('license_warning_devices_count_limit_reached_description')}</p>
        )}
        <Button
          color="primary"
          startIcon={<MailIcon />}
          href="mailto:sales@thingslog.com"
          className="text-gray-900 font-bold"
        >
          sales@thingslog.com
        </Button>
      </div>
    </div>
  );
};

interface WarningBoxProps {
  warningStatus: WarningStatus;
}

export default WarningBox;
