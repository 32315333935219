import React, { Component } from 'react';
import 'react-select/dist/react-select.css';
import companiesClient from './CompaniesClient';
import timeZonesClient from './TimeZonesClient';
import { FormGroup, ControlLabel, FormControl } from 'react-bootstrap';
import Snackbar from '@mui/material/Snackbar';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Header from './components/header';

import { withRouter } from './common/withRouter';
import connect from 'react-redux/es/connect/connect';
import * as actions from './actions';
import { withTranslation } from 'react-i18next';

import { AccountSolution } from '@thingslog/repositories';

class CompanyManager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: '',
      open: false,
      buttonsDisabled: false,
      companyName: '',
      license: '',
      solution: null,
      timezones: [],
      timezoneId: 'UTC',
    };
    this.handleCreateCompany = this.handleCreateCompany.bind(this);
    this.handleDeleteCompany = this.handleDeleteCompany.bind(this);
    this.handleCompanyCreated = this.handleCompanyCreated.bind(this);
    this.handleCompanyDeleted = this.handleCompanyDeleted.bind(this);
    this.handleLoadTimezones = this.handleLoadTimezones.bind(this);
    this.handleRequestClose = this.handleRequestClose.bind(this);
    this.handleCompanyNameChange = this.handleCompanyNameChange.bind(this);
    this.handleCompanySolutionChange = this.handleCompanySolutionChange.bind(this);
    this.handleLicenseChange = this.handleLicenseChange.bind(this);
    this.errorCallback = this.errorCallback.bind(this);
  }

  errorCallback(error) {
    if (error.response === undefined) {
      throw error;
    }
    if (error.response.status === 401) {
      this.props.history.push('/app/signout');
    } else if (error.response.status === 403) {
      this.setState({ message: 'You are not authorized', open: true, buttonsDisabled: false });
    } else {
      this.setState({
        message: 'Error: ' + error.response.data.message,
        open: true,
        buttonsDisabled: false,
      });
    }
  }

  handleCompanyNameChange = (ev) => this.setState({ companyName: ev.target.value });
  handleLicenseChange = (ev) => this.setState({ license: ev.target.value });

  handleCreateCompany(ev) {
    this.setState({
      open: true,
      message: this.props.t('company_manager_creating_account', {
        company_name: this.state.companyName,
      }),
      buttonsDisabled: true,
    });
    companiesClient.postCompany(
      {
        name: this.state.companyName,
        parentId: this.props.company.id,
        license: this.state.license,
        solution: this.state.solution,
        zoneId: this.state.timezoneId,
      },
      this.handleCompanyCreated,
      this.errorCallback
    );
    ev.preventDefault();
  }

  handleCompanyCreated() {
    this.setState({
      message: this.props.t('company_manager_account_created'),
      open: true,
      buttonsDisabled: false,
    });
  }

  handleCompanyDeleted() {
    this.setState({
      message: this.props.t('company_manager_account_deleted'),
      open: true,
      buttonsDisabled: false,
    });
    this.props.companySelected({ id: null, name: null });
  }

  handleDeleteCompany(ev) {
    this.setState({
      open: true,
      message: this.props.t('company_manager_deleting_account', {
        company_name: this.state.companyName,
      }),
      buttonsDisabled: true,
    });
    companiesClient.deleteCompany(
      this.state.companyName,
      this.handleCompanyDeleted,
      this.errorCallback
    );
    ev.preventDefault();
  }

  handleLoadTimezones = () => {
    timeZonesClient.getTimeZones((timeZones) => {
      this.setState({ timeZones });
    });
  };

  handleCompanySolutionChange = (ev) => this.setState({ solution: ev.target.value });

  handleRequestClose = () => {
    this.setState({ open: false });
  };

  render() {
    const wellStyles = { maxWidth: 400, margin: '0 auto 10px' };
    return (
      <Header>
        <div className="well" style={wellStyles}>
          <p>
            {this.props.t('company_manager_title', {
              company_name: this.props.match.params.companyName,
            })}
          </p>
          <form>
            <FormGroup controlId="Company Name">
              <ControlLabel>{this.props.t('company_manager_account_name')}</ControlLabel>
              <FormControl
                value={this.state.companyName}
                type="text"
                placeholder={this.props.t('company_manager_account_name')}
                onChange={this.handleCompanyNameChange}
              />
            </FormGroup>
            <FormGroup controlId="Company Solution">
              <ControlLabel>{this.props.t('company_manager_solution')}</ControlLabel>
              <FormControl
                value={this.state.solution}
                componentClass="select"
                placeholder={this.props.t('company_manager_solution')}
                onChange={this.handleCompanySolutionChange.bind(this)}
              >
                <option value={null}>{this.props.t('company_manager_solution_option_none')}</option>
                <option value={AccountSolution.AGRI}>
                  {this.props.t('company_manager_solution_option_agri')}
                </option>
                <option value={AccountSolution.AIR_QUALITY}>
                  {this.props.t('company_manager_solution_option_air_quality')}
                </option>
                <option value={AccountSolution.STREET_LIGHTS}>
                  {this.props.t('company_manager_solution_option_street_lights')}
                </option>
                <option value={AccountSolution.PUMP_STATION}>
                  {this.props.t('company_manager_solution_option_pump_station')}
                </option>
              </FormControl>
            </FormGroup>
            <FormGroup controlId="License">
              <ControlLabel>{this.props.t('company_manager_license')}</ControlLabel>
              <FormControl
                value={this.state.license}
                type="text"
                componentClass={'textarea'}
                placeholder={this.props.t('company_manager_license')}
                rows={10}
                onChange={this.handleLicenseChange}
              />
            </FormGroup>
            <Button onClick={this.handleCreateCompany} disabled={this.state.buttonsDisabled}>
              {this.props.t('button_create')}
            </Button>
            <Button onClick={this.handleDeleteCompany} disabled={this.state.buttonsDisabled}>
              {this.props.t('button_delete')}
            </Button>
            {this.state.timeZones && (
              <Autocomplete
                defaultValue={this.state.timezoneId}
                className="w-full"
                options={this.state.timeZones}
                value={this.state.timezoneId}
                onChange={(event, newValue) => this.setState({ timezoneId: newValue })}
                renderInput={(params) => (
                  <TextField
                    required
                    {...params}
                    error={!this.state.timezoneId}
                    label={this.props.t('company_manager_timezone')}
                    size="small"
                    className="bg-white"
                  />
                )}
              />
            )}

            <Snackbar
              open={this.state.open}
              message={this.state.message}
              autoHideDuration={4000}
              onClose={this.handleRequestClose}
            />
          </form>
        </div>
      </Header>
    );
  }

  componentDidMount() {
    this.handleLoadTimezones();
  }

  componentWillUnmount() {}
}

function mapStateToProps(state) {
  return {
    company: state.company,
  };
}

export default withRouter(connect(mapStateToProps, actions)(withTranslation()(CompanyManager)));
