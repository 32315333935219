import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../actions/';
import deviceCountersStatisticsClient from '../DeviceCountersStatisticsClient';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography/Typography';
import { withTranslation } from 'react-i18next';

export class SensorStatistics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      min: null,
      max: null,
      avg: null,
      total: null,
      isLoaded: false,
    };
    this.errorCallback = this.errorCallback.bind(this);
  }

  async componentDidMount() {
    let fromDate = this.props.fromDate;
    let toDate = this.props.toDate;
    fromDate.setHours(0, 0, 0, 0);
    toDate.setHours(23, 59, 59, 999);
    let tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
    let fromDateISO = new Date(fromDate - tzoffset).toISOString();
    let toDateISO = new Date(toDate - tzoffset).toISOString();

    await deviceCountersStatisticsClient.getDeviceCountersStatisticsGeneral(
      this.props.deviceNumber,
      this.props.sensorIndex,
      fromDateISO,
      toDateISO,
      this.props.every,
      (data) => {
        this.setState({
          min: data.min,
          max: data.max,
          avg: data.avg,
          total: data.total,
          isLoaded: true,
        });
      },
      this.errorCallback
    );

    if (this.props.flowUnit === 'l/s') {
      this.setState({
        min: (this.state.min * 1000) / 3600,
        max: (this.state.max * 1000) / 3600,
        avg: (this.state.avg * 1000) / 3600,
      });
    }
  }

  errorCallback(error) {
    if (error.response === undefined) {
      throw error;
    }
    if (error.response.status === 401 || error.response.status === 403) {
      this.props.history.push('/app/signout');
    } else {
      if (error.response.data.message) {
        alert('Error: ' + error.response.data.message);
      } else {
        alert('Error: ' + error.response.data);
      }
    }
  }

  render() {
    const { isLoaded } = this.state;
    return (
      <div>
        <Grid container spacing={4} justifyContent="center" item alignItems="flex-start">
          <React.Fragment>
            <Grid item>
              <Typography component="p">
                {isLoaded ? (
                  this.props.t('sensor_statistics_min', {
                    min: this.state.min.toFixed(3),
                  })
                ) : (
                  <>
                    {this.props.t('sensor_statistics_min')}
                    <CircularProgress size={20} thickness={5} />
                  </>
                )}
              </Typography>
            </Grid>
            <Grid item>
              <Typography component="p">
                {isLoaded ? (
                  this.props.t('sensor_statistics_max', {
                    max: this.state.max.toFixed(3),
                  })
                ) : (
                  <>
                    {this.props.t('sensor_statistics_max')}
                    <CircularProgress size={20} thickness={5} />
                  </>
                )}
              </Typography>
            </Grid>
            <Grid item>
              <Typography component="p">
                {isLoaded ? (
                  this.props.t('sensor_statistics_avg', {
                    avrg: this.state.avg.toFixed(3),
                  })
                ) : (
                  <>
                    {this.props.t('sensor_statistics_avg')}
                    <CircularProgress size={20} thickness={5} />
                  </>
                )}
              </Typography>
            </Grid>
            {this.props.unit && (
              <Grid item>
                <Typography component="p">
                  {isLoaded ? (
                    this.props.t('sensor_statistics_total', {
                      total: `${this.state.total.toFixed(3)} ${this.props.unit}`,
                    })
                  ) : (
                    <>
                      {this.props.t('sensor_statistics_total')}
                      <CircularProgress size={20} thickness={5} />
                    </>
                  )}
                </Typography>
              </Grid>
            )}
          </React.Fragment>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  fromDate: state.period.fromDate,
  toDate: state.period.toDate,
  sensorIndex: state.dev.sensorIndex,
});

export default connect(mapStateToProps, actions)(withTranslation()(SensorStatistics));
