import React, { Component } from 'react';
import 'react-select/dist/react-select.css';
import devicesClient from './DevicesClient';
import deviceConfigClient from './DeviceConfigClient';
import deviceCountersStatisticsClient from './DeviceCountersStatisticsClient';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import CircularProgress from '@mui/material/CircularProgress';
import * as actions from './actions';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import Header from './components/header';
import { withRouter } from './common/withRouter';
import Typography from '@mui/material/Typography/Typography';
import PeriodPicker from './components/DatePicker/PeriodPicker';
import SensorSelector from './components/SensorSelector';
import Grid from '@mui/material/Grid/Grid';
import Select from '@mui/material/Select/Select';
import InputLabel from '@mui/material/InputLabel/InputLabel';
import withStyles from '@mui/styles/withStyles';
import FormControl from '@mui/material/FormControl';
import sensorsConfigClient from './SensorsConfigClient';
import { withTranslation } from 'react-i18next';

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(2),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  menu: {
    width: 200,
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  errorMessage: {
    color: 'red',
    float: 'left',
  },
});

const sensorType = {
  GENERIC_DIGITAL: 'generic_digital_sensor',
  WATER: 'water_meter',
  GAS_FLOW: 'gas_flow_meter',
  POWER: 'power_meter',
  GENERIC_ANALOG: 'generic_analog_sensor',
  PRESSURE: 'pressure_sensor',
  HUMIDITY: 'humidity_sensor',
  TEMPERATURE: 'temperature_sensor',
  LEVEL: 'level_sensor',
  WEIGHT: 'weight_meter',
  HEAT: 'heat_meter',
  ON_OFF: 'on_off_sensor',
  WEIGHT_SENSOR: 'weight_sensor',
  LOAD_CELLS: 'load_cells',
  RAIN: 'rain_sensor',
  WIND_SPEED: 'wind_speed_sensor',
  WIND_DIRECTION: 'wind_direction_sensor',
  BAROMETRIC_PRESURE: 'barometric_presure_sensor',
  SOIL_MOISTURE: 'soil_moisture_sensor',
  HEAT: 'heat_meter',
  DEW_POINT: 'dew_point_sensor',
  SOIL_TEMPERATURE: 'soil_temperature_sensor',
  SOIL_POTENTIAL: 'soil_potential_sensor',
  PARTICULATE_MATTER: 'particulate_matter_sensor',
  GEIGER_COUNTER: 'geiger_mueller_counter',
};

class DeviceCountersStatistics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      perFlow: null,
      perQFlow: null,
      perTime: null,
      every: 5,
      general: null,
      description: null,
      sensorIndex: 0,
    };
    this.loadDeviceCountersStatisticsFromServer =
      this.loadDeviceCountersStatisticsFromServer.bind(this);
    this.handleFromDateChanged = this.handleFromDateChanged.bind(this);
    this.handleToDateChanged = this.handleToDateChanged.bind(this);
    this.handleApplyButtonClick = this.handleApplyButtonClick.bind(this);
    this.handleEveryChange = this.handleEveryChange.bind(this);
    this.errorCallback = this.errorCallback.bind(this);
  }

  handleSensorChange = (event) => this.setState({ sensorIndex: event.target.value });

  errorCallback(error) {
    if (error.response === undefined) {
      throw error;
    }
    if (error.response.status === 401 || error.response.status === 403) {
      this.props.history.push('/app/signout');
    } else {
      if (error.response.data.message) {
        alert('Error: ' + error.response.data.message);
      } else {
        alert('Error: ' + error.response.data);
      }
      this.setState({ perQFlow: [], perFlow: [], perTime: [], perQTime: [], general: [] });
    }
  }

  getWaterMeterSensors = () => {
    if (!this.state.portsConfig) {
      return [];
    }
    const allPorts = Object.keys(this.state.portsConfig).map((key) => this.state.portsConfig[key]);
    const waterMeterSensors = allPorts
      .filter((port) => {
        if (port.enabled && port.sensor['@type'] === sensorType.WATER) {
          return port;
        }
      })
      .map((port) => {
        return port.sensor;
      });

    return waterMeterSensors;
  };

  hasMeterInfo = (waterMeterSensors) => {
    let hasMeterInfo = true;
    waterMeterSensors.forEach((sensor) => {
      if (
        sensor.q3 === null ||
        sensor.q3 === undefined ||
        sensor.r === null ||
        sensor.r === undefined ||
        sensor.diameter === null ||
        sensor.diameter === undefined
      ) {
        hasMeterInfo = false;
      }
    });
    return hasMeterInfo;
  };

  handleFromDateChanged(event, date) {
    this.props.periodFromDateChanged(date);
  }

  handleToDateChanged(event, date) {
    this.props.periodToDateChanged(date);
  }

  handleApplyButtonClick() {
    this.loadDeviceCountersStatisticsFromServer();
  }

  handleEveryChange = (event) => {
    let every = event.target.value;
    this.setState({
      every: every,
    });
    this.loadDeviceCountersStatisticsFromServer();
  };

  render() {
    const wellStyles = { maxWidth: 720, margin: '0 auto 10px' };
    const waterMeterSensors = this.getWaterMeterSensors();
    const { classes } = this.props;
    let options = {};
    if (this.state.perFlow != null && this.state.perTime != null) {
      return (
        <Header>
          <div className="well" style={wellStyles}>
            <Grid container justifyContent={'center'} direction="column">
              <Grid item>
                <Typography variant="h6" component="h5" paragraph>
                  {this.props.t('device_counter_statistics_statistics_id', {
                    device_number: this.props.match.params.deviceNumber,
                    description: this.state.description,
                  })}
                </Typography>
              </Grid>

              <Grid container justifyContent="center" alignItems="flex-end" spacing={2}>
                <PeriodPicker alwaysShowDatePickers />
                <Grid item>
                  <Button variant="contained" onClick={this.handleApplyButtonClick.bind(this)}>
                    {this.props.t('button_apply')}
                  </Button>
                </Grid>
              </Grid>

              <Grid
                container
                spacing={2}
                justifyContent="center"
                alignItems="center"
                style={{ marginTop: 5, marginBottom: 5 }}
              >
                <Grid item>
                  <Typography color="textSecondary">{this.props.t('every')}</Typography>
                </Grid>
                <Grid item>
                  <FormControl>
                    <Select
                      value={this.state.every}
                      onChange={this.handleEveryChange}
                      style={{ width: 100 }}
                      inputProps={{
                        name: 'quarter',
                        id: 'select-quarter',
                      }}
                    >
                      <option value={1}>{this.state.confEvery * 1} min</option>
                      <option value={2}>{this.state.confEvery * 2} min</option>
                      <option value={3}>{this.state.confEvery * 3} min</option>
                      <option value={5}>{this.state.confEvery * 5} min</option>
                      <option value={10}>{this.state.confEvery * 10} min</option>
                      <option value={15}>{this.state.confEvery * 15} min</option>
                      <option value={30}>{this.state.confEvery * 30} min</option>
                      <option value={60}>{this.state.confEvery * 60} min</option>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item>
                  <SensorSelector portsConfig={this.state.portsConfig} />
                </Grid>
              </Grid>
            </Grid>
            <BootstrapTable
              ref="table"
              data={
                waterMeterSensors.length > 0 && this.hasMeterInfo(waterMeterSensors)
                  ? this.state.perQFlow
                  : [{ range: '', value: '', percent: '' }]
              }
              striped={true}
              hover={true}
              options={options}
            >
              <TableHeaderColumn isKey={true} hidden={true} dataField="range">
                id
              </TableHeaderColumn>
              <TableHeaderColumn dataField="range" dataAlign="center">
                {this.props.t('device_counter_statistics_flow')}
              </TableHeaderColumn>
              <TableHeaderColumn dataField="value" dataAlign="center">
                m^3
              </TableHeaderColumn>
              <TableHeaderColumn dataField="percent" dataAlign="center">
                %
              </TableHeaderColumn>
            </BootstrapTable>
            <Typography className={classes.errorMessage}>
              {waterMeterSensors.length == 0 &&
                this.props.t('device_counter_statistics_digital_ports_must_be_present')}
              {!this.hasMeterInfo(waterMeterSensors) &&
                this.props.t('device_counter_statistics_must_have_initialized_params')}
            </Typography>
            <BootstrapTable
              ref="table"
              data={this.state.perFlow}
              striped={true}
              hover={true}
              options={options}
            >
              <TableHeaderColumn isKey={true} hidden={true} dataField="range">
                id
              </TableHeaderColumn>
              <TableHeaderColumn dataField="range" dataAlign="center">
                {this.props.t('device_counter_statistics_flow')} [m^3/h]
              </TableHeaderColumn>
              <TableHeaderColumn dataField="value" dataAlign="center">
                m^3
              </TableHeaderColumn>
              <TableHeaderColumn dataField="percent" dataAlign="center">
                %
              </TableHeaderColumn>
            </BootstrapTable>
            <br />
            <BootstrapTable
              ref="table"
              data={this.state.perTime}
              striped={true}
              hover={true}
              options={options}
            >
              <TableHeaderColumn isKey={true} hidden={true} dataField="range">
                id
              </TableHeaderColumn>
              <TableHeaderColumn dataField="range" dataAlign="center">
                {this.props.t('device_counter_statistics_hour')}
              </TableHeaderColumn>
              <TableHeaderColumn dataField="value" dataAlign="center">
                m^3
              </TableHeaderColumn>
              <TableHeaderColumn dataField="percent" dataAlign="center">
                %
              </TableHeaderColumn>
            </BootstrapTable>
            <br />
            <BootstrapTable
              ref="table"
              data={this.state.general}
              striped={true}
              hover={true}
              options={options}
            >
              <TableHeaderColumn isKey={true} hidden={true} dataField="range">
                id
              </TableHeaderColumn>
              <TableHeaderColumn dataField="name" dataAlign="center">
                {this.props.t('device_counter_statistics_quantity')}
              </TableHeaderColumn>
              <TableHeaderColumn dataField="value" dataAlign="center">
                {this.props.t('device_counter_statistics_value')}
              </TableHeaderColumn>
            </BootstrapTable>
          </div>
        </Header>
      );
    } else {
      return (
        <Header>
          <div>
            <CircularProgress size={80} thickness={5} />
          </div>
        </Header>
      );
    }
  }

  componentDidMount() {
    this.loadDeviceCountersStatisticsFromServer();
  }

  componentWillUnmount() {}

  loadDeviceCountersStatisticsFromServer() {
    let fromDate = this.props.fromDate;
    let toDate = this.props.toDate;
    let tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
    fromDate.setHours(0, 0, 0, 0);
    toDate.setHours(23, 59, 59, 999);
    let fromDateISO = new Date(fromDate - tzoffset).toISOString();
    let toDateISO = new Date(toDate - tzoffset).toISOString();
    devicesClient.getDevice(
      this.props.match.params.deviceNumber,
      (device) => {
        sensorsConfigClient.getPortsConfig(
          this.props.match.params.deviceNumber,
          (portsConfig) => {
            deviceConfigClient.getDeviceConfig(
              this.props.match.params.deviceNumber,
              (conf) => {
                let every = this.state.every;
                deviceCountersStatisticsClient.getDeviceCountersStatisticsFlow(
                  this.props.match.params.deviceNumber,
                  this.state.sensorIndex,
                  fromDateISO,
                  toDateISO,
                  this.state.every,
                  (data) => {
                    let perFlow = [];
                    for (let i = 0; i < data.nbins - 1; i++) {
                      perFlow[i] = {
                        range: data.edges[i] + ' - ' + data.edges[i + 1],
                        value: data.values[i].toFixed(2),
                        percent: ((100 * data.values[i]) / data.total).toFixed(2),
                      };
                    }
                    deviceCountersStatisticsClient.getDeviceCountersStatisticsTime(
                      this.props.match.params.deviceNumber,
                      this.state.sensorIndex,
                      fromDateISO,
                      toDateISO,
                      (data) => {
                        let perTime = [];
                        for (let i = 0; i < data.nbins - 1; i++) {
                          perTime[i] = {
                            range: data.edges[i] + ' - ' + data.edges[i + 1],
                            value: data.values[i].toFixed(2),
                            percent: ((100 * data.values[i]) / data.total).toFixed(2),
                          };
                        }
                        deviceCountersStatisticsClient.getDeviceCountersStatisticsQFlow(
                          this.props.match.params.deviceNumber,
                          this.state.sensorIndex,
                          fromDateISO,
                          toDateISO,
                          this.state.every,
                          (data) => {
                            let perQFlow = [];
                            for (let i = 0; i < data.nbins - 1; i++) {
                              perQFlow[i] = {
                                range:
                                  (i > 0 ? 'Q' + i : 0) +
                                  ' - ' +
                                  (i + 1 < data.nbins - 1 ? 'Q' + (i + 1) : 'Infinity'),
                                value: data.values[i].toFixed(2),
                                percent: ((100 * data.values[i]) / data.total).toFixed(2),
                              };
                            }
                            deviceCountersStatisticsClient.getDeviceCountersStatisticsGeneral(
                              this.props.match.params.deviceNumber,
                              this.state.sensorIndex,
                              fromDateISO,
                              toDateISO,
                              this.state.every,
                              (data) => {
                                let general = [];
                                let i = 0;
                                general[i++] = {
                                  name: 'min',
                                  value: data.min == null ? '' : data.min + ' m^3/h',
                                };
                                general[i++] = {
                                  name: 'max',
                                  value: data.max == null ? '' : data.max + ' m^3/h',
                                };
                                general[i++] = {
                                  name: 'avg',
                                  value: data.avg == null ? '' : data.avg + ' m^3/h',
                                };
                                general[i++] = {
                                  name: 'total',
                                  value: data.total == null ? '' : data.total + ' m^3',
                                };
                                general[i++] = {
                                  name: 'hours',
                                  value: data.hours == null ? '' : data.hours + ' h',
                                };
                                general[i++] = { name: 'counters', value: data.counters };

                                const description = [
                                  device.customerInfo?.name,
                                  device.customerInfo?.municipality,
                                  device.customerInfo?.quarter,
                                  device.customerInfo?.address,
                                ]
                                  .filter(Boolean)
                                  .join(', ');

                                this.setState({
                                  perQFlow: perQFlow,
                                  perFlow: perFlow,
                                  perTime: perTime,
                                  general: general,
                                  confEvery: conf.every,
                                  every: every,
                                  description: description,
                                  portsConfig: portsConfig,
                                });
                              },
                              this.errorCallback
                            );
                          },
                          this.errorCallback
                        );
                      },
                      this.errorCallback
                    );
                  },
                  this.errorCallback
                );
              },
              this.errorCallback
            );
          },
          this.errorCallback
        );
      },
      this.errorCallback
    );
  }
}

function mapStateToProps(state) {
  return {
    fromDate: state.period.fromDate,
    toDate: state.period.toDate,
  };
}

export default withRouter(
  connect(mapStateToProps, actions)(withStyles(styles)(withTranslation()(DeviceCountersStatistics)))
);
