import React, { Component, ReactNode } from 'react';
import Header from '../header';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import RegisterAsset from '../../resources/register_confirmation.png';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import { withRouter, WithRouterProps } from './../../common/withRouter';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import CircularProgress from '@mui/material/CircularProgress';
import ConfirmRegistrationClient from './ConfirmRegistrationClient';
import { AxiosResponse } from 'axios';
import ErrorUtil from '../../common/ErrorUtil';

class ConfirmRegistration extends Component<ConfirmRegistrationProps, ConfirmRegistrationState> {
  public constructor(props: ConfirmRegistrationProps) {
    super(props);
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.changeLanguage = this.changeLanguage.bind(this);
  }

  public state: ConfirmRegistrationState = {
    isLoaded: false,
    isSuccessful: false,
    language: 'en',
    dropdownOpen: false,
  };

  public async componentDidMount(): Promise<void> {
    const query = new URLSearchParams(this.props.location.search);
    const token = query.get('token');

    if (token) {
      ConfirmRegistrationClient.getRegistrationConfirmation(
        token,
        (response: AxiosResponse) => {
          this.setState({ isSuccessful: response.data.success, isLoaded: true });
        },
        ErrorUtil.handleError
      );
    } else {
      this.setState({ isLoaded: true });
    }
  }

  private toggleDropdown(): void {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  }

  private changeLanguage(event: SelectChangeEvent<string>, child: ReactNode): void {
    this.props.i18n.changeLanguage(event.target.value as string);
    this.setState({ language: event.target.value as string });
  }

  public render(): React.ReactNode {
    const { isSuccessful, isLoaded } = this.state;
    return (
      <Header>
        <Container maxWidth="md" style={{ minWidth: 300 }}>
          <Paper style={{ padding: 30 }}>
            <Grid container direction="row" justifyContent="flex-end">
              <Grid item>
                <Select
                  open={this.state.dropdownOpen}
                  onOpen={this.toggleDropdown}
                  onClose={this.toggleDropdown}
                  value={this.state.language}
                  onChange={this.changeLanguage}
                  variant="filled"
                >
                  <MenuItem value="en">English</MenuItem>
                  <MenuItem value="bg">Български</MenuItem>
                </Select>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <img src={RegisterAsset} width={80} alt="Register Asset" />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1" style={{ marginTop: 10 }}>
                  {this.props.i18n.t<string>('confirm_registration_welcome_to_thingslog')}
                </Typography>
                {isLoaded ? (
                  <Typography variant="h4" component="h1" style={{ marginTop: 5 }}>
                    <b>
                      {isSuccessful
                        ? this.props.i18n.t<string>('confirm_registration_completed')
                        : this.props.i18n.t<string>('confirm_registration_failed')}
                    </b>
                  </Typography>
                ) : (
                  <CircularProgress size={80} thickness={5} />
                )}
              </Grid>
              <Grid item xs={12}>
                <Divider style={{ marginTop: 20, marginBottom: 20 }} />
              </Grid>
            </Grid>
            {isLoaded && (
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant="body1">
                    {isSuccessful
                      ? this.props.i18n.t<string>('confirm_registration_completed_description')
                      : this.props.i18n.t<string>('confirm_registration_failed_description')}
                    <a href="mailto:support@thingslog.com">support@thingslog.com</a>
                  </Typography>
                </Grid>
              </Grid>
            )}
            <Grid container>
              {isLoaded && (
                <Grid item xs={12} style={{ marginTop: 15, marginBottom: 10 }}>
                  <Link to="/" style={{ textDecoration: 'none' }}>
                    <Button size="medium" variant="contained" color="primary">
                      {this.props.i18n.t<string>('button_continue')}
                    </Button>
                  </Link>
                </Grid>
              )}
              <Grid item xs={12}>
                <Button
                  size="small"
                  color="primary"
                  href="https://thingslog.com/"
                  target="_blank"
                  style={{ textDecoration: 'none' }}
                >
                  {this.props.i18n.t<string>('confirm_registration_visit_website')}
                </Button>
                <Button
                  size="small"
                  color="primary"
                  href="https://thingslog4u.com/"
                  target="_blank"
                  style={{ textDecoration: 'none' }}
                >
                  {this.props.i18n.t<string>('confirm_registration_visit_ecommerce')}
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Container>
      </Header>
    );
  }
}

interface ChangeLanguageEvent
  extends React.ChangeEvent<{
    name?: string | undefined;
    value: unknown;
  }> {}

interface ConfirmRegistrationProps extends WithRouterProps, WithTranslation {
  classes: { [key: string]: string };
}

interface ConfirmRegistrationState {
  isLoaded: boolean;
  isSuccessful: boolean;
  language: string;
  dropdownOpen: boolean;
}

export default withRouter(withTranslation()(ConfirmRegistration));
